{
  "name": "policaro-fe",
  "version": "1.2.120",
  "private": true,
  "repository": "git@bitbucket.org:usertech/policaro-fe.git",
  "author": "https://u.plus/",
  "scripts": {
    "build": "rm -rf dist && PARCEL_WORKERS=2 parcel build",
    "cdk:build": "yarn build",
    "cdk:bootstrap": "cdk bootstrap",
    "cdk:deploy": "cdk deploy --all --require-approval never --ci",
    "dev": "yarn openapi && parcel ./src/index.html",
    "dev:build": "rm -rf dist && rm -rf .parcel-cache && NODE_ENV=development PARCEL_WORKERS=6 parcel build --no-cache --no-optimize",
    "dev:serve": "parcel serve ./src/index.html",
    "dev:deploy-debug": "rm -rf src/go.rails.local:3300 && mkdir src/go.rails.local:3300 && mkdir src/go.rails.local:3300/__parcel_source_root && chmod 777 ./DeployDebug.sh && ./DeployDebug.sh",
    "dev:debug": "yarn openapi && yarn dev:build && yarn dev:deploy-debug && yarn dev:serve",
    "lint:check": "eslint --ext .js,.ts,.tsx .",
    "lint:fix": "npm run lint:check --fix",
    "openapi": "orval --config ./orval.config.ts",
    "prepare": "is-ci || husky install",
    "commit": "cz",
    "release": "release-it --ci -eVV",
    "tsc:check": "tsc --noEmit"
  },
  "lint-staged": {
    "packages/**/*.{js,ts?(x)}": [
      "eslint --fix"
    ],
    "packages/**/*.{js,ts,tsx}": [
      "prettier --write"
    ]
  },
  "resolutions": {
    "eslint-webpack-plugin": "2.3.0"
  },
  "dependencies": {
    "@emotion/cache": "^11.10.2",
    "@emotion/react": "^11.9.0",
    "@emotion/styled": "^11.8.1",
    "@fontsource/roboto": "^4.5.3",
    "@hookform/devtools": "^4.0.1",
    "@hookform/resolvers": "^2.8.2",
    "@mui/base": "5.0.0-alpha.96",
    "@mui/icons-material": "5.10.3",
    "@mui/lab": "5.0.0-alpha.98",
    "@mui/material": "5.10.4",
    "@mui/utils": "^5.9.3",
    "@mui/x-date-pickers": "^6.18.1",
    "@react-google-maps/api": "^2.7.0",
    "@reduxjs/toolkit": "^1.6.2",
    "@stripe/react-stripe-js": "^1.9.0",
    "@stripe/stripe-js": "^1.32.0",
    "axios": "^0.27.2",
    "date-fns": "^2.25.0",
    "dotenv": "^16.0.2",
    "react": "^17.0.2",
    "react-dom": "^17.0.2",
    "react-google-autocomplete": "^2.6.1",
    "react-hook-form": "^7.17.5",
    "react-intersection-observer": "^9.4.0",
    "react-intl": "^5.24.6",
    "react-phone-number-input": "^3.1.42",
    "react-query": "~3.31.0",
    "react-quill": "^1.3.5",
    "react-redux": "^7.2.6",
    "react-router": "^6.3.0",
    "react-router-dom": "^6.3.0",
    "universal-cookie": "^4.0.4",
    "web-vitals": "^1.0.1",
    "yup": "^0.32.11"
  },
  "devDependencies": {
    "@babel/core": "^7.15.8",
    "@parcel/compressor-gzip": "^2.5.0",
    "@parcel/reporter-bundle-analyzer": "^2.5.0",
    "@parcel/transformer-typescript-tsc": "^2.5.0",
    "@parcel/validator-typescript": "^2.5.0",
    "@release-it/conventional-changelog": "^5.0.0",
    "@types/node": "^17.0.31",
    "@types/react": "^17.0.0",
    "@types/react-dom": "^17.0.0",
    "@types/react-router": "^5.1.17",
    "@types/react-router-dom": "^5.3.2",
    "@types/webpack-env": "^1.16.4",
    "@typescript-eslint/eslint-plugin": "^5.21.0",
    "@typescript-eslint/parser": "^5.22.0",
    "@uplus/eslint-config-typescript": "1.1.2",
    "aws-cdk": "^2.23.0",
    "aws-cdk-lib": "^2.23.0",
    "commitizen": "^4.2.4",
    "constructs": "^10.1.4",
    "cypress": "^9.7.0",
    "cz-emoji-conventional": "^1.0.1",
    "eslint": "^7.32.0",
    "eslint-config-react-app": "^6.0.0",
    "eslint-plugin-cypress": "^2.12.1",
    "eslint-plugin-import": "^2.25.2",
    "eslint-plugin-jsx-a11y": "^6.4.1",
    "eslint-plugin-prettier": "^4.0.0",
    "eslint-plugin-react": "^7.26.1",
    "eslint-plugin-react-hooks": "^4.6.0",
    "husky": "^7.0.0",
    "is-ci": "^3.0.1",
    "lint-staged": "^11.2.3",
    "orval": "^6.7.1",
    "parcel": "^2.5.0",
    "parcel-plugin-static-files-copy": "^2.6.0",
    "parcel-resolver-tspaths": "^0.0.9",
    "prettier": "^2.6.2",
    "pretty-quick": "^3.1.1",
    "process": "^0.11.10",
    "release-it": "^15.0.0",
    "source-map-support": "^0.5.21",
    "ts-node": "^10.7.0",
    "typescript": "^4.6.4"
  },
  "autorun": "dist/index.html",
  "integration": "dist/integration.js",
  "targets": {
    "autorun": {
      "source": "src/index.html"
    },
    "integration": {
      "source": "src/index.tsx"
    }
  },
  "staticFiles": {
    "staticPath": "public",
    "watcherGlob": "**"
  }
}
