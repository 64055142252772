{
  "add": "Add",
  "add-away-slot": "New away slot",
  "add-customer-form-address": "Address",
  "add-customer-form-button-submit": "Submit",
  "add-customer-form-code": "Code",
  "add-customer-form-company-(optional)": "Company (optional)",
  "add-customer-form-driving-license": "Driving license",
  "add-customer-form-email": "Email",
  "add-customer-form-gender": "Gender",
  "add-customer-form-header": "New customer",
  "add-customer-form-insurance-company": "Insurance company",
  "add-customer-form-mobile-number": "Mobile Number",
  "add-customer-form-name": "Name",
  "add-customer-form-policy-number": "Policy number",
  "add-customer-form-province-issued-driving-license": "Region of driving license",
  "add-customer-form-select-advisor": "Select Advisor",
  "add-customer-form-service-advisor": "Service Advisor",
  "add-customer-form-unit-number-(optional)": "Unit number (optional)",
  "add-item": "New item",
  "advisor": "Service advisor",
  "amount": "Amount",
  "subtotal": "Subtotal",
  "billed-on": "Billed on",
  "paid-on": "Paid on",
  "amount-due": "Amount Due",
  "appointment": "Booking",
  "appointment-closed-on": "The invoice was processed on ",
  "appointment-detail": "Booking detail",
  "appointment-drawer-arrival-back-to-dealership": "Arrival back to dealership",
  "appointment-drawer-color": "Color",
  "appointment-drawer-customer": "Customer",
  "appointment-drawer-customer-vehicle-detail": "Customer Vehicle Detail",
  "appointment-drawer-departure-time": "Departure time",
  "appointment-drawer-destination-address": "Destination address",
  "appointment-drawer-detail": "Detail",
  "appointment-drawer-driver": "Driver",
  "appointment-drawer-drop-off-at": "Drop-off at",
  "appointment-drawer-drop-off-customer-at": "Drop-off customer at",
  "appointment-drawer-drop-off-vehicle-at": "Drop-off vehicle at",
  "appointment-drawer-from": "From",
  "appointment-drawer-loaner-detail": "Loaner Detail",
  "appointment-drawer-loaner-detail-inactive": "Inactive",
  "appointment-drawer-make": "Make",
  "appointment-drawer-model": "Model",
  "appointment-drawer-notes": "Notes",
  "appointment-drawer-ro-number": "RO Number",
  "appointment-drawer-ro-number-not-available": "N/A",
  "appointment-drawer-agreements": "Documents",
  "appointment-drawer-no-agreements": "No documents for this booking",
  "appointment-drawer-booking-started-by": "Checked out by",
  "appointment-drawer-booking-completed-by": "Checked in by",
  "appointment-drawer-initial-loaner-values": "Start: ",
  "appointment-drawer-final-loaner-values": "End: ",
  "appointment-drawer-loaner-values-fuel": "Fuel",
  "appointment-drawer-loaner-values-mileage": "Odometer",
  "appointment-drawer-pickup-at": "Pickup at",
  "appointment-drawer-pickup-customer-at": "Pickup customer at",
  "appointment-drawer-pickup-vehicle-at": "Pickup vehicle at",
  "appointment-drawer-return": "Return",
  "appointment-drawer-return-at": "Return at",
  "appointment-drawer-service-advisor": "Service Advisor",
  "appointment-drawer-shuttle-driver": "Shuttle Driver",
  "appointment-drawer-stock-#": "Stock #",
  "appointment-drawer-trailer": "Trailer",
  "appointment-drawer-trailer-return-trip-dialog-btn-confirm": "Schedule Now",
  "appointment-drawer-trailer-return-trip-dialog-btn-reject": "No",
  "appointment-drawer-trailer-return-trip-dialog-heading": "Schedule Return Trip",
  "appointment-drawer-trailer-return-trip-dialog-message": "Do you want to schedule a return trip for this customer?",
  "appointment-drawer-travel-time-to-destination": "Travel time to destination",
  "appointment-drawer-valet-driver": "Valet Driver",
  "appointment-drawer-vehicle": "Vehicle",
  "appointment-drawer-vin": "VIN",
  "appointment-drawer-year": "Year",
  "appointment-drawer-no-fees-applied": "No fees applied",
  "appointment-drawer-pool-detail": "Vehicle Pool Detail",
  "appointment-drawer-pool-name": "Name",
  "appointment-drawer-pool-capacity": "Total Capacity",
  "appointment-drawer-pool-overbooked-days": "Overbooked Days",
  "appointment-form-available-trailer-info": "Available capacity: {capacity} vehicles",
  "appointment-form-button-book-appointment": "New Booking",
  "appointment-form-button-reserve-vehicle": "Reserve Vehicle",
  "appointment-form-error-distance-not-found": "Distance not found",
  "appointment-form-error-no-route-was-found": "No route was found",
  "appointment-form-label-additional-notes": "Additional notes",
  "appointment-form-label-ro-number": "Repair Order number",
  "appointment-form-label-address": "Address",
  "appointment-form-label-arrival-at-destination-address": "Arrival at destination address",
  "appointment-form-label-arrival-at-return-address": "Arrival at return address",
  "appointment-form-label-arrival-at-start-address": "Arrival at start address",
  "appointment-form-label-arrival-to-destination-time": "Arrival to destination time",
  "appointment-form-label-available-drivers": "Available drivers",
  "appointment-form-label-available-trailers": "Available Trailers",
  "appointment-form-label-back-to-dealership": "Back to dealership",
  "appointment-form-label-back-to-dealership-time": "Back to dealership time",
  "appointment-form-label-buffer-time": "Buffer Time",
  "appointment-form-label-color": "Color",
  "appointment-form-label-customer": "Customer",
  "appointment-form-label-customer-address-invalid": "Customer address is invalid",
  "appointment-form-label-customer-drop-off-date-&-time": "Customer Drop-off Date & Time",
  "appointment-form-label-customer-pickup-date-&-time": "Customer Pickup Date & Time",
  "appointment-form-label-customer-vehicle-info": "Customer Vehicle Info",
  "appointment-form-label-customer-vehicle-return": "Customer vehicle return",
  "appointment-form-label-date": "Date",
  "appointment-form-label-departure-address": "Departure Address",
  "appointment-form-label-departure-time": "Departure time",
  "appointment-form-label-destination-address": "Destination Address",
  "appointment-form-label-destination-arrival": "Destination arrival",
  "appointment-form-label-driver": "Driver",
  "appointment-form-label-drop-off-address": "Drop-off Address",
  "appointment-form-label-error-calculation-label": "Time estimation error",
  "appointment-form-label-flow-type": "Flow Type",
  "appointment-form-label-from": "From",
  "appointment-form-label-from-date": "From Date",
  "appointment-form-label-from-time": "From Time",
  "appointment-form-label-vin-last-6-characters": "Customer Vehicle VIN Last Six Digits",
  "appointment-form-label-loaner-address": "Loaner address",
  "appointment-form-label-loaner-onboarding-time": "Loaner Onboarding Time",
  "appointment-form-label-loaner-pickup": "Loaner pickup",
  "appointment-form-label-loaner-vehicle": "Loaner Vehicle",
  "appointment-form-label-logistics": "Logistics",
  "appointment-form-label-make": "Make",
  "appointment-form-label-minutes": "min",
  "appointment-form-label-model": "Model",
  "appointment-form-label-notes-optional": "Notes (optional)",
  "appointment-form-label-ro-number-optional": "RO Number (optional)",
  "appointment-form-label-onboarding-buffer-time": "Onboarding Buffer Time",
  "appointment-form-label-pickup-address": "Pickup Address",
  "appointment-form-label-primary-driver": "Primary Driver",
  "appointment-form-label-return": "Return",
  "appointment-form-label-return-address": "Return Address",
  "appointment-form-label-return-date": "Return Date",
  "appointment-form-label-return-shuttle-trip": "Return Shuttle trip",
  "appointment-form-label-return-time": "Return Time",
  "appointment-form-label-secondary-driver": "Secondary Driver",
  "appointment-form-label-select-address": "Select Address",
  "appointment-form-label-service-advisor": "Service advisor",
  "appointment-form-label-shuttle-driver": "Shuttle Driver",
  "appointment-form-label-shuttle-trip": "Shuttle trip",
  "appointment-form-label-shuttle-type": "Shuttle type",
  "appointment-form-label-start-address": "Start Address",
  "appointment-form-label-time": "Time",
  "appointment-form-label-trailer-driver": "Trailer driver",
  "appointment-form-label-trailer-location": "Trailer location",
  "appointment-form-label-trailer-return-address-(optional)": "Trailer return address (Optional)",
  "appointment-form-label-trailer-return-location": "Trailer return location",
  "appointment-form-label-trailer-trip": "Trailer trip",
  "appointment-form-label-trailer-type": "Trailer type",
  "appointment-form-label-trailers-search-label": "Filter trailers by stock #",
  "appointment-form-label-trailers-search-placeholder": "Filter trailers by stock #",
  "appointment-form-label-travel-time": "Travel time",
  "appointment-form-label-valet-trip": "Valet trip",
  "appointment-form-label-valet-type": "Valet type",
  "appointment-form-label-valet-vehicle-driver": "Valet vehicle driver",
  "appointment-form-label-vehicle": "Vehicle",
  "appointment-form-label-vehicle-drop-off-address": "Vehicle Drop-off Address",
  "appointment-form-label-vehicle-onboarding-time": "Vehicle Onboarding Time",
  "appointment-form-label-vehicle-pickup-address": "Vehicle Pickup Address",
  "appointment-form-label-vehicle-pickup-date-&-time": "Vehicle Pickup Date & Time",
  "appointment-form-label-vehicle-return-address": "Vehicle Return Address",
  "appointment-form-label-vehicle-return-date-&-time": "Vehicle Return Date & Time",
  "appointment-form-label-year": "Year",
  "appointment-form-time-to-destination": "{timeToDestination} away",
  "appointment-form-overbooked-warning-dialog-title": "Warning! Overbooked Loaner Vehicle Pool",
  "appointment-form-overbooked-warning-dialog-caption-line1": "This loaner vehicle is overbooked for the selected dates.",
  "appointment-form-overbooked-warning-dialog-caption-line2": "Are you sure you want to continue?",
  "appointment-form-overbooked-warning-dialog-continue": "Continue",
  "appointment-form-overbooked-warning-dialog-cancel": "Cancel",
  "appointment-mobility-table-header-advisor": "Service Advisor",
  "appointment-mobility-table-header-appointment": "Booking",
  "appointment-mobility-table-header-customer": "Customer",
  "appointment-mobility-table-header-driver": "Driver",
  "appointment-mobility-table-header-email": "Email",
  "appointment-mobility-table-header-from": "From",
  "appointment-mobility-table-header-logistics": "Logistic Type",
  "appointment-mobility-table-header-location": "Location",
  "appointment-mobility-table-header-status": "Status",
  "appointment-mobility-table-header-to": "To",
  "appointment-mobility-table-header-stock-number": "Stock",
  "appointment-mobility-table-header-vehicle": "Vehicle",
  "appointment-mobility-table-header-pool": "Pool",
  "appointment-mobility-table-no-appointments": "No bookings",
  "appointments-calendar-arrival-at-destination": "Arrival at destination",
  "appointments-calendar-arrival-back-to-dealership": "Back to dealership",
  "appointments-calendar-customer": "Customer",
  "appointments-calendar-driver": "Driver",
  "appointments-calendar-drop-off-address": "Drop-off address",
  "appointments-calendar-notes": "Notes",
  "appointments-calendar-service-advisor": "Service advisor",
  "appointments-calendar-travel-time-in-minutes": "{number} min",
  "appointments-calendar-travel-time-to-destination": "Travel time",
  "appointments-create-appointment": "New Booking",
  "appointments-logistics-lo": "Loaner",
  "appointments-logistics-sh": "Shuttle",
  "appointments-logistics-tr": "Trailer",
  "appointments-logistics-va": "Valet",
  "appointments-overview-find-driver": "Find driver",
  "appointments-overview-logistic-type-input-label": "Logistic type",
  "appointments-overview-search-input": "Search",
  "appointments-table-view-loaners": "Loaners",
  "appointments-table-view-mobility": "Mobility",
  "available-trailer-marker-capacity": "Capacity",
  "available-trailer-marker-distance": "Distance",
  "available-trailer-marker-next-task": "Next Task",
  "available-trailer-marker-no-tasks": "No tasks",
  "available-vehicles-during-selected-time-and-date": "Pools and available vehicles during selected time and date",
  "away": "Away",
  "back": "Back",
  "billing": "Billing",
  "buffer-time-select": "{number} min",
  "button-see-all": "See all",
  "cancel": "Cancel",
  "calculate-trip": "Calculate Trip",
  "calendar-edit-button": "Edit",
  "calendar-delete-button": "Delete",
  "calendar-detail-button": "Detail",
  "chip-status-av": "Available",
  "chip-status-available": "Available",
  "chip-status-booked": "Booked",
  "chip-status-bo": "Booked",
  "chip-status-cd": "Completed",
  "chip-status-connectivity-cd": "Connected",
  "chip-status-connectivity-dd": "Disconnected",
  "chip-status-connectivity-er": "Error",
  "chip-status-connectivity-nd": "Not Created",
  "chip-status-disabled": "Disabled",
  "chip-status-dl": "Delayed",
  "chip-status-di": "Disabled",
  "chip-status-ia": "Inactive",
  "chip-status-iae": "Inactive",
  "chip-status-in-progress": "In Progress",
  "chip-status-in-use": "In Use",
  "chip-status-inactive": "Inactive",
  "chip-status-inspect": "Inspect",
  "chip-status-ip": "In Progress",
  "chip-status-ips": "In Progress",
  "chip-status-is": "Inspect",
  "chip-status-not-utilized": "Not Utilized",
  "chip-status-od": "Overdue",
  "chip-status-ode": "Overdue",
  "chip-status-of": "Off Lot",
  "chip-status-off-lot": "Off Lot",
  "chip-status-ofl": "Off Lot",
  "chip-status-ol": "On Lot",
  "chip-status-on": "On Lot",
  "chip-status-on-lot": "On Lot",
  "chip-status-onl": "On Lot",
  "chip-status-on-job": "On Job",
  "chip-status-on-route": "On Route",
  "chip-status-or": "On Route",
  "chip-status-ov": "Overdue",
  "chip-status-overdue": "Overdue",
  "chip-status-past-due": "Past Due",
  "chip-status-reserved": "Reserved",
  "chip-status-returning": "Returning",
  "chip-status-rs": "Reserved",
  "chip-status-rsd": "Reserved",
  "chip-status-sd": "Scheduled",
  "chip-status-ua": "Unassigned",
  "chip-status-unavailable": "Unavailable",
  "confirm": "Confirm",
  "country-canada": "Canada",
  "country-usa": "United States",
  "country-unspecified": "Unspecified",
  "credit": "Credit",
  "credit-all": "Credit All",
  "credit-for": "Credit for",
  "credit-for-loaner-admin-fee": "Credit for Loaner Admin Fee",
  "credit-for-loaner-daily-fee": "Credit for Loaner Daily Fee",
  "credit-for-trailer-fee": "Credit for Trailer Fee",
  "credit-for-valet-fee": "Credit for Valet Fee",
  "customer": "Customer",
  "customer-customer-detail-edit-button": "Edit",
  "customer-customer-detail-save-button": "Save",
  "customer-table-heading-label-customer": "Customer",
  "customer-table-heading-label-email": "Email",
  "customer-table-heading-label-last-appointment": "Last Booking",
  "customer-table-heading-label-phone": "Phone",
  "customer-table-heading-label-service-advisor": "Service advisor",
  "customer-table-heading-label-status": "Status",
  "customers-add-customer-button": "New customer",
  "customers-customer-detail-address": "Address",
  "customers-customer-detail-cell-phone-number": "Phone",
  "customers-customer-detail-company": "Company",
  "customers-customer-detail-driving-license": "Driving license",
  "customers-customer-detail-email": "Email",
  "customers-customer-detail-first-name": "Name",
  "customers-customer-detail-gender": "Gender",
  "customers-customer-detail-insurance-company": "Insurance",
  "customers-customer-detail-policy-number": "Policy number",
  "customers-customer-detail-unit-number": "Unit",
  "customers-customer-detail-mobile-number": "Mobile number",
  "customers-customer-detail-name": "Name",
  "customers-customer-detail-service-advisor": "Service advisor",
  "customers-customer-detail-street": "Address",
  "customers-customer-detail-title": "Customer Detail",
  "dashboard-check-availability-button": "Check Availability",
  "dashboard-map-control-heading": "Bookings Timeline",
  "dashboard-map-back-at-dealership": "Back at dealership",
  "dashboard-map-customer": "Customer",
  "dashboard-map-date": "Date",
  "dashboard-map-departure-time": "Departure time",
  "dashboard-map-destination-address": "Destination address",
  "dashboard-map-destination-arrival": "Destination arrival",
  "dashboard-map-details": "Details",
  "dashboard-map-driver": "Driver",
  "dashboard-map-notes": "Notes",
  "dashboard-map-service-advisor": "Service Advisor",
  "dashboard-map-travel-time": "Travel time",
  "dashboard-mobility-appointments-title": "Mobility Bookings",
  "dashboard-vehicle-appointments-title": "Vehicle Bookings",
  "dashboard-vehicle-statistics-fuel": "Fuel",
  "dashboard-vehicle-statistics-mileage": "Mileage",
  "dashboard-vehicle-statistics-section-ip": "In Progress",
  "dashboard-vehicle-statistics-section-on": "On Lot",
  "dashboard-vehicle-statistics-title": "Vehicle statistics",
  "dashboard-vehicle-statistics-vehicle": "Vehicle",
  "date-picker-label-date": "Date",
  "date-picker-label-time": "Time",
  "date-picker-label-week": "Week",
  "day-friday": "Friday",
  "day-monday": "Monday",
  "day-saturday": "Saturday",
  "day-sunday": "Sunday",
  "day-thursday": "Thursday",
  "day-tuesday": "Tuesday",
  "day-wednesday": "Wednesday",
  "delete-away-slot": "Delete away slot",
  "detail-cancel-button": "Cancel",
  "detail-update-button": "Update",
  "detail-add-button": "New",
  "drivers-add-driver-button": "New Driver",
  "drivers-calendar-autocomplete-title": "Drivers",
  "drivers-create-drawer-title": "New Driver",
  "drivers-create-driver-helper-text": "Selected user will have driver capabilities.",
  "drivers-detail-add-away-slot": "New away slot",
  "drivers-detail-all-day": "All day",
  "drivers-detail-assigned-trailer": "Assigned Trailer",
  "drivers-detail-availability-header": "Availability",
  "drivers-detail-away-header": "Away",
  "drivers-detail-back-button": "Back",
  "drivers-detail-button-add": "Add",
  "drivers-detail-cancel-button": "Cancel",
  "drivers-detail-confirm-button": "Confirm",
  "drivers-detail-days": "Days",
  "drivers-detail-dealership": "Dealership",
  "drivers-detail-delete-away-slot": "Delete away slot",
  "drivers-detail-delete-button": "Delete",
  "drivers-detail-driver-enabled-radio-button-label": "Driver is enabled",
  "drivers-detail-driver-disabled-radio-button-label": "Driver is disabled",
  "drivers-detail-driver-details": "Driver Details",
  "drivers-detail-edit-button": "Edit",
  "drivers-detail-email": "Email",
  "drivers-detail-availability-error": "Cannot save change: A driver must have at least one available day",
  "drivers-detail-time-range-error": "To time must be after From time",
  "drivers-detail-from": "From",
  "drivers-detail-from:": "From",
  "drivers-detail-modal-body-text": "{name} has an active trailer license, and no assigned trailer.",
  "drivers-detail-modal-conflict-away": "Are you sure you want to continue? The affected booking will become Unassigned.",
  "drivers-detail-modal-conflict-away-banner": "There are {count} booking(s) assigned to {name} during the selected Away time.",
  "drivers-detail-modal-conflict-away-header": "Attention",
  "drivers-detail-modal-header-text": "Assign trailer to {name}",
  "drivers-detail-modal-not-add-button": "Do Not Add",
  "drivers-detail-modal-submit-button": "Select Trailer",
  "drivers-detail-name": "Name",
  "drivers-detail-no-selected-days": "Select Driver’s daily availability",
  "drivers-detail-phone-number": "Phone Number",
  "drivers-detail-remove-as-driver": "Remove as Driver",
  "drivers-detail-select-days": "Select Days",
  "drivers-detail-select-days-close-button": "Close",
  "drivers-detail-select-trailer": "Select Trailer",
  "drivers-detail-stock-number": "Trailer",
  "drivers-detail-to": "To",
  "drivers-detail-to:": "To",
  "drivers-detail-trailer-license": "Trailer License",
  "driver-information-title-default-driver-name": "Driver",
  "driver-information-title": "{driver}'s Informations",
  "driver-information-driving-license-title": "Driver's License",
  "driver-information-driving-license-number": "License Number",
  "driver-information-driving-license-expiration-date": "Expiration Date",
  "driver-information-driving-license-issuer-country": "Issuer Country",
  "driver-information-driving-license-issuer": "License Issuer",
  "driver-information-insurance-title": "Insurance Information",
  "driver-information-insurance-company": "Insurance Company",
  "driver-information-insurance-number": "Insurance Number",
  "driver-information-insurance-expiration-date": "Expiration Date",
  "driver-information-validation-license-number-unset": "License number is required if issuer or expiration date is set",
  "driver-information-validation-license-issuer-unset": "License issuer must be set",
  "driver-information-validation-license-issuer-unset-country-set": "License issuer is required if country is set",
  "driver-information-validation-license-issuer-unset-insurance-number-set": "License issuer must be set",
  "driver-information-validation-license-expired": "License is expired",
  "driver-information-validation-insurance-company-unset": "Insurance company is required",
  "driver-information-validation-insurance-number-unset": "Insurance policy number is required",
  "driver-information-validation-insurance-expired": "Policy is expired",
  "driver-unavailable-for-scheduled-times-error-message": "No drivers available between {startTime} and {endTime}. Check driver schedule.",
  "drivers-find-new-driver": "Find new driver",
  "drivers-remove-driver-title": "Remove {name}",
  "drivers-table-dealership": "Dealership",
  "drivers-table-driver": "Driver",
  "drivers-table-email": "Email",
  "drivers-table-no-drivers-were-found": "No drivers were found",
  "drivers-table-phone": "Phone",
  "drivers-table-status": "Status",
  "driver-tooltip-calculation-info-message": "Calculate the trip to see available drivers",
  "delete": "Delete",
  "edit": "Edit",
  "edit-loaner-title": "Edit Loaner Booking",
  "edit-shuttle-title": "Edit Shuttle Booking",
  "edit-valet-title": "Edit Valet Booking",
  "edit-trailer-title": "Edit Trailer Booking",
  "email": "Email",
  "empty-string": " ",
  "error-message-information-about-appointment-group": "There was an error while getting information about booking groups.",
  "error-message-appointment-not-deleted": "Error deleting booking",
  "error-message-adddriver-is-a-required-field": "Driver is required",
  "error-message-address-invalid": "Address is invalid",
  "error-message-appointment-creation-error": "Booking creation error",
  "error-message-appointment-update-error": "Booking update error",
  "error-message-available-vehicles-during-selected-time-and-date.": "Available vehicles during selected time and date.",
  "error-message-confirm-new-password-is-required": "Confirm new password is required",
  "error-message-distance-not-found": "Distance not found",
  "error-message-driver-is-available-from-<time>-to-<time>": "Driver is available from <time> to <time>",
  "error-message-driving-license-invalid": "Provided driving license is invalid",
  "error-message-email-invalid": "Email is not valid. Please check that you typed it in correctly.",
  "error-message-email-is-not-valid-please-check-that-you-typed-it-in-correctly": "Email is not valid. Please check that you typed it in correctly.",
  "error-message-field-is-required": "Field is required",
  "error-message-field-required": "Field is required",
  "error-message-gender-invalid": "Gender is not valid. Please select value from the list.",
  "error-message-greater-than-debit": "Credit value cannot be greater than original value",
  "error-message-invalid-addresses": "Invalid address(es)",
  "error-message-invalid-request": "Most likely you are trying to calculate estimates for a past date.",
  "error-message-license-plate-characters": "License plate must contain only uppercase letters, numbers, spaces and dashes",
  "error-message-license-plate-max": "License plate must be max 8 characters long",
  "error-message-license-plate-min": "License plate must be min 2 characters long",
  "error-message-login-expired": "Your login has expired",
  "error-message-must-be-number": "Field must be number",
  "error-message-must-be-positive": "Field must be greater than 0",
  "error-message-new-password-is-required": "New password is required",
  "error-message-no-route-was-found": "No route was found",
  "error-message-only-uppercase-letters,-numbers-and-spaces-allowed.-max-length-10-symbols": "Only uppercase letters, numbers and spaces allowed. Max length 10 symbols",
  "error-message-oops-some-error-occurred": "Ooops, some error occurred",
  "error-message-passwords-are-not-equal": "passwords are not equal",
  "error-message-phone-number-invalid": "Please provide a valid phone number",
  "error-message-please-provide-a-valid-phone-number": "Please provide a valid phone number",
  "error-message-policy-number-characters": "Policy number should be numeric",
  "error-message-policy-number-length": "Policy number must be max 19 characters long",
  "error-message-policy-number-must-be-max-19-characters-long": "Policy number must be max 19 characters long",
  "error-message-policy-number-should-be-numeric": "Policy number should be numeric",
  "error-message-provided-driving-license-is-invalid": "Provided driving license is invalid",
  "error-message-provided-email-is-invalid": "Provided email is invalid",
  "error-message-required": "Required",
  "error-message-return-time-too-early": "Must be later than first trip time",
  "error-message-stock-number-characters-pattern": "Only numbers, letters, dashes and spaces.",
  "error-message-stock-number-characters-length": "Max 20 characters",
  "error-message-trailer-update": "Trailer update failed",
  "error-message-vin-characters": "The format of the provided VIN is not valid",
  "error-message-invalid-vin": "The provided VIN is not valid",
  "error-message-vin-last-6-symbols-length": "Must be Six Digits Long",
  "error-message-vin-length": "VIN must be 17 characters long",
  "error-message-payment-method": "Error updating payment method",
  "error-message-is-valet-driver-the-same-as-shuttle-driver": "Valet driver can't be the same as shuttle driver",
  "error-message-appointment-form-submit-prevented": "Booking form submit prevented. Please check the form",
  "error-message-appointment-form-submit-prevented-calculate-trip": "Booking form submit prevented. Please calculate the trip",
  "error-message-vehicle-updated": "Error updating vehicle",
  "error-message-vehicle-deleted": "Error deleting vehicle",
  "error-message-trailer-deleted": "Error deleting trailer",
  "error-message-time-needs-to-be-in-the-future": "Time needs to be in the future",
  "error-message-date-needs-to-be-in-the-future": "Date needs to be in the future",
  "error-message-return-date-has-to-be-after-from-date": "Return date has to be after from date",
  "error-message-vehicle-with-this-stock-number-already-exists": "Vehicle with this stock number already exists",
  "error-message-vehicle-with-this-license-plate-already-exists": "Vehicle with this license plate already exists",
  "error-message-active-vehicle-with-this-vin-already-exists-on-this-account": "A vehicle with this vin already exists on this account",
  "estimated-amount": "Est. amount",
  "flow-label-lv": "Loaner Vehicle",
  "flow-label-lo": "Loaner Vehicle",
  "flow-label-sh": "Shuttle",
  "flow-label-tr": "Trailer",
  "flow-label-va": "Valet",
  "flow-select-option-all": "All",
  "flow-select-option-lo": "Loaner Vehicle",
  "flow-select-option-loaner-vehicle": "Loaner Vehicle",
  "flow-select-option-sh": "Shuttle",
  "flow-select-option-shuttle": "Shuttle",
  "flow-select-option-tr": "Trailer",
  "flow-select-option-trailer": "Trailer",
  "flow-select-option-va": "Valet",
  "flow-select-option-valet": "Valet",
  "fp": "Paid",
  "from": "From",
  "gender": "Gender",
  "gender-fe": "Female",
  "gender-me": "Male",
  "gender-or": "Other",
  "gender-us": "-",
  "generate-invoice": "Display Invoice",
  "grand-total": "Grand Total",
  "invalid-date": "Invalid date",
  "invoice": "Invoice",
  "invoice-processed-by-caption": "by",
  "loaner-admin-fee": "Loaner Admin Fee",
  "administration-courtoisie": "Loaner Admin Fee",
  "loaner-cleaning-fee": "Loaner Cleaning Fee",
  "frais-de-nettoyage": "Loaner Cleaning Fee",
  "loaner-daily-fee": "Loaner Daily Fee",
  "frais-quotidien": "Loaner Daily Fee",
  "loaner-late-daily-fee": "Loaner Late Daily Fee",
  "frais-de-retard": "Loaner Late Daily Fee",
  "loaner-per-litre-charge": "Loaner Per Liter Charge",
  "loaner-per-litre-fee": "Loaner Per Liter Fee",
  "logistic-type": "Logistics type",
  "logistics": "Logistics type",
  "menu-appointments": "Bookings",
  "menu-customers": "Customers",
  "menu-dashboard": "Dashboard",
  "menu-drivers": "Drivers",
  "menu-language-select": "Language",
  "menu-settings": "Settings",
  "menu-trailers": "Trailers",
  "menu-vehicles": "Loaners",
  "mobile-number": "Mobile number",
  "name": "Name",
  "new-appointment-title": "New Booking",
  "new-item": "New Item",
  "no-return-date": "No return date",
  "np": "Payment Due",
  "option-canada-state-alberta": "Alberta",
  "option-canada-state-british-columbia": "British Columbia",
  "option-canada-state-manitoba": "Manitoba",
  "option-canada-state-new-brunswick": "New Brunswick",
  "option-canada-state-newfoundland-and-labrador": "Newfoundland and Labrador",
  "option-canada-state-northwest-territories": "Northwest Territories",
  "option-canada-state-nova-scotia": "Nova Scotia",
  "option-canada-state-nunavut": "Nunavut",
  "option-canada-state-ontario": "Ontario",
  "option-canada-state-prince-edward-island": "Prince Edward Island",
  "option-canada-state-quebec": "Quebec",
  "option-canada-state-saskatchewan": "Saskatchewan",
  "option-canada-state-yukon-territory": "Yukon Territory",
  "potential-fees": "Potential Fees",
  "pool-status-at-availability": "Availability",
  "pool-status-at-capacity": "At Capacity",
  "pool-status-overbooked": "Overbooked",
  "pool-no-daily-appointments": "No bookings for this day",
  "overdue": "Payment Due",
  "paid": "Paid",
  "payment-requested": "Payment Requested",
  "payments-due": "Payments Due",
  "prior-date": "From date is prior To",
  "provide-valid-customer-address": "Provide valid customer address",
  "provide-valid-customer-address-button": "Update customer address",
  "qty": "Qty",
  "quantity-short": "Qty",
  "radio-button-other": "Other",
  "radio-button-parts": "Parts",
  "radio-button-sales": "Sales",
  "radio-button-service": "Service",
  "radio-button-pick-up": "Pick-Up",
  "radio-button-drop-off": "Drop-Off",
  "remove": "Remove",
  "remove-appointment-title": "Delete booking",
  "remove-appointment-success": "Booking is deleted",
  "remove-appointment-info-line": "Are you sure you want to delete this booking?",
  "remove-appointment-info-line-plural": "This booking is in a group with other bookings. Only the whole group can be deleted. Are you sure you want to delete those?",
  "remove-appointment-checking": "Checking booking groups ...",
  "remove-appointment-deleting": "Deleting...",
  "remove-appointment-body": "booking for {customer}, departure at {date}.",
  "rental-agreement": "Rental agreement",
  "rental-receipt": "Vehicle Return Statement",
  "billing-total-will-be-rounded-to-zero": "Payments under $1 will be rounded down to $0 due to processing restrictions.",
  "process-payment": "Process payment",
  "complete-payment": "Complete payment",
  "save": "Save",
  "search-input-label-filter-trailers-by-stock-#": "Filter trailers by stock #",
  "search-input-label-search": "Search",
  "search-input-label-search-customers": "Search customers",
  "search-input-placeholder-filter-trailers-by-stock-#": "Filter trailers by stock #",
  "search-input-placeholder-search": "Search",
  "search-input-placeholder-search-customers": "Search customers",
  "section-tabs-billing": "Billing",
  "section-tabs-calendar": "Calendar",
  "section-tabs-connectivity": "Connectivity",
  "section-tabs-geofences": "Geofences",
  "section-tabs-map": "Map",
  "section-tabs-notifications": "Notifications",
  "section-tabs-overview": "Overview",
  "section-tabs-reporting": "Reporting",
  "section-tabs-vehicles": "Vehicles",
  "select-gender": "Select Gender",
  "service-advisor": "Service advisor",
  "settings-cancel-button": "Cancel",
  "settings-change-description": "Change description",
  "settings-change-label": "Change label",
  "settings-close-button": "Close",
  "settings-confirm-button": "Confirm",
  "settings-connectivity-add-connection": "New Connection",
  "settings-connectivity-dialog-select-brand-button-confirm": "New connection",
  "settings-connectivity-make-select-label": "Select Make",
  "settings-connectivity-popover-synchronize-close": "Close",
  "settings-connectivity-popover-synchronize-confirm": "Synchronize",
  "settings-connectivity-popover-synchronize-description": "Are you sure you want to synchronize this fleet?",
  "settings-connectivity-popover-synchronize-description-reauthenticate": "You will have to enter your account credentials and the Multi-Factor verification code.",
  "settings-connectivity-popover-synchronize-label": "Synchronize",
  "settings-connectivity-popover-synchronize-no-change": "There was no change to the fleet.",
  "settings-connectivity-popover-synchronize-vin": "VIN",
  "settings-connectivity-popover-synchronize-added-vehicles": "Added Vehicles VINs",
  "settings-connectivity-popover-synchronize-removed-vehicles": "Removed Vehicles VINs",
  "settings-connectivity-popover-disconnect-cancel": "Cancel",
  "settings-connectivity-popover-disconnect-confirm": "Disconnect",
  "settings-connectivity-popover-disconnect-description": " Are you sure you want to disconnect this vehicle?",
  "settings-connectivity-popover-disconnect-label": "Disconnect",
  "settings-connectivity-popover-select-brand-label": "New connection",
  "settings-connectivity-table-header-brand-name": "Make",
  "settings-connectivity-table-header-connected-vehicles": "Connected Vehicles",
  "settings-connectivity-table-header-connection-status": "Status",
  "settings-connectivity-table-header-disconnect": "Disconnect",
  "settings-connectivity-table-header-synchronize": "Update",
  "settings-connectivity-table-header-last-refresh": "Date of Last Refresh",
  "settings-connectivity-table-header-localized-last-fleet-synchronization": "Last Update",
  "settings-connectivity-table-header-mobility-created-at": "Date of Last Connection",
  "settings-connectivity-table-header-token-expiration": "Days to Token Expiry",
  "settings-connectivity-table-no-connections": "There are no connected vehicles",
  "settings-connectivity-vehicles-synchronize-error": "Fleet synchronization failed",
  "settings-connectivity-vehicles-synchronize-success": "Fleet Synchronized",
  "settings-connectivity-vehicles-disconnect-error": "Ooopps! Something went wrong",
  "settings-connectivity-vehicles-disconnect-success": "Vehicles disconnected",
  "settings-connectivity-popover-update-echoes-fleet-dialog-title": "Connected cars",
  "settings-connectivity-popover-update-echoes-fleet-add-vehicle": "NEW CONNECTED CAR",
  "settings-connectivity-popover-update-echoes-fleet-add-vehicle-button": "New car",
  "settings-connectivity-popover-update-echoes-fleet-new-vehicle-vin": "VIN",
  "settings-connectivity-popover-update-echoes-fleet-table-header-vin": "VIN",
  "settings-connectivity-popover-update-echoes-fleet-table-header-model": "Vehicle model",
  "settings-connectivity-echoes-vehicle-adding-success": "Vehicle successfully added",
  "settings-connectivity-echoes-vehicle-adding-failure": "An error occured while adding the vehicle",
  "settings-connectivity-echoes-vehicle-deleting-success": "Vehicle successfully deleted",
  "settings-connectivity-echoes-vehicle-deleting-failure": "An error occured while deleting the vehicle",
  "settings-connectivity-echoes-unconfigured-vehicle-placeholder": "Pending Configuration",
  "settings-description-3rd-tax-is-compounded-on-the-total-amount": "3rd tax is compounded on the total amount.",
  "settings-description-amount-charged-for-each-litre-of-fuel-used": "Amount charged for each percent of the fuel tank used.",
  "settings-description-amount-charged-for-environmental-reasons": "Amount charged for environmental reasons.",
  "settings-description-amount-charged-for-late-return-of-loaner": "Amount charged for late return of loaner.",
  "settings-description-amount-charged-for-the-administration-of-loaners": "Amount charged for the administration of loaners.",
  "settings-description-amount-charged-for-the-tax-#1": "Amount charged for the tax #1.",
  "settings-description-amount-charged-for-the-tax-#2": "Amount charged for the tax #2.",
  "settings-description-amount-charged-for-the-use-of-trailers": "Amount charged for the use of trailers.",
  "settings-description-amount-charged-for-the-valet-service": "Amount charged for the valet service.",
  "settings-description-amount-charged-for-toll-roads": "Amount charged for toll roads.",
  "settings-description-currency-info": "Currency info.",
  "settings-description-units-info": "Units info.",
  "settings-geofence-area-cancel-btn": "Cancel",
  "settings-geofence-area-subtitle": "Define an area around your Dealership to receive Driver and Loaner return notifications.",
  "settings-geofence-area-title": "Geofence Area",
  "settings-geofence-area-update-btn": "Update",
  "settings-geofence-general-help-info": "Some information about geofence",
  "settings-geofence-general-subtitle-heading": "Dealership Address",
  "settings-geofence-general-title": "General",
  "settings-header-billing-settings": "Billing Settings",
  "settings-header-general": "General",
  "settings-header-geofence-area": "Geofence Area",
  "settings-header-maximum-distance-boundary": "Maximum Distance Boundary",
  "settings-header-notification-settings": "Notification Settings",
  "settings-header-connectivity": "Connectivity",
  "settings-header-vehicles-settings": "Vehicles Settings",
  "settings-input-label-chance-fee": "Chance Fee",
  "settings-input-label-currency": "Currency",
  "settings-input-label-damages": "Damages",
  "settings-input-label-enviromental-fee": "Environmental Fee",
  "settings-input-label-environmental-fee": "Environmental Fee",
  "settings-input-label-loaner-admin-fee": "Loaner Admin Fee",
  "settings-input-label-loaner-cleaning-fee": "Loaner Cleaning Fee",
  "settings-input-label-loaner-daily-fee": "Loaner Daily Fee",
  "settings-input-label-loaner-late-daily-fee": "Loaner Late Daily Fee",
  "settings-input-label-loaner-per-litre-charge": "Per-Percentage Fuel Charge",
  "settings-input-label-typical-per-percent-charge": "Typically, $1-2",
  "settings-input-label-meet-fee": "Meet Fees",
  "settings-input-label-mission-fee": "Mission Fee",
  "settings-input-label-others-fee": "Other Fees",
  "settings-input-label-per-20%-of-fuel-tank": "Per 20% of fuel tank",
  "settings-input-label-amount-plus-20-percent": "Amount + 20% Administration",
  "settings-input-label-reason-fee": "Reason Fee",
  "settings-input-label-tax-#1": "Tax #1",
  "settings-input-label-tax-#2": "Tax #2",
  "settings-input-label-tax-#3": "Tax #3",
  "settings-input-label-toll-road-fee": "Toll Road Fee",
  "settings-input-label-trailer-fee": "Trailer Fee",
  "settings-input-label-units": "Units",
  "settings-input-label-valet-fee": "Valet Fee",
  "settings-max-distance-boundary-help-info": "Some information about the maximum distance boundary",
  "settings-maximum-distance-boundary-province-selector-title": "Province",
  "settings-maximum-distance-boundary-state-selector-option-alabama": "Alabama",
  "settings-maximum-distance-boundary-state-selector-option-alaska": "Alaska",
  "settings-maximum-distance-boundary-state-selector-option-alberta": "Alberta",
  "settings-maximum-distance-boundary-state-selector-option-arizona": "Arizona",
  "settings-maximum-distance-boundary-state-selector-option-arkansas": "Arkansas",
  "settings-maximum-distance-boundary-state-selector-option-british-columbia": "British Columbia",
  "settings-maximum-distance-boundary-state-selector-option-california": "California",
  "settings-maximum-distance-boundary-state-selector-option-colorado": "Colorado",
  "settings-maximum-distance-boundary-state-selector-option-connecticut": "Connecticut",
  "settings-maximum-distance-boundary-state-selector-option-delaware": "Delaware",
  "settings-maximum-distance-boundary-state-selector-option-district-of-columbia": "District of Columbia",
  "settings-maximum-distance-boundary-state-selector-option-florida": "Florida",
  "settings-maximum-distance-boundary-state-selector-option-georgia": "Georgia",
  "settings-maximum-distance-boundary-state-selector-option-hawaii": "Hawaii",
  "settings-maximum-distance-boundary-state-selector-option-idaho": "Idaho",
  "settings-maximum-distance-boundary-state-selector-option-illinois": "Illinois",
  "settings-maximum-distance-boundary-state-selector-option-indiana": "Indiana",
  "settings-maximum-distance-boundary-state-selector-option-iowa": "Iowa",
  "settings-maximum-distance-boundary-state-selector-option-kansas": "Kansas",
  "settings-maximum-distance-boundary-state-selector-option-kentucky": "Kentucky",
  "settings-maximum-distance-boundary-state-selector-option-louisiana": "Louisiana",
  "settings-maximum-distance-boundary-state-selector-option-maine": "Maine",
  "settings-maximum-distance-boundary-state-selector-option-manitoba": "Manitoba",
  "settings-maximum-distance-boundary-state-selector-option-maryland": "Maryland",
  "settings-maximum-distance-boundary-state-selector-option-massachusetts": "Massachusetts",
  "settings-maximum-distance-boundary-state-selector-option-michigan": "Michigan",
  "settings-maximum-distance-boundary-state-selector-option-minnesota": "Minnesota",
  "settings-maximum-distance-boundary-state-selector-option-mississippi": "Mississippi",
  "settings-maximum-distance-boundary-state-selector-option-missouri": "Missouri",
  "settings-maximum-distance-boundary-state-selector-option-montana": "Montana",
  "settings-maximum-distance-boundary-state-selector-option-nebraska": "Nebraska",
  "settings-maximum-distance-boundary-state-selector-option-nevada": "Nevada",
  "settings-maximum-distance-boundary-state-selector-option-new-brunswick": "New Brunswick",
  "settings-maximum-distance-boundary-state-selector-option-new-hampshire": "New Hampshire",
  "settings-maximum-distance-boundary-state-selector-option-new-jersey": "New Jersey",
  "settings-maximum-distance-boundary-state-selector-option-new-mexico": "New Mexico",
  "settings-maximum-distance-boundary-state-selector-option-new-york": "New York",
  "settings-maximum-distance-boundary-state-selector-option-newfoundland-and-labrador": "Newfoundland and Labrador",
  "settings-maximum-distance-boundary-state-selector-option-north-carolina": "North Carolina",
  "settings-maximum-distance-boundary-state-selector-option-north-dakota": "North Dakota",
  "settings-maximum-distance-boundary-state-selector-option-northwest-territories": "Northwest Territories",
  "settings-maximum-distance-boundary-state-selector-option-nova-scotia": "Nova Scotia",
  "settings-maximum-distance-boundary-state-selector-option-ohio": "Ohio",
  "settings-maximum-distance-boundary-state-selector-option-oklahoma": "Oklahoma",
  "settings-maximum-distance-boundary-state-selector-option-ontario": "Ontario",
  "settings-maximum-distance-boundary-state-selector-option-oregon": "Oregon",
  "settings-maximum-distance-boundary-state-selector-option-pennsylvania": "Pennsylvania",
  "settings-maximum-distance-boundary-state-selector-option-prince-edward-island": "Prince Edward Island",
  "settings-maximum-distance-boundary-state-selector-option-puerto-rico": "Puerto Rico",
  "settings-maximum-distance-boundary-state-selector-option-quebec": "Quebec",
  "settings-maximum-distance-boundary-state-selector-option-rhode-island": "Rhode Island",
  "settings-maximum-distance-boundary-state-selector-option-saskatchewan": "Saskatchewan",
  "settings-maximum-distance-boundary-state-selector-option-south-carolina": "South Carolina",
  "settings-maximum-distance-boundary-state-selector-option-south-dakota": "South Dakota",
  "settings-maximum-distance-boundary-state-selector-option-tennessee": "Tennessee",
  "settings-maximum-distance-boundary-state-selector-option-texas": "Texas",
  "settings-maximum-distance-boundary-state-selector-option-utah": "Utah",
  "settings-maximum-distance-boundary-state-selector-option-vermont": "Vermont",
  "settings-maximum-distance-boundary-state-selector-option-virginia": "Virginia",
  "settings-maximum-distance-boundary-state-selector-option-washington": "Washington",
  "settings-maximum-distance-boundary-state-selector-option-washington-state": "Washington State",
  "settings-maximum-distance-boundary-state-selector-option-west-virginia": "West Virginia",
  "settings-maximum-distance-boundary-state-selector-option-wisconsin": "Wisconsin",
  "settings-maximum-distance-boundary-state-selector-option-wyoming": "Wyoming",
  "settings-maximum-distance-boundary-state-selector-option-yukon": "Yukon",
  "settings-maximum-distance-boundary-subtitle": "Define a maximum area a Loaner vehicle is allowed to travel within. Receive notifications when a the limit is breached.",
  "settings-maximum-distance-boundary-title": "Maximum Distance Boundary",
  "settings-new-description": "New Description",
  "settings-new-label": "New Label",
  "settings-notifications-appointment-confirmation": "Enable 24-hour Booking Confirmation",
  "settings-notifications-appointment-reminder": "24-hour Customer Booking Reminder",
  "settings-notifications-back-to-dealership": "Driver Back to Dealership",
  "settings-notifications-back-to-dealership-directions": "Customer Loaner Back to Dealership Directions",
  "settings-notifications-customer-no-fee-invoice-sms": "Send invoice to customer when there are no fees incurred",
  "settings-notifications-settings-updated": "Settings successfully updated.",
  "settings-reset-dialog-text": "Are you sure? This action can't be undone.",
  "settings-reset-dialog-title": "Reset default label and description",
  "settings-reset-to-default-values": "Reset to default values",
  "settings-submit-button": "Submit",
  "settings-update-button": "Update",
  "settings-delete-button": "Delete",
  "settings-vehicle-rental-agreement": "Rental Agreement",
  "settings-vehicle-valet-agreement": "Valet/Trailer Agreement",
  "settings-vehicles": "Vehicles Settings",
  "settings-vehicles-close-button": "Close",
  "settings-vehicles-custom-label-alert-mileage": "Maximum mileage when car needs to show service alert",
  "settings-vehicles-custom-label-max-mileage": "Maximum car mileage between services",
  "settings-vehicles-custom-receipt-require-signature": "Vehicle Return Statement Requires Signature when completing booking",
  "settings-vehicles-custom-send-receipt-to-customer": "Send Vehicle Return Statement to customer",
  "settings-vehicles-max-mileage": "Maximum Mileage",
  "settings-vehicles-max-mileage-alert": "Maximum Mileage Alert",
  "settings-vehicles-receipt-require-signature": "Vehicle Return Statement Requires Signature when completing booking",
  "settings-vehicles-send-receipt-to-customer": "Send Vehicle Return Statement to customer",
  "settings-vehicles-loaner-bookings-opcode": "Loaner Booking OPCodes",
  "settings-vehicles-subtitle": "Per Loaner vehicle",
  "settings-vehicles-subtitle-default-pool": "Comma Separated",
  "settings-vehicles-subtitle-empty": "",
  "settings-vehicles-pools-title": "Vehicle Pools",
  "settings-vehicles-pools-add-pool-button": "New Pool",
  "settings-vehicles-pools-name": "Name",
  "settings-vehicles-pools-description": "Description",
  "settings-vehicles-pools-vehicle-count": "# of Vehicles",
  "settings-vehicles-pools-is-default": "Default Pool",
  "settings-vehicles-pools-empty-list": "No pools to display",
  "settings-vehicles-pools-default": "Default",
  "settings-vehicles-pools-make-default-button": "Make Default",
  "settings-vehicles-pools-success-deleting-pool": "Pool deleted successfully",
  "settings-vehicles-pools-error-deleting-pool": "Error deleting pool",
  "settings-vehicles-pools-success-creating-pool": "Pool created successfully",
  "settings-vehicles-pools-error-creating-pool": "Error creating pool",
  "settings-vehicles-pools-success-updating-pool": "Pool updated successfully",
  "settings-vehicles-pools-error-updating-pool": "Error updating pool",
  "settings-vehicles-pools-success-setting-default-pool": "Default Pool changed successfully",
  "settings-vehicles-pools-error-setting-default-pool": "Error changing the default pool",
  "settings-vehicles-pools-create-pool-dialog-title": "New Pool",
  "settings-vehicles-pools-edit-pool-dialog-title": "Edit Pool",
  "settings-vehicles-pools-create-pool-dialog-new-pool-name-caption": "Name",
  "settings-vehicles-pools-create-pool-edit-pool-button-caption": "Edit",
  "settings-vehicles-pools-create-pool-dialog-add-pool-button-caption": "Add",
  "settings-vehicles-pools-create-pool-dialog-save-pool-button-caption": "Save",
  "settings-vehicles-pools-create-pool-dialog-cancel-pool-button-caption": "Cancel",
  "settings-vehicles-pools-create-pool-dialog-set-as-default-caption": "Set as default",
  "settings-vehicles-pools-reassign-vehicles-dialog-title": "Reassign Vehicles",
  "settings-vehicles-pools-reassign-vehicles-dialog-destination-pool-caption": "Destination Pool",
  "settings-vehicles-pools-reassign-vehicles-dialog-reassign-button-caption": "Reassign",
  "settings-vehicles-pools-reassign-vehicles-dialog-reassign-delete-success": "Vehicles reassigned successfully and pool is deleted",
  "settings-vehicles-pools-reassign-vehicles-dialog-reassign-delete-error": "Error reassigning vehicles and deleting the pool",
  "settings-vehicles-pools-reassign-vehicles-dialog-reassign-success": "Vehicles reassigned successfully",
  "settings-vehicles-pools-reassign-vehicles-dialog-reassign-error": "Error reassigning vehicles",
  "sign-in-button-reset-password": "Reset Password",
  "sign-in-button-sign-in": "Sign In",
  "sign-in-button-submit": "Submit",
  "sign-in-email": "Email",
  "sign-in-new-password": "New password",
  "sign-in-new-password-confirmation": "New password confirmation",
  "sign-in-password": "Password",
  "status": "Status",
  "status-card-button-show": "show",
  "status-card-text-amount-due": "Amount Due",
  "status-card-text-available-drivers": "Available Drivers",
  "status-card-text-available-trailers": "Available Trailers",
  "status-card-text-available-vehicles": "Available Vehicles",
  "status-card-text-mobility-appointments": "Mobility Bookings",
  "status-card-text-payments-due": "Payments Due",
  "status-card-text-vehicles-returning": "Vehicles Returning",
  "status-selector-label-filter-status": "Filter status",
  "status-selector-label-status": "Status",
  "stripe-error-card-declined": "Card was declined",
  "stripe-error-generic-decline": "Card was declined, try again with a different card",
  "stripe-error-insufficient-funds": "Card has unsufficient funds",
  "stripe-error-lost-card": "Card is reported lost",
  "stripe-error-stolen-card": "Card is reported stolen",
  "stripe-error-expired-card": "Card is expired",
  "stripe-error-incorrect-cvc": "Incorrect CVC",
  "stripe-error-processing-error": "Error processing card",
  "stripe-error-incorrect-number": "Incorrect card number",
  "stripe-error-card-velocity-exceeded": "Card exceeding velocity limit",
  "stripe-error-unknown-error": "A processing error occured",
  "submit": "Submit",
  "success-message-appointment": "Booking was successfully updated",
  "success-message-appointment-created": "Booking created successfully",
  "success-message-appointment-updated": "Booking updated successfully",
  "success-message-customer-created": "Customer created successfully",
  "success-message-customer-updated": "Customer updated successfully",
  "success-message-driver-created": "Driver created successfully",
  "success-message-driver-updated": "Driver updated successfully",
  "success-message-password-changed": "Your password has been changed successfully",
  "success-message-password-created": "Your password has been created successfully",
  "success-message-password-reset": "Your password has been reset successfully, please follow instruction in your email box",
  "success-message-settings-updated": "Settings were successfully updated",
  "success-message-geofence-deleted": "Geofence area was deleted",
  "success-message-trailer-created": "Trailer created successfully",
  "success-message-trailer-update": "Trailer updated successfully",
  "success-message-vehicle-created": "Vehicle created successfully",
  "success-message-vehicle-updated": "Vehicle updated successfully",
  "success-message-vehicle-deleted": "Vehicle deleted successfully",
  "success-message-trailer-deleted": "Trailer deleted successfully",
  "success-message-payment-method-updated": "Customer payment method updated successfully",
  "taxable": "Taxable",
  "to": "To",
  "to-lowercase": "to",
  "toll-road-fee": "Toll Road Fee",
  "total": "Total",
  "trailer-error-message-empty-field": "You can't leave field empty",
  "trailer-fee": "Trailer Fee",
  "trailers-add-trailer-button": "New Trailer",
  "trailers-calendar-autocomplete-label": "Trailers",
  "trailers-create-trailer-submit": "Submit",
  "trailers-create-trailer-title": "New Trailer",
  "trailers-detail-address": "Address",
  "trailers-detail-appointments-header": "Bookings",
  "trailers-detail-available-spots": "Available spots",
  "trailers-detail-capacity": "Capacity",
  "trailers-detail-disable-trailer-radio-button": "Active",
  "trailers-detail-driver": "Assigned Driver",
  "trailers-detail-expected-return": "Expected return",
  "trailers-detail-header": "Trailer Details",
  "trailers-detail-homebase-address": "Homebase Address",
  "trailers-detail-last-known-address": "Last Known Address",
  "trailers-detail-license-plate": "License Plate",
  "trailers-detail-logistic-type": "Logistic Type",
  "trailers-detail-modal-body-text": "This trailer does not have an assigned driver.",
  "trailers-detail-modal-header": "Assign driver",
  "trailers-detail-notes": "Notes",
  "trailers-detail-select-driver": "Select Driver",
  "trailers-detail-select-driver-button": "Select Driver",
  "trailers-detail-service-advisor": "Service advisor",
  "trailers-detail-start-date": "Start date",
  "trailers-detail-stock-number": "Stock Number",
  "trailers-detail-stock-number-caption": "Unique identifier",
  "trailers-detail-tab-future": "Future",
  "trailers-detail-tab-in-progress": "In progress",
  "trailers-detail-tab-past": "Past",
  "trailers-detail-total-capacity": "Total capacity",
  "trailers-detail-total-capacity-caption": "Total number of vehicles a trailer can transport. ",
  "trailers-detail-trailer-label": "Trailer",
  "trailers-status-active": "Disable trailer",
  "trailers-status-in-use": "In Use",
  "trailers-status-inactive": "Activate trailer",
  "trailers-status-on-lot": "On a Lot",
  "trailers-status-unavailable": "Unavailable",
  "trailers-table-heading-available-spots": "Available",
  "trailers-table-heading-capacity": "Capacity",
  "trailers-table-heading-driver": "Driver",
  "trailers-table-heading-last-known-address": "Last Known Address",
  "trailers-table-heading-status": "Status",
  "trailers-table-heading-stock-number": "Stock",
  "trailers-tabs-no-content": "No trailers found",
  "unavailable": "Unavailable",
  "update": "Update",
  "error-processing-payment": "Error processing payment",
  "payment-processed-successfully": "Payment processed successfully",
  "update-error-appointment-group": "Error updating booking group",
  "update-success-appointment-group": "Booking group updated successfully",
  "user-profile-change-password-button": "Change password",
  "user-profile-confirm-new-password": "Confirm new password",
  "user-profile-dealership": "Dealership",
  "user-profile-first-name": "First name",
  "user-profile-last-name": "Last name",
  "user-profile-logout": "Logout",
  "user-profile-new-password": "New password",
  "user-profile-title": "User profile",
  "valet-agreement": "Valet agreement",
  "valet-fee": "Valet Fee",
  "frais-de-valet": "Valet Fee",
  "vehicle-autocomplete-category-available": "Available",
  "vehicle-autocomplete-category-overdue": "Overdue",
  "vehicle-autocomplete-category-pools": "Vehicle Pools",
  "vehicle-color-beige": "Beige",
  "vehicle-color-black": "Black",
  "vehicle-color-blue": "Blue",
  "vehicle-color-brown": "Brown",
  "vehicle-color-gray": "Gray",
  "vehicle-color-green": "Green",
  "vehicle-color-label": "Color",
  "vehicle-color-orange": "Orange",
  "vehicle-color-purple": "Purple",
  "vehicle-color-red": "Red",
  "vehicle-color-silver": "Silver",
  "vehicle-color-white": "White",
  "vehicle-color-yellow": "Yellow",
  "vehicle-status-active": "Active",
  "vehicle-status-booked": "Booked",
  "vehicle-status-out-of-service": "Out of service",
  "vehicle-status-pd": "Past Due",
  "vehicle-status-av": "Available",
  "vehicle-status-bo": "Booked",
  "vehicle-status-cd": "Completed",
  "vehicle-status-connectivity-cd": "Connected",
  "vehicle-status-connectivity-dd": "Disconnected",
  "vehicle-status-connectivity-er": "Error",
  "vehicle-status-connectivity-nd": "Not Created",
  "vehicle-status-disabled": "Disabled",
  "vehicle-status-dl": "Delayed",
  "vehicle-status-di": "Disabled",
  "vehicle-status-ia": "Inactive",
  "vehicle-status-iae": "Inactive",
  "vehicle-status-in-progress": "In Progress",
  "vehicle-status-in-use": "In Use",
  "vehicle-status-inactive": "Inactive",
  "vehicle-status-inspect": "Inspect",
  "vehicle-status-ip": "In Progress",
  "vehicle-status-ips": "In Progress",
  "vehicle-status-is": "Inspect",
  "vehicle-status-not-utilized": "Not Utilized",
  "vehicle-status-od": "Overdue",
  "vehicle-status-ode": "Overdue",
  "vehicle-status-of": "Off Lot",
  "vehicle-status-off-lot": "Off Lot",
  "vehicle-status-ofl": "Off Lot",
  "vehicle-status-ol": "On Lot",
  "vehicle-status-on": "On Lot",
  "vehicle-status-on-lot": "On Lot",
  "vehicle-status-onl": "On Lot",
  "vehicle-status-on-job": "On Job",
  "vehicle-status-on-route": "On Route",
  "vehicle-status-or": "On Route",
  "vehicle-status-ov": "Overdue",
  "vehicle-status-overdue": "Overdue",
  "vehicle-status-past-due": "Past Due",
  "vehicle-status-reserved": "Reserved",
  "vehicle-status-returning": "Returning",
  "vehicle-status-rs": "Reserved",
  "vehicle-status-rsd": "Reserved",
  "vehicle-status-sd": "Scheduled",
  "vehicle-status-ua": "Unassigned",
  "vehicle-status-unavailable": "Unavailable",
  "vehicles-add-vehicle-button": "New vehicle",
  "vehicles-calendar-appointment-cell-at": "at",
  "vehicles-calendar-autocomplete-form-title": "Vehicles",
  "vehicles-calendar-edit-heading": "Vehicle Booking Time Change",
  "vehicles-calendar-popup-at": "at",
  "vehicles-calendar-popup-booking-conflict": "Booking Conflict",
  "vehicles-calendar-popup-cancel": "Cancel",
  "vehicles-calendar-popup-clear": "Clear",
  "vehicles-calendar-popup-confirm": "Confirm",
  "vehicles-calendar-popup-customer": "Customer",
  "vehicles-calendar-popup-delete": "Delete",
  "vehicles-calendar-popup-edit": "Edit",
  "vehicles-calendar-popup-enter-end-from-date": "Enter new end date",
  "vehicles-calendar-popup-enter-new-from-date": "Enter new from date",
  "vehicles-calendar-popup-from:": "From: ",
  "vehicles-calendar-popup-fuel": "Fuel",
  "vehicles-calendar-popup-is-active": "Popup is active",
  "vehicles-calendar-popup-license-plate": "License plate",
  "vehicles-calendar-popup-loaner-time-change": "Loaner Time Change",
  "vehicles-calendar-popup-mileage": "Mileage",
  "vehicles-calendar-popup-new": "New",
  "vehicles-calendar-popup-ok": "Ok",
  "vehicles-calendar-popup-original": "Original",
  "vehicles-calendar-popup-placement": "Placement",
  "vehicles-calendar-popup-return-date": "Return date",
  "vehicles-calendar-popup-select-date-&-time": "Select date & time",
  "vehicles-calendar-popup-service-advisor": "Service advisor",
  "vehicles-calendar-popup-start-date": "Start date",
  "vehicles-calendar-popup-the-vehicle-is-already-booked-for-this-date-and-time": "The vehicle is already booked for this date and time.",
  "vehicles-calendar-popup-to:": "To: ",
  "vehicles-calendar-popup-vin": "VIN",
  "vehicles-calendar-popup-width": "Width",
  "vehicles-create-drawer-color": "Color",
  "vehicles-create-drawer-license-plate": "License Plate",
  "vehicles-create-drawer-make": "Make",
  "vehicles-create-drawer-model": "Model",
  "vehicles-create-drawer-stock-number": "Stock Number",
  "vehicles-create-drawer-submit-button": "Create",
  "vehicles-create-drawer-title": "New vehicle",
  "vehicles-create-drawer-vin": "VIN",
  "vehicles-create-drawer-year": "Year",
  "vehicles-create-drawer-connect-this-vehicle": "Connect this Vehicle",
  "vehicle-detail-edit-vehicle-detail-button-caption": "Edit",
  "vehicle-detail-cancel-edit-vehicle-detail-button-caption": "Cancel",
  "vehicle-detail-save-vehicle-detail-button-caption": "Save",
  "vehicles-detail-active": "Active",
  "vehicles-detail-appointment": "Booking",
  "vehicles-detail-customer": "Customer",
  "vehicles-detail-expected-return": "Expected Return",
  "vehicles-detail-fuel": "Fuel",
  "vehicles-detail-in-use": "In Progress",
  "vehicles-detail-inactive": "Out of service",
  "vehicles-detail-license-plate": "License Plate",
  "vehicles-detail-mileage": "Mileage",
  "vehicles-detail-more": "More",
  "vehicles-detail-name": "Name",
  "vehicles-detail-phone": "Phone",
  "vehicles-detail-start-date": "Start Date",
  "vehicles-detail-stock-number": "Stock Number",
  "vehicles-detail-vin": "VIN",
  "vehicles-detail-year": "Year",
  "vehicles-detail-make": "Make",
  "vehicles-detail-model": "Model",
  "vehicles-detail-color": "Color",
  "vehicles-detail-stocknumber": "Stock #",
  "vehicles-detail-licenseplate": "License Plate",
  "vehicles-detail-title": "Vehicle Details",
  "vehicles-detail-connect-vehicle": "Connect Vehicle",
  "vehicles-detail-disconnect-vehicle": "Disconnect Vehicle",
  "vehicles-detail-connectivity-not-supported-tooltip": "Connectivity not available with this OEM",
  "vehicles-detail-connect-vehicle-success": "Vehicle successfully connected",
  "vehicles-detail-connect-vehicle-failure": "An error occured while connecting the vehicle",
  "vehicles-detail-disconnect-vehicle-success": "Vehicle successfully disconnected",
  "vehicles-detail-disconnect-vehicle-failure": "An error occured while disconnecting the vehicle",
  "vehicles-detail-disconnect-confirmation-dialog-header": "Are you sure you want to disconnect this vehicle?",
  "vehicles-detail-disconnect-confirmation-dialog-cancel-button": "Cancel",
  "vehicles-detail-disconnect-confirmation-dialog-disconnect-button": "Disconnect",
  "vehicle-detail-vehicle-pool-dropdown-caption": "Vehicle Pool",
  "vehicle-detail-vehicle-pool-dropdown-helptext": "Select the vehicle's pool",
  "vehicle-detail-vehicle-pool-save-button-caption": "Save",
  "vehicles-map-detail-color": "Color",
  "vehicles-map-detail-create-new-appointment-with-this-vehicle": "Create new booking with this vehicle.",
  "vehicles-map-detail-edit": "Edit",
  "vehicles-map-detail-customer": "Customer",
  "vehicles-map-detail-end-date": "Expected return",
  "vehicles-map-detail-expected-return": "Expected Return",
  "vehicles-map-detail-fuel": "Fuel",
  "vehicles-map-detail-future": "Future",
  "vehicles-map-detail-in-progress": "In progress",
  "vehicles-map-detail-license-plate": "License Plate",
  "vehicles-map-detail-logistic-type": "Logistic Type",
  "vehicles-map-detail-make": "Make",
  "vehicles-map-detail-mileage": "Mileage",
  "vehicles-map-detail-model": "Model",
  "vehicles-map-detail-new-appointment": "New booking",
  "vehicles-map-detail-no-appointments-in-progress": "No bookings in progress",
  "vehicles-map-detail-no-future-appointments": "No future bookings",
  "vehicles-map-detail-no-past-appointments": "No past bookings",
  "vehicles-map-detail-notes": "Notes",
  "vehicles-map-detail-past": "Past",
  "vehicles-map-detail-service-advisor": "Service Advisor",
  "vehicles-map-detail-start-date": "Start Date",
  "vehicles-map-detail-stock-#": "Stock #",
  "vehicles-map-detail-title": "Vehicle Details",
  "vehicles-map-detail-appointment-title": "Details",
  "vehicles-map-detail-title-appointments": "Bookings",
  "vehicles-map-detail-vin": "VIN",
  "vehicles-map-detail-year": "Year",
  "vehicles-table-heading-connectivity": "Sync",
  "vehicles-table-heading-customer": "Customer",
  "vehicles-table-heading-expected-return": "Expected Return",
  "vehicles-table-heading-fuel": "Fuel",
  "vehicles-table-heading-mileage": "Mileage",
  "vehicles-table-heading-status": "Status",
  "vehicles-table-heading-location": "Location",
  "vehicles-table-heading-stock-#": "Stock #",
  "vehicles-table-heading-vehicle": "Vehicle",
  "vehicles-table-heading-vin": "VIN",
  "vehicles-table-heading-vehicle-pool": "Pool",
  "vehicle-table-filter-vehicle-pool-caption": "Filter Vehicle Pools",
  "vehicle-table-vehicle-needs-initial-configuration-heading": "This vehicle Needs Initial Configuration",
  "vehicle-table-vehicle-needs-initial-configuration": "Configure Vehicle",
  "vehicle-detail-field-vehicle-needs-initial-configuration": "Configure",
  "vehicles-table-no-vehicles-were-found": "No vehicles were found",
  "vehicle-table-generate-usage-report-button": "Usage Report",
  "usage-report-dialog-title": "Generate Fleet Usage Report",
  "usage-report-dialog-report-begin-date": "Report Start Date",
  "usage-report-dialog-report-end-date": "Report End Date",
  "usage-report-dialog-report-filename-prefix": "Filename Prefix (optional)",
  "usage-report-dialog-report-filename-prefix-too-long": "Maximum 20 characters",
  "usage-report-dialog-report-filename-prefix-invalid-characters": "Only Alphanumerical, dashes and underscore characters allowed",
  "usage-report-dialog-report-format": "File Format",
  "usage-report-dialog-generate-button": "Generate",
  "usage-report-dialog-cancel-button": "Cancel",
  "usage-report-dialog-success": "Your report is being generated. You will receive it by e-mail shortly",
  "usage-report-dialog-error": "An error occured while generating your report",
  "vehicles-utilization": "Vehicles Utilization",
  "vehicles-utilization-description-positive": "Great job! There is enough buffer and vehicles are utilized well",
  "vehicles-utilization-status-positive": "up in comparison to average utilization this month",
  "vehicle-detail-status-select-option": "Change to {status}",
  "error-message-geocoder-geocode-over-query-limit": "Too many requests in a short time. Please try again in a few seconds.",
  "payment-method-heading": "Card payment method",
  "not-provided": "Not Provided",
  "payment-method-card-ending": "ending in",
  "payment-method-card-expiration": "Expires",
  "vehicle-delete": "Delete Vehicle",
  "trailer-delete": "Delete Trailer",
  "vehicle-delete-confirmation-heading": "Delete this vehicle?",
  "trailer-delete-confirmation-heading": "Delete this trailer?",
  "geofence-delete-confirmation-heading": "Delete geofence area?",
  "notifications-mark-unread": "Mark as unread",
  "notifications-mark-read": "Mark as read",
  "notifications-mark-all-read": "Mark all as read",
  "calendar-type-week": "Weekly",
  "calendar-type-date": "Daily",
  "calendar-type": "Calendar Type",
  "no-customer": "No customer found",
  "warning-this-vehicle-is-overdue": "Warning: this vehicle is overdue"
}
