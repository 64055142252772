{
  "UN": {
    "name": "Unspecified",
    "regions": [
        {
          "name": "Unspecified",
          "id": 65,
          "coordinates": [
            [
              [
                -0.0,
                0.0
              ]
            ]
          ],
          "iso_code": "UN"
        }
      ]
    },
  "CA": {
    "name": "Canada",
    "regions": [
      {
        "name": "British Columbia",
        "id": 53,
        "coordinates": [
          [
            [
              -131.077103,
              52.150089
            ],
            [
              -130.948105,
              52.093609
            ],
            [
              -130.992493,
              52.060822
            ],
            [
              -131.070007,
              52.07222
            ],
            [
              -130.985504,
              52.012211
            ],
            [
              -131.041702,
              52.022491
            ],
            [
              -131.007004,
              51.97887
            ],
            [
              -131.0392,
              51.96693
            ],
            [
              -131.021698,
              51.94083
            ],
            [
              -131.110306,
              51.999161
            ],
            [
              -131.085007,
              52.023319
            ],
            [
              -131.120499,
              52.05555
            ],
            [
              -131.122696,
              52.123779
            ],
            [
              -131.077103,
              52.150089
            ]
          ],
          [
            [
              -130.164993,
              55.01416
            ],
            [
              -130.149704,
              54.947491
            ],
            [
              -130.270294,
              54.83083
            ],
            [
              -130.3992,
              54.764721
            ],
            [
              -130.459396,
              54.817501
            ],
            [
              -130.213303,
              55.012501
            ],
            [
              -130.164993,
              55.01416
            ]
          ],
          [
            [
              -130.458603,
              54.80027
            ],
            [
              -130.435806,
              54.763329
            ],
            [
              -130.463303,
              54.708599
            ],
            [
              -130.538605,
              54.70388
            ],
            [
              -130.610504,
              54.753052
            ],
            [
              -130.458603,
              54.80027
            ]
          ],
          [
            [
              -130.746902,
              54.615269
            ],
            [
              -130.760803,
              54.55471
            ],
            [
              -130.964996,
              54.45694
            ],
            [
              -130.925598,
              54.619438
            ],
            [
              -130.746902,
              54.615269
            ]
          ],
          [
            [
              -132.8806,
              53.600269
            ],
            [
              -133.007797,
              53.67638
            ],
            [
              -132.925293,
              53.690269
            ],
            [
              -132.979401,
              53.710819
            ],
            [
              -132.972794,
              53.763889
            ],
            [
              -133.0336,
              53.752781
            ],
            [
              -133.109207,
              53.786942
            ],
            [
              -133.067001,
              53.820831
            ],
            [
              -133.134705,
              53.87027
            ],
            [
              -133.139999,
              53.908039
            ],
            [
              -133.090805,
              53.954159
            ],
            [
              -133.102798,
              53.994999
            ],
            [
              -133.041107,
              54.031658
            ],
            [
              -133.081894,
              54.102489
            ],
            [
              -133.029205,
              54.158878
            ],
            [
              -133.0672,
              54.173882
            ],
            [
              -132.827499,
              54.12249
            ],
            [
              -132.658401,
              54.14222
            ],
            [
              -132.575302,
              54.115551
            ],
            [
              -132.566696,
              54.02916
            ],
            [
              -132.6642,
              53.98333
            ],
            [
              -132.681702,
              53.95277
            ],
            [
              -132.658905,
              53.93943
            ],
            [
              -132.409698,
              54.098598
            ],
            [
              -132.290604,
              54.11055
            ],
            [
              -132.228302,
              54.06583
            ],
            [
              -132.232803,
              54.025269
            ],
            [
              -132.162506,
              54.005268
            ],
            [
              -132.111099,
              53.87833
            ],
            [
              -132.225006,
              53.78027
            ],
            [
              -132.6633,
              53.67944
            ],
            [
              -132.595001,
              53.672771
            ],
            [
              -132.599197,
              53.626659
            ],
            [
              -132.541702,
              53.66444
            ],
            [
              -132.491898,
              53.64222
            ],
            [
              -132.350296,
              53.668331
            ],
            [
              -132.463593,
              53.61277
            ],
            [
              -132.468308,
              53.570831
            ],
            [
              -132.418106,
              53.606098
            ],
            [
              -132.279205,
              53.623322
            ],
            [
              -132.328293,
              53.64333
            ],
            [
              -132.315308,
              53.666939
            ],
            [
              -132.200302,
              53.65221
            ],
            [
              -132.155594,
              53.699711
            ],
            [
              -132.190002,
              53.783051
            ],
            [
              -132.079407,
              53.889431
            ],
            [
              -132.133606,
              54.010551
            ],
            [
              -132.177505,
              54.04055
            ],
            [
              -131.9711,
              54.025269
            ],
            [
              -131.727798,
              54.106098
            ],
            [
              -131.655899,
              54.15971
            ],
            [
              -131.705002,
              53.96693
            ],
            [
              -131.868896,
              53.793331
            ],
            [
              -131.865494,
              53.721661
            ],
            [
              -131.933304,
              53.615269
            ],
            [
              -131.911102,
              53.351662
            ],
            [
              -131.982193,
              53.251659
            ],
            [
              -132.210495,
              53.230549
            ],
            [
              -132.272797,
              53.21027
            ],
            [
              -132.237793,
              53.188881
            ],
            [
              -132.124405,
              53.194149
            ],
            [
              -132.186996,
              53.160549
            ],
            [
              -132.316406,
              53.189709
            ],
            [
              -132.3228,
              53.159431
            ],
            [
              -132.446701,
              53.143608
            ],
            [
              -132.459198,
              53.18388
            ],
            [
              -132.499405,
              53.159161
            ],
            [
              -132.536697,
              53.178879
            ],
            [
              -132.520798,
              53.193321
            ],
            [
              -132.577805,
              53.20055
            ],
            [
              -132.549698,
              53.23138
            ],
            [
              -132.703094,
              53.257771
            ],
            [
              -132.609207,
              53.314991
            ],
            [
              -132.537003,
              53.32111
            ],
            [
              -132.752808,
              53.316101
            ],
            [
              -132.697495,
              53.376942
            ],
            [
              -132.514206,
              53.358601
            ],
            [
              -132.410599,
              53.29472
            ],
            [
              -132.400299,
              53.33083
            ],
            [
              -132.520798,
              53.412209
            ],
            [
              -132.660004,
              53.434719
            ],
            [
              -132.660599,
              53.489429
            ],
            [
              -132.688293,
              53.44833
            ],
            [
              -132.752808,
              53.4575
            ],
            [
              -132.719101,
              53.490269
            ],
            [
              -132.743607,
              53.515549
            ],
            [
              -132.867493,
              53.463612
            ],
            [
              -132.823593,
              53.516941
            ],
            [
              -132.923599,
              53.519161
            ],
            [
              -132.889999,
              53.55777
            ],
            [
              -132.968308,
              53.551659
            ],
            [
              -132.994095,
              53.587769
            ],
            [
              -132.8806,
              53.600269
            ]
          ],
          [
            [
              -130.336105,
              54.067501
            ],
            [
              -130.228897,
              53.96471
            ],
            [
              -130.344498,
              53.83527
            ],
            [
              -130.406403,
              53.83416
            ],
            [
              -130.4272,
              53.871658
            ],
            [
              -130.483597,
              53.84388
            ],
            [
              -130.497498,
              53.8736
            ],
            [
              -130.449997,
              53.888599
            ],
            [
              -130.407806,
              53.963329
            ],
            [
              -130.327499,
              53.96693
            ],
            [
              -130.349197,
              53.997219
            ],
            [
              -130.443893,
              53.9786
            ],
            [
              -130.482498,
              53.893608
            ],
            [
              -130.667007,
              53.963879
            ],
            [
              -130.698105,
              53.91415
            ],
            [
              -130.617996,
              53.85722
            ],
            [
              -130.642502,
              53.832771
            ],
            [
              -130.710297,
              53.85638
            ],
            [
              -130.720306,
              53.934719
            ],
            [
              -130.661697,
              53.990829
            ],
            [
              -130.411102,
              54.10083
            ],
            [
              -130.336105,
              54.067501
            ]
          ],
          [
            [
              -129.479996,
              53.249161
            ],
            [
              -129.491394,
              53.209431
            ],
            [
              -129.572495,
              53.20916
            ],
            [
              -129.658905,
              53.29694
            ],
            [
              -129.635605,
              53.311939
            ],
            [
              -129.660294,
              53.342491
            ],
            [
              -129.711105,
              53.33416
            ],
            [
              -129.807205,
              53.384159
            ],
            [
              -129.815796,
              53.466381
            ],
            [
              -129.895004,
              53.477489
            ],
            [
              -129.8703,
              53.545551
            ],
            [
              -129.922501,
              53.563049
            ],
            [
              -129.883896,
              53.57972
            ],
            [
              -129.936401,
              53.587212
            ],
            [
              -129.911697,
              53.611382
            ],
            [
              -130.016098,
              53.572769
            ],
            [
              -130.056396,
              53.603882
            ],
            [
              -129.965393,
              53.641781
            ],
            [
              -129.874405,
              53.638889
            ],
            [
              -129.9496,
              53.659248
            ],
            [
              -130.083893,
              53.641109
            ],
            [
              -130.099197,
              53.702221
            ],
            [
              -130.209702,
              53.72887
            ],
            [
              -130.2108,
              53.810822
            ],
            [
              -130.286407,
              53.83749
            ],
            [
              -130.266403,
              53.881378
            ],
            [
              -130.186401,
              53.9161
            ],
            [
              -130.030899,
              53.845268
            ],
            [
              -129.523605,
              53.496658
            ],
            [
              -129.635605,
              53.480549
            ],
            [
              -129.491898,
              53.470268
            ],
            [
              -129.425293,
              53.43082
            ],
            [
              -129.453598,
              53.296661
            ],
            [
              -129.516403,
              53.302219
            ],
            [
              -129.479996,
              53.249161
            ]
          ],
          [
            [
              -130.271896,
              53.797779
            ],
            [
              -130.2258,
              53.77499
            ],
            [
              -130.225006,
              53.720268
            ],
            [
              -130.1194,
              53.686649
            ],
            [
              -130.091095,
              53.569439
            ],
            [
              -130.329193,
              53.61805
            ],
            [
              -130.403397,
              53.682499
            ],
            [
              -130.287506,
              53.748878
            ],
            [
              -130.301697,
              53.79388
            ],
            [
              -130.271896,
              53.797779
            ]
          ],
          [
            [
              -128.823593,
              53.70055
            ],
            [
              -128.872192,
              53.661098
            ],
            [
              -128.871597,
              53.57222
            ],
            [
              -128.986404,
              53.58083
            ],
            [
              -129.085495,
              53.505001
            ],
            [
              -129.086395,
              53.446098
            ],
            [
              -129.163101,
              53.38221
            ],
            [
              -129.153107,
              53.638599
            ],
            [
              -129.008102,
              53.693321
            ],
            [
              -128.823593,
              53.70055
            ]
          ],
          [
            [
              -130.391098,
              53.61694
            ],
            [
              -129.920593,
              53.42416
            ],
            [
              -129.729202,
              53.215271
            ],
            [
              -129.742493,
              53.178329
            ],
            [
              -129.931702,
              53.158039
            ],
            [
              -130.014496,
              53.246941
            ],
            [
              -130.078903,
              53.270271
            ],
            [
              -130.094498,
              53.325829
            ],
            [
              -130.140594,
              53.321941
            ],
            [
              -130.203293,
              53.37888
            ],
            [
              -130.305801,
              53.384159
            ],
            [
              -130.412003,
              53.49332
            ],
            [
              -130.333893,
              53.514999
            ],
            [
              -130.458603,
              53.514721
            ],
            [
              -130.527206,
              53.552219
            ],
            [
              -130.508408,
              53.631939
            ],
            [
              -130.391098,
              53.61694
            ]
          ],
          [
            [
              -128.985504,
              53.523048
            ],
            [
              -128.901093,
              53.386662
            ],
            [
              -128.918106,
              53.331379
            ],
            [
              -129.122192,
              53.31805
            ],
            [
              -129.134506,
              53.383881
            ],
            [
              -129.054993,
              53.450829
            ],
            [
              -129.037796,
              53.520271
            ],
            [
              -128.985504,
              53.523048
            ]
          ],
          [
            [
              -129.168594,
              53.296101
            ],
            [
              -129.131897,
              53.113609
            ],
            [
              -129.274994,
              53.084431
            ],
            [
              -129.332504,
              53.137501
            ],
            [
              -129.338303,
              53.18499
            ],
            [
              -129.245499,
              53.282211
            ],
            [
              -129.273895,
              53.328049
            ],
            [
              -129.226898,
              53.326099
            ],
            [
              -129.168594,
              53.296101
            ]
          ],
          [
            [
              -128.593597,
              52.659161
            ],
            [
              -128.597198,
              52.609161
            ],
            [
              -128.751694,
              52.60083
            ],
            [
              -128.727798,
              52.662491
            ],
            [
              -128.748001,
              52.754169
            ],
            [
              -128.644699,
              52.90416
            ],
            [
              -128.653397,
              52.96666
            ],
            [
              -128.679993,
              52.957771
            ],
            [
              -128.681107,
              52.898609
            ],
            [
              -128.742798,
              52.91444
            ],
            [
              -128.724197,
              52.876659
            ],
            [
              -128.780594,
              52.73999
            ],
            [
              -128.766098,
              52.661659
            ],
            [
              -128.801102,
              52.693321
            ],
            [
              -128.892502,
              52.64888
            ],
            [
              -129.080307,
              52.772491
            ],
            [
              -129.119995,
              52.86916
            ],
            [
              -129.016998,
              52.84444
            ],
            [
              -129.068298,
              52.889992
            ],
            [
              -128.983902,
              52.934719
            ],
            [
              -128.941406,
              53
            ],
            [
              -128.836395,
              53.035549
            ],
            [
              -129.011703,
              53.139721
            ],
            [
              -128.993896,
              53.09721
            ],
            [
              -128.862198,
              53.034161
            ],
            [
              -128.957199,
              53.008331
            ],
            [
              -129.069199,
              52.903881
            ],
            [
              -129.165802,
              52.92305
            ],
            [
              -129.188004,
              53.024158
            ],
            [
              -129.161697,
              53.07111
            ],
            [
              -129.115005,
              53.064709
            ],
            [
              -129.073303,
              53.109161
            ],
            [
              -129.058899,
              53.23138
            ],
            [
              -129.086105,
              53.29361
            ],
            [
              -128.891403,
              53.287769
            ],
            [
              -128.705307,
              53.170551
            ],
            [
              -128.605606,
              53.145271
            ],
            [
              -128.531692,
              53.021099
            ],
            [
              -128.517807,
              52.92749
            ],
            [
              -128.593597,
              52.659161
            ]
          ],
          [
            [
              -131.074402,
              52.219379
            ],
            [
              -131.014999,
              52.225552
            ],
            [
              -131.024704,
              52.178051
            ],
            [
              -131.113907,
              52.16777
            ],
            [
              -131.136993,
              52.209721
            ],
            [
              -131.143906,
              52.140831
            ],
            [
              -131.189697,
              52.121929
            ],
            [
              -131.212204,
              52.191929
            ],
            [
              -131.277206,
              52.240829
            ],
            [
              -131.232193,
              52.16193
            ],
            [
              -131.236893,
              52.126942
            ],
            [
              -131.270798,
              52.120831
            ],
            [
              -131.349197,
              52.233051
            ],
            [
              -131.360306,
              52.189159
            ],
            [
              -131.505905,
              52.321659
            ],
            [
              -131.551102,
              52.323879
            ],
            [
              -131.532501,
              52.351391
            ],
            [
              -131.571106,
              52.350269
            ],
            [
              -131.580597,
              52.390549
            ],
            [
              -131.510605,
              52.408878
            ],
            [
              -131.613007,
              52.422771
            ],
            [
              -131.661697,
              52.478321
            ],
            [
              -131.775299,
              52.511669
            ],
            [
              -131.892807,
              52.582771
            ],
            [
              -131.933594,
              52.653881
            ],
            [
              -132.081894,
              52.727489
            ],
            [
              -132.040298,
              52.760551
            ],
            [
              -131.968597,
              52.726101
            ],
            [
              -132.002502,
              52.776939
            ],
            [
              -131.917496,
              52.722488
            ],
            [
              -131.951706,
              52.781109
            ],
            [
              -132.077194,
              52.854439
            ],
            [
              -132.055603,
              52.79361
            ],
            [
              -132.127502,
              52.750832
            ],
            [
              -132.236694,
              52.824711
            ],
            [
              -132.2258,
              52.864441
            ],
            [
              -132.283295,
              52.87471
            ],
            [
              -132.343002,
              52.942211
            ],
            [
              -132.2453,
              52.95388
            ],
            [
              -132.171097,
              52.923611
            ],
            [
              -132.199997,
              52.960548
            ],
            [
              -132.141098,
              52.93277
            ],
            [
              -132.157501,
              52.964161
            ],
            [
              -132.112793,
              52.997768
            ],
            [
              -132.339996,
              53.037769
            ],
            [
              -132.479706,
              53.02721
            ],
            [
              -132.552795,
              53.08971
            ],
            [
              -132.5289,
              53.112209
            ],
            [
              -132.562195,
              53.145271
            ],
            [
              -132.413605,
              53.127491
            ],
            [
              -132.070801,
              53.153881
            ],
            [
              -131.985001,
              53.212212
            ],
            [
              -131.794495,
              53.251389
            ],
            [
              -131.762207,
              53.196659
            ],
            [
              -131.784195,
              53.164711
            ],
            [
              -131.637497,
              53.109718
            ],
            [
              -131.594696,
              53.035271
            ],
            [
              -131.794693,
              53.073051
            ],
            [
              -131.998901,
              53.05138
            ],
            [
              -131.945801,
              53.04166
            ],
            [
              -131.917007,
              52.984718
            ],
            [
              -131.847794,
              53.043049
            ],
            [
              -131.644699,
              53.00388
            ],
            [
              -131.598099,
              52.975269
            ],
            [
              -131.628601,
              52.94276
            ],
            [
              -131.611694,
              52.926102
            ],
            [
              -131.6875,
              52.86916
            ],
            [
              -131.8022,
              52.864719
            ],
            [
              -131.8983,
              52.941101
            ],
            [
              -131.944702,
              52.93166
            ],
            [
              -131.891403,
              52.909988
            ],
            [
              -131.982498,
              52.879711
            ],
            [
              -131.886993,
              52.88916
            ],
            [
              -131.852493,
              52.861938
            ],
            [
              -131.880798,
              52.833328
            ],
            [
              -131.812195,
              52.797218
            ],
            [
              -131.6492,
              52.800541
            ],
            [
              -131.851105,
              52.766109
            ],
            [
              -131.806396,
              52.748329
            ],
            [
              -131.837799,
              52.710819
            ],
            [
              -131.726898,
              52.695541
            ],
            [
              -131.681396,
              52.637501
            ],
            [
              -131.705002,
              52.621658
            ],
            [
              -131.615295,
              52.56889
            ],
            [
              -131.660599,
              52.538891
            ],
            [
              -131.467499,
              52.501389
            ],
            [
              -131.483597,
              52.479431
            ],
            [
              -131.415604,
              52.45805
            ],
            [
              -131.447006,
              52.390549
            ],
            [
              -131.391403,
              52.390831
            ],
            [
              -131.397202,
              52.35305
            ],
            [
              -131.347504,
              52.36805
            ],
            [
              -131.353104,
              52.418331
            ],
            [
              -131.236404,
              52.439159
            ],
            [
              -131.301102,
              52.38055
            ],
            [
              -131.304199,
              52.356659
            ],
            [
              -131.247498,
              52.365829
            ],
            [
              -131.263107,
              52.34277
            ],
            [
              -131.358902,
              52.343609
            ],
            [
              -131.328293,
              52.285
            ],
            [
              -131.226898,
              52.251942
            ],
            [
              -131.248306,
              52.302219
            ],
            [
              -131.136398,
              52.311378
            ],
            [
              -131.089401,
              52.249161
            ],
            [
              -131.180298,
              52.228321
            ],
            [
              -131.074402,
              52.219379
            ]
          ],
          [
            [
              -129.462799,
              53.179989
            ],
            [
              -129.313004,
              53.057499
            ],
            [
              -129.289505,
              52.971931
            ],
            [
              -129.4142,
              53.010551
            ],
            [
              -129.475296,
              53.10194
            ],
            [
              -129.544693,
              53.12833
            ],
            [
              -129.521698,
              53.183601
            ],
            [
              -129.462799,
              53.179989
            ]
          ],
          [
            [
              -128.498901,
              52.870831
            ],
            [
              -128.450806,
              52.805271
            ],
            [
              -128.512497,
              52.641109
            ],
            [
              -128.539993,
              52.70332
            ],
            [
              -128.498901,
              52.870831
            ]
          ],
          [
            [
              -128.170303,
              52.817768
            ],
            [
              -128.247192,
              52.620541
            ],
            [
              -128.2845,
              52.598598
            ],
            [
              -128.324997,
              52.7761
            ],
            [
              -128.225006,
              52.782211
            ],
            [
              -128.170303,
              52.817768
            ]
          ],
          [
            [
              -129.231094,
              52.816101
            ],
            [
              -128.923294,
              52.606659
            ],
            [
              -128.943604,
              52.469711
            ],
            [
              -128.982803,
              52.453049
            ],
            [
              -129.008896,
              52.525551
            ],
            [
              -129.109695,
              52.552769
            ],
            [
              -129.118896,
              52.60471
            ],
            [
              -129.2108,
              52.64888
            ],
            [
              -129.224197,
              52.67749
            ],
            [
              -129.193893,
              52.683048
            ],
            [
              -129.270599,
              52.719151
            ],
            [
              -129.281403,
              52.817211
            ],
            [
              -129.231094,
              52.816101
            ]
          ],
          [
            [
              -128.373306,
              52.791111
            ],
            [
              -128.274994,
              52.48999
            ],
            [
              -128.365799,
              52.38221
            ],
            [
              -128.441696,
              52.36805
            ],
            [
              -128.467804,
              52.393051
            ],
            [
              -128.394196,
              52.465
            ],
            [
              -128.423096,
              52.474991
            ],
            [
              -128.437805,
              52.54361
            ],
            [
              -128.450607,
              52.626942
            ],
            [
              -128.418304,
              52.70805
            ],
            [
              -128.441696,
              52.746941
            ],
            [
              -128.373306,
              52.791111
            ]
          ],
          [
            [
              -131.468903,
              52.730549
            ],
            [
              -131.439194,
              52.70166
            ],
            [
              -131.458298,
              52.630821
            ],
            [
              -131.5905,
              52.662491
            ],
            [
              -131.593308,
              52.63361
            ],
            [
              -131.547806,
              52.611382
            ],
            [
              -131.585495,
              52.58527
            ],
            [
              -131.709702,
              52.705269
            ],
            [
              -131.574707,
              52.72694
            ],
            [
              -131.674698,
              52.70499
            ],
            [
              -131.549393,
              52.688042
            ],
            [
              -131.468903,
              52.730549
            ]
          ],
          [
            [
              -128.535294,
              52.647221
            ],
            [
              -128.464996,
              52.473049
            ],
            [
              -128.486893,
              52.43721
            ],
            [
              -128.536407,
              52.43499
            ],
            [
              -128.6828,
              52.514439
            ],
            [
              -128.714706,
              52.483879
            ],
            [
              -128.813004,
              52.519989
            ],
            [
              -128.802505,
              52.546101
            ],
            [
              -128.697006,
              52.520828
            ],
            [
              -128.738907,
              52.58749
            ],
            [
              -128.577499,
              52.593609
            ],
            [
              -128.552505,
              52.665272
            ],
            [
              -128.535294,
              52.647221
            ]
          ],
          [
            [
              -128.616104,
              52.448601
            ],
            [
              -128.617798,
              52.32999
            ],
            [
              -128.675598,
              52.266392
            ],
            [
              -128.726395,
              52.316101
            ],
            [
              -128.759201,
              52.449429
            ],
            [
              -128.674393,
              52.482208
            ],
            [
              -128.616104,
              52.448601
            ]
          ],
          [
            [
              -127.596298,
              52.151791
            ],
            [
              -127.6539,
              52.130821
            ],
            [
              -127.717499,
              51.98027
            ],
            [
              -127.8797,
              51.944988
            ],
            [
              -127.9011,
              51.985821
            ],
            [
              -127.8797,
              52.084991
            ],
            [
              -127.7892,
              52.221931
            ],
            [
              -127.510002,
              52.306938
            ],
            [
              -127.400299,
              52.395271
            ],
            [
              -127.263901,
              52.436649
            ],
            [
              -127.234703,
              52.416939
            ],
            [
              -127.244499,
              52.36916
            ],
            [
              -127.207802,
              52.344711
            ],
            [
              -127.232201,
              52.313049
            ],
            [
              -127.596298,
              52.151791
            ]
          ],
          [
            [
              -128.092499,
              52.408329
            ],
            [
              -128.056702,
              52.32888
            ],
            [
              -128.213593,
              52.27972
            ],
            [
              -128.1147,
              52.418331
            ],
            [
              -128.092499,
              52.408329
            ]
          ],
          [
            [
              -127.961899,
              52.28944
            ],
            [
              -127.907501,
              52.204708
            ],
            [
              -127.924698,
              52.17416
            ],
            [
              -128.092804,
              52.192211
            ],
            [
              -128.011703,
              52.23193
            ],
            [
              -128.046402,
              52.258049
            ],
            [
              -128.169998,
              52.249722
            ],
            [
              -128.047806,
              52.300541
            ],
            [
              -127.961899,
              52.28944
            ]
          ],
          [
            [
              -128.148102,
              52.183331
            ],
            [
              -128.151093,
              52.081108
            ],
            [
              -128.2061,
              52.018879
            ],
            [
              -128.253098,
              52.019711
            ],
            [
              -128.291702,
              52.118881
            ],
            [
              -128.2397,
              52.184158
            ],
            [
              -128.148102,
              52.183331
            ]
          ],
          [
            [
              -128.031692,
              52.163288
            ],
            [
              -127.879204,
              52.16666
            ],
            [
              -127.962799,
              52.074711
            ],
            [
              -128.105804,
              52.100269
            ],
            [
              -128.1147,
              52.149158
            ],
            [
              -128.031692,
              52.163288
            ]
          ],
          [
            [
              -127.951202,
              52.046909
            ],
            [
              -127.970596,
              51.925831
            ],
            [
              -128.032196,
              51.840549
            ],
            [
              -128.0186,
              51.770271
            ],
            [
              -128.131897,
              51.743881
            ],
            [
              -128.110504,
              51.801929
            ],
            [
              -128.132797,
              51.85527
            ],
            [
              -128.254501,
              51.865829
            ],
            [
              -128.199203,
              51.913052
            ],
            [
              -128.222198,
              51.95332
            ],
            [
              -128.145294,
              52.038601
            ],
            [
              -127.951202,
              52.046909
            ]
          ],
          [
            [
              -127.998001,
              51.711658
            ],
            [
              -127.873596,
              51.464439
            ],
            [
              -127.914398,
              51.41082
            ],
            [
              -128.003403,
              51.468601
            ],
            [
              -128.0811,
              51.474709
            ],
            [
              -128.153107,
              51.601662
            ],
            [
              -128.136398,
              51.661659
            ],
            [
              -127.998001,
              51.711658
            ]
          ],
          [
            [
              -127.121101,
              49.852291
            ],
            [
              -127.241402,
              49.961941
            ],
            [
              -127.178596,
              50.009159
            ],
            [
              -127.176697,
              50.0611
            ],
            [
              -127.091904,
              50.09943
            ],
            [
              -127.090302,
              50.141941
            ],
            [
              -127.172501,
              50.09193
            ],
            [
              -127.292503,
              50.146389
            ],
            [
              -127.277496,
              50.055271
            ],
            [
              -127.380501,
              50.0261
            ],
            [
              -127.470802,
              50.08543
            ],
            [
              -127.420799,
              50.12804
            ],
            [
              -127.485001,
              50.12804
            ],
            [
              -127.428596,
              50.180271
            ],
            [
              -127.596901,
              50.115551
            ],
            [
              -127.635597,
              50.135551
            ],
            [
              -127.609703,
              50.180271
            ],
            [
              -127.650803,
              50.175549
            ],
            [
              -127.788101,
              50.081669
            ],
            [
              -127.906998,
              50.120831
            ],
            [
              -127.745003,
              50.24194
            ],
            [
              -127.8442,
              50.275551
            ],
            [
              -127.804398,
              50.321381
            ],
            [
              -127.931396,
              50.316101
            ],
            [
              -127.9786,
              50.342491
            ],
            [
              -127.923897,
              50.462769
            ],
            [
              -127.584198,
              50.486938
            ],
            [
              -127.446999,
              50.372761
            ],
            [
              -127.57,
              50.512211
            ],
            [
              -127.490303,
              50.56805
            ],
            [
              -127.4142,
              50.575829
            ],
            [
              -127.419403,
              50.596661
            ],
            [
              -127.553001,
              50.56805
            ],
            [
              -127.596703,
              50.596939
            ],
            [
              -127.873299,
              50.623878
            ],
            [
              -127.786697,
              50.582211
            ],
            [
              -127.634499,
              50.578049
            ],
            [
              -127.595596,
              50.536659
            ],
            [
              -127.995102,
              50.466389
            ],
            [
              -128.058594,
              50.486382
            ],
            [
              -128.051407,
              50.44669
            ],
            [
              -128.224197,
              50.531109
            ],
            [
              -128.241394,
              50.58749
            ],
            [
              -128.3367,
              50.63694
            ],
            [
              -128.272797,
              50.66972
            ],
            [
              -128.375305,
              50.6786
            ],
            [
              -128.416702,
              50.769161
            ],
            [
              -128.064194,
              50.872219
            ],
            [
              -127.909698,
              50.87138
            ],
            [
              -127.507202,
              50.772221
            ],
            [
              -127.477097,
              50.700069
            ],
            [
              -127.408897,
              50.746101
            ],
            [
              -127.407204,
              50.700001
            ],
            [
              -127.200302,
              50.61277
            ],
            [
              -126.854698,
              50.55444
            ],
            [
              -126.852501,
              50.523319
            ],
            [
              -126.776398,
              50.546101
            ],
            [
              -126.592499,
              50.47694
            ],
            [
              -126.327797,
              50.48082
            ],
            [
              -126.069199,
              50.438599
            ],
            [
              -125.9478,
              50.384991
            ],
            [
              -125.725601,
              50.352219
            ],
            [
              -125.564201,
              50.36388
            ],
            [
              -125.431702,
              50.310551
            ],
            [
              -125.351898,
              50.149158
            ],
            [
              -125.368301,
              50.125271
            ],
            [
              -125.300598,
              50.10527
            ],
            [
              -125.168602,
              49.912769
            ],
            [
              -125,
              49.796299
            ],
            [
              -124.866402,
              49.711109
            ],
            [
              -124.883904,
              49.666382
            ],
            [
              -124.977203,
              49.668598
            ],
            [
              -124.789398,
              49.464161
            ],
            [
              -124.716698,
              49.463051
            ],
            [
              -124.535599,
              49.373878
            ],
            [
              -124.170502,
              49.311939
            ],
            [
              -124.110802,
              49.275269
            ],
            [
              -124.175598,
              49.255829
            ],
            [
              -123.957802,
              49.228321
            ],
            [
              -123.909698,
              49.127491
            ],
            [
              -123.810303,
              49.115829
            ],
            [
              -123.749397,
              49.035271
            ],
            [
              -123.757004,
              48.986938
            ],
            [
              -123.8311,
              48.997219
            ],
            [
              -123.582802,
              48.83194
            ],
            [
              -123.603302,
              48.79583
            ],
            [
              -123.565598,
              48.789719
            ],
            [
              -123.567497,
              48.744438
            ],
            [
              -123.648102,
              48.750271
            ],
            [
              -123.538101,
              48.691662
            ],
            [
              -123.509499,
              48.58749
            ],
            [
              -123.535004,
              48.529991
            ],
            [
              -123.4758,
              48.568329
            ],
            [
              -123.4767,
              48.63166
            ],
            [
              -123.439201,
              48.647221
            ],
            [
              -123.4692,
              48.67944
            ],
            [
              -123.4011,
              48.681381
            ],
            [
              -123.341698,
              48.508888
            ],
            [
              -123.2761,
              48.451099
            ],
            [
              -123.336403,
              48.396938
            ],
            [
              -123.443001,
              48.443321
            ],
            [
              -123.546097,
              48.30777
            ],
            [
              -123.583298,
              48.301102
            ],
            [
              -123.762497,
              48.36166
            ],
            [
              -123.924202,
              48.3661
            ],
            [
              -124.423599,
              48.516941
            ],
            [
              -124.438301,
              48.532768
            ],
            [
              -124.396103,
              48.56583
            ],
            [
              -124.489403,
              48.53944
            ],
            [
              -124.688599,
              48.578331
            ],
            [
              -124.8228,
              48.652489
            ],
            [
              -125.113899,
              48.731098
            ],
            [
              -125.184998,
              48.800831
            ],
            [
              -125.005997,
              48.88903
            ],
            [
              -125,
              48.939468
            ],
            [
              -124.842796,
              49.015549
            ],
            [
              -124.7808,
              49.131378
            ],
            [
              -124.8078,
              49.240551
            ],
            [
              -124.826103,
              49.12249
            ],
            [
              -124.906097,
              49.001942
            ],
            [
              -125.092201,
              48.972488
            ],
            [
              -125.150597,
              48.996941
            ],
            [
              -125.241898,
              48.95166
            ],
            [
              -125.331902,
              48.96999
            ],
            [
              -125.258301,
              49.01833
            ],
            [
              -125.3414,
              49.03611
            ],
            [
              -125.371902,
              48.97916
            ],
            [
              -125.483597,
              48.915821
            ],
            [
              -125.737801,
              49.049999
            ],
            [
              -125.8769,
              49.088329
            ],
            [
              -125.858299,
              49.110821
            ],
            [
              -125.738098,
              49.105549
            ],
            [
              -125.605797,
              49.21027
            ],
            [
              -125.753304,
              49.147491
            ],
            [
              -125.735001,
              49.257221
            ],
            [
              -125.802498,
              49.255001
            ],
            [
              -125.776901,
              49.365829
            ],
            [
              -125.846397,
              49.25816
            ],
            [
              -125.894402,
              49.273609
            ],
            [
              -125.970299,
              49.224709
            ],
            [
              -126.0233,
              49.268051
            ],
            [
              -125.945503,
              49.316669
            ],
            [
              -125.904701,
              49.435822
            ],
            [
              -126.001999,
              49.321941
            ],
            [
              -126.065002,
              49.348331
            ],
            [
              -126.052803,
              49.446098
            ],
            [
              -126.1903,
              49.400829
            ],
            [
              -126.258598,
              49.445541
            ],
            [
              -126.296997,
              49.357769
            ],
            [
              -126.403603,
              49.411098
            ],
            [
              -126.422501,
              49.470268
            ],
            [
              -126.480301,
              49.44305
            ],
            [
              -126.466103,
              49.380268
            ],
            [
              -126.542198,
              49.374439
            ],
            [
              -126.577797,
              49.407768
            ],
            [
              -126.555,
              49.44471
            ],
            [
              -126.566101,
              49.584431
            ],
            [
              -126.470299,
              49.635551
            ],
            [
              -126.134201,
              49.64999
            ],
            [
              -126.086899,
              49.657211
            ],
            [
              -126.103104,
              49.679161
            ],
            [
              -126.324997,
              49.64888
            ],
            [
              -126.438301,
              49.66415
            ],
            [
              -126.483101,
              49.702492
            ],
            [
              -126.408897,
              49.770271
            ],
            [
              -126.456398,
              49.78833
            ],
            [
              -126.4972,
              49.712769
            ],
            [
              -126.584999,
              49.701099
            ],
            [
              -126.645599,
              49.892769
            ],
            [
              -126.731697,
              49.880268
            ],
            [
              -126.829498,
              49.96471
            ],
            [
              -126.800797,
              49.913879
            ],
            [
              -126.845596,
              49.88221
            ],
            [
              -126.884201,
              49.879162
            ],
            [
              -126.914703,
              49.965832
            ],
            [
              -126.9394,
              49.86277
            ],
            [
              -127.121101,
              49.852291
            ]
          ],
          [
            [
              -126.797798,
              50.768879
            ],
            [
              -126.8647,
              50.78944
            ],
            [
              -126.9058,
              50.822769
            ],
            [
              -126.881401,
              50.83028
            ],
            [
              -126.794197,
              50.80471
            ],
            [
              -126.779198,
              50.831669
            ],
            [
              -126.643303,
              50.846939
            ],
            [
              -126.563004,
              50.799999
            ],
            [
              -126.797798,
              50.768879
            ]
          ],
          [
            [
              -126.251701,
              50.818932
            ],
            [
              -126.176697,
              50.743881
            ],
            [
              -126.243896,
              50.717209
            ],
            [
              -126.266403,
              50.654709
            ],
            [
              -126.6175,
              50.664989
            ],
            [
              -126.389702,
              50.806381
            ],
            [
              -126.251701,
              50.818932
            ]
          ],
          [
            [
              -126.336403,
              50.52166
            ],
            [
              -126.623901,
              50.533878
            ],
            [
              -126.286102,
              50.598331
            ],
            [
              -126.224701,
              50.55666
            ],
            [
              -126.336403,
              50.52166
            ]
          ],
          [
            [
              -125.370796,
              50.45583
            ],
            [
              -125.330597,
              50.42527
            ],
            [
              -125.385803,
              50.369709
            ],
            [
              -125.457802,
              50.34943
            ],
            [
              -125.536903,
              50.379162
            ],
            [
              -125.370796,
              50.45583
            ]
          ],
          [
            [
              -125.273102,
              50.43111
            ],
            [
              -125.168098,
              50.382771
            ],
            [
              -125.051903,
              50.22665
            ],
            [
              -125.049202,
              50.193321
            ],
            [
              -125.088303,
              50.197208
            ],
            [
              -125.133904,
              50.122761
            ],
            [
              -125.154198,
              50.133331
            ],
            [
              -125.1269,
              50.21944
            ],
            [
              -125.2136,
              50.316669
            ],
            [
              -125.393097,
              50.29583
            ],
            [
              -125.388603,
              50.343048
            ],
            [
              -125.313301,
              50.340832
            ],
            [
              -125.320602,
              50.385551
            ],
            [
              -125.273102,
              50.43111
            ]
          ],
          [
            [
              -124.898598,
              50.29388
            ],
            [
              -124.811401,
              50.275551
            ],
            [
              -124.820297,
              50.20583
            ],
            [
              -124.756699,
              50.178329
            ],
            [
              -124.806999,
              50.11388
            ],
            [
              -124.870796,
              50.156651
            ],
            [
              -124.834396,
              50.192211
            ],
            [
              -124.915604,
              50.188881
            ],
            [
              -124.963097,
              50.236382
            ],
            [
              -124.898598,
              50.29388
            ]
          ],
          [
            [
              -125.345497,
              50.263901
            ],
            [
              -125.2547,
              50.29361
            ],
            [
              -125.167198,
              50.213612
            ],
            [
              -125.186699,
              50.141659
            ],
            [
              -125.233101,
              50.11805
            ],
            [
              -125.178001,
              50.078609
            ],
            [
              -125.167801,
              49.98082
            ],
            [
              -125.215599,
              50.07888
            ],
            [
              -125.323601,
              50.14333
            ],
            [
              -125.345497,
              50.263901
            ]
          ],
          [
            [
              -124.983299,
              50.225552
            ],
            [
              -124.891098,
              50.06805
            ],
            [
              -124.987198,
              50.012772
            ],
            [
              -125,
              50.064308
            ],
            [
              -125.067001,
              50.107498
            ],
            [
              -124.982201,
              50.176102
            ],
            [
              -125.005798,
              50.225819
            ],
            [
              -124.983299,
              50.225552
            ]
          ],
          [
            [
              -126.7687,
              49.87862
            ],
            [
              -126.681702,
              49.849998
            ],
            [
              -126.636101,
              49.759441
            ],
            [
              -126.613297,
              49.648331
            ],
            [
              -126.633102,
              49.5961
            ],
            [
              -126.807999,
              49.619709
            ],
            [
              -126.974197,
              49.740269
            ],
            [
              -126.855797,
              49.776939
            ],
            [
              -126.988602,
              49.811378
            ],
            [
              -126.970299,
              49.832211
            ],
            [
              -126.7687,
              49.87862
            ]
          ],
          [
            [
              -124.122803,
              49.49361
            ],
            [
              -124.440002,
              49.612499
            ],
            [
              -124.4767,
              49.67194
            ],
            [
              -124.654198,
              49.736111
            ],
            [
              -124.6567,
              49.79694
            ],
            [
              -124.351402,
              49.69804
            ],
            [
              -124.122803,
              49.49361
            ]
          ],
          [
            [
              -126.1269,
              49.39027
            ],
            [
              -126.0728,
              49.343048
            ],
            [
              -126.064697,
              49.250832
            ],
            [
              -126.239197,
              49.289719
            ],
            [
              -126.234703,
              49.374161
            ],
            [
              -126.1269,
              49.39027
            ]
          ],
          [
            [
              -123.674698,
              49.093048
            ],
            [
              -123.521698,
              48.96027
            ],
            [
              -123.3125,
              48.86805
            ],
            [
              -123.378899,
              48.856941
            ],
            [
              -123.529999,
              48.938042
            ],
            [
              -123.662498,
              49.035271
            ],
            [
              -123.705299,
              49.100269
            ],
            [
              -123.674698,
              49.093048
            ]
          ],
          [
            [
              -123.577202,
              48.929161
            ],
            [
              -123.433899,
              48.84444
            ],
            [
              -123.494499,
              48.845268
            ],
            [
              -123.371696,
              48.7575
            ],
            [
              -123.456398,
              48.76527
            ],
            [
              -123.431999,
              48.7211
            ],
            [
              -123.524696,
              48.722488
            ],
            [
              -123.551903,
              48.75222
            ],
            [
              -123.532501,
              48.795269
            ],
            [
              -123.571701,
              48.829159
            ],
            [
              -123.547798,
              48.859989
            ],
            [
              -123.592796,
              48.898331
            ],
            [
              -123.596703,
              48.946941
            ],
            [
              -123.577202,
              48.929161
            ]
          ],
          [
            [
              -117.030998,
              49
            ],
            [
              -117.4375,
              49
            ],
            [
              -118.199203,
              49
            ],
            [
              -118.842499,
              49
            ],
            [
              -120.855797,
              48.999969
            ],
            [
              -122.7603,
              48.999439
            ],
            [
              -122.862503,
              49.022221
            ],
            [
              -122.859398,
              49.077221
            ],
            [
              -122.900002,
              49.087212
            ],
            [
              -123.0392,
              49.0425
            ],
            [
              -123.034302,
              48.999439
            ],
            [
              -123.145798,
              49.058601
            ],
            [
              -123.036102,
              49.149719
            ],
            [
              -123.115501,
              49.108601
            ],
            [
              -123.205002,
              49.1236
            ],
            [
              -123.2033,
              49.194439
            ],
            [
              -123.127197,
              49.19582
            ],
            [
              -123.248901,
              49.273609
            ],
            [
              -123.146698,
              49.271381
            ],
            [
              -123.147797,
              49.308041
            ],
            [
              -122.923599,
              49.28833
            ],
            [
              -122.852798,
              49.4361
            ],
            [
              -122.876404,
              49.455551
            ],
            [
              -122.901398,
              49.36055
            ],
            [
              -122.953102,
              49.310268
            ],
            [
              -123.269203,
              49.331108
            ],
            [
              -123.288101,
              49.374989
            ],
            [
              -123.233299,
              49.426102
            ],
            [
              -123.256699,
              49.512772
            ],
            [
              -123.159698,
              49.699162
            ],
            [
              -123.241898,
              49.660549
            ],
            [
              -123.254501,
              49.588329
            ],
            [
              -123.4925,
              49.50972
            ],
            [
              -123.4767,
              49.419159
            ],
            [
              -123.535599,
              49.381378
            ],
            [
              -123.749199,
              49.461109
            ],
            [
              -123.896103,
              49.470539
            ],
            [
              -124.0681,
              49.633881
            ],
            [
              -124.021103,
              49.726379
            ],
            [
              -123.940598,
              49.742489
            ],
            [
              -123.833099,
              49.627491
            ],
            [
              -123.769699,
              49.504711
            ],
            [
              -123.759201,
              49.601101
            ],
            [
              -123.614998,
              49.63916
            ],
            [
              -123.534203,
              49.701389
            ],
            [
              -123.7967,
              49.638329
            ],
            [
              -123.931396,
              49.76416
            ],
            [
              -123.872803,
              49.877209
            ],
            [
              -123.959702,
              49.981659
            ],
            [
              -123.744499,
              50.086941
            ],
            [
              -123.821404,
              50.15221
            ],
            [
              -123.985001,
              50.216099
            ],
            [
              -123.809196,
              50.099998
            ],
            [
              -123.999199,
              50.00639
            ],
            [
              -123.979698,
              49.91666
            ],
            [
              -123.911102,
              49.869709
            ],
            [
              -123.926399,
              49.825829
            ],
            [
              -124.004204,
              49.810551
            ],
            [
              -124.037804,
              49.922489
            ],
            [
              -124.071999,
              49.873322
            ],
            [
              -124.046097,
              49.841099
            ],
            [
              -124.084396,
              49.79916
            ],
            [
              -124.257797,
              49.775829
            ],
            [
              -124.2714,
              49.742771
            ],
            [
              -124.511902,
              49.796101
            ],
            [
              -124.532799,
              49.84444
            ],
            [
              -124.741898,
              49.958328
            ],
            [
              -124.829697,
              50.061939
            ],
            [
              -124.686401,
              49.98444
            ],
            [
              -124.704697,
              50.030819
            ],
            [
              -124.668602,
              50.076389
            ],
            [
              -124.700302,
              50.10833
            ],
            [
              -124.626099,
              50.165272
            ],
            [
              -124.601402,
              50.23888
            ],
            [
              -124.714996,
              50.327492
            ],
            [
              -124.651901,
              50.38916
            ],
            [
              -124.434196,
              50.43166
            ],
            [
              -124.347801,
              50.502491
            ],
            [
              -124.563004,
              50.42083
            ],
            [
              -124.671402,
              50.425831
            ],
            [
              -124.830002,
              50.309429
            ],
            [
              -124.959999,
              50.33305
            ],
            [
              -124.948898,
              50.37582
            ],
            [
              -124.994698,
              50.430271
            ],
            [
              -124.9953,
              50.353882
            ],
            [
              -125.0783,
              50.322491
            ],
            [
              -125.056702,
              50.47694
            ],
            [
              -125,
              50.490829
            ],
            [
              -124.858002,
              50.590549
            ],
            [
              -124.860298,
              50.6786
            ],
            [
              -124.822502,
              50.723598
            ],
            [
              -124.913597,
              50.79361
            ],
            [
              -124.786903,
              50.884441
            ],
            [
              -124.849998,
              50.935261
            ],
            [
              -124.912804,
              50.841099
            ],
            [
              -124.983299,
              50.8036
            ],
            [
              -124.897003,
              50.72971
            ],
            [
              -124.9217,
              50.661381
            ],
            [
              -124.903603,
              50.619991
            ],
            [
              -125.104202,
              50.496941
            ],
            [
              -125.1147,
              50.436939
            ],
            [
              -125.170303,
              50.412491
            ],
            [
              -125.246101,
              50.462212
            ],
            [
              -125.245003,
              50.503609
            ],
            [
              -125.315804,
              50.465271
            ],
            [
              -125.363297,
              50.541382
            ],
            [
              -125.4217,
              50.465271
            ],
            [
              -125.573303,
              50.458889
            ],
            [
              -125.527802,
              50.540279
            ],
            [
              -125.532204,
              50.627209
            ],
            [
              -125.4328,
              50.713879
            ],
            [
              -125.547203,
              50.66193
            ],
            [
              -125.573898,
              50.544159
            ],
            [
              -125.623901,
              50.50639
            ],
            [
              -125.613899,
              50.465271
            ],
            [
              -125.700302,
              50.428051
            ],
            [
              -125.795502,
              50.496101
            ],
            [
              -125.738602,
              50.516109
            ],
            [
              -125.815804,
              50.534161
            ],
            [
              -125.952202,
              50.46888
            ],
            [
              -126.275002,
              50.5075
            ],
            [
              -126.232803,
              50.53611
            ],
            [
              -126.175797,
              50.519161
            ],
            [
              -126.170303,
              50.573879
            ],
            [
              -125.9403,
              50.629162
            ],
            [
              -126.207802,
              50.588879
            ],
            [
              -126.273903,
              50.632141
            ],
            [
              -125.704201,
              50.678329
            ],
            [
              -125.6772,
              50.73444
            ],
            [
              -125.611099,
              50.764721
            ],
            [
              -125.641998,
              50.853321
            ],
            [
              -125.546402,
              50.861382
            ],
            [
              -125.5056,
              50.933331
            ],
            [
              -125.551697,
              51.042221
            ],
            [
              -125.633904,
              51.096939
            ],
            [
              -125.5811,
              50.95388
            ],
            [
              -125.542801,
              50.932499
            ],
            [
              -125.6744,
              50.86694
            ],
            [
              -125.680298,
              50.81554
            ],
            [
              -125.643097,
              50.783878
            ],
            [
              -125.703598,
              50.764999
            ],
            [
              -125.746902,
              50.70805
            ],
            [
              -125.936897,
              50.681381
            ],
            [
              -125.9953,
              50.70805
            ],
            [
              -126.210297,
              50.665272
            ],
            [
              -126.2089,
              50.707771
            ],
            [
              -126.024696,
              50.793049
            ],
            [
              -126.181396,
              50.778332
            ],
            [
              -126.1772,
              50.86805
            ],
            [
              -126.493301,
              50.81638
            ],
            [
              -126.552803,
              50.834721
            ],
            [
              -126.557503,
              50.876659
            ],
            [
              -126.494499,
              50.918049
            ],
            [
              -126.2258,
              50.898609
            ],
            [
              -126.170303,
              50.936649
            ],
            [
              -126.4636,
              50.94804
            ],
            [
              -126.516403,
              51.070549
            ],
            [
              -126.549202,
              51.014439
            ],
            [
              -126.524399,
              50.937489
            ],
            [
              -126.6614,
              50.86805
            ],
            [
              -126.854401,
              50.93943
            ],
            [
              -126.917198,
              50.899158
            ],
            [
              -127.176399,
              50.929161
            ],
            [
              -127.062798,
              50.885269
            ],
            [
              -126.917503,
              50.876659
            ],
            [
              -127.022499,
              50.817501
            ],
            [
              -127.2547,
              50.913879
            ],
            [
              -127.398598,
              50.92638
            ],
            [
              -127.441101,
              50.947769
            ],
            [
              -127.420799,
              50.978321
            ],
            [
              -127.535301,
              51.000549
            ],
            [
              -127.5028,
              51.097488
            ],
            [
              -127.381104,
              51.059052
            ],
            [
              -127.330803,
              51.047779
            ],
            [
              -127.236099,
              51.041111
            ],
            [
              -126.848602,
              51.069721
            ],
            [
              -126.765602,
              51.056381
            ],
            [
              -126.7117,
              51.000832
            ],
            [
              -126.704498,
              51.039719
            ],
            [
              -126.743103,
              51.0825
            ],
            [
              -126.652802,
              51.14999
            ],
            [
              -126.658302,
              51.19276
            ],
            [
              -126.806999,
              51.089161
            ],
            [
              -127.206703,
              51.056381
            ],
            [
              -127.388397,
              51.067989
            ],
            [
              -127.500603,
              51.11805
            ],
            [
              -127.649399,
              51.09222
            ],
            [
              -127.790001,
              51.165539
            ],
            [
              -127.761902,
              51.249439
            ],
            [
              -127.565598,
              51.293049
            ],
            [
              -127.306702,
              51.280499
            ],
            [
              -127.143303,
              51.318329
            ],
            [
              -127.115799,
              51.391659
            ],
            [
              -127.188004,
              51.323608
            ],
            [
              -127.280602,
              51.301102
            ],
            [
              -127.509697,
              51.310268
            ],
            [
              -127.465302,
              51.358051
            ],
            [
              -127.57,
              51.328609
            ],
            [
              -127.784203,
              51.33305
            ],
            [
              -127.778297,
              51.361111
            ],
            [
              -127.663902,
              51.385551
            ],
            [
              -127.551697,
              51.468319
            ],
            [
              -127.500603,
              51.613609
            ],
            [
              -127.233299,
              51.662491
            ],
            [
              -126.6633,
              51.64888
            ],
            [
              -126.605301,
              51.713051
            ],
            [
              -126.6633,
              51.797218
            ],
            [
              -126.639999,
              51.709991
            ],
            [
              -126.693001,
              51.664711
            ],
            [
              -127.053902,
              51.697769
            ],
            [
              -127.2742,
              51.683331
            ],
            [
              -127.3367,
              51.712769
            ],
            [
              -127.425797,
              51.66666
            ],
            [
              -127.433098,
              51.75
            ],
            [
              -127.3619,
              51.77166
            ],
            [
              -127.351097,
              51.863609
            ],
            [
              -127.392799,
              51.77721
            ],
            [
              -127.453102,
              51.772491
            ],
            [
              -127.474403,
              51.70499
            ],
            [
              -127.644203,
              51.69804
            ],
            [
              -127.462196,
              51.6675
            ],
            [
              -127.540298,
              51.638889
            ],
            [
              -127.5858,
              51.570549
            ],
            [
              -127.561096,
              51.538601
            ],
            [
              -127.640297,
              51.458599
            ],
            [
              -127.7547,
              51.479988
            ],
            [
              -127.656403,
              51.508888
            ],
            [
              -127.795502,
              51.5261
            ],
            [
              -127.787201,
              51.560268
            ],
            [
              -127.840599,
              51.641731
            ],
            [
              -127.878098,
              51.673882
            ],
            [
              -127.866096,
              51.89999
            ],
            [
              -127.789703,
              51.941101
            ],
            [
              -127.667801,
              51.949162
            ],
            [
              -127.651398,
              52.051659
            ],
            [
              -127.580299,
              52.129162
            ],
            [
              -127.480598,
              52.1511
            ],
            [
              -127.462502,
              52.112499
            ],
            [
              -127.584396,
              52.093609
            ],
            [
              -127.6203,
              52.027771
            ],
            [
              -127.547203,
              52.07972
            ],
            [
              -127.4114,
              52.090549
            ],
            [
              -127.453102,
              52.179989
            ],
            [
              -127.2453,
              52.248878
            ],
            [
              -127.1717,
              52.312489
            ],
            [
              -127.004997,
              52.3036
            ],
            [
              -126.938004,
              52.246941
            ],
            [
              -126.937202,
              52.196098
            ],
            [
              -126.751999,
              52.078609
            ],
            [
              -126.669403,
              51.983601
            ],
            [
              -126.669701,
              52.041939
            ],
            [
              -126.751701,
              52.125271
            ],
            [
              -126.900597,
              52.205269
            ],
            [
              -126.940804,
              52.303879
            ],
            [
              -126.867798,
              52.35416
            ],
            [
              -126.736702,
              52.37804
            ],
            [
              -126.792801,
              52.39555
            ],
            [
              -126.9814,
              52.33527
            ],
            [
              -127.082497,
              52.334991
            ],
            [
              -127.186401,
              52.380821
            ],
            [
              -127.2342,
              52.517208
            ],
            [
              -127.074203,
              52.61639
            ],
            [
              -126.989403,
              52.631939
            ],
            [
              -126.922203,
              52.71888
            ],
            [
              -126.966904,
              52.828609
            ],
            [
              -127.071098,
              52.883881
            ],
            [
              -126.980797,
              52.72443
            ],
            [
              -127.075699,
              52.639721
            ],
            [
              -127.257202,
              52.54583
            ],
            [
              -127.266701,
              52.464161
            ],
            [
              -127.452499,
              52.40443
            ],
            [
              -127.611702,
              52.29472
            ],
            [
              -127.746101,
              52.276661
            ],
            [
              -127.714203,
              52.353611
            ],
            [
              -127.736702,
              52.349998
            ],
            [
              -127.858299,
              52.206661
            ],
            [
              -127.928596,
              52.30471
            ],
            [
              -127.905296,
              52.3461
            ],
            [
              -127.841698,
              52.365002
            ],
            [
              -127.916397,
              52.44582
            ],
            [
              -127.869698,
              52.5061
            ],
            [
              -127.900002,
              52.509991
            ],
            [
              -127.930603,
              52.434429
            ],
            [
              -127.889999,
              52.384991
            ],
            [
              -127.963898,
              52.321659
            ],
            [
              -128.057205,
              52.394711
            ],
            [
              -128.068298,
              52.447769
            ],
            [
              -128.009506,
              52.50861
            ],
            [
              -127.9375,
              52.499722
            ],
            [
              -127.8797,
              52.57972
            ],
            [
              -128.099701,
              52.503052
            ],
            [
              -128.151093,
              52.409988
            ],
            [
              -128.304993,
              52.26944
            ],
            [
              -128.393906,
              52.291382
            ],
            [
              -128.224396,
              52.459721
            ],
            [
              -128.239197,
              52.56694
            ],
            [
              -128.113007,
              52.748329
            ],
            [
              -128.139206,
              52.837212
            ],
            [
              -128.128098,
              52.88055
            ],
            [
              -128.025299,
              52.912209
            ],
            [
              -128.139206,
              52.924438
            ],
            [
              -128.235306,
              52.80582
            ],
            [
              -128.441101,
              52.822769
            ],
            [
              -128.495499,
              52.887501
            ],
            [
              -128.537796,
              53.073051
            ],
            [
              -128.496597,
              53.09721
            ],
            [
              -128.554199,
              53.118881
            ],
            [
              -128.4814,
              53.141941
            ],
            [
              -128.571899,
              53.142769
            ],
            [
              -128.663605,
              53.239429
            ],
            [
              -128.685303,
              53.18943
            ],
            [
              -128.858902,
              53.283611
            ],
            [
              -128.878296,
              53.338039
            ],
            [
              -128.8461,
              53.36832
            ],
            [
              -128.883606,
              53.369991
            ],
            [
              -128.889206,
              53.430271
            ],
            [
              -128.829407,
              53.477772
            ],
            [
              -128.923294,
              53.45388
            ],
            [
              -128.972794,
              53.553051
            ],
            [
              -128.866592,
              53.535
            ],
            [
              -128.794998,
              53.5686
            ],
            [
              -128.754196,
              53.493881
            ],
            [
              -128.670807,
              53.45055
            ],
            [
              -128.692505,
              53.485271
            ],
            [
              -128.643906,
              53.474991
            ],
            [
              -128.4953,
              53.388599
            ],
            [
              -128.481903,
              53.33971
            ],
            [
              -128.474396,
              53.389721
            ],
            [
              -128.504196,
              53.407768
            ],
            [
              -128.373596,
              53.442211
            ],
            [
              -128.352493,
              53.475819
            ],
            [
              -128.131897,
              53.448879
            ],
            [
              -128.071106,
              53.396389
            ],
            [
              -128.099396,
              53.33638
            ],
            [
              -128.032196,
              53.367489
            ],
            [
              -127.952003,
              53.326099
            ],
            [
              -127.946098,
              53.25222
            ],
            [
              -127.867798,
              53.239719
            ],
            [
              -127.93,
              53.284721
            ],
            [
              -127.925003,
              53.329159
            ],
            [
              -128.050293,
              53.395
            ],
            [
              -128.114395,
              53.488041
            ],
            [
              -128.380295,
              53.499439
            ],
            [
              -128.389206,
              53.462212
            ],
            [
              -128.517502,
              53.431381
            ],
            [
              -128.756104,
              53.560268
            ],
            [
              -128.816696,
              53.623322
            ],
            [
              -128.764694,
              53.71944
            ],
            [
              -128.7939,
              53.764999
            ],
            [
              -128.770599,
              53.79583
            ],
            [
              -128.689697,
              53.81221
            ],
            [
              -128.643097,
              53.863049
            ],
            [
              -128.471405,
              53.8325
            ],
            [
              -128.688599,
              53.889992
            ],
            [
              -128.609207,
              54.03138
            ],
            [
              -128.685303,
              54.000832
            ],
            [
              -128.720001,
              53.938881
            ],
            [
              -128.9319,
              53.774712
            ],
            [
              -128.904694,
              53.856659
            ],
            [
              -128.943893,
              53.80999
            ],
            [
              -128.950806,
              53.878601
            ],
            [
              -129.003906,
              53.89333
            ],
            [
              -128.966904,
              53.863049
            ],
            [
              -128.964203,
              53.782211
            ],
            [
              -129.009201,
              53.762211
            ],
            [
              -129.0569,
              53.811939
            ],
            [
              -129.028595,
              53.7486
            ],
            [
              -129.232498,
              53.62582
            ],
            [
              -129.261993,
              53.517769
            ],
            [
              -129.2314,
              53.500832
            ],
            [
              -129.237503,
              53.433601
            ],
            [
              -129.310699,
              53.379501
            ],
            [
              -129.606094,
              53.567768
            ],
            [
              -129.734695,
              53.67527
            ],
            [
              -129.699707,
              53.671101
            ],
            [
              -129.713593,
              53.699429
            ],
            [
              -129.989395,
              53.845829
            ],
            [
              -129.962204,
              53.88583
            ],
            [
              -130.031692,
              53.876381
            ],
            [
              -130.101395,
              53.946659
            ],
            [
              -130.090805,
              54.071659
            ],
            [
              -130.046707,
              54.15332
            ],
            [
              -129.950302,
              54.158039
            ],
            [
              -129.839996,
              54.027771
            ],
            [
              -129.718903,
              53.951389
            ],
            [
              -129.737198,
              54.001659
            ],
            [
              -129.834198,
              54.05471
            ],
            [
              -129.929703,
              54.183048
            ],
            [
              -129.831894,
              54.21944
            ],
            [
              -129.614197,
              54.178921
            ],
            [
              -129.470001,
              54.237209
            ],
            [
              -129.616898,
              54.194149
            ],
            [
              -129.854996,
              54.238041
            ],
            [
              -130.122803,
              54.15443
            ],
            [
              -130.278595,
              54.208889
            ],
            [
              -130.279999,
              54.24361
            ],
            [
              -130.195602,
              54.259991
            ],
            [
              -130.2603,
              54.33749
            ],
            [
              -130.250305,
              54.400829
            ],
            [
              -130.275803,
              54.34972
            ],
            [
              -130.348907,
              54.326939
            ],
            [
              -130.481094,
              54.364719
            ],
            [
              -130.473602,
              54.435822
            ],
            [
              -130.381104,
              54.45805
            ],
            [
              -130.433594,
              54.496658
            ],
            [
              -130.429993,
              54.562489
            ],
            [
              -130.375793,
              54.571659
            ],
            [
              -130.439697,
              54.612209
            ],
            [
              -130.416702,
              54.630268
            ],
            [
              -130.063004,
              54.339989
            ],
            [
              -129.982803,
              54.3111
            ],
            [
              -129.959702,
              54.326939
            ],
            [
              -130.038605,
              54.34193
            ],
            [
              -130.089706,
              54.402771
            ],
            [
              -130.059204,
              54.47916
            ],
            [
              -129.978897,
              54.516941
            ],
            [
              -130.0672,
              54.49416
            ],
            [
              -130.082794,
              54.434719
            ],
            [
              -130.123306,
              54.42749
            ],
            [
              -130.351395,
              54.61805
            ],
            [
              -130.368607,
              54.66777
            ],
            [
              -130.171906,
              54.703609
            ],
            [
              -130.000595,
              54.614719
            ],
            [
              -129.880798,
              54.614719
            ],
            [
              -130.004501,
              54.632771
            ],
            [
              -130.079193,
              54.691929
            ],
            [
              -130.201706,
              54.72694
            ],
            [
              -130.155594,
              54.815269
            ],
            [
              -130.050293,
              54.88694
            ],
            [
              -130.173904,
              54.846661
            ],
            [
              -130.031998,
              54.965271
            ],
            [
              -129.954193,
              54.95916
            ],
            [
              -129.974396,
              54.914989
            ],
            [
              -129.824402,
              54.989159
            ],
            [
              -129.622498,
              54.997768
            ],
            [
              -129.998306,
              55.018051
            ],
            [
              -129.961395,
              55.093319
            ],
            [
              -129.860504,
              55.170551
            ],
            [
              -129.815598,
              55.255001
            ],
            [
              -129.749405,
              55.28833
            ],
            [
              -129.643295,
              55.434158
            ],
            [
              -129.523895,
              55.439991
            ],
            [
              -129.4711,
              55.472759
            ],
            [
              -129.636993,
              55.456661
            ],
            [
              -129.708298,
              55.409988
            ],
            [
              -129.811996,
              55.629162
            ],
            [
              -129.772797,
              55.473881
            ],
            [
              -129.839401,
              55.387501
            ],
            [
              -129.824402,
              55.361938
            ],
            [
              -129.790604,
              55.398048
            ],
            [
              -129.808594,
              55.367771
            ],
            [
              -129.782501,
              55.353611
            ],
            [
              -129.830795,
              55.323051
            ],
            [
              -129.813904,
              55.289719
            ],
            [
              -129.920807,
              55.151932
            ],
            [
              -130.068298,
              54.996941
            ],
            [
              -130.113602,
              54.99638
            ],
            [
              -130.160004,
              55.086109
            ],
            [
              -129.943893,
              55.282211
            ],
            [
              -130.007202,
              55.355
            ],
            [
              -130.0242,
              55.46471
            ],
            [
              -130.1017,
              55.556381
            ],
            [
              -130.074707,
              55.672489
            ],
            [
              -130.174698,
              55.75444
            ],
            [
              -130.015106,
              55.90918
            ],
            [
              -130.088593,
              56.11805
            ],
            [
              -130.4142,
              56.12888
            ],
            [
              -130.461899,
              56.235271
            ],
            [
              -130.627197,
              56.25861
            ],
            [
              -130.774399,
              56.3661
            ],
            [
              -131.054993,
              56.398048
            ],
            [
              -131.5578,
              56.602219
            ],
            [
              -131.824203,
              56.596939
            ],
            [
              -131.861404,
              56.799721
            ],
            [
              -132.103104,
              56.866661
            ],
            [
              -132.027496,
              57.036381
            ],
            [
              -132.336899,
              57.088329
            ],
            [
              -132.2267,
              57.204708
            ],
            [
              -133.136993,
              58.13583
            ],
            [
              -133.361099,
              58.280548
            ],
            [
              -133.429993,
              58.45916
            ],
            [
              -133.826904,
              58.726101
            ],
            [
              -134.245499,
              58.856941
            ],
            [
              -134.461395,
              59.126659
            ],
            [
              -134.566406,
              59.13055
            ],
            [
              -134.688004,
              59.24332
            ],
            [
              -134.951904,
              59.279991
            ],
            [
              -134.989197,
              59.389992
            ],
            [
              -135.091705,
              59.426941
            ],
            [
              -135.021103,
              59.4711
            ],
            [
              -135.014496,
              59.567501
            ],
            [
              -135.473602,
              59.801929
            ],
            [
              -136.343903,
              59.602779
            ],
            [
              -136.233902,
              59.525829
            ],
            [
              -136.463593,
              59.469711
            ],
            [
              -136.462799,
              59.28944
            ],
            [
              -136.583893,
              59.163319
            ],
            [
              -136.808899,
              59.165272
            ],
            [
              -137.4655,
              58.906101
            ],
            [
              -137.590805,
              59.238602
            ],
            [
              -138.615799,
              59.774158
            ],
            [
              -138.690308,
              59.90694
            ],
            [
              -138.938599,
              59.997551
            ],
            [
              -134.906601,
              60
            ],
            [
              -134.639297,
              60.000198
            ],
            [
              -134.151703,
              59.999722
            ],
            [
              -133.791199,
              60
            ],
            [
              -125.000801,
              60.003868
            ],
            [
              -123.816902,
              59.994709
            ],
            [
              -119.982903,
              59.988522
            ],
            [
              -120.000801,
              54.005871
            ],
            [
              -120.000801,
              53.808041
            ],
            [
              -119.904701,
              53.778599
            ],
            [
              -119.921097,
              53.718319
            ],
            [
              -119.810303,
              53.707771
            ],
            [
              -119.735298,
              53.634991
            ],
            [
              -119.7686,
              53.603321
            ],
            [
              -119.932198,
              53.60722
            ],
            [
              -119.882797,
              53.560268
            ],
            [
              -119.902496,
              53.520271
            ],
            [
              -119.842499,
              53.518051
            ],
            [
              -119.778099,
              53.446941
            ],
            [
              -119.738098,
              53.395
            ],
            [
              -119.606102,
              53.37804
            ],
            [
              -119.385803,
              53.36166
            ],
            [
              -119.348099,
              53.275269
            ],
            [
              -119.265602,
              53.196659
            ],
            [
              -119.045502,
              53.137772
            ],
            [
              -119.013603,
              53.144711
            ],
            [
              -119.012199,
              53.223049
            ],
            [
              -118.9786,
              53.237209
            ],
            [
              -118.759697,
              53.12471
            ],
            [
              -118.781097,
              53.05582
            ],
            [
              -118.669998,
              53.034439
            ],
            [
              -118.679703,
              52.970539
            ],
            [
              -118.625,
              52.884159
            ],
            [
              -118.491402,
              52.89777
            ],
            [
              -118.415604,
              52.83638
            ],
            [
              -118.428902,
              52.78611
            ],
            [
              -118.3461,
              52.74332
            ],
            [
              -118.305,
              52.674709
            ],
            [
              -118.349998,
              52.61805
            ],
            [
              -118.2817,
              52.565269
            ],
            [
              -118.286903,
              52.527771
            ],
            [
              -118.208298,
              52.48082
            ],
            [
              -118.259697,
              52.442211
            ],
            [
              -118.223099,
              52.365551
            ],
            [
              -118.145798,
              52.39888
            ],
            [
              -118.047501,
              52.404991
            ],
            [
              -118.0056,
              52.48777
            ],
            [
              -117.902802,
              52.424999
            ],
            [
              -117.7547,
              52.403599
            ],
            [
              -117.7314,
              52.344151
            ],
            [
              -117.829697,
              52.27277
            ],
            [
              -117.760002,
              52.200829
            ],
            [
              -117.6875,
              52.196098
            ],
            [
              -117.600304,
              52.13055
            ],
            [
              -117.363098,
              52.138599
            ],
            [
              -117.318901,
              52.181938
            ],
            [
              -117.269997,
              52.044998
            ],
            [
              -117.0392,
              51.908039
            ],
            [
              -116.919197,
              51.70916
            ],
            [
              -116.812798,
              51.715271
            ],
            [
              -116.815804,
              51.750549
            ],
            [
              -116.746101,
              51.79916
            ],
            [
              -116.658897,
              51.796661
            ],
            [
              -116.597504,
              51.716381
            ],
            [
              -116.595001,
              51.65749
            ],
            [
              -116.488098,
              51.613049
            ],
            [
              -116.466103,
              51.56638
            ],
            [
              -116.399696,
              51.54472
            ],
            [
              -116.3797,
              51.488602
            ],
            [
              -116.305,
              51.461658
            ],
            [
              -116.306099,
              51.388329
            ],
            [
              -116.262199,
              51.316669
            ],
            [
              -116.060501,
              51.24638
            ],
            [
              -116.0261,
              51.21693
            ],
            [
              -116.016098,
              51.138329
            ],
            [
              -115.957802,
              51.103882
            ],
            [
              -115.776703,
              51.073608
            ],
            [
              -115.759697,
              51.035831
            ],
            [
              -115.618599,
              50.969151
            ],
            [
              -115.571999,
              50.90332
            ],
            [
              -115.647202,
              50.85471
            ],
            [
              -115.527802,
              50.791939
            ],
            [
              -115.419197,
              50.754169
            ],
            [
              -115.395798,
              50.71693
            ],
            [
              -115.346703,
              50.732208
            ],
            [
              -115.311699,
              50.706661
            ],
            [
              -115.283096,
              50.658878
            ],
            [
              -115.320602,
              50.638599
            ],
            [
              -115.218903,
              50.54694
            ],
            [
              -115.1017,
              50.588329
            ],
            [
              -115.016998,
              50.575001
            ],
            [
              -114.8367,
              50.39666
            ],
            [
              -114.770302,
              50.35833
            ],
            [
              -114.793602,
              50.334721
            ],
            [
              -114.754997,
              50.288891
            ],
            [
              -114.724197,
              50.125549
            ],
            [
              -114.658897,
              50.065269
            ],
            [
              -114.643303,
              49.977772
            ],
            [
              -114.684998,
              49.9575
            ],
            [
              -114.6875,
              49.913601
            ],
            [
              -114.626099,
              49.797218
            ],
            [
              -114.636101,
              49.727772
            ],
            [
              -114.679703,
              49.63221
            ],
            [
              -114.738297,
              49.608601
            ],
            [
              -114.6894,
              49.547218
            ],
            [
              -114.576401,
              49.556099
            ],
            [
              -114.599998,
              49.442211
            ],
            [
              -114.576401,
              49.389992
            ],
            [
              -114.476097,
              49.341381
            ],
            [
              -114.429398,
              49.265549
            ],
            [
              -114.376701,
              49.25639
            ],
            [
              -114.372803,
              49.204159
            ],
            [
              -114.225304,
              49.188599
            ],
            [
              -114.154198,
              49.148048
            ],
            [
              -114.1567,
              49.103611
            ],
            [
              -114.035301,
              49.031109
            ],
            [
              -114.060303,
              49
            ],
            [
              -114.0625,
              49
            ],
            [
              -114.728302,
              49
            ],
            [
              -116.0625,
              49
            ],
            [
              -117.030998,
              49
            ]
          ]
        ],
        "nom": "Colombie-Britannique",
        "iso_code": "BC"
      },
      {
        "name": "Newfoundland and Labrador",
        "id": 56,
        "coordinates": [
          [
            [
              -55.88306,
              53.486382
            ],
            [
              -55.729439,
              53.450272
            ],
            [
              -55.79528,
              53.458328
            ],
            [
              -55.774441,
              53.41444
            ],
            [
              -55.817219,
              53.41777
            ],
            [
              -55.801392,
              53.39249
            ],
            [
              -55.971661,
              53.44582
            ],
            [
              -55.88306,
              53.486382
            ]
          ],
          [
            [
              -55.466942,
              50.784161
            ],
            [
              -55.557499,
              50.701389
            ],
            [
              -55.653049,
              50.727211
            ],
            [
              -55.61916,
              50.791382
            ],
            [
              -55.589161,
              50.772491
            ],
            [
              -55.46278,
              50.80582
            ],
            [
              -55.466942,
              50.784161
            ]
          ],
          [
            [
              -54.08112,
              49.736382
            ],
            [
              -53.98167,
              49.649441
            ],
            [
              -54.257778,
              49.566669
            ],
            [
              -54.293331,
              49.580551
            ],
            [
              -54.303329,
              49.670269
            ],
            [
              -54.25528,
              49.672489
            ],
            [
              -54.28278,
              49.71666
            ],
            [
              -54.215279,
              49.720829
            ],
            [
              -54.19194,
              49.674999
            ],
            [
              -54.148338,
              49.74749
            ],
            [
              -54.08112,
              49.736382
            ]
          ],
          [
            [
              -54.52972,
              49.633881
            ],
            [
              -54.576672,
              49.558601
            ],
            [
              -54.727219,
              49.528332
            ],
            [
              -54.739441,
              49.490269
            ],
            [
              -54.871941,
              49.487499
            ],
            [
              -54.807499,
              49.517208
            ],
            [
              -54.884171,
              49.546101
            ],
            [
              -54.891941,
              49.590549
            ],
            [
              -54.793331,
              49.602779
            ],
            [
              -54.788342,
              49.55777
            ],
            [
              -54.743889,
              49.544998
            ],
            [
              -54.62722,
              49.633049
            ],
            [
              -54.620831,
              49.602779
            ],
            [
              -54.536121,
              49.66415
            ],
            [
              -54.52972,
              49.633881
            ]
          ],
          [
            [
              -53.564159,
              48.19054
            ],
            [
              -53.509731,
              48.19833
            ],
            [
              -53.512501,
              48.145271
            ],
            [
              -53.553329,
              48.106941
            ],
            [
              -53.53083,
              48.097771
            ],
            [
              -53.634171,
              48.075272
            ],
            [
              -53.85611,
              48.098049
            ],
            [
              -53.935829,
              48.182499
            ],
            [
              -53.906391,
              48.21027
            ],
            [
              -53.850559,
              48.161381
            ],
            [
              -53.765282,
              48.174709
            ],
            [
              -53.68972,
              48.147221
            ],
            [
              -53.564159,
              48.19054
            ]
          ],
          [
            [
              -55.927502,
              47.676659
            ],
            [
              -55.93,
              47.639431
            ],
            [
              -55.876389,
              47.61166
            ],
            [
              -55.901939,
              47.602489
            ],
            [
              -56.113621,
              47.644711
            ],
            [
              -55.927502,
              47.676659
            ]
          ],
          [
            [
              -54.125,
              47.640831
            ],
            [
              -54.243328,
              47.399441
            ],
            [
              -54.364449,
              47.406651
            ],
            [
              -54.262218,
              47.474709
            ],
            [
              -54.205559,
              47.546391
            ],
            [
              -54.240002,
              47.558041
            ],
            [
              -54.172779,
              47.621101
            ],
            [
              -54.17194,
              47.585548
            ],
            [
              -54.135281,
              47.668049
            ],
            [
              -54.125,
              47.640831
            ]
          ],
          [
            [
              -64.631828,
              60.35746
            ],
            [
              -64.790283,
              60.391109
            ],
            [
              -64.867493,
              60.450272
            ],
            [
              -64.85611,
              60.473881
            ],
            [
              -64.639999,
              60.484718
            ],
            [
              -64.525558,
              60.413319
            ],
            [
              -64.532227,
              60.374161
            ],
            [
              -64.47084,
              60.405819
            ],
            [
              -64.426941,
              60.401379
            ],
            [
              -64.427673,
              60.372929
            ],
            [
              -64.478333,
              60.334431
            ],
            [
              -64.423607,
              60.28249
            ],
            [
              -64.631828,
              60.35746
            ]
          ],
          [
            [
              -64.534821,
              60.302601
            ],
            [
              -64.384171,
              60.238331
            ],
            [
              -64.613052,
              60.28944
            ],
            [
              -64.759453,
              60.231098
            ],
            [
              -64.554169,
              60.262772
            ],
            [
              -64.463333,
              60.22694
            ],
            [
              -64.562767,
              60.17305
            ],
            [
              -64.405563,
              60.220268
            ],
            [
              -64.426666,
              60.19249
            ],
            [
              -64.376938,
              60.160549
            ],
            [
              -64.486938,
              60.115551
            ],
            [
              -64.469162,
              60.082771
            ],
            [
              -64.627487,
              60.070831
            ],
            [
              -64.827499,
              59.986382
            ],
            [
              -64.603333,
              60.051659
            ],
            [
              -64.45639,
              60.059429
            ],
            [
              -64.373894,
              60.1236
            ],
            [
              -64.371933,
              60.073051
            ],
            [
              -64.416397,
              60.06332
            ],
            [
              -64.371109,
              60.04361
            ],
            [
              -64.389183,
              60.01416
            ],
            [
              -64.588608,
              60.001942
            ],
            [
              -64.398064,
              59.971661
            ],
            [
              -64.396957,
              59.937771
            ],
            [
              -64.499443,
              59.891659
            ],
            [
              -64.363327,
              59.922489
            ],
            [
              -64.330002,
              59.984161
            ],
            [
              -64.224442,
              59.99527
            ],
            [
              -64.325287,
              60.015831
            ],
            [
              -64.314713,
              60.05138
            ],
            [
              -64.173607,
              60.028332
            ],
            [
              -64.150284,
              59.985271
            ],
            [
              -64.294449,
              59.948601
            ],
            [
              -64.186394,
              59.91972
            ],
            [
              -64.259743,
              59.872219
            ],
            [
              -64.126099,
              59.896099
            ],
            [
              -64.177223,
              59.785549
            ],
            [
              -64.261948,
              59.789162
            ],
            [
              -64.254997,
              59.75666
            ],
            [
              -64.163887,
              59.684158
            ],
            [
              -63.956669,
              59.689991
            ],
            [
              -63.96611,
              59.658878
            ],
            [
              -63.923889,
              59.653599
            ],
            [
              -64.047501,
              59.635269
            ],
            [
              -64.122498,
              59.519711
            ],
            [
              -64.040833,
              59.5536
            ],
            [
              -63.997219,
              59.626659
            ],
            [
              -63.863621,
              59.601662
            ],
            [
              -63.98,
              59.505268
            ],
            [
              -63.87138,
              59.56638
            ],
            [
              -63.7225,
              59.513889
            ],
            [
              -63.799728,
              59.41666
            ],
            [
              -64.000557,
              59.41444
            ],
            [
              -64.06221,
              59.382488
            ],
            [
              -63.763889,
              59.38805
            ],
            [
              -63.755562,
              59.358051
            ],
            [
              -63.71167,
              59.365269
            ],
            [
              -63.805828,
              59.240269
            ],
            [
              -63.741112,
              59.25639
            ],
            [
              -63.64999,
              59.362499
            ],
            [
              -63.54306,
              59.348049
            ],
            [
              -63.534729,
              59.264439
            ],
            [
              -63.637779,
              59.218601
            ],
            [
              -63.605282,
              59.209721
            ],
            [
              -63.380001,
              59.283878
            ],
            [
              -63.45084,
              59.240829
            ],
            [
              -63.431671,
              59.22443
            ],
            [
              -63.462219,
              59.1936
            ],
            [
              -63.376659,
              59.213329
            ],
            [
              -63.366661,
              59.186378
            ],
            [
              -63.42556,
              59.126381
            ],
            [
              -63.551392,
              59.084431
            ],
            [
              -63.76778,
              59.04916
            ],
            [
              -63.934441,
              59.081108
            ],
            [
              -64.047234,
              59.01944
            ],
            [
              -63.898891,
              59.05666
            ],
            [
              -63.91222,
              59.000549
            ],
            [
              -63.868328,
              58.95332
            ],
            [
              -63.86916,
              59.031658
            ],
            [
              -63.527222,
              59.030548
            ],
            [
              -63.372768,
              59.101101
            ],
            [
              -63.125,
              59.055271
            ],
            [
              -63.175282,
              59.026939
            ],
            [
              -63.336109,
              59.02499
            ],
            [
              -63.224998,
              59.001389
            ],
            [
              -63.304169,
              58.94249
            ],
            [
              -63.173061,
              58.97971
            ],
            [
              -63.170559,
              58.954708
            ],
            [
              -63.226391,
              58.939709
            ],
            [
              -63.160549,
              58.92638
            ],
            [
              -63.325001,
              58.855831
            ],
            [
              -63.15778,
              58.873322
            ],
            [
              -63.182781,
              58.833599
            ],
            [
              -63.160278,
              58.823051
            ],
            [
              -63.071949,
              58.902771
            ],
            [
              -62.994999,
              58.885551
            ],
            [
              -63.053329,
              58.859161
            ],
            [
              -62.924171,
              58.821381
            ],
            [
              -62.90527,
              58.789989
            ],
            [
              -62.918331,
              58.74527
            ],
            [
              -63.04084,
              58.707771
            ],
            [
              -62.867771,
              58.71138
            ],
            [
              -62.843609,
              58.659431
            ],
            [
              -62.917221,
              58.630821
            ],
            [
              -62.915829,
              58.600269
            ],
            [
              -63.16917,
              58.503052
            ],
            [
              -63.439991,
              58.51833
            ],
            [
              -63.52417,
              58.470829
            ],
            [
              -63.385281,
              58.488331
            ],
            [
              -63.34333,
              58.4561
            ],
            [
              -63.589439,
              58.300831
            ],
            [
              -63.264721,
              58.463051
            ],
            [
              -63.089722,
              58.458328
            ],
            [
              -63.151939,
              58.373878
            ],
            [
              -63.050549,
              58.450272
            ],
            [
              -63.015011,
              58.42527
            ],
            [
              -62.80444,
              58.492222
            ],
            [
              -62.77528,
              58.451099
            ],
            [
              -62.745281,
              58.49361
            ],
            [
              -62.589439,
              58.499722
            ],
            [
              -62.556389,
              58.478039
            ],
            [
              -62.650551,
              58.408329
            ],
            [
              -62.61972,
              58.376942
            ],
            [
              -62.623051,
              58.30444
            ],
            [
              -62.858608,
              58.262501
            ],
            [
              -62.90527,
              58.209991
            ],
            [
              -62.832779,
              58.251659
            ],
            [
              -62.658611,
              58.270271
            ],
            [
              -62.59277,
              58.2486
            ],
            [
              -62.582779,
              58.21693
            ],
            [
              -62.661381,
              58.17305
            ],
            [
              -63.074718,
              58.151379
            ],
            [
              -63.012218,
              58.135551
            ],
            [
              -63.023891,
              58.118881
            ],
            [
              -63.21167,
              58.062489
            ],
            [
              -63.132221,
              58.058331
            ],
            [
              -63.15583,
              58.026939
            ],
            [
              -63.340839,
              57.979988
            ],
            [
              -63.12888,
              57.997768
            ],
            [
              -63.089161,
              58.06221
            ],
            [
              -62.838051,
              58.145
            ],
            [
              -62.846111,
              58.118881
            ],
            [
              -62.709438,
              58.14249
            ],
            [
              -62.652222,
              58.118599
            ],
            [
              -62.57362,
              58.164711
            ],
            [
              -62.554722,
              58.121101
            ],
            [
              -62.52306,
              58.1661
            ],
            [
              -62.452782,
              58.17527
            ],
            [
              -62.531391,
              58.095268
            ],
            [
              -62.50639,
              58.055271
            ],
            [
              -62.46674,
              58.102371
            ],
            [
              -62.368889,
              58.11166
            ],
            [
              -62.426392,
              58.081379
            ],
            [
              -62.38612,
              58.07222
            ],
            [
              -62.388889,
              58.042221
            ],
            [
              -62.33556,
              58.067501
            ],
            [
              -62.309719,
              58.028599
            ],
            [
              -62.386669,
              57.993881
            ],
            [
              -62.528339,
              58.00555
            ],
            [
              -62.672779,
              57.929989
            ],
            [
              -62.456951,
              57.967491
            ],
            [
              -62.423611,
              57.957771
            ],
            [
              -62.408611,
              57.865551
            ],
            [
              -62.34222,
              57.975819
            ],
            [
              -62.333611,
              57.90638
            ],
            [
              -62.276661,
              57.950829
            ],
            [
              -62.206108,
              57.907768
            ],
            [
              -62.127491,
              57.968048
            ],
            [
              -62.059719,
              57.89777
            ],
            [
              -62.13805,
              57.835819
            ],
            [
              -62.120831,
              57.800831
            ],
            [
              -61.99667,
              57.772221
            ],
            [
              -61.946949,
              57.71471
            ],
            [
              -61.965839,
              57.687489
            ],
            [
              -61.89333,
              57.66972
            ],
            [
              -61.88306,
              57.637501
            ],
            [
              -62.051392,
              57.588879
            ],
            [
              -62.13612,
              57.528332
            ],
            [
              -62.271938,
              57.533051
            ],
            [
              -62.33556,
              57.456379
            ],
            [
              -62.54528,
              57.50111
            ],
            [
              -62.36528,
              57.41972
            ],
            [
              -62.167221,
              57.464439
            ],
            [
              -61.891392,
              57.41193
            ],
            [
              -61.803051,
              57.358891
            ],
            [
              -61.91972,
              57.365551
            ],
            [
              -61.868328,
              57.3386
            ],
            [
              -61.863892,
              57.285549
            ],
            [
              -62.015839,
              57.24305
            ],
            [
              -61.89555,
              57.211109
            ],
            [
              -61.852501,
              57.165272
            ],
            [
              -61.77306,
              57.19305
            ],
            [
              -61.779171,
              57.148331
            ],
            [
              -61.636391,
              57.232491
            ],
            [
              -61.623329,
              57.215271
            ],
            [
              -61.696659,
              57.153881
            ],
            [
              -61.441669,
              57.148609
            ],
            [
              -61.358341,
              57.08749
            ],
            [
              -61.37944,
              57.05138
            ],
            [
              -61.348339,
              57.025269
            ],
            [
              -61.371941,
              56.995541
            ],
            [
              -61.346111,
              56.951099
            ],
            [
              -61.378052,
              56.982208
            ],
            [
              -61.487782,
              56.981659
            ],
            [
              -61.639999,
              56.883881
            ],
            [
              -61.661381,
              56.809429
            ],
            [
              -61.906391,
              56.795269
            ],
            [
              -61.889999,
              56.69804
            ],
            [
              -61.846111,
              56.75639
            ],
            [
              -61.702499,
              56.73082
            ],
            [
              -61.73444,
              56.697208
            ],
            [
              -61.825001,
              56.706661
            ],
            [
              -61.65889,
              56.64777
            ],
            [
              -61.688889,
              56.61721
            ],
            [
              -61.923061,
              56.64555
            ],
            [
              -61.895279,
              56.68082
            ],
            [
              -61.992771,
              56.660271
            ],
            [
              -62.49889,
              56.77972
            ],
            [
              -62.279442,
              56.826389
            ],
            [
              -62.04945,
              56.8325
            ],
            [
              -62.543892,
              56.837212
            ],
            [
              -62.58556,
              56.79277
            ],
            [
              -62.503059,
              56.762211
            ],
            [
              -61.916389,
              56.62027
            ],
            [
              -62.22472,
              56.609161
            ],
            [
              -61.945271,
              56.57888
            ],
            [
              -61.715,
              56.57222
            ],
            [
              -61.65527,
              56.533878
            ],
            [
              -61.66222,
              56.5061
            ],
            [
              -61.77306,
              56.484718
            ],
            [
              -62.046951,
              56.504711
            ],
            [
              -62.082218,
              56.481659
            ],
            [
              -61.949169,
              56.46138
            ],
            [
              -62.139172,
              56.44471
            ],
            [
              -61.98473,
              56.415272
            ],
            [
              -61.930279,
              56.437489
            ],
            [
              -61.79084,
              56.39249
            ],
            [
              -61.84277,
              56.380821
            ],
            [
              -61.772221,
              56.336651
            ],
            [
              -61.589161,
              56.28722
            ],
            [
              -61.811668,
              56.305271
            ],
            [
              -61.65889,
              56.26749
            ],
            [
              -61.778339,
              56.25444
            ],
            [
              -61.827782,
              56.264721
            ],
            [
              -61.753059,
              56.277771
            ],
            [
              -61.878609,
              56.298611
            ],
            [
              -62.076111,
              56.285
            ],
            [
              -61.919449,
              56.212212
            ],
            [
              -61.65527,
              56.224159
            ],
            [
              -61.56028,
              56.194439
            ],
            [
              -61.350842,
              56.22221
            ],
            [
              -61.33028,
              56.176659
            ],
            [
              -61.410549,
              56.124439
            ],
            [
              -61.342499,
              56.14138
            ],
            [
              -61.337502,
              56.100269
            ],
            [
              -61.452221,
              56.056938
            ],
            [
              -61.237499,
              56.04277
            ],
            [
              -61.50362,
              56.006939
            ],
            [
              -61.390839,
              55.998329
            ],
            [
              -61.376659,
              55.97971
            ],
            [
              -61.40472,
              55.97221
            ],
            [
              -61.460831,
              55.99638
            ],
            [
              -61.415001,
              55.96027
            ],
            [
              -61.117771,
              55.966099
            ],
            [
              -61.074451,
              55.928329
            ],
            [
              -61.07917,
              55.893051
            ],
            [
              -61.201672,
              55.884159
            ],
            [
              -61.10445,
              55.845539
            ],
            [
              -60.755562,
              55.84943
            ],
            [
              -60.733059,
              55.80249
            ],
            [
              -60.807781,
              55.755268
            ],
            [
              -60.945339,
              55.751308
            ],
            [
              -60.96666,
              55.74638
            ],
            [
              -60.9375,
              55.727211
            ],
            [
              -60.75639,
              55.729431
            ],
            [
              -60.647221,
              55.822769
            ],
            [
              -60.60194,
              55.814709
            ],
            [
              -60.62389,
              55.783611
            ],
            [
              -60.59277,
              55.704159
            ],
            [
              -60.661381,
              55.66666
            ],
            [
              -60.62888,
              55.629162
            ],
            [
              -60.679169,
              55.563881
            ],
            [
              -60.60223,
              55.626659
            ],
            [
              -60.503059,
              55.803051
            ],
            [
              -60.36195,
              55.761669
            ],
            [
              -60.34,
              55.786381
            ],
            [
              -60.33778,
              55.7486
            ],
            [
              -60.384171,
              55.719711
            ],
            [
              -60.37167,
              55.678329
            ],
            [
              -60.493889,
              55.658039
            ],
            [
              -60.531109,
              55.59721
            ],
            [
              -60.465839,
              55.63166
            ],
            [
              -60.393059,
              55.601101
            ],
            [
              -60.447781,
              55.500271
            ],
            [
              -60.340279,
              55.57888
            ],
            [
              -60.316391,
              55.573879
            ],
            [
              -60.339439,
              55.533611
            ],
            [
              -60.305561,
              55.524158
            ],
            [
              -60.45306,
              55.444988
            ],
            [
              -60.437771,
              55.399441
            ],
            [
              -60.478329,
              55.347488
            ],
            [
              -60.331669,
              55.48666
            ],
            [
              -60.30611,
              55.486382
            ],
            [
              -60.35722,
              55.4286
            ],
            [
              -60.285278,
              55.438042
            ],
            [
              -60.268608,
              55.502491
            ],
            [
              -60.201672,
              55.4786
            ],
            [
              -60.196659,
              55.422218
            ],
            [
              -60.354721,
              55.395
            ],
            [
              -60.399441,
              55.289989
            ],
            [
              -60.46666,
              55.29472
            ],
            [
              -60.533058,
              55.204441
            ],
            [
              -60.612499,
              55.16666
            ],
            [
              -60.553341,
              55.181561
            ],
            [
              -60.510761,
              55.2076
            ],
            [
              -60.46833,
              55.237499
            ],
            [
              -60.430279,
              55.21693
            ],
            [
              -60.396111,
              55.253609
            ],
            [
              -60.348049,
              55.250549
            ],
            [
              -60.46611,
              55.189709
            ],
            [
              -60.52306,
              55.111382
            ],
            [
              -60.670559,
              55.04472
            ],
            [
              -60.683331,
              54.994999
            ],
            [
              -60.482498,
              55.105549
            ],
            [
              -60.446659,
              55.15638
            ],
            [
              -60.385559,
              55.150539
            ],
            [
              -60.16,
              55.285549
            ],
            [
              -60.13805,
              55.278049
            ],
            [
              -60.194439,
              55.192211
            ],
            [
              -60.021389,
              55.266109
            ],
            [
              -60.18972,
              55.096939
            ],
            [
              -60.220551,
              55.10638
            ],
            [
              -60.288342,
              55.053322
            ],
            [
              -60.293892,
              55.01944
            ],
            [
              -60.099442,
              55.136662
            ],
            [
              -60.020279,
              55.234989
            ],
            [
              -59.91695,
              55.233879
            ],
            [
              -59.914719,
              55.26416
            ],
            [
              -59.779171,
              55.32972
            ],
            [
              -59.755001,
              55.2761
            ],
            [
              -59.711941,
              55.269711
            ],
            [
              -59.752781,
              55.220829
            ],
            [
              -59.732498,
              55.197208
            ],
            [
              -59.863892,
              55.15416
            ],
            [
              -59.96278,
              55.161098
            ],
            [
              -59.963619,
              55.110279
            ],
            [
              -59.921108,
              55.139721
            ],
            [
              -59.800831,
              55.108891
            ],
            [
              -59.729439,
              55.14444
            ],
            [
              -59.638889,
              55.13055
            ],
            [
              -59.535278,
              55.16777
            ],
            [
              -59.50584,
              55.213329
            ],
            [
              -59.501671,
              55.141941
            ],
            [
              -59.427219,
              55.139992
            ],
            [
              -59.519169,
              55.054161
            ],
            [
              -59.733059,
              54.967491
            ],
            [
              -59.715549,
              54.946098
            ],
            [
              -59.840549,
              54.879162
            ],
            [
              -59.860279,
              54.845539
            ],
            [
              -59.821388,
              54.846939
            ],
            [
              -59.827499,
              54.82444
            ],
            [
              -59.94416,
              54.749722
            ],
            [
              -59.791111,
              54.785549
            ],
            [
              -59.808048,
              54.836109
            ],
            [
              -59.75695,
              54.895828
            ],
            [
              -59.604172,
              54.954441
            ],
            [
              -59.433331,
              55.06889
            ],
            [
              -59.402222,
              55.028049
            ],
            [
              -59.286949,
              55.18943
            ],
            [
              -59.217499,
              55.217209
            ],
            [
              -59.21722,
              55.164989
            ],
            [
              -59.161381,
              55.236938
            ],
            [
              -59.127491,
              55.20583
            ],
            [
              -59.137218,
              55.15443
            ],
            [
              -59.252781,
              55.102489
            ],
            [
              -59.384171,
              54.97332
            ],
            [
              -59.250839,
              55.035831
            ],
            [
              -59.228882,
              55.081108
            ],
            [
              -59.181671,
              55.097488
            ],
            [
              -59.14917,
              55.06805
            ],
            [
              -59.12611,
              55.123878
            ],
            [
              -59.09,
              55.083328
            ],
            [
              -59.064159,
              55.14666
            ],
            [
              -59.023609,
              55.156651
            ],
            [
              -58.996109,
              55.118599
            ],
            [
              -58.96056,
              55.134991
            ],
            [
              -58.95583,
              55.10083
            ],
            [
              -59.043331,
              54.994999
            ],
            [
              -58.985561,
              55.020828
            ],
            [
              -58.894169,
              54.95277
            ],
            [
              -59.013618,
              54.889721
            ],
            [
              -58.883888,
              54.916382
            ],
            [
              -58.91666,
              54.850552
            ],
            [
              -58.779171,
              54.84972
            ],
            [
              -58.75639,
              54.79805
            ],
            [
              -58.73138,
              54.843048
            ],
            [
              -58.69194,
              54.846378
            ],
            [
              -58.685268,
              54.81554
            ],
            [
              -58.51722,
              54.8036
            ],
            [
              -58.565552,
              54.766109
            ],
            [
              -58.443611,
              54.77388
            ],
            [
              -58.40472,
              54.738331
            ],
            [
              -58.391941,
              54.787498
            ],
            [
              -58.196659,
              54.795269
            ],
            [
              -58.173328,
              54.745541
            ],
            [
              -57.998611,
              54.714161
            ],
            [
              -57.855,
              54.737499
            ],
            [
              -57.660278,
              54.62804
            ],
            [
              -57.545841,
              54.661659
            ],
            [
              -57.45055,
              54.64999
            ],
            [
              -57.414719,
              54.5886
            ],
            [
              -57.3475,
              54.579441
            ],
            [
              -57.431389,
              54.55027
            ],
            [
              -57.37722,
              54.542221
            ],
            [
              -57.385281,
              54.50333
            ],
            [
              -57.70528,
              54.46693
            ],
            [
              -57.421669,
              54.453609
            ],
            [
              -57.52528,
              54.451099
            ],
            [
              -57.523331,
              54.41721
            ],
            [
              -57.660831,
              54.376942
            ],
            [
              -58.146389,
              54.365269
            ],
            [
              -58.261951,
              54.3111
            ],
            [
              -58.095829,
              54.325829
            ],
            [
              -58.243061,
              54.287498
            ],
            [
              -58.233891,
              54.254169
            ],
            [
              -58.446388,
              54.239159
            ],
            [
              -58.72472,
              54.145271
            ],
            [
              -58.915279,
              54.138599
            ],
            [
              -59.37611,
              54.04694
            ],
            [
              -59.575562,
              54.049438
            ],
            [
              -59.509731,
              53.995541
            ],
            [
              -59.276112,
              54.021099
            ],
            [
              -58.687771,
              54.12471
            ],
            [
              -58.37944,
              54.229988
            ],
            [
              -58.610279,
              54.04166
            ],
            [
              -59.038609,
              54.026939
            ],
            [
              -58.919449,
              54.01083
            ],
            [
              -59.008339,
              53.955269
            ],
            [
              -59.11528,
              53.946381
            ],
            [
              -59.463329,
              53.830551
            ],
            [
              -59.82584,
              53.84277
            ],
            [
              -59.88055,
              53.825272
            ],
            [
              -59.80278,
              53.806099
            ],
            [
              -59.828892,
              53.788601
            ],
            [
              -60.13139,
              53.77721
            ],
            [
              -60.01778,
              53.704159
            ],
            [
              -60.097778,
              53.683601
            ],
            [
              -60.119999,
              53.611382
            ],
            [
              -60.064449,
              53.560268
            ],
            [
              -60.133888,
              53.528332
            ],
            [
              -60.39056,
              53.665539
            ],
            [
              -60.810829,
              53.771931
            ],
            [
              -60.875,
              53.83083
            ],
            [
              -60.856949,
              53.79277
            ],
            [
              -60.93306,
              53.7286
            ],
            [
              -60.91806,
              53.709431
            ],
            [
              -60.805561,
              53.723049
            ],
            [
              -60.53722,
              53.678329
            ],
            [
              -60.278881,
              53.558601
            ],
            [
              -60.048061,
              53.49527
            ],
            [
              -60.038059,
              53.439709
            ],
            [
              -60.13805,
              53.453609
            ],
            [
              -60.331951,
              53.388599
            ],
            [
              -60.363892,
              53.351101
            ],
            [
              -60.40583,
              53.364159
            ],
            [
              -60.39584,
              53.331379
            ],
            [
              -60.174999,
              53.343048
            ],
            [
              -60.39806,
              53.265549
            ],
            [
              -60.26112,
              53.2686
            ],
            [
              -60.16444,
              53.309429
            ],
            [
              -60.113892,
              53.270271
            ],
            [
              -60.09,
              53.299999
            ],
            [
              -60.147781,
              53.32666
            ],
            [
              -60.024719,
              53.355
            ],
            [
              -59.90889,
              53.45583
            ],
            [
              -59.87167,
              53.4436
            ],
            [
              -59.874168,
              53.484989
            ],
            [
              -59.785,
              53.477211
            ],
            [
              -59.893059,
              53.528599
            ],
            [
              -59.605831,
              53.52972
            ],
            [
              -59.36945,
              53.648609
            ],
            [
              -59.302219,
              53.686378
            ],
            [
              -59.279442,
              53.662769
            ],
            [
              -59.213619,
              53.69138
            ],
            [
              -59.074169,
              53.683048
            ],
            [
              -59.06834,
              53.71777
            ],
            [
              -59.027779,
              53.711658
            ],
            [
              -59.010559,
              53.746658
            ],
            [
              -59.066391,
              53.794998
            ],
            [
              -58.876389,
              53.888329
            ],
            [
              -58.841671,
              53.943321
            ],
            [
              -58.130001,
              54.096939
            ],
            [
              -57.78944,
              54.0686
            ],
            [
              -57.851669,
              54.100269
            ],
            [
              -58.159161,
              54.13583
            ],
            [
              -58.328892,
              54.089432
            ],
            [
              -58.418331,
              54.139721
            ],
            [
              -58.38361,
              54.189709
            ],
            [
              -58.17028,
              54.23666
            ],
            [
              -57.659161,
              54.199429
            ],
            [
              -57.604721,
              54.18388
            ],
            [
              -57.609169,
              54.16193
            ],
            [
              -57.428341,
              54.182499
            ],
            [
              -57.384171,
              54.150539
            ],
            [
              -57.396389,
              54.128601
            ],
            [
              -57.35973,
              54.134159
            ],
            [
              -57.370831,
              54.10638
            ],
            [
              -57.296391,
              54.00555
            ],
            [
              -57.142231,
              53.934719
            ],
            [
              -57.215,
              53.929989
            ],
            [
              -57.221378,
              53.889992
            ],
            [
              -57.076389,
              53.823051
            ],
            [
              -57.13361,
              53.824169
            ],
            [
              -57.099998,
              53.800831
            ],
            [
              -57.159721,
              53.75861
            ],
            [
              -57.119999,
              53.7411
            ],
            [
              -57.549999,
              53.59193
            ],
            [
              -57.444721,
              53.618599
            ],
            [
              -57.448608,
              53.59222
            ],
            [
              -57.376659,
              53.607498
            ],
            [
              -57.316669,
              53.57972
            ],
            [
              -57.344158,
              53.54277
            ],
            [
              -57.305561,
              53.534439
            ],
            [
              -57.30666,
              53.49638
            ],
            [
              -57.372219,
              53.43082
            ],
            [
              -57.289169,
              53.43388
            ],
            [
              -57.285278,
              53.477489
            ],
            [
              -57.13612,
              53.54916
            ],
            [
              -57.159161,
              53.564159
            ],
            [
              -57.136391,
              53.60944
            ],
            [
              -57.077499,
              53.628601
            ],
            [
              -57.014721,
              53.71138
            ],
            [
              -56.704441,
              53.750549
            ],
            [
              -56.600281,
              53.710819
            ],
            [
              -56.615841,
              53.762501
            ],
            [
              -56.464691,
              53.782269
            ],
            [
              -56.421669,
              53.71693
            ],
            [
              -56.725559,
              53.67416
            ],
            [
              -56.480549,
              53.62138
            ],
            [
              -56.333889,
              53.629162
            ],
            [
              -56.263062,
              53.601391
            ],
            [
              -56.33556,
              53.603321
            ],
            [
              -56.320278,
              53.58527
            ],
            [
              -56.027222,
              53.575272
            ],
            [
              -55.978882,
              53.542221
            ],
            [
              -56.00861,
              53.5
            ],
            [
              -56.063332,
              53.540829
            ],
            [
              -56.26334,
              53.540279
            ],
            [
              -56.031391,
              53.459721
            ],
            [
              -56.032219,
              53.432209
            ],
            [
              -55.965549,
              53.409161
            ],
            [
              -56.038342,
              53.367489
            ],
            [
              -55.91222,
              53.395
            ],
            [
              -55.85828,
              53.365269
            ],
            [
              -55.885559,
              53.344151
            ],
            [
              -55.808048,
              53.340549
            ],
            [
              -55.91111,
              53.303879
            ],
            [
              -55.812222,
              53.290829
            ],
            [
              -55.85973,
              53.248051
            ],
            [
              -55.745831,
              53.249439
            ],
            [
              -55.79084,
              53.198879
            ],
            [
              -55.841942,
              53.205269
            ],
            [
              -55.76334,
              53.1786
            ],
            [
              -55.754169,
              53.134991
            ],
            [
              -55.830002,
              53.09721
            ],
            [
              -55.944439,
              53.112499
            ],
            [
              -55.87944,
              53.07999
            ],
            [
              -55.911942,
              53.028332
            ],
            [
              -56.16695,
              53.02943
            ],
            [
              -56.084438,
              53.01527
            ],
            [
              -56.133331,
              52.983051
            ],
            [
              -56.11195,
              52.96666
            ],
            [
              -56.04084,
              53.005829
            ],
            [
              -55.889439,
              52.969151
            ],
            [
              -55.910831,
              52.950272
            ],
            [
              -55.834171,
              52.92194
            ],
            [
              -55.803329,
              52.839432
            ],
            [
              -55.987782,
              52.806099
            ],
            [
              -55.923328,
              52.789989
            ],
            [
              -55.996391,
              52.773319
            ],
            [
              -56.160549,
              52.819439
            ],
            [
              -56.16,
              52.79388
            ],
            [
              -56.053329,
              52.762772
            ],
            [
              -55.96056,
              52.679161
            ],
            [
              -55.76556,
              52.665821
            ],
            [
              -55.738609,
              52.642769
            ],
            [
              -55.794449,
              52.593048
            ],
            [
              -55.937222,
              52.63916
            ],
            [
              -56.122768,
              52.6511
            ],
            [
              -55.974998,
              52.619709
            ],
            [
              -56.047779,
              52.576099
            ],
            [
              -55.989441,
              52.56694
            ],
            [
              -55.999439,
              52.548611
            ],
            [
              -56.089722,
              52.572491
            ],
            [
              -56.237221,
              52.541939
            ],
            [
              -56.496868,
              52.594151
            ],
            [
              -56.245548,
              52.52916
            ],
            [
              -56.10722,
              52.547489
            ],
            [
              -55.971378,
              52.50222
            ],
            [
              -55.7514,
              52.496101
            ],
            [
              -55.735828,
              52.469151
            ],
            [
              -55.766399,
              52.447769
            ],
            [
              -55.64555,
              52.437489
            ],
            [
              -55.641941,
              52.36388
            ],
            [
              -55.799171,
              52.384441
            ],
            [
              -55.782501,
              52.33416
            ],
            [
              -56.195271,
              52.435822
            ],
            [
              -55.82362,
              52.32333
            ],
            [
              -55.849171,
              52.298882
            ],
            [
              -55.70805,
              52.293331
            ],
            [
              -55.79195,
              52.237499
            ],
            [
              -55.720829,
              52.251389
            ],
            [
              -55.618328,
              52.215271
            ],
            [
              -55.776661,
              52.20916
            ],
            [
              -55.688889,
              52.18111
            ],
            [
              -55.713329,
              52.139992
            ],
            [
              -55.68972,
              52.101391
            ],
            [
              -55.792782,
              52.083881
            ],
            [
              -55.763889,
              52.047489
            ],
            [
              -55.84277,
              52.003052
            ],
            [
              -55.959171,
              52.019161
            ],
            [
              -55.894451,
              51.962212
            ],
            [
              -55.915279,
              51.932209
            ],
            [
              -56.117771,
              51.860279
            ],
            [
              -56.209721,
              51.789719
            ],
            [
              -56.43306,
              51.74638
            ],
            [
              -56.493328,
              51.688599
            ],
            [
              -56.68195,
              51.64222
            ],
            [
              -56.695831,
              51.584991
            ],
            [
              -56.807499,
              51.528332
            ],
            [
              -56.81361,
              51.491661
            ],
            [
              -56.858059,
              51.46888
            ],
            [
              -56.933331,
              51.491661
            ],
            [
              -56.942768,
              51.42749
            ],
            [
              -57.10424,
              51.41267
            ],
            [
              -57.101109,
              52
            ],
            [
              -57.367771,
              51.999439
            ],
            [
              -63.75111,
              51.999722
            ],
            [
              -63.824718,
              52.070549
            ],
            [
              -63.637779,
              52.039989
            ],
            [
              -63.65535,
              52.124359
            ],
            [
              -63.941269,
              52.264
            ],
            [
              -63.987499,
              52.362499
            ],
            [
              -64.013062,
              52.387211
            ],
            [
              -63.92445,
              52.383881
            ],
            [
              -63.930279,
              52.41777
            ],
            [
              -63.976391,
              52.458599
            ],
            [
              -64.027496,
              52.433048
            ],
            [
              -64.08168,
              52.470539
            ],
            [
              -63.971378,
              52.570831
            ],
            [
              -63.839722,
              52.61805
            ],
            [
              -63.377781,
              52.650829
            ],
            [
              -63.423061,
              52.707211
            ],
            [
              -63.595829,
              52.770771
            ],
            [
              -63.54195,
              52.970829
            ],
            [
              -63.62611,
              53.042221
            ],
            [
              -63.634731,
              53.094151
            ],
            [
              -63.693329,
              53.113609
            ],
            [
              -63.923611,
              53.077221
            ],
            [
              -63.919449,
              52.97694
            ],
            [
              -63.982769,
              52.933601
            ],
            [
              -63.959999,
              52.867771
            ],
            [
              -64.080841,
              52.881378
            ],
            [
              -64.172501,
              52.85083
            ],
            [
              -64.201401,
              52.790829
            ],
            [
              -64.156662,
              52.738331
            ],
            [
              -64.202217,
              52.672218
            ],
            [
              -64.175278,
              52.60944
            ],
            [
              -64.208893,
              52.548882
            ],
            [
              -64.08168,
              52.37249
            ],
            [
              -64.131378,
              52.28138
            ],
            [
              -64.115829,
              52.151379
            ],
            [
              -64.257782,
              52.12804
            ],
            [
              -64.293327,
              52.046391
            ],
            [
              -64.274437,
              52.00333
            ],
            [
              -64.330841,
              51.965271
            ],
            [
              -64.317497,
              51.903599
            ],
            [
              -64.357224,
              51.850552
            ],
            [
              -64.264183,
              51.739719
            ],
            [
              -64.44249,
              51.66777
            ],
            [
              -64.581123,
              51.55777
            ],
            [
              -64.604172,
              51.642769
            ],
            [
              -64.703056,
              51.69276
            ],
            [
              -64.718063,
              51.747768
            ],
            [
              -64.593887,
              51.796101
            ],
            [
              -64.587784,
              51.82888
            ],
            [
              -64.84584,
              51.93082
            ],
            [
              -64.853333,
              52.021931
            ],
            [
              -65.108612,
              52.17083
            ],
            [
              -65.453613,
              52.20805
            ],
            [
              -65.538597,
              52.123878
            ],
            [
              -65.56723,
              52.046661
            ],
            [
              -65.758621,
              52.111382
            ],
            [
              -65.799438,
              52.095539
            ],
            [
              -65.801666,
              52.04277
            ],
            [
              -65.916946,
              52.050831
            ],
            [
              -66.063889,
              52.11832
            ],
            [
              -66.068069,
              52.216381
            ],
            [
              -66.173889,
              52.220539
            ],
            [
              -66.275558,
              52.311661
            ],
            [
              -66.319733,
              52.289162
            ],
            [
              -66.303879,
              52.222759
            ],
            [
              -66.335564,
              52.136379
            ],
            [
              -66.438889,
              52.200829
            ],
            [
              -66.486656,
              52.312489
            ],
            [
              -66.458618,
              52.3661
            ],
            [
              -66.358047,
              52.406651
            ],
            [
              -66.446663,
              52.530819
            ],
            [
              -66.373611,
              52.5425
            ],
            [
              -66.43222,
              52.611382
            ],
            [
              -66.433884,
              52.706379
            ],
            [
              -66.328339,
              52.736938
            ],
            [
              -66.378883,
              52.836109
            ],
            [
              -66.26973,
              52.877491
            ],
            [
              -66.345551,
              52.974159
            ],
            [
              -66.476936,
              53.034161
            ],
            [
              -66.525284,
              52.943321
            ],
            [
              -66.648064,
              52.94582
            ],
            [
              -66.66806,
              52.725819
            ],
            [
              -66.813316,
              52.66415
            ],
            [
              -66.87471,
              52.747768
            ],
            [
              -66.891388,
              52.673321
            ],
            [
              -67.067497,
              52.699429
            ],
            [
              -67.092773,
              52.72971
            ],
            [
              -67.058327,
              52.804989
            ],
            [
              -67.078056,
              52.94804
            ],
            [
              -66.979446,
              53.027771
            ],
            [
              -66.948608,
              53.109718
            ],
            [
              -66.991669,
              53.195271
            ],
            [
              -66.910004,
              53.340271
            ],
            [
              -66.987503,
              53.403599
            ],
            [
              -67.06778,
              53.384159
            ],
            [
              -67.068619,
              53.4286
            ],
            [
              -67.128883,
              53.464161
            ],
            [
              -67.141953,
              53.542221
            ],
            [
              -67.491943,
              53.603882
            ],
            [
              -67.430557,
              53.778332
            ],
            [
              -67.588058,
              53.85722
            ],
            [
              -67.609161,
              53.928051
            ],
            [
              -67.738441,
              54.000011
            ],
            [
              -67.781952,
              54.063881
            ],
            [
              -67.637222,
              54.174999
            ],
            [
              -67.485001,
              54.234161
            ],
            [
              -67.531677,
              54.248878
            ],
            [
              -67.49472,
              54.314442
            ],
            [
              -67.59584,
              54.365269
            ],
            [
              -67.586121,
              54.4175
            ],
            [
              -67.633057,
              54.479988
            ],
            [
              -67.601387,
              54.56638
            ],
            [
              -67.492493,
              54.58194
            ],
            [
              -67.305557,
              54.504169
            ],
            [
              -67.219452,
              54.607498
            ],
            [
              -67.036392,
              54.621658
            ],
            [
              -67.213898,
              54.66888
            ],
            [
              -67.184998,
              54.71944
            ],
            [
              -67.30777,
              54.783878
            ],
            [
              -67.263344,
              54.825272
            ],
            [
              -67.362213,
              54.87582
            ],
            [
              -67.453056,
              55.018879
            ],
            [
              -67.428329,
              55.07111
            ],
            [
              -67.273903,
              55.066669
            ],
            [
              -67.297783,
              55.008049
            ],
            [
              -67.028061,
              54.89777
            ],
            [
              -66.946953,
              54.801659
            ],
            [
              -66.738602,
              54.732769
            ],
            [
              -66.698608,
              54.742222
            ],
            [
              -66.712219,
              54.78194
            ],
            [
              -66.605003,
              54.790829
            ],
            [
              -66.678329,
              54.894161
            ],
            [
              -66.732773,
              54.94833
            ],
            [
              -66.65976,
              54.978031
            ],
            [
              -66.765839,
              55.105549
            ],
            [
              -66.740829,
              55.17749
            ],
            [
              -66.688889,
              55.204159
            ],
            [
              -66.844162,
              55.326099
            ],
            [
              -66.77861,
              55.338879
            ],
            [
              -66.688049,
              55.301102
            ],
            [
              -66.681953,
              55.25333
            ],
            [
              -66.576401,
              55.250549
            ],
            [
              -66.23999,
              54.99194
            ],
            [
              -66.035004,
              54.90971
            ],
            [
              -65.957779,
              54.92944
            ],
            [
              -65.858337,
              54.907768
            ],
            [
              -65.835281,
              54.831379
            ],
            [
              -65.689987,
              54.713329
            ],
            [
              -65.443878,
              54.745831
            ],
            [
              -65.121933,
              54.69471
            ],
            [
              -64.811661,
              54.749161
            ],
            [
              -64.552223,
              54.720539
            ],
            [
              -64.404327,
              54.75251
            ],
            [
              -64.325562,
              54.751942
            ],
            [
              -63.976189,
              54.626461
            ],
            [
              -63.66917,
              54.612209
            ],
            [
              -63.435268,
              54.973598
            ],
            [
              -63.566109,
              55.060268
            ],
            [
              -63.519451,
              55.183601
            ],
            [
              -63.176571,
              55.296539
            ],
            [
              -63.095829,
              55.349998
            ],
            [
              -63.176392,
              55.419441
            ],
            [
              -63.144169,
              55.526379
            ],
            [
              -63.29834,
              55.523319
            ],
            [
              -63.448051,
              55.59943
            ],
            [
              -63.488331,
              55.696659
            ],
            [
              -63.715279,
              55.859161
            ],
            [
              -63.69416,
              55.942211
            ],
            [
              -63.445,
              56.02277
            ],
            [
              -63.79084,
              56.039162
            ],
            [
              -63.880001,
              56.076939
            ],
            [
              -63.847778,
              56.12027
            ],
            [
              -64.020279,
              56.076389
            ],
            [
              -64.010834,
              56.159988
            ],
            [
              -63.876949,
              56.217209
            ],
            [
              -63.97472,
              56.259159
            ],
            [
              -64.08667,
              56.259159
            ],
            [
              -64.136673,
              56.3036
            ],
            [
              -64.105827,
              56.331379
            ],
            [
              -64.114166,
              56.39027
            ],
            [
              -64.169724,
              56.427769
            ],
            [
              -63.97472,
              56.412769
            ],
            [
              -63.87138,
              56.43943
            ],
            [
              -63.942219,
              56.479431
            ],
            [
              -63.945,
              56.549721
            ],
            [
              -64.12944,
              56.71027
            ],
            [
              -64.083618,
              56.765549
            ],
            [
              -63.992229,
              56.785831
            ],
            [
              -64.00528,
              56.850552
            ],
            [
              -63.87138,
              56.87804
            ],
            [
              -63.917782,
              56.930271
            ],
            [
              -63.863331,
              56.992771
            ],
            [
              -63.873611,
              57.06638
            ],
            [
              -63.78751,
              57.111938
            ],
            [
              -63.7925,
              57.165272
            ],
            [
              -63.741112,
              57.232769
            ],
            [
              -63.81472,
              57.27972
            ],
            [
              -63.855282,
              57.193878
            ],
            [
              -63.87611,
              57.242771
            ],
            [
              -63.836941,
              57.325829
            ],
            [
              -63.709721,
              57.37249
            ],
            [
              -63.763889,
              57.482491
            ],
            [
              -63.765011,
              57.573879
            ],
            [
              -63.721378,
              57.602489
            ],
            [
              -63.60722,
              57.663601
            ],
            [
              -63.595829,
              57.734161
            ],
            [
              -63.653332,
              57.731098
            ],
            [
              -63.70055,
              57.662769
            ],
            [
              -63.765282,
              57.732769
            ],
            [
              -63.825562,
              57.690269
            ],
            [
              -63.891941,
              57.73027
            ],
            [
              -63.92667,
              57.797218
            ],
            [
              -64.006668,
              57.812771
            ],
            [
              -64.022507,
              57.771381
            ],
            [
              -64.057503,
              57.76944
            ],
            [
              -64.214447,
              57.977489
            ],
            [
              -64.208618,
              58.033329
            ],
            [
              -64.431953,
              58.086109
            ],
            [
              -64.398064,
              58.18166
            ],
            [
              -64.21167,
              58.23555
            ],
            [
              -64.156387,
              58.348331
            ],
            [
              -63.81945,
              58.471371
            ],
            [
              -63.886951,
              58.563049
            ],
            [
              -64.022507,
              58.52055
            ],
            [
              -64.084167,
              58.55555
            ],
            [
              -64.097504,
              58.60722
            ],
            [
              -64.051102,
              58.676102
            ],
            [
              -63.574169,
              58.715832
            ],
            [
              -63.480549,
              58.7486
            ],
            [
              -63.588329,
              58.846378
            ],
            [
              -63.766651,
              58.874081
            ],
            [
              -63.841942,
              58.82972
            ],
            [
              -63.999439,
              58.81916
            ],
            [
              -64.156113,
              58.74527
            ],
            [
              -64.257507,
              58.77943
            ],
            [
              -64.258621,
              58.849998
            ],
            [
              -64.300278,
              58.882488
            ],
            [
              -64.568069,
              58.887211
            ],
            [
              -64.718887,
              58.945271
            ],
            [
              -64.809433,
              58.911098
            ],
            [
              -64.878883,
              58.92749
            ],
            [
              -64.838898,
              58.958599
            ],
            [
              -64.864723,
              58.990551
            ],
            [
              -64.829453,
              59.03249
            ],
            [
              -64.720001,
              59.069988
            ],
            [
              -64.656387,
              59.028599
            ],
            [
              -64.415833,
              58.981098
            ],
            [
              -64.291382,
              59.016659
            ],
            [
              -64.365013,
              59.07333
            ],
            [
              -64.493057,
              59.104439
            ],
            [
              -64.493057,
              59.158878
            ],
            [
              -64.534729,
              59.197491
            ],
            [
              -64.507507,
              59.25
            ],
            [
              -64.53833,
              59.285549
            ],
            [
              -64.499161,
              59.33971
            ],
            [
              -64.538063,
              59.3736
            ],
            [
              -64.485001,
              59.396389
            ],
            [
              -64.5,
              59.433048
            ],
            [
              -64.340286,
              59.49638
            ],
            [
              -64.432503,
              59.52943
            ],
            [
              -64.700844,
              59.44582
            ],
            [
              -64.82695,
              59.531109
            ],
            [
              -64.773064,
              59.698879
            ],
            [
              -64.814163,
              59.81889
            ],
            [
              -64.744453,
              59.84499
            ],
            [
              -64.767776,
              59.87999
            ],
            [
              -64.661911,
              59.88361
            ],
            [
              -64.65361,
              59.929989
            ],
            [
              -64.847778,
              59.964161
            ],
            [
              -64.906387,
              60.04805
            ],
            [
              -64.796951,
              60.039719
            ],
            [
              -64.702499,
              60.064991
            ],
            [
              -64.734161,
              60.103321
            ],
            [
              -64.598892,
              60.111111
            ],
            [
              -64.637222,
              60.178329
            ],
            [
              -64.767227,
              60.174999
            ],
            [
              -64.851936,
              60.22332
            ],
            [
              -64.853882,
              60.261669
            ],
            [
              -64.534821,
              60.302601
            ]
          ],
          [
            [
              -64.047783,
              59.84943
            ],
            [
              -64.032784,
              59.80444
            ],
            [
              -64.075562,
              59.78833
            ],
            [
              -63.959721,
              59.75639
            ],
            [
              -63.997219,
              59.723598
            ],
            [
              -64.134171,
              59.695541
            ],
            [
              -64.16333,
              59.703609
            ],
            [
              -64.144447,
              59.727211
            ],
            [
              -64.204453,
              59.73444
            ],
            [
              -64.187767,
              59.76833
            ],
            [
              -64.129173,
              59.757221
            ],
            [
              -64.152786,
              59.788052
            ],
            [
              -64.121933,
              59.84943
            ],
            [
              -64.047783,
              59.84943
            ]
          ],
          [
            [
              -61.884449,
              57.86694
            ],
            [
              -61.881939,
              57.812771
            ],
            [
              -61.947491,
              57.78722
            ],
            [
              -62.10944,
              57.829441
            ],
            [
              -61.994999,
              57.909161
            ],
            [
              -61.928341,
              57.9086
            ],
            [
              -61.939991,
              57.872219
            ],
            [
              -61.884449,
              57.86694
            ]
          ],
          [
            [
              -61.654171,
              57.771099
            ],
            [
              -61.69611,
              57.712212
            ],
            [
              -61.76889,
              57.71693
            ],
            [
              -61.827782,
              57.778881
            ],
            [
              -61.89695,
              57.758331
            ],
            [
              -61.778881,
              57.845268
            ],
            [
              -61.698608,
              57.83028
            ],
            [
              -61.723881,
              57.783329
            ],
            [
              -61.676392,
              57.795551
            ],
            [
              -61.654171,
              57.771099
            ]
          ],
          [
            [
              -61.926949,
              57.452492
            ],
            [
              -62.021938,
              57.521099
            ],
            [
              -61.974998,
              57.581379
            ],
            [
              -61.931671,
              57.585548
            ],
            [
              -61.946949,
              57.54916
            ],
            [
              -61.896111,
              57.586109
            ],
            [
              -61.781391,
              57.54805
            ],
            [
              -61.783058,
              57.508331
            ],
            [
              -61.926949,
              57.452492
            ]
          ],
          [
            [
              -61.64389,
              57.52277
            ],
            [
              -61.69138,
              57.495541
            ],
            [
              -61.64695,
              57.482769
            ],
            [
              -61.674171,
              57.463879
            ],
            [
              -61.78973,
              57.46027
            ],
            [
              -61.826389,
              57.43082
            ],
            [
              -61.69416,
              57.441662
            ],
            [
              -61.614719,
              57.418598
            ],
            [
              -61.648338,
              57.393608
            ],
            [
              -61.90028,
              57.437489
            ],
            [
              -61.782261,
              57.486259
            ],
            [
              -61.737221,
              57.536942
            ],
            [
              -61.64389,
              57.52277
            ]
          ],
          [
            [
              -61.355282,
              56.91082
            ],
            [
              -61.40472,
              56.879711
            ],
            [
              -61.361671,
              56.848049
            ],
            [
              -61.439442,
              56.816101
            ],
            [
              -61.448051,
              56.84166
            ],
            [
              -61.493061,
              56.838039
            ],
            [
              -61.503059,
              56.804161
            ],
            [
              -61.58556,
              56.766392
            ],
            [
              -61.38612,
              56.775829
            ],
            [
              -61.36861,
              56.685822
            ],
            [
              -61.404999,
              56.662491
            ],
            [
              -61.390839,
              56.618881
            ],
            [
              -61.445549,
              56.62027
            ],
            [
              -61.644451,
              56.73444
            ],
            [
              -61.631939,
              56.859718
            ],
            [
              -61.533619,
              56.902489
            ],
            [
              -61.52277,
              56.941929
            ],
            [
              -61.46777,
              56.958889
            ],
            [
              -61.42889,
              56.930271
            ],
            [
              -61.34277,
              56.93388
            ],
            [
              -61.355282,
              56.91082
            ]
          ],
          [
            [
              -61.558048,
              56.551929
            ],
            [
              -61.325279,
              56.512211
            ],
            [
              -61.360561,
              56.471931
            ],
            [
              -61.17445,
              56.47665
            ],
            [
              -61.224171,
              56.450829
            ],
            [
              -61.148891,
              56.441101
            ],
            [
              -61.474442,
              56.46666
            ],
            [
              -61.50333,
              56.4361
            ],
            [
              -61.63055,
              56.465271
            ],
            [
              -61.630001,
              56.490551
            ],
            [
              -61.417782,
              56.48333
            ],
            [
              -61.633331,
              56.50639
            ],
            [
              -61.571671,
              56.51833
            ],
            [
              -61.60223,
              56.552769
            ],
            [
              -61.558048,
              56.551929
            ]
          ],
          [
            [
              -61.546669,
              56.390831
            ],
            [
              -61.47472,
              56.406651
            ],
            [
              -61.411671,
              56.372219
            ],
            [
              -61.464161,
              56.345539
            ],
            [
              -61.416111,
              56.32222
            ],
            [
              -61.482769,
              56.30999
            ],
            [
              -61.68639,
              56.352779
            ],
            [
              -61.796108,
              56.413052
            ],
            [
              -61.546669,
              56.390831
            ]
          ],
          [
            [
              -61.089161,
              56.169991
            ],
            [
              -61.045841,
              56.153881
            ],
            [
              -61.06583,
              56.123051
            ],
            [
              -60.944439,
              56.09499
            ],
            [
              -61.009171,
              56.035271
            ],
            [
              -60.934719,
              56.011379
            ],
            [
              -61.040562,
              56.005268
            ],
            [
              -61.220829,
              56.050831
            ],
            [
              -61.22694,
              56.098049
            ],
            [
              -61.14917,
              56.091099
            ],
            [
              -61.183331,
              56.106659
            ],
            [
              -61.089161,
              56.169991
            ]
          ],
          [
            [
              -60.747501,
              55.93166
            ],
            [
              -60.75111,
              55.90971
            ],
            [
              -60.68639,
              55.92194
            ],
            [
              -60.75639,
              55.880268
            ],
            [
              -60.902222,
              55.87138
            ],
            [
              -60.815281,
              55.88583
            ],
            [
              -60.874439,
              55.90443
            ],
            [
              -60.865009,
              55.95277
            ],
            [
              -60.747501,
              55.93166
            ]
          ],
          [
            [
              -57.94083,
              54.91193
            ],
            [
              -57.988609,
              54.86721
            ],
            [
              -57.983891,
              54.802219
            ],
            [
              -57.93861,
              54.82777
            ],
            [
              -57.884998,
              54.802219
            ],
            [
              -57.864719,
              54.832211
            ],
            [
              -57.841671,
              54.812489
            ],
            [
              -57.881939,
              54.79055
            ],
            [
              -58.139172,
              54.757221
            ],
            [
              -58.22472,
              54.83527
            ],
            [
              -58.215839,
              54.87804
            ],
            [
              -58.17028,
              54.85416
            ],
            [
              -57.94083,
              54.91193
            ]
          ],
          [
            [
              -55.876949,
              50.86277
            ],
            [
              -55.906658,
              50.89666
            ],
            [
              -56.097221,
              50.71693
            ],
            [
              -56.091671,
              50.759991
            ],
            [
              -56.116112,
              50.746101
            ],
            [
              -56.133331,
              50.773048
            ],
            [
              -56.103889,
              50.898331
            ],
            [
              -56.15527,
              50.885551
            ],
            [
              -56.157219,
              50.69083
            ],
            [
              -56.09972,
              50.679722
            ],
            [
              -56.108059,
              50.651932
            ],
            [
              -56.206108,
              50.595539
            ],
            [
              -56.240841,
              50.524158
            ],
            [
              -56.321671,
              50.52388
            ],
            [
              -56.25861,
              50.502781
            ],
            [
              -56.40472,
              50.374439
            ],
            [
              -56.501671,
              50.382771
            ],
            [
              -56.423061,
              50.352779
            ],
            [
              -56.462502,
              50.272221
            ],
            [
              -56.566391,
              50.157768
            ],
            [
              -56.670559,
              50.129162
            ],
            [
              -56.63583,
              50.100552
            ],
            [
              -56.743061,
              50.02277
            ],
            [
              -56.77861,
              49.93388
            ],
            [
              -56.71833,
              49.949162
            ],
            [
              -56.759998,
              49.83638
            ],
            [
              -56.906391,
              49.746941
            ],
            [
              -56.781391,
              49.727211
            ],
            [
              -56.85751,
              49.545551
            ],
            [
              -56.735561,
              49.666939
            ],
            [
              -56.477779,
              49.89222
            ],
            [
              -56.4175,
              49.84222
            ],
            [
              -56.43195,
              49.890549
            ],
            [
              -56.36528,
              49.896938
            ],
            [
              -56.38805,
              49.94305
            ],
            [
              -56.33028,
              50.02499
            ],
            [
              -56.153881,
              50.150539
            ],
            [
              -56.116661,
              50.153049
            ],
            [
              -56.062771,
              50.071659
            ],
            [
              -56.188332,
              49.923882
            ],
            [
              -56.005001,
              50.03138
            ],
            [
              -56.028339,
              49.970539
            ],
            [
              -55.977779,
              49.991379
            ],
            [
              -55.962219,
              50.035271
            ],
            [
              -55.90527,
              50.033878
            ],
            [
              -55.746109,
              49.92305
            ],
            [
              -55.671391,
              49.973049
            ],
            [
              -55.594158,
              49.96249
            ],
            [
              -55.491699,
              50.007309
            ],
            [
              -55.459721,
              49.9575
            ],
            [
              -55.492229,
              49.91721
            ],
            [
              -55.891109,
              49.75972
            ],
            [
              -56.002781,
              49.748051
            ],
            [
              -56.007229,
              49.716099
            ],
            [
              -56.160278,
              49.622761
            ],
            [
              -56.17223,
              49.579159
            ],
            [
              -56.044449,
              49.669991
            ],
            [
              -55.89389,
              49.714161
            ],
            [
              -55.95834,
              49.672489
            ],
            [
              -55.833328,
              49.686649
            ],
            [
              -55.938332,
              49.603611
            ],
            [
              -55.88028,
              49.584991
            ],
            [
              -55.937222,
              49.52972
            ],
            [
              -56.080002,
              49.486938
            ],
            [
              -56.129169,
              49.425549
            ],
            [
              -55.827782,
              49.524158
            ],
            [
              -55.720829,
              49.475819
            ],
            [
              -55.779999,
              49.44249
            ],
            [
              -55.674171,
              49.466099
            ],
            [
              -55.698879,
              49.42749
            ],
            [
              -55.66444,
              49.381939
            ],
            [
              -55.52306,
              49.48666
            ],
            [
              -55.568611,
              49.41888
            ],
            [
              -55.566669,
              49.36277
            ],
            [
              -55.48917,
              49.470829
            ],
            [
              -55.36945,
              49.50333
            ],
            [
              -55.34972,
              49.487209
            ],
            [
              -55.431671,
              49.422489
            ],
            [
              -55.316391,
              49.45166
            ],
            [
              -55.44416,
              49.355
            ],
            [
              -55.391392,
              49.374161
            ],
            [
              -55.373051,
              49.331108
            ],
            [
              -55.32806,
              49.406101
            ],
            [
              -55.320839,
              49.380821
            ],
            [
              -55.29306,
              49.4011
            ],
            [
              -55.301941,
              49.36055
            ],
            [
              -55.338612,
              49.35527
            ],
            [
              -55.30444,
              49.311661
            ],
            [
              -55.300549,
              49.348598
            ],
            [
              -55.233608,
              49.39444
            ],
            [
              -55.311111,
              49.529991
            ],
            [
              -55.228611,
              49.53833
            ],
            [
              -55.19611,
              49.5
            ],
            [
              -55.193611,
              49.53944
            ],
            [
              -55.137218,
              49.540829
            ],
            [
              -55.124722,
              49.465271
            ],
            [
              -55.23222,
              49.347771
            ],
            [
              -55.221111,
              49.26194
            ],
            [
              -55.365841,
              49.165272
            ],
            [
              -55.31945,
              49.149158
            ],
            [
              -55.339722,
              49.122219
            ],
            [
              -55.307781,
              49.099159
            ],
            [
              -55.383331,
              49.040829
            ],
            [
              -55.182781,
              49.12027
            ],
            [
              -55.272221,
              49.099998
            ],
            [
              -55.279999,
              49.160271
            ],
            [
              -55.25333,
              49.18388
            ],
            [
              -55.288059,
              49.18721
            ],
            [
              -55.058609,
              49.284439
            ],
            [
              -55.07806,
              49.356941
            ],
            [
              -55.03751,
              49.321941
            ],
            [
              -55.007778,
              49.33971
            ],
            [
              -54.990002,
              49.286942
            ],
            [
              -55.041672,
              49.224159
            ],
            [
              -54.982769,
              49.257221
            ],
            [
              -54.959721,
              49.299438
            ],
            [
              -54.909439,
              49.275829
            ],
            [
              -54.87167,
              49.293331
            ],
            [
              -54.858891,
              49.332211
            ],
            [
              -54.890282,
              49.35416
            ],
            [
              -54.848049,
              49.410271
            ],
            [
              -54.824451,
              49.269161
            ],
            [
              -54.777222,
              49.29916
            ],
            [
              -54.76722,
              49.352489
            ],
            [
              -54.739719,
              49.320549
            ],
            [
              -54.541111,
              49.526661
            ],
            [
              -54.44722,
              49.543049
            ],
            [
              -54.530281,
              49.444988
            ],
            [
              -54.43195,
              49.470829
            ],
            [
              -54.488049,
              49.371101
            ],
            [
              -54.452221,
              49.333328
            ],
            [
              -54.48695,
              49.25972
            ],
            [
              -54.400829,
              49.325272
            ],
            [
              -54.407501,
              49.37471
            ],
            [
              -54.358059,
              49.426941
            ],
            [
              -54.24889,
              49.397491
            ],
            [
              -54.219719,
              49.41721
            ],
            [
              -54.228882,
              49.361111
            ],
            [
              -54.206108,
              49.402771
            ],
            [
              -54.166111,
              49.37804
            ],
            [
              -54.139999,
              49.448601
            ],
            [
              -54.041672,
              49.48082
            ],
            [
              -53.988049,
              49.458599
            ],
            [
              -54.033329,
              49.42749
            ],
            [
              -53.92778,
              49.451099
            ],
            [
              -53.455559,
              49.251942
            ],
            [
              -53.560001,
              49.124989
            ],
            [
              -53.619999,
              49.117489
            ],
            [
              -53.581951,
              49.092491
            ],
            [
              -53.593891,
              49.035549
            ],
            [
              -53.83527,
              49.03249
            ],
            [
              -53.725269,
              49.00972
            ],
            [
              -53.888889,
              48.982769
            ],
            [
              -53.813061,
              48.92527
            ],
            [
              -53.97472,
              48.89138
            ],
            [
              -53.883888,
              48.886662
            ],
            [
              -53.980831,
              48.82888
            ],
            [
              -54.178051,
              48.800831
            ],
            [
              -54.19944,
              48.768051
            ],
            [
              -54.172779,
              48.761669
            ],
            [
              -53.990841,
              48.810822
            ],
            [
              -53.998341,
              48.76194
            ],
            [
              -53.957779,
              48.772221
            ],
            [
              -53.98,
              48.785831
            ],
            [
              -53.93417,
              48.83028
            ],
            [
              -53.82056,
              48.829441
            ],
            [
              -53.808609,
              48.805271
            ],
            [
              -53.91333,
              48.79805
            ],
            [
              -53.900551,
              48.759991
            ],
            [
              -53.843609,
              48.772491
            ],
            [
              -53.89056,
              48.733601
            ],
            [
              -54.022499,
              48.740829
            ],
            [
              -53.937771,
              48.722488
            ],
            [
              -53.94833,
              48.666382
            ],
            [
              -53.89389,
              48.646389
            ],
            [
              -53.932499,
              48.62471
            ],
            [
              -53.790001,
              48.679989
            ],
            [
              -53.92445,
              48.671379
            ],
            [
              -53.831951,
              48.749439
            ],
            [
              -53.85194,
              48.702221
            ],
            [
              -53.7425,
              48.711658
            ],
            [
              -53.70306,
              48.685261
            ],
            [
              -53.722221,
              48.654991
            ],
            [
              -53.59972,
              48.68499
            ],
            [
              -53.658611,
              48.641941
            ],
            [
              -53.928341,
              48.575829
            ],
            [
              -53.952499,
              48.53944
            ],
            [
              -53.715279,
              48.559719
            ],
            [
              -53.78944,
              48.543049
            ],
            [
              -53.750839,
              48.507771
            ],
            [
              -53.902222,
              48.47971
            ],
            [
              -54.01112,
              48.421661
            ],
            [
              -54.09444,
              48.425831
            ],
            [
              -54.163891,
              48.383049
            ],
            [
              -54.127491,
              48.353321
            ],
            [
              -54.075562,
              48.401932
            ],
            [
              -53.992229,
              48.402489
            ],
            [
              -53.841671,
              48.470829
            ],
            [
              -53.81139,
              48.465
            ],
            [
              -53.884449,
              48.383049
            ],
            [
              -53.834171,
              48.371658
            ],
            [
              -53.849442,
              48.392769
            ],
            [
              -53.768608,
              48.475269
            ],
            [
              -53.647221,
              48.541111
            ],
            [
              -53.620831,
              48.534439
            ],
            [
              -53.659721,
              48.51305
            ],
            [
              -53.618061,
              48.498878
            ],
            [
              -53.725269,
              48.426102
            ],
            [
              -53.639721,
              48.43943
            ],
            [
              -53.586391,
              48.525269
            ],
            [
              -53.587219,
              48.476101
            ],
            [
              -53.547501,
              48.48666
            ],
            [
              -53.608891,
              48.40749
            ],
            [
              -53.473331,
              48.5075
            ],
            [
              -53.42445,
              48.625549
            ],
            [
              -53.338051,
              48.612499
            ],
            [
              -53.303329,
              48.58194
            ],
            [
              -53.318611,
              48.549438
            ],
            [
              -53.240002,
              48.523319
            ],
            [
              -53.153881,
              48.628601
            ],
            [
              -53.098881,
              48.64222
            ],
            [
              -53.072781,
              48.700272
            ],
            [
              -52.976391,
              48.599159
            ],
            [
              -53.05389,
              48.44276
            ],
            [
              -53.19416,
              48.348598
            ],
            [
              -53.35223,
              48.385269
            ],
            [
              -53.376389,
              48.33028
            ],
            [
              -53.37888,
              48.278049
            ],
            [
              -53.44833,
              48.27943
            ],
            [
              -53.66222,
              48.163319
            ],
            [
              -53.940552,
              48.23082
            ],
            [
              -53.944019,
              48.16346
            ],
            [
              -53.912498,
              48.08416
            ],
            [
              -53.66444,
              48.064159
            ],
            [
              -53.76334,
              48.026379
            ],
            [
              -53.91917,
              48.022491
            ],
            [
              -53.779171,
              47.996658
            ],
            [
              -53.605831,
              48.046391
            ],
            [
              -53.692768,
              47.899441
            ],
            [
              -53.788059,
              47.900539
            ],
            [
              -53.724442,
              47.883331
            ],
            [
              -53.721661,
              47.84943
            ],
            [
              -53.793331,
              47.7686
            ],
            [
              -53.874439,
              47.845539
            ],
            [
              -53.930279,
              47.849159
            ],
            [
              -53.85445,
              47.794998
            ],
            [
              -53.82917,
              47.71944
            ],
            [
              -53.85751,
              47.710819
            ],
            [
              -53.760281,
              47.609989
            ],
            [
              -53.695,
              47.685822
            ],
            [
              -53.665001,
              47.67416
            ],
            [
              -53.712502,
              47.598049
            ],
            [
              -53.639172,
              47.573051
            ],
            [
              -53.66666,
              47.52166
            ],
            [
              -53.617771,
              47.556381
            ],
            [
              -53.54528,
              47.534439
            ],
            [
              -53.559719,
              47.576939
            ],
            [
              -53.535561,
              47.589432
            ],
            [
              -53.561939,
              47.61916
            ],
            [
              -53.51445,
              47.614441
            ],
            [
              -53.461109,
              47.80666
            ],
            [
              -53.398609,
              47.872761
            ],
            [
              -53.355831,
              47.865829
            ],
            [
              -53.368061,
              47.893879
            ],
            [
              -53.29084,
              47.999439
            ],
            [
              -53.153881,
              48.066101
            ],
            [
              -53.053329,
              48.049721
            ],
            [
              -52.91972,
              48.169159
            ],
            [
              -52.832779,
              48.096939
            ],
            [
              -52.9175,
              48.084721
            ],
            [
              -52.953892,
              48.007221
            ],
            [
              -53.058048,
              47.922489
            ],
            [
              -53.053329,
              47.867771
            ],
            [
              -53.202221,
              47.733601
            ],
            [
              -53.162769,
              47.71471
            ],
            [
              -53.178341,
              47.651379
            ],
            [
              -53.26722,
              47.606098
            ],
            [
              -53.20583,
              47.609989
            ],
            [
              -53.248341,
              47.57555
            ],
            [
              -53.16972,
              47.597488
            ],
            [
              -53.261669,
              47.546391
            ],
            [
              -53.185829,
              47.55444
            ],
            [
              -53.20639,
              47.46999
            ],
            [
              -53.160549,
              47.50111
            ],
            [
              -53.152222,
              47.471931
            ],
            [
              -53.199718,
              47.43832
            ],
            [
              -53.14584,
              47.44804
            ],
            [
              -53.121941,
              47.413319
            ],
            [
              -52.89695,
              47.558601
            ],
            [
              -52.779442,
              47.803051
            ],
            [
              -52.70055,
              47.749439
            ],
            [
              -52.714451,
              47.6661
            ],
            [
              -52.65778,
              47.65749
            ],
            [
              -52.646111,
              47.599159
            ],
            [
              -52.68861,
              47.551102
            ],
            [
              -52.620281,
              47.500271
            ],
            [
              -52.688049,
              47.461109
            ],
            [
              -52.650551,
              47.428329
            ],
            [
              -52.743061,
              47.309719
            ],
            [
              -52.796951,
              47.300541
            ],
            [
              -52.759171,
              47.293331
            ],
            [
              -52.810001,
              47.267208
            ],
            [
              -52.865551,
              47.098598
            ],
            [
              -52.934441,
              47.086941
            ],
            [
              -52.848339,
              47.067768
            ],
            [
              -52.873611,
              47.048328
            ],
            [
              -52.852779,
              47.022491
            ],
            [
              -52.895279,
              47.001389
            ],
            [
              -52.884171,
              46.941662
            ],
            [
              -52.938332,
              46.789162
            ],
            [
              -53.023891,
              46.73888
            ],
            [
              -53.090839,
              46.64333
            ],
            [
              -53.207218,
              46.630268
            ],
            [
              -53.266941,
              46.733879
            ],
            [
              -53.387779,
              46.681938
            ],
            [
              -53.36195,
              46.737499
            ],
            [
              -53.561668,
              46.61277
            ],
            [
              -53.617771,
              46.644161
            ],
            [
              -53.644169,
              46.70916
            ],
            [
              -53.552502,
              46.864441
            ],
            [
              -53.481941,
              46.897221
            ],
            [
              -53.5425,
              46.884159
            ],
            [
              -53.64806,
              46.796661
            ],
            [
              -53.5975,
              46.90638
            ],
            [
              -53.512218,
              46.955551
            ],
            [
              -53.563061,
              46.937489
            ],
            [
              -53.566952,
              46.977211
            ],
            [
              -53.600559,
              46.94138
            ],
            [
              -53.60194,
              46.991661
            ],
            [
              -53.64167,
              46.983879
            ],
            [
              -53.577782,
              47.08527
            ],
            [
              -53.499168,
              47.123878
            ],
            [
              -53.523609,
              47.16777
            ],
            [
              -53.57333,
              47.15443
            ],
            [
              -53.559441,
              47.20277
            ],
            [
              -53.600559,
              47.128601
            ],
            [
              -53.639999,
              47.101662
            ],
            [
              -53.630829,
              47.15443
            ],
            [
              -53.654171,
              47.14027
            ],
            [
              -53.703609,
              47.053051
            ],
            [
              -53.765011,
              47.034439
            ],
            [
              -53.95084,
              46.853882
            ],
            [
              -54.053329,
              46.794998
            ],
            [
              -54.189991,
              46.823608
            ],
            [
              -54.178341,
              46.94276
            ],
            [
              -54.012218,
              47.2286
            ],
            [
              -53.931671,
              47.22332
            ],
            [
              -53.993889,
              47.26527
            ],
            [
              -53.997501,
              47.310268
            ],
            [
              -53.92194,
              47.304161
            ],
            [
              -53.875561,
              47.35416
            ],
            [
              -53.918331,
              47.352779
            ],
            [
              -53.913891,
              47.374161
            ],
            [
              -53.80389,
              47.426941
            ],
            [
              -53.906391,
              47.424709
            ],
            [
              -53.924171,
              47.451389
            ],
            [
              -53.885559,
              47.576939
            ],
            [
              -53.89695,
              47.609989
            ],
            [
              -53.930279,
              47.606659
            ],
            [
              -54.00639,
              47.81916
            ],
            [
              -54.063889,
              47.797218
            ],
            [
              -54.081669,
              47.86916
            ],
            [
              -54.11417,
              47.824169
            ],
            [
              -54.26334,
              47.9011
            ],
            [
              -54.19722,
              47.86277
            ],
            [
              -54.21278,
              47.777771
            ],
            [
              -54.285561,
              47.692211
            ],
            [
              -54.33778,
              47.698879
            ],
            [
              -54.320278,
              47.651661
            ],
            [
              -54.348511,
              47.610649
            ],
            [
              -54.460281,
              47.494709
            ],
            [
              -54.403332,
              47.45055
            ],
            [
              -54.448879,
              47.42083
            ],
            [
              -54.453609,
              47.45583
            ],
            [
              -54.51722,
              47.369438
            ],
            [
              -54.613331,
              47.36277
            ],
            [
              -54.418331,
              47.603611
            ],
            [
              -54.62138,
              47.389992
            ],
            [
              -54.671391,
              47.37249
            ],
            [
              -54.685551,
              47.396389
            ],
            [
              -54.71999,
              47.352219
            ],
            [
              -54.818611,
              47.363609
            ],
            [
              -54.78751,
              47.41888
            ],
            [
              -54.836941,
              47.372761
            ],
            [
              -54.83112,
              47.421101
            ],
            [
              -55.043892,
              47.220829
            ],
            [
              -55.012218,
              47.172771
            ],
            [
              -55.067501,
              47.147221
            ],
            [
              -55.084171,
              47.201931
            ],
            [
              -55.134171,
              47.166939
            ],
            [
              -55.08556,
              47.16082
            ],
            [
              -55.06945,
              47.082211
            ],
            [
              -55.104721,
              47.078049
            ],
            [
              -55.15472,
              47.008049
            ],
            [
              -55.16972,
              47.065269
            ],
            [
              -55.199169,
              47.070549
            ],
            [
              -55.189991,
              46.996101
            ],
            [
              -55.230282,
              46.928329
            ],
            [
              -55.365841,
              46.904709
            ],
            [
              -55.35445,
              46.879711
            ],
            [
              -55.384171,
              46.865829
            ],
            [
              -55.463619,
              46.877491
            ],
            [
              -55.46806,
              46.928329
            ],
            [
              -55.512218,
              46.912491
            ],
            [
              -55.52528,
              46.939709
            ],
            [
              -55.548611,
              46.894161
            ],
            [
              -55.57362,
              46.910549
            ],
            [
              -55.626949,
              46.868881
            ],
            [
              -55.740841,
              46.849998
            ],
            [
              -55.931389,
              46.892769
            ],
            [
              -55.983059,
              46.952492
            ],
            [
              -55.865551,
              47.072769
            ],
            [
              -55.487782,
              47.137211
            ],
            [
              -55.29834,
              47.267208
            ],
            [
              -55.266659,
              47.39666
            ],
            [
              -55.196388,
              47.449429
            ],
            [
              -54.951389,
              47.505001
            ],
            [
              -54.845829,
              47.556938
            ],
            [
              -54.841381,
              47.583599
            ],
            [
              -54.876949,
              47.570271
            ],
            [
              -54.850281,
              47.60722
            ],
            [
              -54.69722,
              47.664711
            ],
            [
              -54.769169,
              47.678051
            ],
            [
              -54.94944,
              47.59972
            ],
            [
              -55.079731,
              47.589989
            ],
            [
              -54.936111,
              47.781658
            ],
            [
              -55.11916,
              47.576939
            ],
            [
              -55.153881,
              47.604439
            ],
            [
              -55.130001,
              47.66193
            ],
            [
              -55.176392,
              47.635269
            ],
            [
              -55.31889,
              47.668049
            ],
            [
              -55.363892,
              47.649158
            ],
            [
              -55.35751,
              47.726101
            ],
            [
              -55.392502,
              47.691101
            ],
            [
              -55.427502,
              47.711658
            ],
            [
              -55.422501,
              47.672771
            ],
            [
              -55.467499,
              47.61916
            ],
            [
              -55.40028,
              47.615551
            ],
            [
              -55.432499,
              47.581379
            ],
            [
              -55.389721,
              47.58638
            ],
            [
              -55.406658,
              47.49305
            ],
            [
              -55.521111,
              47.459721
            ],
            [
              -55.58778,
              47.398609
            ],
            [
              -55.58778,
              47.453049
            ],
            [
              -55.664162,
              47.436939
            ],
            [
              -55.588612,
              47.510281
            ],
            [
              -55.62833,
              47.541382
            ],
            [
              -55.654171,
              47.49527
            ],
            [
              -55.70528,
              47.502491
            ],
            [
              -55.70528,
              47.475269
            ],
            [
              -55.763618,
              47.454708
            ],
            [
              -55.763062,
              47.53833
            ],
            [
              -55.80167,
              47.45916
            ],
            [
              -55.85778,
              47.473598
            ],
            [
              -55.92556,
              47.439159
            ],
            [
              -55.745831,
              47.58527
            ],
            [
              -56.104172,
              47.463612
            ],
            [
              -56.16917,
              47.492489
            ],
            [
              -55.805561,
              47.605831
            ],
            [
              -55.62833,
              47.674709
            ],
            [
              -55.83889,
              47.61721
            ],
            [
              -55.9175,
              47.65749
            ],
            [
              -55.830559,
              47.732769
            ],
            [
              -55.79945,
              47.79916
            ],
            [
              -55.821949,
              47.834431
            ],
            [
              -55.742229,
              47.923321
            ],
            [
              -55.773609,
              47.95694
            ],
            [
              -55.85445,
              47.79472
            ],
            [
              -55.987499,
              47.7575
            ],
            [
              -56.054722,
              47.695541
            ],
            [
              -56.10667,
              47.693321
            ],
            [
              -56.111111,
              47.724159
            ],
            [
              -56.053612,
              47.77388
            ],
            [
              -56.100559,
              47.750271
            ],
            [
              -56.15694,
              47.832211
            ],
            [
              -56.125,
              47.757221
            ],
            [
              -56.168331,
              47.715832
            ],
            [
              -56.141941,
              47.684719
            ],
            [
              -56.191109,
              47.676941
            ],
            [
              -56.161381,
              47.634159
            ],
            [
              -56.216389,
              47.65527
            ],
            [
              -56.232498,
              47.625549
            ],
            [
              -56.29945,
              47.62249
            ],
            [
              -56.309441,
              47.7486
            ],
            [
              -56.334171,
              47.695271
            ],
            [
              -56.310001,
              47.639431
            ],
            [
              -56.34528,
              47.60833
            ],
            [
              -56.682499,
              47.623051
            ],
            [
              -56.639999,
              47.587212
            ],
            [
              -56.67889,
              47.57444
            ],
            [
              -56.756672,
              47.629429
            ],
            [
              -56.751579,
              47.589741
            ],
            [
              -56.70639,
              47.56554
            ],
            [
              -56.779442,
              47.526661
            ],
            [
              -56.849171,
              47.522491
            ],
            [
              -56.85194,
              47.54805
            ],
            [
              -56.9925,
              47.583881
            ],
            [
              -57.12611,
              47.563881
            ],
            [
              -57.168331,
              47.583599
            ],
            [
              -57.141941,
              47.598598
            ],
            [
              -57.171391,
              47.632488
            ],
            [
              -57.184441,
              47.598331
            ],
            [
              -57.312771,
              47.58083
            ],
            [
              -57.334999,
              47.639431
            ],
            [
              -57.488892,
              47.6236
            ],
            [
              -57.53722,
              47.64777
            ],
            [
              -57.64389,
              47.597488
            ],
            [
              -57.738892,
              47.653599
            ],
            [
              -57.77055,
              47.614159
            ],
            [
              -57.916389,
              47.648609
            ],
            [
              -57.884171,
              47.696659
            ],
            [
              -57.952499,
              47.661659
            ],
            [
              -58.036388,
              47.696098
            ],
            [
              -58.074169,
              47.666939
            ],
            [
              -58.07917,
              47.695271
            ],
            [
              -58.353889,
              47.643608
            ],
            [
              -58.272221,
              47.751659
            ],
            [
              -58.338329,
              47.745541
            ],
            [
              -58.430561,
              47.633049
            ],
            [
              -58.535278,
              47.666382
            ],
            [
              -58.535,
              47.631939
            ],
            [
              -58.60611,
              47.61166
            ],
            [
              -58.642231,
              47.62804
            ],
            [
              -58.690552,
              47.598881
            ],
            [
              -59.118889,
              47.55471
            ],
            [
              -59.30444,
              47.609989
            ],
            [
              -59.28083,
              47.751659
            ],
            [
              -59.322231,
              47.761108
            ],
            [
              -59.400829,
              47.906651
            ],
            [
              -59.316391,
              47.934158
            ],
            [
              -59.218609,
              48.02916
            ],
            [
              -58.978882,
              48.123878
            ],
            [
              -58.58778,
              48.43055
            ],
            [
              -58.492771,
              48.450272
            ],
            [
              -58.591942,
              48.39888
            ],
            [
              -58.483608,
              48.427769
            ],
            [
              -58.418331,
              48.48666
            ],
            [
              -58.26556,
              48.51749
            ],
            [
              -58.519718,
              48.500549
            ],
            [
              -58.56945,
              48.538601
            ],
            [
              -58.682499,
              48.554989
            ],
            [
              -59.246948,
              48.46666
            ],
            [
              -59.228882,
              48.52916
            ],
            [
              -58.774719,
              48.778881
            ],
            [
              -58.958611,
              48.61388
            ],
            [
              -58.89584,
              48.588879
            ],
            [
              -58.89695,
              48.551929
            ],
            [
              -58.848339,
              48.588879
            ],
            [
              -58.848049,
              48.634991
            ],
            [
              -58.81778,
              48.569988
            ],
            [
              -58.709438,
              48.570831
            ],
            [
              -58.658051,
              48.74305
            ],
            [
              -58.543892,
              48.860821
            ],
            [
              -58.500839,
              49.003609
            ],
            [
              -58.35194,
              49.150269
            ],
            [
              -58.360001,
              49.056938
            ],
            [
              -58.17194,
              49.061939
            ],
            [
              -58.06028,
              48.976101
            ],
            [
              -57.961391,
              48.956661
            ],
            [
              -57.892231,
              48.981659
            ],
            [
              -58.00922,
              48.980419
            ],
            [
              -58.108059,
              49.037769
            ],
            [
              -58.144451,
              49.121929
            ],
            [
              -57.92556,
              49.123051
            ],
            [
              -57.849442,
              49.181938
            ],
            [
              -57.939442,
              49.145
            ],
            [
              -57.96999,
              49.16415
            ],
            [
              -57.924171,
              49.18832
            ],
            [
              -58.09111,
              49.150829
            ],
            [
              -58.10722,
              49.183048
            ],
            [
              -57.99472,
              49.180271
            ],
            [
              -57.928051,
              49.240269
            ],
            [
              -58.03944,
              49.21249
            ],
            [
              -58.20084,
              49.239719
            ],
            [
              -58.24194,
              49.29166
            ],
            [
              -58.223331,
              49.39027
            ],
            [
              -58.152222,
              49.46888
            ],
            [
              -57.998341,
              49.559158
            ],
            [
              -57.92028,
              49.527771
            ],
            [
              -57.918331,
              49.465832
            ],
            [
              -57.88055,
              49.428051
            ],
            [
              -57.906109,
              49.477489
            ],
            [
              -57.86306,
              49.506939
            ],
            [
              -57.746948,
              49.453609
            ],
            [
              -57.696388,
              49.463612
            ],
            [
              -57.788891,
              49.500832
            ],
            [
              -57.824718,
              49.559719
            ],
            [
              -57.877491,
              49.528332
            ],
            [
              -57.944721,
              49.607498
            ],
            [
              -57.94833,
              49.67416
            ],
            [
              -57.601109,
              50.174999
            ],
            [
              -57.526661,
              50.364719
            ],
            [
              -57.372501,
              50.590832
            ],
            [
              -57.150829,
              50.6161
            ],
            [
              -57.261951,
              50.613331
            ],
            [
              -57.277222,
              50.630268
            ],
            [
              -57.240841,
              50.641109
            ],
            [
              -57.39806,
              50.69249
            ],
            [
              -57.351109,
              50.723598
            ],
            [
              -57.230282,
              50.72971
            ],
            [
              -57.089439,
              50.780548
            ],
            [
              -56.977219,
              50.8736
            ],
            [
              -56.972771,
              50.909161
            ],
            [
              -56.927502,
              50.915821
            ],
            [
              -56.989719,
              50.929161
            ],
            [
              -56.966942,
              50.968601
            ],
            [
              -57.089161,
              51.019989
            ],
            [
              -57.004719,
              51.016109
            ],
            [
              -56.976662,
              50.98555
            ],
            [
              -56.902222,
              51.013329
            ],
            [
              -56.978882,
              51.05027
            ],
            [
              -56.880001,
              51.06554
            ],
            [
              -56.860828,
              51.113609
            ],
            [
              -56.784451,
              51.137772
            ],
            [
              -56.80666,
              51.18943
            ],
            [
              -56.746391,
              51.204441
            ],
            [
              -56.793892,
              51.23999
            ],
            [
              -56.688889,
              51.336941
            ],
            [
              -56.110561,
              51.52388
            ],
            [
              -55.890282,
              51.629429
            ],
            [
              -55.822781,
              51.618599
            ],
            [
              -55.884998,
              51.562489
            ],
            [
              -55.841942,
              51.545269
            ],
            [
              -55.877781,
              51.492222
            ],
            [
              -55.620281,
              51.472759
            ],
            [
              -55.73917,
              51.55666
            ],
            [
              -55.6175,
              51.610279
            ],
            [
              -55.653332,
              51.590549
            ],
            [
              -55.625,
              51.56554
            ],
            [
              -55.515839,
              51.602219
            ],
            [
              -55.404442,
              51.571941
            ],
            [
              -55.41917,
              51.548328
            ],
            [
              -55.467499,
              51.56361
            ],
            [
              -55.432499,
              51.53722
            ],
            [
              -55.45084,
              51.499161
            ],
            [
              -55.533058,
              51.500832
            ],
            [
              -55.452499,
              51.460819
            ],
            [
              -55.50806,
              51.363331
            ],
            [
              -55.562222,
              51.38805
            ],
            [
              -55.55917,
              51.34277
            ],
            [
              -55.609169,
              51.344151
            ],
            [
              -55.597778,
              51.3036
            ],
            [
              -55.759449,
              51.346378
            ],
            [
              -56.08556,
              51.36832
            ],
            [
              -56.09639,
              51.318329
            ],
            [
              -56.025002,
              51.308601
            ],
            [
              -56.010281,
              51.270271
            ],
            [
              -56.075279,
              51.227211
            ],
            [
              -56.013062,
              51.213329
            ],
            [
              -55.965,
              51.153049
            ],
            [
              -55.941669,
              51.15527
            ],
            [
              -55.959438,
              51.197491
            ],
            [
              -55.83778,
              51.230549
            ],
            [
              -55.709999,
              51.173611
            ],
            [
              -55.746948,
              51.158039
            ],
            [
              -55.719158,
              51.123051
            ],
            [
              -55.735561,
              51.074169
            ],
            [
              -55.810829,
              51.056381
            ],
            [
              -55.77639,
              51.029991
            ],
            [
              -55.836109,
              50.968319
            ],
            [
              -55.819172,
              50.92527
            ],
            [
              -55.859169,
              50.94249
            ],
            [
              -55.87389,
              50.908329
            ],
            [
              -55.844719,
              50.90416
            ],
            [
              -55.876949,
              50.86277
            ]
          ]
        ],
        "nom": "Terre-Neuve-et-Labrador",
        "iso_code": "NL"
      },
      {
        "name": "Saskatchewan",
        "id": 61,
        "coordinates": [
          [
            [
              -102.001701,
              59.99992
            ],
            [
              -102.0009,
              59.918838
            ],
            [
              -102.001503,
              58.12854
            ],
            [
              -102.001297,
              58.034302
            ],
            [
              -102.001297,
              57.868439
            ],
            [
              -102.0009,
              57.49725
            ],
            [
              -102.001297,
              57.25964
            ],
            [
              -102.001099,
              57.013351
            ],
            [
              -102.000999,
              56.366421
            ],
            [
              -102.001404,
              56.221241
            ],
            [
              -102.0009,
              55.830151
            ],
            [
              -101.967598,
              55.76067
            ],
            [
              -101.967201,
              55.67194
            ],
            [
              -101.967201,
              55.467468
            ],
            [
              -101.925797,
              55.466381
            ],
            [
              -101.925598,
              55.117489
            ],
            [
              -101.883598,
              55.116661
            ],
            [
              -101.883904,
              54.76749
            ],
            [
              -101.851402,
              54.766392
            ],
            [
              -101.851799,
              54.53513
            ],
            [
              -101.851898,
              54.41721
            ],
            [
              -101.811401,
              54.41666
            ],
            [
              -101.810799,
              54.066101
            ],
            [
              -101.7714,
              54.06638
            ],
            [
              -101.772102,
              54.00256
            ],
            [
              -101.742798,
              53.369438
            ],
            [
              -101.700798,
              53.368881
            ],
            [
              -101.672203,
              52.67083
            ],
            [
              -101.639999,
              52.671101
            ],
            [
              -101.639503,
              52.32222
            ],
            [
              -101.612503,
              52.32222
            ],
            [
              -101.613297,
              51.96999
            ],
            [
              -101.567001,
              51.970829
            ],
            [
              -101.544998,
              50.950001
            ],
            [
              -101.514702,
              50.949429
            ],
            [
              -101.514999,
              50.595539
            ],
            [
              -101.484703,
              50.589989
            ],
            [
              -101.483597,
              50.24192
            ],
            [
              -101.4533,
              50.241661
            ],
            [
              -101.453598,
              49.888599
            ],
            [
              -101.426102,
              49.888599
            ],
            [
              -101.425598,
              49.5341
            ],
            [
              -101.400299,
              49.533051
            ],
            [
              -101.400299,
              49.177761
            ],
            [
              -101.3675,
              49.17749
            ],
            [
              -101.367203,
              49
            ],
            [
              -101.5,
              49
            ],
            [
              -102.021797,
              49
            ],
            [
              -102.9375,
              49
            ],
            [
              -104.0625,
              49
            ],
            [
              -105.0625,
              49
            ],
            [
              -106.125,
              49
            ],
            [
              -107.1875,
              49
            ],
            [
              -108.25,
              49
            ],
            [
              -109.5,
              49
            ],
            [
              -110.001404,
              49
            ],
            [
              -110.000504,
              54.00573
            ],
            [
              -110.000702,
              54.44952
            ],
            [
              -110.000603,
              54.624401
            ],
            [
              -110.000801,
              54.769001
            ],
            [
              -110.000603,
              56.276039
            ],
            [
              -110.0009,
              56.36282
            ],
            [
              -110.000801,
              58.968639
            ],
            [
              -110.000504,
              59.309391
            ],
            [
              -110.0009,
              59.995281
            ],
            [
              -107.113602,
              59.994709
            ],
            [
              -106.255997,
              59.999771
            ],
            [
              -106.044403,
              59.999592
            ],
            [
              -105.859001,
              59.99968
            ],
            [
              -105.5411,
              60
            ],
            [
              -104.7099,
              59.999771
            ],
            [
              -104.270699,
              59.9995
            ],
            [
              -102.001701,
              59.99992
            ]
          ]
        ],
        "nom": "Saskatchewan",
        "iso_code": "SK"
      },
      {
        "name": "Quebec",
        "id": 60,
        "coordinates": [
          [
            [
              -78.019173,
              62.59193
            ],
            [
              -77.867218,
              62.589161
            ],
            [
              -77.837784,
              62.556938
            ],
            [
              -78.103333,
              62.559158
            ],
            [
              -78.107773,
              62.582771
            ],
            [
              -78.019173,
              62.59193
            ]
          ],
          [
            [
              -69.182503,
              59.128601
            ],
            [
              -69.198608,
              59.067211
            ],
            [
              -69.132767,
              59.055271
            ],
            [
              -69.221123,
              58.991661
            ],
            [
              -69.223053,
              58.95332
            ],
            [
              -69.290283,
              58.977211
            ],
            [
              -69.35556,
              58.949711
            ],
            [
              -69.278343,
              59.066669
            ],
            [
              -69.357224,
              59.139721
            ],
            [
              -69.275284,
              59.154991
            ],
            [
              -69.182503,
              59.128601
            ]
          ],
          [
            [
              -79.496384,
              56.766941
            ],
            [
              -79.486107,
              56.658039
            ],
            [
              -79.523621,
              56.66721
            ],
            [
              -79.567497,
              56.615269
            ],
            [
              -79.583893,
              56.652771
            ],
            [
              -79.549438,
              56.68111
            ],
            [
              -79.551941,
              56.73193
            ],
            [
              -79.589996,
              56.76833
            ],
            [
              -79.58168,
              56.807209
            ],
            [
              -79.496384,
              56.766941
            ]
          ],
          [
            [
              -78.900284,
              56.319439
            ],
            [
              -79.061394,
              56.124439
            ],
            [
              -79.210831,
              55.856098
            ],
            [
              -79.188599,
              55.942211
            ],
            [
              -78.986656,
              56.26749
            ],
            [
              -78.983322,
              56.389721
            ],
            [
              -79.055267,
              56.34444
            ],
            [
              -79.100281,
              56.221371
            ],
            [
              -79.04834,
              56.222759
            ],
            [
              -79.061394,
              56.18832
            ],
            [
              -79.283333,
              55.864441
            ],
            [
              -79.133904,
              56.126659
            ],
            [
              -79.150558,
              56.233051
            ],
            [
              -79.243881,
              56.1511
            ],
            [
              -79.360283,
              55.969711
            ],
            [
              -79.493057,
              55.858891
            ],
            [
              -79.636124,
              55.898609
            ],
            [
              -79.785553,
              55.788891
            ],
            [
              -79.474716,
              56.098331
            ],
            [
              -79.513062,
              56.134991
            ],
            [
              -79.597229,
              56.091381
            ],
            [
              -79.877213,
              55.83194
            ],
            [
              -79.906113,
              55.824711
            ],
            [
              -79.947487,
              55.889992
            ],
            [
              -80.024719,
              55.901379
            ],
            [
              -79.74472,
              56.15638
            ],
            [
              -79.529167,
              56.275551
            ],
            [
              -79.475563,
              56.38055
            ],
            [
              -79.456673,
              56.553322
            ],
            [
              -79.419449,
              56.4436
            ],
            [
              -79.480003,
              56.346378
            ],
            [
              -79.491096,
              56.264439
            ],
            [
              -79.609734,
              56.13166
            ],
            [
              -79.427223,
              56.203049
            ],
            [
              -79.301102,
              56.447208
            ],
            [
              -79.269173,
              56.624989
            ],
            [
              -79.252792,
              56.550831
            ],
            [
              -79.133057,
              56.54277
            ],
            [
              -79.075562,
              56.444149
            ],
            [
              -78.928879,
              56.425831
            ],
            [
              -78.908051,
              56.343048
            ],
            [
              -78.951401,
              56.316101
            ],
            [
              -78.900284,
              56.319439
            ]
          ],
          [
            [
              -79.555557,
              56.4986
            ],
            [
              -79.701111,
              56.306381
            ],
            [
              -79.986107,
              56.199711
            ],
            [
              -80.109734,
              56.197769
            ],
            [
              -80.044449,
              56.310822
            ],
            [
              -79.869164,
              56.331669
            ],
            [
              -79.877213,
              56.379162
            ],
            [
              -79.698883,
              56.368881
            ],
            [
              -79.614441,
              56.43166
            ],
            [
              -79.585281,
              56.499161
            ],
            [
              -79.543327,
              56.527771
            ],
            [
              -79.555557,
              56.4986
            ]
          ],
          [
            [
              -78.660828,
              56.414711
            ],
            [
              -78.676392,
              56.18111
            ],
            [
              -78.933319,
              56.115551
            ],
            [
              -78.916946,
              56.172489
            ],
            [
              -78.825287,
              56.262772
            ],
            [
              -78.845551,
              56.276661
            ],
            [
              -78.809433,
              56.338879
            ],
            [
              -78.832497,
              56.35083
            ],
            [
              -78.731667,
              56.44054
            ],
            [
              -78.667221,
              56.439709
            ],
            [
              -78.660828,
              56.414711
            ]
          ],
          [
            [
              -79.009354,
              56.06361
            ],
            [
              -78.957497,
              56.083599
            ],
            [
              -78.948883,
              56.03722
            ],
            [
              -79.002792,
              56.012772
            ],
            [
              -78.934158,
              56.020271
            ],
            [
              -79.002502,
              55.979988
            ],
            [
              -79.054443,
              55.865551
            ],
            [
              -79.135559,
              55.789162
            ],
            [
              -79.030838,
              55.968601
            ],
            [
              -79.009354,
              56.06361
            ]
          ],
          [
            [
              -63.492229,
              49.840832
            ],
            [
              -62.944439,
              49.732208
            ],
            [
              -62.510281,
              49.590832
            ],
            [
              -62.39389,
              49.492222
            ],
            [
              -62.25111,
              49.456661
            ],
            [
              -62.20583,
              49.411381
            ],
            [
              -61.875561,
              49.34499
            ],
            [
              -61.661671,
              49.14444
            ],
            [
              -61.70055,
              49.090271
            ],
            [
              -61.81361,
              49.06694
            ],
            [
              -62.242771,
              49.06221
            ],
            [
              -62.456951,
              49.125549
            ],
            [
              -63.09,
              49.228039
            ],
            [
              -63.283329,
              49.317768
            ],
            [
              -63.5825,
              49.387211
            ],
            [
              -63.61972,
              49.492771
            ],
            [
              -63.841942,
              49.63916
            ],
            [
              -64.238602,
              49.751659
            ],
            [
              -64.362778,
              49.82444
            ],
            [
              -64.389999,
              49.789719
            ],
            [
              -64.513901,
              49.863609
            ],
            [
              -64.458618,
              49.900829
            ],
            [
              -64.203613,
              49.950272
            ],
            [
              -63.930828,
              49.885269
            ],
            [
              -63.492229,
              49.840832
            ]
          ],
          [
            [
              -61.40778,
              47.641109
            ],
            [
              -61.38055,
              47.62027
            ],
            [
              -61.493061,
              47.55249
            ],
            [
              -61.54945,
              47.553879
            ],
            [
              -61.470551,
              47.61694
            ],
            [
              -61.54195,
              47.61721
            ],
            [
              -61.690109,
              47.534672
            ],
            [
              -61.711842,
              47.518051
            ],
            [
              -61.85556,
              47.41721
            ],
            [
              -61.777222,
              47.43166
            ],
            [
              -61.674999,
              47.535
            ],
            [
              -61.59444,
              47.555271
            ],
            [
              -61.701389,
              47.49194
            ],
            [
              -61.763889,
              47.397491
            ],
            [
              -61.829731,
              47.401661
            ],
            [
              -61.860001,
              47.352489
            ],
            [
              -61.933331,
              47.333328
            ],
            [
              -61.9575,
              47.26194
            ],
            [
              -61.908329,
              47.27277
            ],
            [
              -61.81945,
              47.23333
            ],
            [
              -61.783058,
              47.260551
            ],
            [
              -61.79945,
              47.232769
            ],
            [
              -61.85931,
              47.217739
            ],
            [
              -62.009998,
              47.221371
            ],
            [
              -61.928051,
              47.407211
            ],
            [
              -61.535839,
              47.63166
            ],
            [
              -61.40778,
              47.641109
            ]
          ],
          [
            [
              -70.814438,
              46.998329
            ],
            [
              -70.904167,
              46.913601
            ],
            [
              -71.13028,
              46.856098
            ],
            [
              -70.896667,
              47.013329
            ],
            [
              -70.836403,
              47.027771
            ],
            [
              -70.814438,
              46.998329
            ]
          ],
          [
            [
              -57.10424,
              51.41267
            ],
            [
              -57.208611,
              51.415821
            ],
            [
              -57.247219,
              51.504169
            ],
            [
              -57.60223,
              51.428051
            ],
            [
              -57.581669,
              51.465271
            ],
            [
              -57.67667,
              51.429989
            ],
            [
              -57.684441,
              51.47694
            ],
            [
              -57.748051,
              51.47221
            ],
            [
              -57.76445,
              51.401661
            ],
            [
              -57.800831,
              51.433331
            ],
            [
              -58.021389,
              51.30888
            ],
            [
              -58.227219,
              51.271381
            ],
            [
              -58.26112,
              51.288052
            ],
            [
              -58.249168,
              51.332771
            ],
            [
              -58.283058,
              51.335819
            ],
            [
              -58.29723,
              51.2686
            ],
            [
              -58.430279,
              51.309719
            ],
            [
              -58.580002,
              51.24416
            ],
            [
              -58.628052,
              51.275551
            ],
            [
              -58.61972,
              51.231659
            ],
            [
              -58.667221,
              51.25639
            ],
            [
              -58.68,
              51.234161
            ],
            [
              -58.59222,
              51.184719
            ],
            [
              -58.61861,
              51.153049
            ],
            [
              -58.83778,
              51.0825
            ],
            [
              -58.829731,
              51.041111
            ],
            [
              -58.889999,
              50.992222
            ],
            [
              -58.91333,
              51
            ],
            [
              -58.89806,
              51.053879
            ],
            [
              -59.00333,
              51.015549
            ],
            [
              -58.952499,
              50.992489
            ],
            [
              -58.958611,
              50.92194
            ],
            [
              -58.939442,
              50.883331
            ],
            [
              -58.975269,
              50.857769
            ],
            [
              -58.948051,
              50.828331
            ],
            [
              -59.029171,
              50.775551
            ],
            [
              -59.015282,
              50.7486
            ],
            [
              -59.087219,
              50.775269
            ],
            [
              -59.09333,
              50.820831
            ],
            [
              -59.055271,
              50.870831
            ],
            [
              -59.098881,
              50.879429
            ],
            [
              -59.118889,
              50.8036
            ],
            [
              -59.193611,
              50.72971
            ],
            [
              -59.256111,
              50.75972
            ],
            [
              -59.268608,
              50.71249
            ],
            [
              -59.350281,
              50.662769
            ],
            [
              -59.423889,
              50.661381
            ],
            [
              -59.404999,
              50.63472
            ],
            [
              -59.454441,
              50.621929
            ],
            [
              -59.58527,
              50.478039
            ],
            [
              -59.816109,
              50.43388
            ],
            [
              -59.881672,
              50.371658
            ],
            [
              -59.853889,
              50.326099
            ],
            [
              -59.814999,
              50.349159
            ],
            [
              -59.833889,
              50.31916
            ],
            [
              -59.948051,
              50.255001
            ],
            [
              -60.114449,
              50.233051
            ],
            [
              -60.134171,
              50.20388
            ],
            [
              -60.132771,
              50.22971
            ],
            [
              -60.17223,
              50.231659
            ],
            [
              -60.14999,
              50.288601
            ],
            [
              -60.243328,
              50.232208
            ],
            [
              -60.26722,
              50.236111
            ],
            [
              -60.23695,
              50.268051
            ],
            [
              -60.274719,
              50.288891
            ],
            [
              -60.291672,
              50.24527
            ],
            [
              -60.360001,
              50.250832
            ],
            [
              -60.35611,
              50.22332
            ],
            [
              -60.448051,
              50.259441
            ],
            [
              -60.583611,
              50.208328
            ],
            [
              -60.61972,
              50.246941
            ],
            [
              -60.666111,
              50.220268
            ],
            [
              -60.748341,
              50.23444
            ],
            [
              -60.788609,
              50.201099
            ],
            [
              -60.840549,
              50.219151
            ],
            [
              -60.792782,
              50.261669
            ],
            [
              -60.934441,
              50.1936
            ],
            [
              -61.02639,
              50.225819
            ],
            [
              -61.112782,
              50.187771
            ],
            [
              -61.15583,
              50.202221
            ],
            [
              -61.162498,
              50.17749
            ],
            [
              -61.297501,
              50.199162
            ],
            [
              -61.720829,
              50.09193
            ],
            [
              -61.783058,
              50.106941
            ],
            [
              -61.59306,
              50.15527
            ],
            [
              -61.577221,
              50.1861
            ],
            [
              -61.790279,
              50.13361
            ],
            [
              -61.849998,
              50.21249
            ],
            [
              -62.21056,
              50.234161
            ],
            [
              -62.397499,
              50.294441
            ],
            [
              -62.44611,
              50.2575
            ],
            [
              -62.73695,
              50.282211
            ],
            [
              -62.712502,
              50.315269
            ],
            [
              -62.805271,
              50.288601
            ],
            [
              -62.963058,
              50.285549
            ],
            [
              -62.994999,
              50.311939
            ],
            [
              -63.163891,
              50.291382
            ],
            [
              -63.159161,
              50.254711
            ],
            [
              -63.358608,
              50.241661
            ],
            [
              -63.394718,
              50.213329
            ],
            [
              -63.488609,
              50.26194
            ],
            [
              -63.597778,
              50.250832
            ],
            [
              -63.812771,
              50.313049
            ],
            [
              -64.162216,
              50.262211
            ],
            [
              -64.455002,
              50.319439
            ],
            [
              -64.872223,
              50.267208
            ],
            [
              -64.91362,
              50.2925
            ],
            [
              -65.189987,
              50.286659
            ],
            [
              -65.258057,
              50.31889
            ],
            [
              -65.713898,
              50.257221
            ],
            [
              -65.952499,
              50.288891
            ],
            [
              -66.056381,
              50.216099
            ],
            [
              -66.093338,
              50.244438
            ],
            [
              -66.089722,
              50.19138
            ],
            [
              -66.235817,
              50.21888
            ],
            [
              -66.369164,
              50.193321
            ],
            [
              -66.441673,
              50.269161
            ],
            [
              -66.543327,
              50.20805
            ],
            [
              -66.527222,
              50.16972
            ],
            [
              -66.42778,
              50.164711
            ],
            [
              -66.459442,
              50.133049
            ],
            [
              -66.638062,
              50.181381
            ],
            [
              -66.61972,
              50.149158
            ],
            [
              -66.741096,
              50.042221
            ],
            [
              -66.958618,
              49.974709
            ],
            [
              -66.963333,
              49.914711
            ],
            [
              -67.037514,
              49.820831
            ],
            [
              -67.073898,
              49.848049
            ],
            [
              -67.151398,
              49.806938
            ],
            [
              -67.179718,
              49.74527
            ],
            [
              -67.163063,
              49.679722
            ],
            [
              -67.198883,
              49.651661
            ],
            [
              -67.238892,
              49.590549
            ],
            [
              -67.233887,
              49.470268
            ],
            [
              -67.386124,
              49.32222
            ],
            [
              -67.569733,
              49.32999
            ],
            [
              -67.809723,
              49.273609
            ],
            [
              -67.957779,
              49.295269
            ],
            [
              -68.11972,
              49.27166
            ],
            [
              -68.156387,
              49.20166
            ],
            [
              -68.299438,
              49.195541
            ],
            [
              -68.19249,
              49.164711
            ],
            [
              -68.189713,
              49.112209
            ],
            [
              -68.325562,
              49.09972
            ],
            [
              -68.408051,
              49.05555
            ],
            [
              -68.444443,
              49.059719
            ],
            [
              -68.367767,
              49.132771
            ],
            [
              -68.443604,
              49.191662
            ],
            [
              -68.404167,
              49.122761
            ],
            [
              -68.606949,
              49.0425
            ],
            [
              -68.643341,
              48.9561
            ],
            [
              -68.696381,
              48.939991
            ],
            [
              -68.642227,
              48.91666
            ],
            [
              -68.787781,
              48.907768
            ],
            [
              -68.95639,
              48.81583
            ],
            [
              -68.96666,
              48.782768
            ],
            [
              -69.060822,
              48.76749
            ],
            [
              -69.142502,
              48.59499
            ],
            [
              -69.231377,
              48.585548
            ],
            [
              -69.29277,
              48.457771
            ],
            [
              -69.43277,
              48.30777
            ],
            [
              -69.57695,
              48.23999
            ],
            [
              -69.683884,
              48.137772
            ],
            [
              -69.810272,
              48.155819
            ],
            [
              -69.959442,
              48.26944
            ],
            [
              -70.151108,
              48.274441
            ],
            [
              -70.373322,
              48.374161
            ],
            [
              -70.684433,
              48.3811
            ],
            [
              -70.761398,
              48.431938
            ],
            [
              -70.98056,
              48.462212
            ],
            [
              -71.070572,
              48.440109
            ],
            [
              -70.969727,
              48.447208
            ],
            [
              -70.785553,
              48.395828
            ],
            [
              -70.887222,
              48.358601
            ],
            [
              -70.864166,
              48.317211
            ],
            [
              -70.768066,
              48.350552
            ],
            [
              -70.464172,
              48.349159
            ],
            [
              -70.272781,
              48.298328
            ],
            [
              -70.180557,
              48.241379
            ],
            [
              -69.936943,
              48.221931
            ],
            [
              -69.860001,
              48.143879
            ],
            [
              -69.730293,
              48.10944
            ],
            [
              -69.925827,
              47.773048
            ],
            [
              -70.139183,
              47.659161
            ],
            [
              -70.206123,
              47.57666
            ],
            [
              -70.225563,
              47.49638
            ],
            [
              -70.5,
              47.43499
            ],
            [
              -70.574173,
              47.274441
            ],
            [
              -70.721657,
              47.101391
            ],
            [
              -70.973618,
              47.00333
            ],
            [
              -71.197487,
              46.852489
            ],
            [
              -71.299156,
              46.742222
            ],
            [
              -71.143623,
              46.844711
            ],
            [
              -71.006119,
              46.847771
            ],
            [
              -70.541382,
              47.002491
            ],
            [
              -70.339172,
              47.152489
            ],
            [
              -70.201401,
              47.312489
            ],
            [
              -70.044159,
              47.390831
            ],
            [
              -70.017776,
              47.487499
            ],
            [
              -69.896393,
              47.541111
            ],
            [
              -69.545837,
              47.8325
            ],
            [
              -69.556381,
              47.866661
            ],
            [
              -69.450562,
              47.97916
            ],
            [
              -69.34111,
              48.01416
            ],
            [
              -68.823334,
              48.36388
            ],
            [
              -68.726936,
              48.37249
            ],
            [
              -68.541382,
              48.451389
            ],
            [
              -68.453613,
              48.53249
            ],
            [
              -68.058609,
              48.691101
            ],
            [
              -67.973618,
              48.695541
            ],
            [
              -67.553047,
              48.85471
            ],
            [
              -67.067497,
              48.96693
            ],
            [
              -66.639183,
              49.120831
            ],
            [
              -66.225014,
              49.200829
            ],
            [
              -65.722229,
              49.227211
            ],
            [
              -65.588058,
              49.260551
            ],
            [
              -64.94249,
              49.214439
            ],
            [
              -64.588058,
              49.112209
            ],
            [
              -64.23555,
              48.910271
            ],
            [
              -64.158051,
              48.7561
            ],
            [
              -64.298889,
              48.823879
            ],
            [
              -64.557503,
              48.880268
            ],
            [
              -64.473328,
              48.832211
            ],
            [
              -64.539436,
              48.821381
            ],
            [
              -64.401108,
              48.81694
            ],
            [
              -64.374161,
              48.787769
            ],
            [
              -64.428329,
              48.774441
            ],
            [
              -64.336937,
              48.75555
            ],
            [
              -64.160004,
              48.622761
            ],
            [
              -64.26973,
              48.60471
            ],
            [
              -64.279167,
              48.554161
            ],
            [
              -64.189163,
              48.520828
            ],
            [
              -64.309433,
              48.463329
            ],
            [
              -64.315552,
              48.419159
            ],
            [
              -64.719162,
              48.338329
            ],
            [
              -64.693604,
              48.3111
            ],
            [
              -64.771393,
              48.196381
            ],
            [
              -64.96611,
              48.18499
            ],
            [
              -64.945831,
              48.143051
            ],
            [
              -65.269997,
              48.012772
            ],
            [
              -65.455566,
              48.000271
            ],
            [
              -65.504181,
              48.048882
            ],
            [
              -65.810547,
              48.116661
            ],
            [
              -65.891678,
              48.171101
            ],
            [
              -65.893341,
              48.21944
            ],
            [
              -66.080002,
              48.096378
            ],
            [
              -66.293327,
              48.113049
            ],
            [
              -66.268066,
              48.095829
            ],
            [
              -66.29361,
              48.063881
            ],
            [
              -66.47084,
              48.119438
            ],
            [
              -66.538063,
              48.05582
            ],
            [
              -66.843697,
              47.996651
            ],
            [
              -66.943604,
              47.963051
            ],
            [
              -66.946953,
              47.899158
            ],
            [
              -67.047234,
              47.93082
            ],
            [
              -67.188599,
              47.883881
            ],
            [
              -67.322777,
              47.896389
            ],
            [
              -67.364166,
              47.841381
            ],
            [
              -67.604172,
              47.9361
            ],
            [
              -67.612213,
              47.99749
            ],
            [
              -68.116096,
              48.001942
            ],
            [
              -68.12027,
              47.926659
            ],
            [
              -68.372772,
              47.924709
            ],
            [
              -68.378052,
              47.65416
            ],
            [
              -68.38028,
              47.558601
            ],
            [
              -68.576111,
              47.424709
            ],
            [
              -69.039711,
              47.29715
            ],
            [
              -69.047478,
              47.421989
            ],
            [
              -69.230789,
              47.4533
            ],
            [
              -69.985458,
              46.69133
            ],
            [
              -70.014633,
              46.57056
            ],
            [
              -70.047089,
              46.426079
            ],
            [
              -70.191544,
              46.334801
            ],
            [
              -70.283974,
              46.190208
            ],
            [
              -70.229797,
              46.137402
            ],
            [
              -70.305328,
              46.06662
            ],
            [
              -70.280502,
              46.05312
            ],
            [
              -70.310783,
              45.968739
            ],
            [
              -70.247948,
              45.94458
            ],
            [
              -70.254448,
              45.89896
            ],
            [
              -70.416687,
              45.79026
            ],
            [
              -70.396873,
              45.722
            ],
            [
              -70.55275,
              45.660622
            ],
            [
              -70.72039,
              45.512909
            ],
            [
              -70.635406,
              45.391918
            ],
            [
              -70.797447,
              45.425129
            ],
            [
              -70.829613,
              45.390678
            ],
            [
              -70.813148,
              45.35463
            ],
            [
              -70.843361,
              45.278091
            ],
            [
              -70.87693,
              45.225399
            ],
            [
              -70.959862,
              45.338821
            ],
            [
              -71.08799,
              45.301418
            ],
            [
              -71.153572,
              45.237919
            ],
            [
              -71.297707,
              45.293449
            ],
            [
              -71.386864,
              45.23489
            ],
            [
              -71.447037,
              45.236038
            ],
            [
              -71.403,
              45.202759
            ],
            [
              -71.430893,
              45.116951
            ],
            [
              -71.503471,
              45.059841
            ],
            [
              -71.505836,
              45.013309
            ],
            [
              -71.902328,
              45.00729
            ],
            [
              -72.547668,
              45.005329
            ],
            [
              -73.188972,
              45.00845
            ],
            [
              -73.345154,
              45.0061
            ],
            [
              -74.021927,
              44.990799
            ],
            [
              -74.736481,
              44.99287
            ],
            [
              -74.506393,
              45.071659
            ],
            [
              -74.433609,
              45.149441
            ],
            [
              -74.324722,
              45.201099
            ],
            [
              -74.479172,
              45.316669
            ],
            [
              -74.396667,
              45.564159
            ],
            [
              -74.646118,
              45.635551
            ],
            [
              -74.875839,
              45.64159
            ],
            [
              -75.453339,
              45.523609
            ],
            [
              -75.847229,
              45.37249
            ],
            [
              -76.076683,
              45.513889
            ],
            [
              -76.184433,
              45.521099
            ],
            [
              -76.337784,
              45.45583
            ],
            [
              -76.607773,
              45.55249
            ],
            [
              -76.666397,
              45.590549
            ],
            [
              -76.710564,
              45.712769
            ],
            [
              -76.783333,
              45.76194
            ],
            [
              -76.804718,
              45.864719
            ],
            [
              -76.910553,
              45.889992
            ],
            [
              -76.920837,
              45.806381
            ],
            [
              -77.002792,
              45.790279
            ],
            [
              -77.206123,
              45.877209
            ],
            [
              -77.278877,
              45.937771
            ],
            [
              -77.321953,
              46.026661
            ],
            [
              -77.554993,
              46.151932
            ],
            [
              -77.680557,
              46.183601
            ],
            [
              -78.703613,
              46.337212
            ],
            [
              -78.75528,
              46.400539
            ],
            [
              -78.950562,
              46.511669
            ],
            [
              -79.155563,
              46.812489
            ],
            [
              -79.305557,
              46.94054
            ],
            [
              -79.443878,
              47.11055
            ],
            [
              -79.439438,
              47.26527
            ],
            [
              -79.576401,
              47.441929
            ],
            [
              -79.511322,
              47.565571
            ],
            [
              -79.519012,
              48.605289
            ],
            [
              -79.520683,
              48.764679
            ],
            [
              -79.537613,
              50.958599
            ],
            [
              -79.355827,
              50.777489
            ],
            [
              -79.348053,
              50.73193
            ],
            [
              -79.330002,
              50.758331
            ],
            [
              -79.383987,
              50.836269
            ],
            [
              -79.421944,
              50.880859
            ],
            [
              -79.537804,
              50.982368
            ],
            [
              -79.539436,
              51.191662
            ],
            [
              -79.547096,
              51.460129
            ],
            [
              -79.508347,
              51.468601
            ],
            [
              -79.551392,
              51.553051
            ],
            [
              -79.353882,
              51.656101
            ],
            [
              -79.239723,
              51.634991
            ],
            [
              -79.285278,
              51.562489
            ],
            [
              -79.274719,
              51.530548
            ],
            [
              -79.127487,
              51.538052
            ],
            [
              -79.024437,
              51.476379
            ],
            [
              -78.938316,
              51.285831
            ],
            [
              -78.951401,
              51.215549
            ],
            [
              -78.844727,
              51.163601
            ],
            [
              -78.920547,
              51.231098
            ],
            [
              -78.904449,
              51.305271
            ],
            [
              -78.823624,
              51.34943
            ],
            [
              -78.888344,
              51.39666
            ],
            [
              -78.763634,
              51.486382
            ],
            [
              -78.688599,
              51.48444
            ],
            [
              -78.820282,
              51.51305
            ],
            [
              -78.804001,
              51.61021
            ],
            [
              -78.943047,
              51.658878
            ],
            [
              -78.938316,
              51.694439
            ],
            [
              -79.033333,
              51.776379
            ],
            [
              -78.836937,
              51.841381
            ],
            [
              -78.894173,
              51.93832
            ],
            [
              -78.747772,
              51.97332
            ],
            [
              -78.695831,
              52.008049
            ],
            [
              -78.704178,
              52.0536
            ],
            [
              -78.638062,
              52.057209
            ],
            [
              -78.648064,
              52.085819
            ],
            [
              -78.579453,
              52.111382
            ],
            [
              -78.537514,
              52.18082
            ],
            [
              -78.556381,
              52.23555
            ],
            [
              -78.405838,
              52.23777
            ],
            [
              -78.451683,
              52.271381
            ],
            [
              -78.556664,
              52.272221
            ],
            [
              -78.497223,
              52.358601
            ],
            [
              -78.544159,
              52.435822
            ],
            [
              -78.506958,
              52.460548
            ],
            [
              -78.566391,
              52.485271
            ],
            [
              -78.545273,
              52.514721
            ],
            [
              -78.763344,
              52.564442
            ],
            [
              -78.69194,
              52.5961
            ],
            [
              -78.730003,
              52.615002
            ],
            [
              -78.725281,
              52.648048
            ],
            [
              -78.778877,
              52.653599
            ],
            [
              -78.754997,
              52.695271
            ],
            [
              -78.820847,
              52.703609
            ],
            [
              -78.771393,
              52.709721
            ],
            [
              -78.783333,
              52.734718
            ],
            [
              -78.850281,
              52.764721
            ],
            [
              -78.724716,
              52.785549
            ],
            [
              -78.728882,
              52.823879
            ],
            [
              -78.780838,
              52.81805
            ],
            [
              -78.68721,
              52.87999
            ],
            [
              -78.85611,
              52.877769
            ],
            [
              -78.899727,
              52.922771
            ],
            [
              -78.864723,
              52.963612
            ],
            [
              -78.786667,
              52.96944
            ],
            [
              -78.826111,
              52.978039
            ],
            [
              -78.845551,
              53.01194
            ],
            [
              -78.983063,
              53.023609
            ],
            [
              -78.918327,
              53.052769
            ],
            [
              -78.974716,
              53.069439
            ],
            [
              -78.915558,
              53.165272
            ],
            [
              -78.944443,
              53.182499
            ],
            [
              -78.905563,
              53.191929
            ],
            [
              -78.92749,
              53.218601
            ],
            [
              -78.888062,
              53.224709
            ],
            [
              -78.920273,
              53.247768
            ],
            [
              -78.895844,
              53.26527
            ],
            [
              -78.956673,
              53.283611
            ],
            [
              -78.941101,
              53.379162
            ],
            [
              -78.988052,
              53.4436
            ],
            [
              -79.063316,
              53.43943
            ],
            [
              -79.051392,
              53.457211
            ],
            [
              -79.110283,
              53.502491
            ],
            [
              -79.012787,
              53.531109
            ],
            [
              -79.041382,
              53.50555
            ],
            [
              -79.001404,
              53.490829
            ],
            [
              -78.959167,
              53.51416
            ],
            [
              -79.031113,
              53.560822
            ],
            [
              -78.915833,
              53.560551
            ],
            [
              -78.984734,
              53.614719
            ],
            [
              -79.063049,
              53.596939
            ],
            [
              -79.003342,
              53.641659
            ],
            [
              -79.04361,
              53.6675
            ],
            [
              -79.011124,
              53.683601
            ],
            [
              -79.151398,
              53.70499
            ],
            [
              -79.036392,
              53.72971
            ],
            [
              -79.067497,
              53.764439
            ],
            [
              -79.042503,
              53.784721
            ],
            [
              -79.06778,
              53.80249
            ],
            [
              -79.020554,
              53.821941
            ],
            [
              -79.046951,
              53.835548
            ],
            [
              -78.901398,
              53.815269
            ],
            [
              -79.0625,
              53.856659
            ],
            [
              -79.059998,
              53.889721
            ],
            [
              -79.101669,
              53.909988
            ],
            [
              -78.993332,
              53.91193
            ],
            [
              -79.136398,
              53.956661
            ],
            [
              -79.033073,
              53.946381
            ],
            [
              -79.093887,
              53.963329
            ],
            [
              -79.044724,
              53.974991
            ],
            [
              -79.097778,
              53.97887
            ],
            [
              -79.001099,
              53.999989
            ],
            [
              -78.964951,
              53.997162
            ],
            [
              -78.976669,
              54.01833
            ],
            [
              -79.077499,
              54.037498
            ],
            [
              -79.126938,
              54.081108
            ],
            [
              -79.286942,
              54.082211
            ],
            [
              -79.175278,
              54.086651
            ],
            [
              -79.16777,
              54.117771
            ],
            [
              -79.116386,
              54.10305
            ],
            [
              -79.130829,
              54.124989
            ],
            [
              -79.098053,
              54.12888
            ],
            [
              -79.138344,
              54.144711
            ],
            [
              -79.048889,
              54.183331
            ],
            [
              -79.205841,
              54.154991
            ],
            [
              -79.379707,
              54.1936
            ],
            [
              -79.283333,
              54.248878
            ],
            [
              -79.402222,
              54.263611
            ],
            [
              -79.438599,
              54.276379
            ],
            [
              -79.419159,
              54.30138
            ],
            [
              -79.461121,
              54.304161
            ],
            [
              -79.472778,
              54.364441
            ],
            [
              -79.506958,
              54.370831
            ],
            [
              -79.446663,
              54.416939
            ],
            [
              -79.501404,
              54.421379
            ],
            [
              -79.488052,
              54.458599
            ],
            [
              -79.548607,
              54.504711
            ],
            [
              -79.524719,
              54.523048
            ],
            [
              -79.602783,
              54.555271
            ],
            [
              -79.521393,
              54.587212
            ],
            [
              -79.576401,
              54.60944
            ],
            [
              -79.630547,
              54.57111
            ],
            [
              -79.596657,
              54.63916
            ],
            [
              -79.675552,
              54.62582
            ],
            [
              -79.662514,
              54.66777
            ],
            [
              -79.761124,
              54.658329
            ],
            [
              -79.636124,
              54.698879
            ],
            [
              -79.62999,
              54.740551
            ],
            [
              -79.619164,
              54.711109
            ],
            [
              -79.240829,
              54.81221
            ],
            [
              -78.976669,
              54.843048
            ],
            [
              -78.862778,
              54.9086
            ],
            [
              -78.335564,
              55.03944
            ],
            [
              -77.760277,
              55.287769
            ],
            [
              -77.65834,
              55.37027
            ],
            [
              -77.214722,
              55.595268
            ],
            [
              -77.086121,
              55.705551
            ],
            [
              -77.18306,
              55.689709
            ],
            [
              -76.840286,
              55.928879
            ],
            [
              -76.797501,
              55.990269
            ],
            [
              -76.658051,
              56.060822
            ],
            [
              -76.619453,
              56.185822
            ],
            [
              -76.53833,
              56.297779
            ],
            [
              -76.51889,
              56.406101
            ],
            [
              -76.505569,
              56.803051
            ],
            [
              -76.555832,
              57.035
            ],
            [
              -76.529716,
              57.105831
            ],
            [
              -76.653061,
              57.401379
            ],
            [
              -76.811394,
              57.62471
            ],
            [
              -76.806107,
              57.669159
            ],
            [
              -77.147232,
              58.02277
            ],
            [
              -77.487503,
              58.165272
            ],
            [
              -77.444153,
              58.187771
            ],
            [
              -77.609444,
              58.269989
            ],
            [
              -78.012512,
              58.378601
            ],
            [
              -78.013062,
              58.413052
            ],
            [
              -78.074173,
              58.454441
            ],
            [
              -78.120003,
              58.446941
            ],
            [
              -78.268623,
              58.519161
            ],
            [
              -78.397232,
              58.620831
            ],
            [
              -78.426102,
              58.606098
            ],
            [
              -78.327499,
              58.53722
            ],
            [
              -78.383904,
              58.54166
            ],
            [
              -78.47139,
              58.61166
            ],
            [
              -78.568619,
              58.614441
            ],
            [
              -78.549438,
              58.660271
            ],
            [
              -78.570007,
              58.67305
            ],
            [
              -78.467216,
              58.70166
            ],
            [
              -78.549438,
              58.767769
            ],
            [
              -78.495003,
              58.77721
            ],
            [
              -78.488052,
              58.820831
            ],
            [
              -78.549438,
              58.91082
            ],
            [
              -78.530289,
              58.938881
            ],
            [
              -78.570557,
              58.96138
            ],
            [
              -78.450844,
              58.909988
            ],
            [
              -78.43721,
              58.952221
            ],
            [
              -78.361656,
              58.958599
            ],
            [
              -78.363892,
              58.912491
            ],
            [
              -78.338333,
              58.912769
            ],
            [
              -78.245003,
              58.99194
            ],
            [
              -78.316963,
              58.98555
            ],
            [
              -78.269173,
              59.016941
            ],
            [
              -78.283333,
              59.049999
            ],
            [
              -78.205566,
              59.050541
            ],
            [
              -78.21611,
              59.106941
            ],
            [
              -78.178329,
              59.08527
            ],
            [
              -78.127487,
              59.10833
            ],
            [
              -78.153343,
              59.15221
            ],
            [
              -78.08667,
              59.156651
            ],
            [
              -78.092773,
              59.215
            ],
            [
              -77.828888,
              59.281109
            ],
            [
              -77.890839,
              59.310822
            ],
            [
              -77.778061,
              59.32333
            ],
            [
              -77.764717,
              59.351662
            ],
            [
              -77.800827,
              59.368599
            ],
            [
              -77.684998,
              59.39333
            ],
            [
              -77.779167,
              59.426102
            ],
            [
              -77.912514,
              59.415272
            ],
            [
              -77.848618,
              59.446098
            ],
            [
              -77.867493,
              59.5
            ],
            [
              -77.72139,
              59.539719
            ],
            [
              -77.712784,
              59.620831
            ],
            [
              -77.801102,
              59.67527
            ],
            [
              -77.741943,
              59.691101
            ],
            [
              -77.773903,
              59.709721
            ],
            [
              -77.670273,
              59.674438
            ],
            [
              -77.570282,
              59.69582
            ],
            [
              -77.473053,
              59.634991
            ],
            [
              -77.454178,
              59.579159
            ],
            [
              -77.313889,
              59.564991
            ],
            [
              -77.440552,
              59.59972
            ],
            [
              -77.452499,
              59.663052
            ],
            [
              -77.541672,
              59.688881
            ],
            [
              -77.491943,
              59.71249
            ],
            [
              -77.533073,
              59.754711
            ],
            [
              -77.472504,
              59.742771
            ],
            [
              -77.43277,
              59.784161
            ],
            [
              -77.29361,
              59.79361
            ],
            [
              -77.363617,
              59.890831
            ],
            [
              -77.42749,
              59.914711
            ],
            [
              -77.300278,
              59.965
            ],
            [
              -77.323059,
              59.98888
            ],
            [
              -77.236389,
              59.994709
            ],
            [
              -77.313889,
              60.012501
            ],
            [
              -77.206947,
              60.04277
            ],
            [
              -77.189987,
              60.086109
            ],
            [
              -77.059433,
              60.062771
            ],
            [
              -76.958054,
              60.10305
            ],
            [
              -76.858887,
              60.101101
            ],
            [
              -76.788597,
              60.068329
            ],
            [
              -76.833618,
              60.115002
            ],
            [
              -76.758904,
              60.159161
            ],
            [
              -76.924438,
              60.11166
            ],
            [
              -77.199997,
              60.150829
            ],
            [
              -77.105003,
              60.108601
            ],
            [
              -77.259171,
              60.11805
            ],
            [
              -77.232498,
              60.053879
            ],
            [
              -77.315826,
              60.030548
            ],
            [
              -77.358047,
              60.060822
            ],
            [
              -77.433319,
              60.036381
            ],
            [
              -77.630829,
              60.065269
            ],
            [
              -77.558037,
              60.126659
            ],
            [
              -77.406387,
              60.130268
            ],
            [
              -77.551392,
              60.19471
            ],
            [
              -77.470284,
              60.213329
            ],
            [
              -77.573624,
              60.25972
            ],
            [
              -77.543327,
              60.285831
            ],
            [
              -77.623322,
              60.26833
            ],
            [
              -77.602783,
              60.32999
            ],
            [
              -77.743607,
              60.39333
            ],
            [
              -77.740829,
              60.423611
            ],
            [
              -77.574448,
              60.502491
            ],
            [
              -77.553879,
              60.542221
            ],
            [
              -77.41333,
              60.544159
            ],
            [
              -77.549438,
              60.571381
            ],
            [
              -77.670273,
              60.549721
            ],
            [
              -77.833893,
              60.639431
            ],
            [
              -77.607773,
              60.758331
            ],
            [
              -77.745003,
              60.76416
            ],
            [
              -77.511673,
              60.83638
            ],
            [
              -77.890289,
              60.75
            ],
            [
              -77.858337,
              60.791939
            ],
            [
              -77.965843,
              60.791382
            ],
            [
              -77.896393,
              60.828049
            ],
            [
              -78.190552,
              60.788891
            ],
            [
              -78.159439,
              60.86721
            ],
            [
              -77.946663,
              61.004711
            ],
            [
              -77.811111,
              61.052219
            ],
            [
              -77.790283,
              61.151379
            ],
            [
              -77.694443,
              61.20277
            ],
            [
              -77.752228,
              61.229431
            ],
            [
              -77.719162,
              61.26527
            ],
            [
              -77.79277,
              61.45388
            ],
            [
              -77.700287,
              61.426102
            ],
            [
              -77.678879,
              61.461109
            ],
            [
              -77.560547,
              61.468048
            ],
            [
              -77.54834,
              61.486111
            ],
            [
              -77.670273,
              61.519161
            ],
            [
              -77.621109,
              61.5611
            ],
            [
              -77.474716,
              61.54166
            ],
            [
              -77.631943,
              61.579441
            ],
            [
              -77.589172,
              61.604439
            ],
            [
              -77.710564,
              61.605831
            ],
            [
              -77.785828,
              61.700001
            ],
            [
              -77.898903,
              61.686378
            ],
            [
              -78.006119,
              61.733051
            ],
            [
              -78.003067,
              61.77972
            ],
            [
              -78.08139,
              61.865829
            ],
            [
              -78.08139,
              61.951099
            ],
            [
              -78.137787,
              62.009159
            ],
            [
              -78.161667,
              62.169159
            ],
            [
              -78.155838,
              62.278332
            ],
            [
              -78.023903,
              62.39333
            ],
            [
              -77.70195,
              62.470268
            ],
            [
              -77.486938,
              62.590271
            ],
            [
              -77.418327,
              62.58971
            ],
            [
              -77.374161,
              62.551929
            ],
            [
              -77.04834,
              62.519161
            ],
            [
              -76.948883,
              62.535831
            ],
            [
              -76.143066,
              62.379162
            ],
            [
              -76.097778,
              62.340549
            ],
            [
              -75.949722,
              62.350269
            ],
            [
              -75.645844,
              62.291939
            ],
            [
              -75.592216,
              62.264439
            ],
            [
              -75.836937,
              62.187771
            ],
            [
              -75.894997,
              62.1586
            ],
            [
              -75.82695,
              62.158878
            ],
            [
              -75.486938,
              62.296391
            ],
            [
              -75.321953,
              62.3111
            ],
            [
              -75.152496,
              62.284721
            ],
            [
              -75.152786,
              62.227489
            ],
            [
              -75.066673,
              62.26833
            ],
            [
              -74.928329,
              62.249161
            ],
            [
              -74.765007,
              62.14444
            ],
            [
              -74.556381,
              62.10471
            ],
            [
              -74.751106,
              62.191101
            ],
            [
              -74.72583,
              62.244999
            ],
            [
              -74.410004,
              62.251389
            ],
            [
              -74.280563,
              62.321659
            ],
            [
              -74,
              62.358051
            ],
            [
              -73.868057,
              62.458328
            ],
            [
              -73.678879,
              62.479988
            ],
            [
              -73.5625,
              62.377769
            ],
            [
              -73.387512,
              62.36916
            ],
            [
              -73.211403,
              62.312771
            ],
            [
              -73.204178,
              62.261379
            ],
            [
              -73.063606,
              62.182499
            ],
            [
              -72.903877,
              62.142769
            ],
            [
              -72.94249,
              62.11805
            ],
            [
              -72.784439,
              62.106941
            ],
            [
              -72.815002,
              62.137772
            ],
            [
              -72.736938,
              62.14333
            ],
            [
              -72.626663,
              62.115551
            ],
            [
              -72.617493,
              62.09193
            ],
            [
              -72.661942,
              62.05666
            ],
            [
              -72.596123,
              62.04916
            ],
            [
              -72.618332,
              62.033611
            ],
            [
              -72.604446,
              61.9711
            ],
            [
              -72.678879,
              61.949429
            ],
            [
              -72.680832,
              61.900269
            ],
            [
              -72.794197,
              61.834751
            ],
            [
              -72.66806,
              61.879162
            ],
            [
              -72.669159,
              61.847771
            ],
            [
              -72.596123,
              61.80555
            ],
            [
              -72.636124,
              61.90221
            ],
            [
              -72.59584,
              61.94276
            ],
            [
              -72.205841,
              61.863331
            ],
            [
              -72.12999,
              61.79916
            ],
            [
              -72.200287,
              61.789162
            ],
            [
              -72.224716,
              61.751659
            ],
            [
              -72.145844,
              61.75388
            ],
            [
              -72.061661,
              61.73555
            ],
            [
              -71.99028,
              61.67638
            ],
            [
              -72.087784,
              61.598881
            ],
            [
              -72.236656,
              61.619438
            ],
            [
              -72.303329,
              61.567211
            ],
            [
              -72.210831,
              61.598331
            ],
            [
              -72.08371,
              61.582458
            ],
            [
              -71.980293,
              61.599998
            ],
            [
              -71.933609,
              61.663601
            ],
            [
              -71.958054,
              61.70694
            ],
            [
              -71.675278,
              61.658878
            ],
            [
              -71.571671,
              61.605549
            ],
            [
              -71.549438,
              61.55888
            ],
            [
              -71.855003,
              61.530548
            ],
            [
              -71.789436,
              61.521931
            ],
            [
              -71.795273,
              61.492489
            ],
            [
              -71.746948,
              61.465832
            ],
            [
              -71.887787,
              61.43082
            ],
            [
              -71.585281,
              61.406651
            ],
            [
              -71.689163,
              61.36832
            ],
            [
              -71.822777,
              61.372761
            ],
            [
              -71.792503,
              61.357498
            ],
            [
              -71.818619,
              61.346661
            ],
            [
              -71.664436,
              61.32777
            ],
            [
              -71.72583,
              61.285831
            ],
            [
              -71.598892,
              61.254169
            ],
            [
              -71.62999,
              61.204708
            ],
            [
              -71.569458,
              61.214439
            ],
            [
              -71.578339,
              61.15527
            ],
            [
              -71.512222,
              61.161659
            ],
            [
              -71.530838,
              61.213612
            ],
            [
              -71.473328,
              61.223049
            ],
            [
              -71.370003,
              61.118599
            ],
            [
              -71.361389,
              61.14777
            ],
            [
              -71.215843,
              61.16777
            ],
            [
              -71.193329,
              61.115829
            ],
            [
              -71.174713,
              61.139992
            ],
            [
              -71.011398,
              61.121658
            ],
            [
              -70.928329,
              61.102489
            ],
            [
              -70.936111,
              61.073051
            ],
            [
              -70.818069,
              61.064442
            ],
            [
              -70.767227,
              61.048328
            ],
            [
              -70.780563,
              61.078331
            ],
            [
              -70.751404,
              61.082211
            ],
            [
              -70.656387,
              61.050541
            ],
            [
              -70.676941,
              61.02166
            ],
            [
              -70.547501,
              61.0075
            ],
            [
              -70.566391,
              61.040829
            ],
            [
              -70.527222,
              61.060822
            ],
            [
              -70.426392,
              61.023319
            ],
            [
              -70.444992,
              61.053879
            ],
            [
              -70.410004,
              61.05471
            ],
            [
              -70.41362,
              61.086651
            ],
            [
              -70.351936,
              61.061378
            ],
            [
              -70.326401,
              61.09388
            ],
            [
              -70.246658,
              61.067501
            ],
            [
              -70.146118,
              61.084721
            ],
            [
              -70.107773,
              61.059719
            ],
            [
              -70.159729,
              61.014439
            ],
            [
              -70.09639,
              61.009991
            ],
            [
              -70.114441,
              60.985271
            ],
            [
              -70.085831,
              60.95499
            ],
            [
              -70.088058,
              60.89777
            ],
            [
              -70.147781,
              60.87999
            ],
            [
              -70.067497,
              60.875271
            ],
            [
              -70.061111,
              60.84444
            ],
            [
              -69.92749,
              60.80777
            ],
            [
              -69.849731,
              60.846661
            ],
            [
              -69.93306,
              60.850552
            ],
            [
              -69.756958,
              60.91888
            ],
            [
              -69.740547,
              60.881378
            ],
            [
              -69.658623,
              60.876942
            ],
            [
              -69.64389,
              60.90332
            ],
            [
              -69.689713,
              60.961658
            ],
            [
              -69.656662,
              61.0536
            ],
            [
              -69.599991,
              61.08194
            ],
            [
              -69.51445,
              61.069439
            ],
            [
              -69.426392,
              60.923611
            ],
            [
              -69.366096,
              60.90694
            ],
            [
              -69.391113,
              60.871929
            ],
            [
              -69.368607,
              60.8111
            ],
            [
              -69.71611,
              60.68277
            ],
            [
              -69.685822,
              60.64444
            ],
            [
              -69.696663,
              60.598049
            ],
            [
              -69.656952,
              60.574711
            ],
            [
              -69.826111,
              60.525551
            ],
            [
              -69.748894,
              60.461658
            ],
            [
              -69.776398,
              60.415539
            ],
            [
              -69.730003,
              60.404991
            ],
            [
              -69.722504,
              60.364159
            ],
            [
              -69.768066,
              60.31221
            ],
            [
              -69.606377,
              60.232769
            ],
            [
              -69.651108,
              60.183331
            ],
            [
              -69.594162,
              60.18082
            ],
            [
              -69.62471,
              60.067501
            ],
            [
              -69.7164,
              60.04166
            ],
            [
              -69.841949,
              60.063049
            ],
            [
              -69.841377,
              60.023048
            ],
            [
              -69.770279,
              60.001942
            ],
            [
              -69.794037,
              59.98941
            ],
            [
              -70.33168,
              60.028332
            ],
            [
              -70.315002,
              60.005001
            ],
            [
              -70.556953,
              59.992771
            ],
            [
              -71.030563,
              60.061939
            ],
            [
              -70.566963,
              59.968601
            ],
            [
              -70.227219,
              59.98666
            ],
            [
              -70.061394,
              59.944988
            ],
            [
              -69.726936,
              59.963612
            ],
            [
              -69.717499,
              59.923882
            ],
            [
              -69.759171,
              59.902489
            ],
            [
              -69.562767,
              59.86805
            ],
            [
              -69.612213,
              59.784439
            ],
            [
              -69.545837,
              59.763611
            ],
            [
              -69.654167,
              59.697769
            ],
            [
              -69.519447,
              59.653049
            ],
            [
              -69.571953,
              59.590271
            ],
            [
              -69.679443,
              59.56361
            ],
            [
              -69.748337,
              59.509991
            ],
            [
              -69.759453,
              59.481098
            ],
            [
              -69.697487,
              59.480549
            ],
            [
              -69.645279,
              59.419159
            ],
            [
              -69.66777,
              59.386662
            ],
            [
              -69.631378,
              59.374989
            ],
            [
              -69.758347,
              59.320271
            ],
            [
              -69.631104,
              59.298882
            ],
            [
              -69.412514,
              59.355
            ],
            [
              -69.362503,
              59.339989
            ],
            [
              -69.367218,
              59.307499
            ],
            [
              -69.249733,
              59.323608
            ],
            [
              -69.266403,
              59.283329
            ],
            [
              -69.235001,
              59.239429
            ],
            [
              -69.29277,
              59.208328
            ],
            [
              -69.371933,
              59.240551
            ],
            [
              -69.373894,
              59.18943
            ],
            [
              -69.419159,
              59.19249
            ],
            [
              -69.420837,
              59.223049
            ],
            [
              -69.470284,
              59.213879
            ],
            [
              -69.540283,
              59.166382
            ],
            [
              -69.533073,
              59.110821
            ],
            [
              -69.501709,
              59.106331
            ],
            [
              -69.463058,
              59.129429
            ],
            [
              -69.344162,
              59.091099
            ],
            [
              -69.439713,
              59.02277
            ],
            [
              -69.506958,
              59.069721
            ],
            [
              -69.454178,
              58.89222
            ],
            [
              -69.518341,
              58.885551
            ],
            [
              -69.547501,
              58.808041
            ],
            [
              -69.670837,
              58.792221
            ],
            [
              -69.71611,
              58.864719
            ],
            [
              -69.672234,
              58.89138
            ],
            [
              -69.671661,
              58.93055
            ],
            [
              -69.711937,
              58.91888
            ],
            [
              -69.709442,
              58.972759
            ],
            [
              -69.756668,
              58.98999
            ],
            [
              -69.78833,
              58.96693
            ],
            [
              -69.869164,
              59.053051
            ],
            [
              -69.861107,
              58.984989
            ],
            [
              -69.895554,
              58.957771
            ],
            [
              -69.80722,
              58.931938
            ],
            [
              -69.797501,
              58.82888
            ],
            [
              -69.898354,
              58.873322
            ],
            [
              -69.899986,
              58.82777
            ],
            [
              -70.25,
              58.769711
            ],
            [
              -70.036392,
              58.733601
            ],
            [
              -69.919724,
              58.77388
            ],
            [
              -69.925003,
              58.69249
            ],
            [
              -69.79834,
              58.704708
            ],
            [
              -69.876663,
              58.643608
            ],
            [
              -69.813049,
              58.589161
            ],
            [
              -69.700287,
              58.697208
            ],
            [
              -69.642776,
              58.695541
            ],
            [
              -69.608047,
              58.754711
            ],
            [
              -69.507507,
              58.774712
            ],
            [
              -69.348892,
              58.871658
            ],
            [
              -69.12999,
              58.901661
            ],
            [
              -68.889183,
              58.876942
            ],
            [
              -68.763062,
              58.921101
            ],
            [
              -68.390694,
              58.81171
            ],
            [
              -68.355827,
              58.774441
            ],
            [
              -68.380547,
              58.697769
            ],
            [
              -68.326683,
              58.664711
            ],
            [
              -68.348343,
              58.600552
            ],
            [
              -68.274437,
              58.590549
            ],
            [
              -68.288887,
              58.524712
            ],
            [
              -68.193604,
              58.540829
            ],
            [
              -68.223587,
              58.397831
            ],
            [
              -68.31723,
              58.241661
            ],
            [
              -68.344162,
              58.127491
            ],
            [
              -68.46666,
              58.045551
            ],
            [
              -68.964722,
              57.95916
            ],
            [
              -69.202499,
              57.868599
            ],
            [
              -69.369049,
              57.765251
            ],
            [
              -68.951401,
              57.938599
            ],
            [
              -68.404167,
              58.039719
            ],
            [
              -68.313606,
              58.10305
            ],
            [
              -68.303329,
              58.195541
            ],
            [
              -68.23056,
              58.25555
            ],
            [
              -68.168327,
              58.414711
            ],
            [
              -68.171387,
              58.48999
            ],
            [
              -68.125397,
              58.526058
            ],
            [
              -68.026672,
              58.51305
            ],
            [
              -68.003342,
              58.576389
            ],
            [
              -67.959167,
              58.558041
            ],
            [
              -67.958344,
              58.51194
            ],
            [
              -67.896118,
              58.500549
            ],
            [
              -67.923317,
              58.403049
            ],
            [
              -67.855827,
              58.314991
            ],
            [
              -67.897232,
              58.25333
            ],
            [
              -68.09584,
              58.138599
            ],
            [
              -68.128326,
              58.073608
            ],
            [
              -68.006668,
              58.131939
            ],
            [
              -67.954453,
              58.211941
            ],
            [
              -67.861656,
              58.232208
            ],
            [
              -67.801392,
              58.296661
            ],
            [
              -67.830566,
              58.331379
            ],
            [
              -67.787514,
              58.464439
            ],
            [
              -67.731949,
              58.461941
            ],
            [
              -67.667503,
              58.42749
            ],
            [
              -67.707497,
              58.383049
            ],
            [
              -67.693047,
              58.354439
            ],
            [
              -67.737213,
              58.326939
            ],
            [
              -67.730827,
              58.283611
            ],
            [
              -67.642776,
              58.2486
            ],
            [
              -67.703056,
              58.16193
            ],
            [
              -67.671661,
              58.070831
            ],
            [
              -67.735817,
              57.966381
            ],
            [
              -67.709732,
              57.923321
            ],
            [
              -67.708344,
              57.982491
            ],
            [
              -67.635559,
              58.04277
            ],
            [
              -67.65361,
              58.122761
            ],
            [
              -67.481377,
              58.27388
            ],
            [
              -67.378883,
              58.276939
            ],
            [
              -67.16861,
              58.37804
            ],
            [
              -67.090561,
              58.350552
            ],
            [
              -67.056381,
              58.388889
            ],
            [
              -67.077499,
              58.4175
            ],
            [
              -67.011398,
              58.424709
            ],
            [
              -66.944153,
              58.501942
            ],
            [
              -66.876938,
              58.47916
            ],
            [
              -66.898064,
              58.445271
            ],
            [
              -66.846657,
              58.426659
            ],
            [
              -66.804443,
              58.493881
            ],
            [
              -66.618607,
              58.48777
            ],
            [
              -66.669449,
              58.53194
            ],
            [
              -66.620827,
              58.54694
            ],
            [
              -66.621109,
              58.60471
            ],
            [
              -66.583618,
              58.631939
            ],
            [
              -66.599167,
              58.673321
            ],
            [
              -66.563606,
              58.673321
            ],
            [
              -66.551941,
              58.71138
            ],
            [
              -66.458893,
              58.721661
            ],
            [
              -66.489723,
              58.75444
            ],
            [
              -66.465012,
              58.819988
            ],
            [
              -66.349991,
              58.843048
            ],
            [
              -66.11055,
              58.720539
            ],
            [
              -66.072243,
              58.650829
            ],
            [
              -65.999161,
              58.65443
            ],
            [
              -65.985947,
              58.622162
            ],
            [
              -65.935822,
              58.60471
            ],
            [
              -66.021393,
              58.495541
            ],
            [
              -65.991096,
              58.40694
            ],
            [
              -66.089447,
              58.365269
            ],
            [
              -66.065552,
              58.320271
            ],
            [
              -66.041672,
              58.36805
            ],
            [
              -65.965012,
              58.391941
            ],
            [
              -65.920273,
              58.44582
            ],
            [
              -65.98056,
              58.483051
            ],
            [
              -65.904999,
              58.52972
            ],
            [
              -65.884171,
              58.58083
            ],
            [
              -65.828613,
              58.572769
            ],
            [
              -65.935547,
              58.643051
            ],
            [
              -65.948608,
              58.682209
            ],
            [
              -66.031387,
              58.689159
            ],
            [
              -66.044998,
              58.755829
            ],
            [
              -66.103882,
              58.773609
            ],
            [
              -66.022507,
              58.86166
            ],
            [
              -65.970001,
              58.826099
            ],
            [
              -65.797501,
              58.847488
            ],
            [
              -65.990829,
              58.899719
            ],
            [
              -65.85083,
              58.946381
            ],
            [
              -65.880829,
              59.010551
            ],
            [
              -65.678047,
              58.926941
            ],
            [
              -65.678329,
              58.963879
            ],
            [
              -65.793327,
              59.005829
            ],
            [
              -65.739723,
              59.018051
            ],
            [
              -65.777496,
              59.029991
            ],
            [
              -65.660278,
              59.044159
            ],
            [
              -65.5,
              58.98333
            ],
            [
              -65.58223,
              59.049721
            ],
            [
              -65.317497,
              59.041382
            ],
            [
              -65.54361,
              59.101101
            ],
            [
              -65.638062,
              59.069439
            ],
            [
              -65.715012,
              59.148331
            ],
            [
              -65.684433,
              59.19305
            ],
            [
              -65.737778,
              59.209991
            ],
            [
              -65.743057,
              59.265831
            ],
            [
              -65.676102,
              59.261108
            ],
            [
              -65.591377,
              59.188042
            ],
            [
              -65.610817,
              59.253052
            ],
            [
              -65.492493,
              59.255001
            ],
            [
              -65.565552,
              59.30138
            ],
            [
              -65.570847,
              59.37804
            ],
            [
              -65.357224,
              59.27721
            ],
            [
              -65.444992,
              59.351391
            ],
            [
              -65.430832,
              59.403599
            ],
            [
              -65.383057,
              59.401661
            ],
            [
              -65.49527,
              59.43388
            ],
            [
              -65.469727,
              59.444149
            ],
            [
              -65.542221,
              59.48999
            ],
            [
              -65.188599,
              59.45916
            ],
            [
              -65.126663,
              59.407768
            ],
            [
              -64.983322,
              59.376381
            ],
            [
              -65.22084,
              59.488331
            ],
            [
              -65.411667,
              59.509441
            ],
            [
              -65.54361,
              59.736382
            ],
            [
              -65.468338,
              59.75972
            ],
            [
              -65.495003,
              59.789989
            ],
            [
              -65.371933,
              59.796391
            ],
            [
              -65.381668,
              59.824169
            ],
            [
              -65.333893,
              59.84721
            ],
            [
              -65.152786,
              59.779991
            ],
            [
              -64.983887,
              59.762772
            ],
            [
              -65.211121,
              59.833599
            ],
            [
              -65.231949,
              59.88583
            ],
            [
              -65.133904,
              59.868599
            ],
            [
              -65.20639,
              59.919991
            ],
            [
              -65.148903,
              59.92305
            ],
            [
              -65.138611,
              59.953049
            ],
            [
              -65.066673,
              59.934429
            ],
            [
              -65.12471,
              59.971661
            ],
            [
              -65.063316,
              59.97694
            ],
            [
              -65.126099,
              60.011108
            ],
            [
              -65.12471,
              60.0425
            ],
            [
              -65.071671,
              60.035271
            ],
            [
              -65.026947,
              60.067501
            ],
            [
              -65.0625,
              60.096939
            ],
            [
              -64.988892,
              60.11694
            ],
            [
              -64.993057,
              60.14888
            ],
            [
              -64.943604,
              60.191101
            ],
            [
              -64.874161,
              60.20583
            ],
            [
              -64.955276,
              60.255829
            ],
            [
              -64.861389,
              60.29694
            ],
            [
              -64.835564,
              60.363331
            ],
            [
              -64.641678,
              60.344711
            ],
            [
              -64.534821,
              60.302601
            ],
            [
              -64.853882,
              60.261669
            ],
            [
              -64.851936,
              60.22332
            ],
            [
              -64.767227,
              60.174999
            ],
            [
              -64.637222,
              60.178329
            ],
            [
              -64.598892,
              60.111111
            ],
            [
              -64.734161,
              60.103321
            ],
            [
              -64.702499,
              60.064991
            ],
            [
              -64.796951,
              60.039719
            ],
            [
              -64.906387,
              60.04805
            ],
            [
              -64.847778,
              59.964161
            ],
            [
              -64.65361,
              59.929989
            ],
            [
              -64.661911,
              59.88361
            ],
            [
              -64.767776,
              59.87999
            ],
            [
              -64.744453,
              59.84499
            ],
            [
              -64.814163,
              59.81889
            ],
            [
              -64.773064,
              59.698879
            ],
            [
              -64.82695,
              59.531109
            ],
            [
              -64.700844,
              59.44582
            ],
            [
              -64.432503,
              59.52943
            ],
            [
              -64.340286,
              59.49638
            ],
            [
              -64.5,
              59.433048
            ],
            [
              -64.485001,
              59.396389
            ],
            [
              -64.538063,
              59.3736
            ],
            [
              -64.499161,
              59.33971
            ],
            [
              -64.53833,
              59.285549
            ],
            [
              -64.507507,
              59.25
            ],
            [
              -64.534729,
              59.197491
            ],
            [
              -64.493057,
              59.158878
            ],
            [
              -64.493057,
              59.104439
            ],
            [
              -64.365013,
              59.07333
            ],
            [
              -64.291382,
              59.016659
            ],
            [
              -64.415833,
              58.981098
            ],
            [
              -64.656387,
              59.028599
            ],
            [
              -64.720001,
              59.069988
            ],
            [
              -64.829453,
              59.03249
            ],
            [
              -64.864723,
              58.990551
            ],
            [
              -64.838898,
              58.958599
            ],
            [
              -64.878883,
              58.92749
            ],
            [
              -64.809433,
              58.911098
            ],
            [
              -64.718887,
              58.945271
            ],
            [
              -64.568069,
              58.887211
            ],
            [
              -64.300278,
              58.882488
            ],
            [
              -64.258621,
              58.849998
            ],
            [
              -64.257507,
              58.77943
            ],
            [
              -64.156113,
              58.74527
            ],
            [
              -63.999439,
              58.81916
            ],
            [
              -63.841942,
              58.82972
            ],
            [
              -63.766651,
              58.874081
            ],
            [
              -63.588329,
              58.846378
            ],
            [
              -63.480549,
              58.7486
            ],
            [
              -63.574169,
              58.715832
            ],
            [
              -64.051102,
              58.676102
            ],
            [
              -64.097504,
              58.60722
            ],
            [
              -64.084167,
              58.55555
            ],
            [
              -64.022507,
              58.52055
            ],
            [
              -63.886951,
              58.563049
            ],
            [
              -63.81945,
              58.471371
            ],
            [
              -64.156387,
              58.348331
            ],
            [
              -64.21167,
              58.23555
            ],
            [
              -64.398064,
              58.18166
            ],
            [
              -64.431953,
              58.086109
            ],
            [
              -64.208618,
              58.033329
            ],
            [
              -64.214447,
              57.977489
            ],
            [
              -64.057503,
              57.76944
            ],
            [
              -64.022507,
              57.771381
            ],
            [
              -64.006668,
              57.812771
            ],
            [
              -63.92667,
              57.797218
            ],
            [
              -63.891941,
              57.73027
            ],
            [
              -63.825562,
              57.690269
            ],
            [
              -63.765282,
              57.732769
            ],
            [
              -63.70055,
              57.662769
            ],
            [
              -63.653332,
              57.731098
            ],
            [
              -63.595829,
              57.734161
            ],
            [
              -63.60722,
              57.663601
            ],
            [
              -63.721378,
              57.602489
            ],
            [
              -63.765011,
              57.573879
            ],
            [
              -63.763889,
              57.482491
            ],
            [
              -63.709721,
              57.37249
            ],
            [
              -63.836941,
              57.325829
            ],
            [
              -63.87611,
              57.242771
            ],
            [
              -63.855282,
              57.193878
            ],
            [
              -63.81472,
              57.27972
            ],
            [
              -63.741112,
              57.232769
            ],
            [
              -63.7925,
              57.165272
            ],
            [
              -63.78751,
              57.111938
            ],
            [
              -63.873611,
              57.06638
            ],
            [
              -63.863331,
              56.992771
            ],
            [
              -63.917782,
              56.930271
            ],
            [
              -63.87138,
              56.87804
            ],
            [
              -64.00528,
              56.850552
            ],
            [
              -63.992229,
              56.785831
            ],
            [
              -64.083618,
              56.765549
            ],
            [
              -64.12944,
              56.71027
            ],
            [
              -63.945,
              56.549721
            ],
            [
              -63.942219,
              56.479431
            ],
            [
              -63.87138,
              56.43943
            ],
            [
              -63.97472,
              56.412769
            ],
            [
              -64.169724,
              56.427769
            ],
            [
              -64.114166,
              56.39027
            ],
            [
              -64.105827,
              56.331379
            ],
            [
              -64.136673,
              56.3036
            ],
            [
              -64.08667,
              56.259159
            ],
            [
              -63.97472,
              56.259159
            ],
            [
              -63.876949,
              56.217209
            ],
            [
              -64.010834,
              56.159988
            ],
            [
              -64.020279,
              56.076389
            ],
            [
              -63.847778,
              56.12027
            ],
            [
              -63.880001,
              56.076939
            ],
            [
              -63.79084,
              56.039162
            ],
            [
              -63.445,
              56.02277
            ],
            [
              -63.69416,
              55.942211
            ],
            [
              -63.715279,
              55.859161
            ],
            [
              -63.488331,
              55.696659
            ],
            [
              -63.448051,
              55.59943
            ],
            [
              -63.29834,
              55.523319
            ],
            [
              -63.144169,
              55.526379
            ],
            [
              -63.176392,
              55.419441
            ],
            [
              -63.095829,
              55.349998
            ],
            [
              -63.176571,
              55.296539
            ],
            [
              -63.519451,
              55.183601
            ],
            [
              -63.566109,
              55.060268
            ],
            [
              -63.435268,
              54.973598
            ],
            [
              -63.66917,
              54.612209
            ],
            [
              -63.976189,
              54.626461
            ],
            [
              -64.325562,
              54.751942
            ],
            [
              -64.404327,
              54.75251
            ],
            [
              -64.552223,
              54.720539
            ],
            [
              -64.811661,
              54.749161
            ],
            [
              -65.121933,
              54.69471
            ],
            [
              -65.443878,
              54.745831
            ],
            [
              -65.689987,
              54.713329
            ],
            [
              -65.835281,
              54.831379
            ],
            [
              -65.858337,
              54.907768
            ],
            [
              -65.957779,
              54.92944
            ],
            [
              -66.035004,
              54.90971
            ],
            [
              -66.23999,
              54.99194
            ],
            [
              -66.576401,
              55.250549
            ],
            [
              -66.681953,
              55.25333
            ],
            [
              -66.688049,
              55.301102
            ],
            [
              -66.77861,
              55.338879
            ],
            [
              -66.844162,
              55.326099
            ],
            [
              -66.688889,
              55.204159
            ],
            [
              -66.740829,
              55.17749
            ],
            [
              -66.765839,
              55.105549
            ],
            [
              -66.65976,
              54.978031
            ],
            [
              -66.732773,
              54.94833
            ],
            [
              -66.678329,
              54.894161
            ],
            [
              -66.605003,
              54.790829
            ],
            [
              -66.712219,
              54.78194
            ],
            [
              -66.698608,
              54.742222
            ],
            [
              -66.738602,
              54.732769
            ],
            [
              -66.946953,
              54.801659
            ],
            [
              -67.028061,
              54.89777
            ],
            [
              -67.297783,
              55.008049
            ],
            [
              -67.273903,
              55.066669
            ],
            [
              -67.428329,
              55.07111
            ],
            [
              -67.453056,
              55.018879
            ],
            [
              -67.362213,
              54.87582
            ],
            [
              -67.263344,
              54.825272
            ],
            [
              -67.30777,
              54.783878
            ],
            [
              -67.184998,
              54.71944
            ],
            [
              -67.213898,
              54.66888
            ],
            [
              -67.036392,
              54.621658
            ],
            [
              -67.219452,
              54.607498
            ],
            [
              -67.305557,
              54.504169
            ],
            [
              -67.492493,
              54.58194
            ],
            [
              -67.601387,
              54.56638
            ],
            [
              -67.633057,
              54.479988
            ],
            [
              -67.586121,
              54.4175
            ],
            [
              -67.59584,
              54.365269
            ],
            [
              -67.49472,
              54.314442
            ],
            [
              -67.531677,
              54.248878
            ],
            [
              -67.485001,
              54.234161
            ],
            [
              -67.637222,
              54.174999
            ],
            [
              -67.781952,
              54.063881
            ],
            [
              -67.738441,
              54.000011
            ],
            [
              -67.609161,
              53.928051
            ],
            [
              -67.588058,
              53.85722
            ],
            [
              -67.430557,
              53.778332
            ],
            [
              -67.491943,
              53.603882
            ],
            [
              -67.141953,
              53.542221
            ],
            [
              -67.128883,
              53.464161
            ],
            [
              -67.068619,
              53.4286
            ],
            [
              -67.06778,
              53.384159
            ],
            [
              -66.987503,
              53.403599
            ],
            [
              -66.910004,
              53.340271
            ],
            [
              -66.991669,
              53.195271
            ],
            [
              -66.948608,
              53.109718
            ],
            [
              -66.979446,
              53.027771
            ],
            [
              -67.078056,
              52.94804
            ],
            [
              -67.058327,
              52.804989
            ],
            [
              -67.092773,
              52.72971
            ],
            [
              -67.067497,
              52.699429
            ],
            [
              -66.891388,
              52.673321
            ],
            [
              -66.87471,
              52.747768
            ],
            [
              -66.813316,
              52.66415
            ],
            [
              -66.66806,
              52.725819
            ],
            [
              -66.648064,
              52.94582
            ],
            [
              -66.525284,
              52.943321
            ],
            [
              -66.476936,
              53.034161
            ],
            [
              -66.345551,
              52.974159
            ],
            [
              -66.26973,
              52.877491
            ],
            [
              -66.378883,
              52.836109
            ],
            [
              -66.328339,
              52.736938
            ],
            [
              -66.433884,
              52.706379
            ],
            [
              -66.43222,
              52.611382
            ],
            [
              -66.373611,
              52.5425
            ],
            [
              -66.446663,
              52.530819
            ],
            [
              -66.358047,
              52.406651
            ],
            [
              -66.458618,
              52.3661
            ],
            [
              -66.486656,
              52.312489
            ],
            [
              -66.438889,
              52.200829
            ],
            [
              -66.335564,
              52.136379
            ],
            [
              -66.303879,
              52.222759
            ],
            [
              -66.319733,
              52.289162
            ],
            [
              -66.275558,
              52.311661
            ],
            [
              -66.173889,
              52.220539
            ],
            [
              -66.068069,
              52.216381
            ],
            [
              -66.063889,
              52.11832
            ],
            [
              -65.916946,
              52.050831
            ],
            [
              -65.801666,
              52.04277
            ],
            [
              -65.799438,
              52.095539
            ],
            [
              -65.758621,
              52.111382
            ],
            [
              -65.56723,
              52.046661
            ],
            [
              -65.538597,
              52.123878
            ],
            [
              -65.453613,
              52.20805
            ],
            [
              -65.108612,
              52.17083
            ],
            [
              -64.853333,
              52.021931
            ],
            [
              -64.84584,
              51.93082
            ],
            [
              -64.587784,
              51.82888
            ],
            [
              -64.593887,
              51.796101
            ],
            [
              -64.718063,
              51.747768
            ],
            [
              -64.703056,
              51.69276
            ],
            [
              -64.604172,
              51.642769
            ],
            [
              -64.581123,
              51.55777
            ],
            [
              -64.44249,
              51.66777
            ],
            [
              -64.264183,
              51.739719
            ],
            [
              -64.357224,
              51.850552
            ],
            [
              -64.317497,
              51.903599
            ],
            [
              -64.330841,
              51.965271
            ],
            [
              -64.274437,
              52.00333
            ],
            [
              -64.293327,
              52.046391
            ],
            [
              -64.257782,
              52.12804
            ],
            [
              -64.115829,
              52.151379
            ],
            [
              -64.131378,
              52.28138
            ],
            [
              -64.08168,
              52.37249
            ],
            [
              -64.208893,
              52.548882
            ],
            [
              -64.175278,
              52.60944
            ],
            [
              -64.202217,
              52.672218
            ],
            [
              -64.156662,
              52.738331
            ],
            [
              -64.201401,
              52.790829
            ],
            [
              -64.172501,
              52.85083
            ],
            [
              -64.080841,
              52.881378
            ],
            [
              -63.959999,
              52.867771
            ],
            [
              -63.982769,
              52.933601
            ],
            [
              -63.919449,
              52.97694
            ],
            [
              -63.923611,
              53.077221
            ],
            [
              -63.693329,
              53.113609
            ],
            [
              -63.634731,
              53.094151
            ],
            [
              -63.62611,
              53.042221
            ],
            [
              -63.54195,
              52.970829
            ],
            [
              -63.595829,
              52.770771
            ],
            [
              -63.423061,
              52.707211
            ],
            [
              -63.377781,
              52.650829
            ],
            [
              -63.839722,
              52.61805
            ],
            [
              -63.971378,
              52.570831
            ],
            [
              -64.08168,
              52.470539
            ],
            [
              -64.027496,
              52.433048
            ],
            [
              -63.976391,
              52.458599
            ],
            [
              -63.930279,
              52.41777
            ],
            [
              -63.92445,
              52.383881
            ],
            [
              -64.013062,
              52.387211
            ],
            [
              -63.987499,
              52.362499
            ],
            [
              -63.941269,
              52.264
            ],
            [
              -63.65535,
              52.124359
            ],
            [
              -63.637779,
              52.039989
            ],
            [
              -63.824718,
              52.070549
            ],
            [
              -63.75111,
              51.999722
            ],
            [
              -57.367771,
              51.999439
            ],
            [
              -57.101109,
              52
            ],
            [
              -57.10424,
              51.41267
            ]
          ]
        ],
        "nom": "Québec",
        "iso_code": "QC"
      },
      {
        "name": "Alberta",
        "id": 52,
        "coordinates": [
          [
            [
              -114.060303,
              49
            ],
            [
              -114.035301,
              49.031109
            ],
            [
              -114.1567,
              49.103611
            ],
            [
              -114.154198,
              49.148048
            ],
            [
              -114.225304,
              49.188599
            ],
            [
              -114.372803,
              49.204159
            ],
            [
              -114.376701,
              49.25639
            ],
            [
              -114.429398,
              49.265549
            ],
            [
              -114.476097,
              49.341381
            ],
            [
              -114.576401,
              49.389992
            ],
            [
              -114.599998,
              49.442211
            ],
            [
              -114.576401,
              49.556099
            ],
            [
              -114.6894,
              49.547218
            ],
            [
              -114.738297,
              49.608601
            ],
            [
              -114.679703,
              49.63221
            ],
            [
              -114.636101,
              49.727772
            ],
            [
              -114.626099,
              49.797218
            ],
            [
              -114.6875,
              49.913601
            ],
            [
              -114.684998,
              49.9575
            ],
            [
              -114.643303,
              49.977772
            ],
            [
              -114.658897,
              50.065269
            ],
            [
              -114.724197,
              50.125549
            ],
            [
              -114.754997,
              50.288891
            ],
            [
              -114.793602,
              50.334721
            ],
            [
              -114.770302,
              50.35833
            ],
            [
              -114.8367,
              50.39666
            ],
            [
              -115.016998,
              50.575001
            ],
            [
              -115.1017,
              50.588329
            ],
            [
              -115.218903,
              50.54694
            ],
            [
              -115.320602,
              50.638599
            ],
            [
              -115.283096,
              50.658878
            ],
            [
              -115.311699,
              50.706661
            ],
            [
              -115.346703,
              50.732208
            ],
            [
              -115.395798,
              50.71693
            ],
            [
              -115.419197,
              50.754169
            ],
            [
              -115.527802,
              50.791939
            ],
            [
              -115.647202,
              50.85471
            ],
            [
              -115.571999,
              50.90332
            ],
            [
              -115.618599,
              50.969151
            ],
            [
              -115.759697,
              51.035831
            ],
            [
              -115.776703,
              51.073608
            ],
            [
              -115.957802,
              51.103882
            ],
            [
              -116.016098,
              51.138329
            ],
            [
              -116.0261,
              51.21693
            ],
            [
              -116.060501,
              51.24638
            ],
            [
              -116.262199,
              51.316669
            ],
            [
              -116.306099,
              51.388329
            ],
            [
              -116.305,
              51.461658
            ],
            [
              -116.3797,
              51.488602
            ],
            [
              -116.399696,
              51.54472
            ],
            [
              -116.466103,
              51.56638
            ],
            [
              -116.488098,
              51.613049
            ],
            [
              -116.595001,
              51.65749
            ],
            [
              -116.597504,
              51.716381
            ],
            [
              -116.658897,
              51.796661
            ],
            [
              -116.746101,
              51.79916
            ],
            [
              -116.815804,
              51.750549
            ],
            [
              -116.812798,
              51.715271
            ],
            [
              -116.919197,
              51.70916
            ],
            [
              -117.0392,
              51.908039
            ],
            [
              -117.269997,
              52.044998
            ],
            [
              -117.318901,
              52.181938
            ],
            [
              -117.363098,
              52.138599
            ],
            [
              -117.600304,
              52.13055
            ],
            [
              -117.6875,
              52.196098
            ],
            [
              -117.760002,
              52.200829
            ],
            [
              -117.829697,
              52.27277
            ],
            [
              -117.7314,
              52.344151
            ],
            [
              -117.7547,
              52.403599
            ],
            [
              -117.902802,
              52.424999
            ],
            [
              -118.0056,
              52.48777
            ],
            [
              -118.047501,
              52.404991
            ],
            [
              -118.145798,
              52.39888
            ],
            [
              -118.223099,
              52.365551
            ],
            [
              -118.259697,
              52.442211
            ],
            [
              -118.208298,
              52.48082
            ],
            [
              -118.286903,
              52.527771
            ],
            [
              -118.2817,
              52.565269
            ],
            [
              -118.349998,
              52.61805
            ],
            [
              -118.305,
              52.674709
            ],
            [
              -118.3461,
              52.74332
            ],
            [
              -118.428902,
              52.78611
            ],
            [
              -118.415604,
              52.83638
            ],
            [
              -118.491402,
              52.89777
            ],
            [
              -118.625,
              52.884159
            ],
            [
              -118.679703,
              52.970539
            ],
            [
              -118.669998,
              53.034439
            ],
            [
              -118.781097,
              53.05582
            ],
            [
              -118.759697,
              53.12471
            ],
            [
              -118.9786,
              53.237209
            ],
            [
              -119.012199,
              53.223049
            ],
            [
              -119.013603,
              53.144711
            ],
            [
              -119.045502,
              53.137772
            ],
            [
              -119.265602,
              53.196659
            ],
            [
              -119.348099,
              53.275269
            ],
            [
              -119.385803,
              53.36166
            ],
            [
              -119.606102,
              53.37804
            ],
            [
              -119.738098,
              53.395
            ],
            [
              -119.778099,
              53.446941
            ],
            [
              -119.842499,
              53.518051
            ],
            [
              -119.902496,
              53.520271
            ],
            [
              -119.882797,
              53.560268
            ],
            [
              -119.932198,
              53.60722
            ],
            [
              -119.7686,
              53.603321
            ],
            [
              -119.735298,
              53.634991
            ],
            [
              -119.810303,
              53.707771
            ],
            [
              -119.921097,
              53.718319
            ],
            [
              -119.904701,
              53.778599
            ],
            [
              -120.000801,
              53.808041
            ],
            [
              -120.000801,
              54.005871
            ],
            [
              -119.982903,
              59.988522
            ],
            [
              -110.0009,
              59.995281
            ],
            [
              -110.000504,
              59.309391
            ],
            [
              -110.000801,
              58.968639
            ],
            [
              -110.0009,
              56.36282
            ],
            [
              -110.000603,
              56.276039
            ],
            [
              -110.000801,
              54.769001
            ],
            [
              -110.000603,
              54.624401
            ],
            [
              -110.000702,
              54.44952
            ],
            [
              -110.000504,
              54.00573
            ],
            [
              -110.001404,
              49
            ],
            [
              -110.75,
              49
            ],
            [
              -111.281898,
              49
            ],
            [
              -112.1875,
              49
            ],
            [
              -114.060303,
              49
            ]
          ]
        ],
        "nom": "Alberta",
        "iso_code": "AB"
      },
      {
        "name": "Manitoba",
        "id": 54,
        "coordinates": [
          [
            [
              -102.001701,
              59.99992
            ],
            [
              -100.039001,
              59.999722
            ],
            [
              -99.626953,
              59.999729
            ],
            [
              -94.800491,
              59.999569
            ],
            [
              -94.82251,
              59.954159
            ],
            [
              -94.768341,
              59.78249
            ],
            [
              -94.819168,
              59.636379
            ],
            [
              -94.761673,
              59.570271
            ],
            [
              -94.793877,
              59.541939
            ],
            [
              -94.727783,
              59.446659
            ],
            [
              -94.710831,
              59.37138
            ],
            [
              -94.736656,
              59.34222
            ],
            [
              -94.658623,
              59.350552
            ],
            [
              -94.775009,
              59.293331
            ],
            [
              -94.794724,
              59.087212
            ],
            [
              -94.934433,
              59.084991
            ],
            [
              -94.996109,
              59.053879
            ],
            [
              -94.804443,
              59.061378
            ],
            [
              -94.804718,
              59.014721
            ],
            [
              -94.679993,
              58.971371
            ],
            [
              -94.74527,
              58.83305
            ],
            [
              -94.675827,
              58.87804
            ],
            [
              -94.586403,
              58.874989
            ],
            [
              -94.482224,
              58.8111
            ],
            [
              -94.421661,
              58.716381
            ],
            [
              -94.32695,
              58.721661
            ],
            [
              -94.276947,
              58.780548
            ],
            [
              -94.203056,
              58.804161
            ],
            [
              -94.239723,
              58.738602
            ],
            [
              -94.202499,
              58.67944
            ],
            [
              -94.271667,
              58.633881
            ],
            [
              -94.306381,
              58.563881
            ],
            [
              -94.287216,
              58.438042
            ],
            [
              -94.328613,
              58.39333
            ],
            [
              -94.363327,
              58.21888
            ],
            [
              -94.228607,
              58.396099
            ],
            [
              -94.246109,
              58.586651
            ],
            [
              -94.140556,
              58.73333
            ],
            [
              -94.177223,
              58.771381
            ],
            [
              -93.501953,
              58.771099
            ],
            [
              -93.46666,
              58.719711
            ],
            [
              -93.400284,
              58.700272
            ],
            [
              -93.319168,
              58.75861
            ],
            [
              -93.152222,
              58.73777
            ],
            [
              -93.118057,
              58.508888
            ],
            [
              -92.931107,
              58.211658
            ],
            [
              -92.852493,
              58.16415
            ],
            [
              -92.868881,
              58.143051
            ],
            [
              -92.803329,
              58.057209
            ],
            [
              -92.749161,
              57.860279
            ],
            [
              -92.781113,
              57.834721
            ],
            [
              -92.728333,
              57.820549
            ],
            [
              -92.600281,
              57.648609
            ],
            [
              -92.554169,
              57.539719
            ],
            [
              -92.450844,
              57.44249
            ],
            [
              -92.418877,
              57.33749
            ],
            [
              -92.441101,
              57.230549
            ],
            [
              -92.576401,
              57.056938
            ],
            [
              -92.715843,
              56.947491
            ],
            [
              -92.876099,
              56.90749
            ],
            [
              -92.691101,
              56.933601
            ],
            [
              -92.443047,
              57.04472
            ],
            [
              -92.212219,
              57.058041
            ],
            [
              -92.4664,
              56.932499
            ],
            [
              -92.180283,
              57.030819
            ],
            [
              -91.001678,
              57.261379
            ],
            [
              -90.451111,
              57.193878
            ],
            [
              -90,
              57.01683
            ],
            [
              -89.179642,
              56.86895
            ],
            [
              -89.305557,
              56.627209
            ],
            [
              -90.843887,
              55.66777
            ],
            [
              -92.388641,
              54.62793
            ],
            [
              -92.476593,
              54.56694
            ],
            [
              -92.60231,
              54.478489
            ],
            [
              -92.698143,
              54.410931
            ],
            [
              -93.272087,
              53.998859
            ],
            [
              -93.652222,
              53.72221
            ],
            [
              -95.150284,
              52.826389
            ],
            [
              -95.154327,
              49.576729
            ],
            [
              -95.153877,
              49.452019
            ],
            [
              -95.151627,
              49.371731
            ],
            [
              -95.157516,
              49
            ],
            [
              -95.276421,
              49
            ],
            [
              -96.406616,
              49
            ],
            [
              -97.229103,
              49
            ],
            [
              -97.9375,
              49
            ],
            [
              -99,
              49
            ],
            [
              -99.53315,
              49
            ],
            [
              -100.1875,
              49
            ],
            [
              -101.367203,
              49
            ],
            [
              -101.3675,
              49.17749
            ],
            [
              -101.400299,
              49.177761
            ],
            [
              -101.400299,
              49.533051
            ],
            [
              -101.425598,
              49.5341
            ],
            [
              -101.426102,
              49.888599
            ],
            [
              -101.453598,
              49.888599
            ],
            [
              -101.4533,
              50.241661
            ],
            [
              -101.483597,
              50.24192
            ],
            [
              -101.484703,
              50.589989
            ],
            [
              -101.514999,
              50.595539
            ],
            [
              -101.514702,
              50.949429
            ],
            [
              -101.544998,
              50.950001
            ],
            [
              -101.567001,
              51.970829
            ],
            [
              -101.613297,
              51.96999
            ],
            [
              -101.612503,
              52.32222
            ],
            [
              -101.639503,
              52.32222
            ],
            [
              -101.639999,
              52.671101
            ],
            [
              -101.672203,
              52.67083
            ],
            [
              -101.700798,
              53.368881
            ],
            [
              -101.742798,
              53.369438
            ],
            [
              -101.772102,
              54.00256
            ],
            [
              -101.7714,
              54.06638
            ],
            [
              -101.810799,
              54.066101
            ],
            [
              -101.811401,
              54.41666
            ],
            [
              -101.851898,
              54.41721
            ],
            [
              -101.851799,
              54.53513
            ],
            [
              -101.851402,
              54.766392
            ],
            [
              -101.883904,
              54.76749
            ],
            [
              -101.883598,
              55.116661
            ],
            [
              -101.925598,
              55.117489
            ],
            [
              -101.925797,
              55.466381
            ],
            [
              -101.967201,
              55.467468
            ],
            [
              -101.967201,
              55.67194
            ],
            [
              -101.967598,
              55.76067
            ],
            [
              -102.0009,
              55.830151
            ],
            [
              -102.001404,
              56.221241
            ],
            [
              -102.000999,
              56.366421
            ],
            [
              -102.001099,
              57.013351
            ],
            [
              -102.001297,
              57.25964
            ],
            [
              -102.0009,
              57.49725
            ],
            [
              -102.001297,
              57.868439
            ],
            [
              -102.001297,
              58.034302
            ],
            [
              -102.001503,
              58.12854
            ],
            [
              -102.0009,
              59.918839
            ],
            [
              -102.001701,
              59.99992
            ]
          ]
        ],
        "nom": "Manitoba",
        "iso_code": "MB"
      },
      {
        "name": "Nova Scotia",
        "id": 57,
        "coordinates": [
          [
            [
              -60.910278,
              45.546101
            ],
            [
              -61.000278,
              45.45583
            ],
            [
              -61.021111,
              45.46888
            ],
            [
              -60.99889,
              45.49361
            ],
            [
              -61.072231,
              45.502491
            ],
            [
              -61.108341,
              45.540829
            ],
            [
              -61.01722,
              45.575272
            ],
            [
              -60.90028,
              45.57555
            ],
            [
              -60.878609,
              45.55582
            ],
            [
              -60.910278,
              45.546101
            ]
          ],
          [
            [
              -66.764717,
              44.801102
            ],
            [
              -66.734734,
              44.72916
            ],
            [
              -66.761124,
              44.676102
            ],
            [
              -66.900284,
              44.613331
            ],
            [
              -66.858612,
              44.74305
            ],
            [
              -66.764717,
              44.801102
            ]
          ],
          [
            [
              -60.59639,
              46.196098
            ],
            [
              -60.46806,
              46.316669
            ],
            [
              -60.416389,
              46.328049
            ],
            [
              -60.419998,
              46.279991
            ],
            [
              -60.64584,
              46.106098
            ],
            [
              -60.870281,
              46.051659
            ],
            [
              -60.84972,
              46.07888
            ],
            [
              -60.880001,
              46.079159
            ],
            [
              -60.97472,
              45.988331
            ],
            [
              -61.11306,
              45.955269
            ],
            [
              -61.125561,
              45.923611
            ],
            [
              -60.993328,
              45.94138
            ],
            [
              -60.875561,
              46.036942
            ],
            [
              -60.734169,
              46.047489
            ],
            [
              -60.80389,
              45.9786
            ],
            [
              -60.807499,
              45.93111
            ],
            [
              -61.066391,
              45.893879
            ],
            [
              -61.043892,
              45.88472
            ],
            [
              -61.08778,
              45.847488
            ],
            [
              -60.96806,
              45.883331
            ],
            [
              -60.917782,
              45.847488
            ],
            [
              -60.97028,
              45.856659
            ],
            [
              -60.940552,
              45.821659
            ],
            [
              -61.01083,
              45.820271
            ],
            [
              -61.118061,
              45.763611
            ],
            [
              -61.142502,
              45.696381
            ],
            [
              -61.070839,
              45.689991
            ],
            [
              -60.91444,
              45.753609
            ],
            [
              -60.797779,
              45.707211
            ],
            [
              -60.844158,
              45.64138
            ],
            [
              -60.76112,
              45.69054
            ],
            [
              -60.733608,
              45.67416
            ],
            [
              -60.74889,
              45.74194
            ],
            [
              -60.719719,
              45.78833
            ],
            [
              -60.39584,
              45.996101
            ],
            [
              -60.686111,
              45.88694
            ],
            [
              -60.785278,
              45.946381
            ],
            [
              -60.305,
              46.210548
            ],
            [
              -60.29528,
              46.232208
            ],
            [
              -60.372768,
              46.22332
            ],
            [
              -60.547501,
              46.10416
            ],
            [
              -60.656109,
              46.067768
            ],
            [
              -60.347778,
              46.310551
            ],
            [
              -60.285278,
              46.321381
            ],
            [
              -60.203331,
              46.235271
            ],
            [
              -60.28944,
              46.154709
            ],
            [
              -60.24028,
              46.173611
            ],
            [
              -60.199169,
              46.143608
            ],
            [
              -60.194439,
              46.20277
            ],
            [
              -60.131939,
              46.2486
            ],
            [
              -60.041672,
              46.227772
            ],
            [
              -60.076389,
              46.199162
            ],
            [
              -59.95055,
              46.201389
            ],
            [
              -59.94138,
              46.151661
            ],
            [
              -59.810551,
              46.162769
            ],
            [
              -59.88805,
              46.088879
            ],
            [
              -59.809719,
              46.10638
            ],
            [
              -59.959999,
              46.02055
            ],
            [
              -59.82362,
              45.99749
            ],
            [
              -59.818611,
              45.929161
            ],
            [
              -59.973331,
              45.901379
            ],
            [
              -59.99416,
              45.8661
            ],
            [
              -60.13028,
              45.867771
            ],
            [
              -60.160549,
              45.835548
            ],
            [
              -60.07362,
              45.796391
            ],
            [
              -60.17445,
              45.763889
            ],
            [
              -60.233891,
              45.701099
            ],
            [
              -60.673061,
              45.564159
            ],
            [
              -60.853889,
              45.603882
            ],
            [
              -60.871498,
              45.639721
            ],
            [
              -61.168331,
              45.55138
            ],
            [
              -61.14584,
              45.579441
            ],
            [
              -61.21722,
              45.606941
            ],
            [
              -61.285,
              45.5425
            ],
            [
              -61.45472,
              45.705551
            ],
            [
              -61.547501,
              46.025551
            ],
            [
              -61.482769,
              46.066669
            ],
            [
              -61.438889,
              46.159431
            ],
            [
              -61.283329,
              46.23888
            ],
            [
              -61.089439,
              46.45805
            ],
            [
              -61.033058,
              46.561661
            ],
            [
              -61.058048,
              46.5961
            ],
            [
              -61.034451,
              46.628601
            ],
            [
              -61.013889,
              46.59166
            ],
            [
              -60.892231,
              46.77388
            ],
            [
              -60.704441,
              46.89138
            ],
            [
              -60.640282,
              47
            ],
            [
              -60.591942,
              47.033329
            ],
            [
              -60.488609,
              46.99416
            ],
            [
              -60.39806,
              47.023319
            ],
            [
              -60.384998,
              46.996101
            ],
            [
              -60.498051,
              46.89666
            ],
            [
              -60.45084,
              46.89777
            ],
            [
              -60.445271,
              46.86166
            ],
            [
              -60.326111,
              46.86832
            ],
            [
              -60.302219,
              46.83749
            ],
            [
              -60.346951,
              46.685551
            ],
            [
              -60.396111,
              46.665821
            ],
            [
              -60.35445,
              46.648609
            ],
            [
              -60.384731,
              46.635551
            ],
            [
              -60.345001,
              46.60638
            ],
            [
              -60.589439,
              46.255001
            ],
            [
              -60.59639,
              46.196098
            ]
          ],
          [
            [
              -64.042793,
              45.991901
            ],
            [
              -63.913059,
              45.979988
            ],
            [
              -63.80389,
              45.88583
            ],
            [
              -63.725769,
              45.869961
            ],
            [
              -63.749168,
              45.835819
            ],
            [
              -63.682499,
              45.848598
            ],
            [
              -63.664162,
              45.815269
            ],
            [
              -63.66222,
              45.870541
            ],
            [
              -63.482498,
              45.877209
            ],
            [
              -63.403881,
              45.854439
            ],
            [
              -63.482769,
              45.858051
            ],
            [
              -63.428612,
              45.823608
            ],
            [
              -63.51556,
              45.807209
            ],
            [
              -63.235001,
              45.808601
            ],
            [
              -63.380001,
              45.766109
            ],
            [
              -63.294449,
              45.721931
            ],
            [
              -63.186939,
              45.772221
            ],
            [
              -63.18972,
              45.73444
            ],
            [
              -63.065552,
              45.75555
            ],
            [
              -63.10778,
              45.80888
            ],
            [
              -62.672779,
              45.767769
            ],
            [
              -62.753891,
              45.758049
            ],
            [
              -62.63612,
              45.714439
            ],
            [
              -62.775002,
              45.671101
            ],
            [
              -62.791111,
              45.638329
            ],
            [
              -62.743061,
              45.654991
            ],
            [
              -62.743889,
              45.611111
            ],
            [
              -62.703892,
              45.652489
            ],
            [
              -62.650829,
              45.62804
            ],
            [
              -62.675282,
              45.671379
            ],
            [
              -62.60944,
              45.684158
            ],
            [
              -62.47361,
              45.655548
            ],
            [
              -62.509731,
              45.6161
            ],
            [
              -62.486389,
              45.603611
            ],
            [
              -62.356949,
              45.67194
            ],
            [
              -62.424721,
              45.673321
            ],
            [
              -62.250278,
              45.708328
            ],
            [
              -61.9175,
              45.885551
            ],
            [
              -61.88139,
              45.686649
            ],
            [
              -61.724998,
              45.620831
            ],
            [
              -61.6325,
              45.637772
            ],
            [
              -61.65556,
              45.624161
            ],
            [
              -61.618061,
              45.61055
            ],
            [
              -61.564999,
              45.673882
            ],
            [
              -61.471378,
              45.682499
            ],
            [
              -61.332779,
              45.540829
            ],
            [
              -61.22916,
              45.501942
            ],
            [
              -61.249729,
              45.447491
            ],
            [
              -61.48167,
              45.367771
            ],
            [
              -61.457218,
              45.343609
            ],
            [
              -61.257778,
              45.33416
            ],
            [
              -60.970551,
              45.321659
            ],
            [
              -60.97028,
              45.269711
            ],
            [
              -61.036388,
              45.293049
            ],
            [
              -61.02306,
              45.25639
            ],
            [
              -61.077782,
              45.21944
            ],
            [
              -61.139721,
              45.210819
            ],
            [
              -61.121941,
              45.27166
            ],
            [
              -61.171391,
              45.255268
            ],
            [
              -61.1675,
              45.20388
            ],
            [
              -61.204441,
              45.246101
            ],
            [
              -61.268059,
              45.254711
            ],
            [
              -61.360001,
              45.209991
            ],
            [
              -61.349442,
              45.174438
            ],
            [
              -61.458611,
              45.144711
            ],
            [
              -61.595829,
              45.143051
            ],
            [
              -61.69611,
              45.177769
            ],
            [
              -61.63805,
              45.12027
            ],
            [
              -61.672501,
              45.114159
            ],
            [
              -61.642231,
              45.08416
            ],
            [
              -61.819729,
              45.104439
            ],
            [
              -61.81361,
              45.073879
            ],
            [
              -61.85944,
              45.075001
            ],
            [
              -61.834999,
              45.051102
            ],
            [
              -61.88306,
              45.024441
            ],
            [
              -61.932499,
              45.046391
            ],
            [
              -62.051109,
              45.00639
            ],
            [
              -61.98138,
              44.983051
            ],
            [
              -62.240002,
              44.95805
            ],
            [
              -62.337219,
              44.921101
            ],
            [
              -62.338612,
              44.888599
            ],
            [
              -62.381111,
              44.913601
            ],
            [
              -62.457218,
              44.82972
            ],
            [
              -62.476391,
              44.89555
            ],
            [
              -62.515011,
              44.85194
            ],
            [
              -62.551392,
              44.861382
            ],
            [
              -62.535561,
              44.798611
            ],
            [
              -62.61195,
              44.839161
            ],
            [
              -62.65361,
              44.791382
            ],
            [
              -62.801109,
              44.778599
            ],
            [
              -62.825562,
              44.744438
            ],
            [
              -62.796108,
              44.723881
            ],
            [
              -62.840279,
              44.711109
            ],
            [
              -62.928612,
              44.733879
            ],
            [
              -62.99778,
              44.703609
            ],
            [
              -63.003059,
              44.765831
            ],
            [
              -63.055561,
              44.77277
            ],
            [
              -63.011951,
              44.70805
            ],
            [
              -63.054722,
              44.673321
            ],
            [
              -63.118889,
              44.786659
            ],
            [
              -63.14278,
              44.688599
            ],
            [
              -63.163891,
              44.725552
            ],
            [
              -63.212219,
              44.673611
            ],
            [
              -63.245831,
              44.732491
            ],
            [
              -63.240002,
              44.676941
            ],
            [
              -63.283058,
              44.627209
            ],
            [
              -63.40361,
              44.641659
            ],
            [
              -63.406391,
              44.683331
            ],
            [
              -63.451389,
              44.672218
            ],
            [
              -63.41222,
              44.63221
            ],
            [
              -63.439991,
              44.590832
            ],
            [
              -63.658329,
              44.715
            ],
            [
              -63.64056,
              44.67305
            ],
            [
              -63.562222,
              44.63221
            ],
            [
              -63.520279,
              44.507771
            ],
            [
              -63.570839,
              44.461941
            ],
            [
              -63.59972,
              44.483601
            ],
            [
              -63.631111,
              44.435822
            ],
            [
              -63.633888,
              44.473881
            ],
            [
              -63.713058,
              44.454159
            ],
            [
              -63.763618,
              44.49749
            ],
            [
              -63.79834,
              44.46944
            ],
            [
              -63.783329,
              44.527489
            ],
            [
              -63.8675,
              44.489429
            ],
            [
              -63.932499,
              44.513329
            ],
            [
              -63.937771,
              44.621658
            ],
            [
              -63.887218,
              44.69054
            ],
            [
              -64.059433,
              44.638329
            ],
            [
              -64.063889,
              44.590549
            ],
            [
              -64.009171,
              44.513329
            ],
            [
              -64.046951,
              44.518879
            ],
            [
              -64.083069,
              44.46666
            ],
            [
              -64.121658,
              44.485271
            ],
            [
              -64.121658,
              44.544159
            ],
            [
              -64.170273,
              44.586109
            ],
            [
              -64.224442,
              44.533878
            ],
            [
              -64.301941,
              44.561378
            ],
            [
              -64.324448,
              44.47332
            ],
            [
              -64.297501,
              44.463879
            ],
            [
              -64.355827,
              44.44276
            ],
            [
              -64.330841,
              44.408878
            ],
            [
              -64.256958,
              44.408039
            ],
            [
              -64.305267,
              44.376099
            ],
            [
              -64.211403,
              44.361938
            ],
            [
              -64.346123,
              44.35722
            ],
            [
              -64.335564,
              44.32666
            ],
            [
              -64.260559,
              44.324169
            ],
            [
              -64.289993,
              44.301929
            ],
            [
              -64.239441,
              44.294159
            ],
            [
              -64.258057,
              44.269989
            ],
            [
              -64.319458,
              44.264721
            ],
            [
              -64.430557,
              44.338039
            ],
            [
              -64.358612,
              44.28833
            ],
            [
              -64.363617,
              44.25666
            ],
            [
              -64.428047,
              44.228321
            ],
            [
              -64.494164,
              44.14027
            ],
            [
              -64.604172,
              44.156651
            ],
            [
              -64.616386,
              44.133049
            ],
            [
              -64.543327,
              44.11639
            ],
            [
              -64.556381,
              44.077221
            ],
            [
              -64.715561,
              44.052219
            ],
            [
              -64.663063,
              44.02388
            ],
            [
              -64.666397,
              43.990269
            ],
            [
              -64.845284,
              43.92194
            ],
            [
              -64.785553,
              43.867771
            ],
            [
              -64.820847,
              43.864719
            ],
            [
              -64.835007,
              43.825001
            ],
            [
              -64.907784,
              43.87833
            ],
            [
              -64.880547,
              43.833881
            ],
            [
              -64.906387,
              43.800541
            ],
            [
              -64.96611,
              43.872761
            ],
            [
              -64.928596,
              43.802219
            ],
            [
              -64.965286,
              43.751659
            ],
            [
              -65.040283,
              43.82666
            ],
            [
              -65.008347,
              43.746941
            ],
            [
              -65.025558,
              43.70805
            ],
            [
              -65.074448,
              43.698879
            ],
            [
              -65.083618,
              43.752491
            ],
            [
              -65.122498,
              43.69305
            ],
            [
              -65.12471,
              43.670551
            ],
            [
              -65.152786,
              43.68721
            ],
            [
              -65.138901,
              43.74416
            ],
            [
              -65.180557,
              43.706661
            ],
            [
              -65.237213,
              43.798611
            ],
            [
              -65.256668,
              43.770828
            ],
            [
              -65.223618,
              43.7075
            ],
            [
              -65.285004,
              43.66972
            ],
            [
              -65.32695,
              43.697491
            ],
            [
              -65.332497,
              43.7686
            ],
            [
              -65.337784,
              43.73555
            ],
            [
              -65.368332,
              43.734989
            ],
            [
              -65.325844,
              43.674999
            ],
            [
              -65.360001,
              43.60527
            ],
            [
              -65.338608,
              43.549438
            ],
            [
              -65.450287,
              43.59972
            ],
            [
              -65.404449,
              43.502781
            ],
            [
              -65.449158,
              43.559719
            ],
            [
              -65.486938,
              43.5186
            ],
            [
              -65.481377,
              43.464439
            ],
            [
              -65.568619,
              43.570271
            ],
            [
              -65.657501,
              43.490269
            ],
            [
              -65.72583,
              43.502491
            ],
            [
              -65.739166,
              43.573051
            ],
            [
              -65.755569,
              43.54916
            ],
            [
              -65.783333,
              43.57111
            ],
            [
              -65.775558,
              43.68832
            ],
            [
              -65.793327,
              43.61166
            ],
            [
              -65.818336,
              43.630268
            ],
            [
              -65.860817,
              43.80444
            ],
            [
              -65.87471,
              43.763611
            ],
            [
              -65.93277,
              43.827221
            ],
            [
              -65.962784,
              43.774712
            ],
            [
              -65.982224,
              43.843319
            ],
            [
              -66.004997,
              43.840549
            ],
            [
              -65.965843,
              43.73082
            ],
            [
              -66.020554,
              43.691101
            ],
            [
              -66.033623,
              43.740269
            ],
            [
              -66.062767,
              43.716381
            ],
            [
              -66.080841,
              43.768051
            ],
            [
              -66.123047,
              43.740551
            ],
            [
              -66.136948,
              43.799999
            ],
            [
              -66.108337,
              43.841099
            ],
            [
              -66.147232,
              43.824169
            ],
            [
              -66.16806,
              43.863049
            ],
            [
              -66.149727,
              44.011108
            ],
            [
              -66.21167,
              44.095268
            ],
            [
              -66.134453,
              44.22971
            ],
            [
              -66.118607,
              44.338039
            ],
            [
              -65.841377,
              44.574169
            ],
            [
              -65.943047,
              44.57777
            ],
            [
              -66.190552,
              44.383331
            ],
            [
              -66.191101,
              44.423321
            ],
            [
              -66.103058,
              44.5
            ],
            [
              -65.785004,
              44.69054
            ],
            [
              -65.753067,
              44.605831
            ],
            [
              -65.684998,
              44.61694
            ],
            [
              -65.428329,
              44.776661
            ],
            [
              -65.721657,
              44.66777
            ],
            [
              -65.75473,
              44.67749
            ],
            [
              -65.741096,
              44.707211
            ],
            [
              -64.861938,
              45.139721
            ],
            [
              -64.434433,
              45.2575
            ],
            [
              -64.393066,
              45.303322
            ],
            [
              -64.489166,
              45.33527
            ],
            [
              -64.327499,
              45.303322
            ],
            [
              -64.368881,
              45.197491
            ],
            [
              -64.353882,
              45.165272
            ],
            [
              -64.385834,
              45.14555
            ],
            [
              -64.359734,
              45.097488
            ],
            [
              -64.324387,
              45.139339
            ],
            [
              -64.244164,
              45.123878
            ],
            [
              -64.157227,
              45.056938
            ],
            [
              -64.156387,
              44.978321
            ],
            [
              -64.103882,
              44.992222
            ],
            [
              -64.098892,
              45.057499
            ],
            [
              -64.198608,
              45.11721
            ],
            [
              -64.152786,
              45.19276
            ],
            [
              -63.854721,
              45.269161
            ],
            [
              -63.78973,
              45.311661
            ],
            [
              -63.539169,
              45.333328
            ],
            [
              -63.470829,
              45.29055
            ],
            [
              -63.46666,
              45.326389
            ],
            [
              -63.360828,
              45.360821
            ],
            [
              -63.755562,
              45.398048
            ],
            [
              -63.883888,
              45.350552
            ],
            [
              -63.923611,
              45.388329
            ],
            [
              -64.069733,
              45.410271
            ],
            [
              -64.385559,
              45.3661
            ],
            [
              -64.563316,
              45.411659
            ],
            [
              -64.701401,
              45.368599
            ],
            [
              -64.698608,
              45.326099
            ],
            [
              -64.76001,
              45.28944
            ],
            [
              -64.763062,
              45.326099
            ],
            [
              -64.815826,
              45.348598
            ],
            [
              -64.93721,
              45.326939
            ],
            [
              -64.909157,
              45.418049
            ],
            [
              -64.458054,
              45.674438
            ],
            [
              -64.436661,
              45.73999
            ],
            [
              -64.375549,
              45.7925
            ],
            [
              -64.296661,
              45.8186
            ],
            [
              -64.335564,
              45.740829
            ],
            [
              -64.266663,
              45.761108
            ],
            [
              -64.30249,
              45.772491
            ],
            [
              -64.272926,
              45.835751
            ],
            [
              -64.154999,
              45.96888
            ],
            [
              -64.042793,
              45.991901
            ]
          ]
        ],
        "nom": "Nouvelle-Écosse",
        "iso_code": "NS"
      },
      {
        "name": "Ontario",
        "id": 58,
        "coordinates": [
          [
            [
              -79.547096,
              51.460129
            ],
            [
              -79.539436,
              51.191662
            ],
            [
              -79.537804,
              50.982368
            ],
            [
              -79.624969,
              51.0355
            ],
            [
              -79.678047,
              51.05471
            ],
            [
              -79.752228,
              51.184429
            ],
            [
              -79.680557,
              51.29805
            ],
            [
              -79.698036,
              51.401379
            ],
            [
              -79.547096,
              51.460129
            ]
          ],
          [
            [
              -79.537613,
              50.958599
            ],
            [
              -79.520683,
              48.764679
            ],
            [
              -79.519012,
              48.605289
            ],
            [
              -79.511322,
              47.565571
            ],
            [
              -79.576401,
              47.441929
            ],
            [
              -79.439438,
              47.26527
            ],
            [
              -79.443878,
              47.11055
            ],
            [
              -79.305557,
              46.94054
            ],
            [
              -79.155563,
              46.812489
            ],
            [
              -78.950562,
              46.511669
            ],
            [
              -78.75528,
              46.400539
            ],
            [
              -78.703613,
              46.337212
            ],
            [
              -77.680557,
              46.183601
            ],
            [
              -77.554993,
              46.151932
            ],
            [
              -77.321953,
              46.026661
            ],
            [
              -77.278877,
              45.937771
            ],
            [
              -77.206123,
              45.877209
            ],
            [
              -77.002792,
              45.790279
            ],
            [
              -76.920837,
              45.806381
            ],
            [
              -76.910553,
              45.889992
            ],
            [
              -76.804718,
              45.864719
            ],
            [
              -76.783333,
              45.76194
            ],
            [
              -76.710564,
              45.712769
            ],
            [
              -76.666397,
              45.590549
            ],
            [
              -76.607773,
              45.55249
            ],
            [
              -76.337784,
              45.45583
            ],
            [
              -76.184433,
              45.521099
            ],
            [
              -76.076683,
              45.513889
            ],
            [
              -75.847229,
              45.37249
            ],
            [
              -75.453339,
              45.523609
            ],
            [
              -74.875839,
              45.64159
            ],
            [
              -74.646118,
              45.635551
            ],
            [
              -74.396667,
              45.564159
            ],
            [
              -74.479172,
              45.316669
            ],
            [
              -74.324722,
              45.201099
            ],
            [
              -74.433609,
              45.149441
            ],
            [
              -74.506393,
              45.071659
            ],
            [
              -74.736481,
              44.99287
            ],
            [
              -74.968819,
              44.948578
            ],
            [
              -75.329201,
              44.81057
            ],
            [
              -75.758972,
              44.517475
            ],
            [
              -75.759117,
              44.517273
            ],
            [
              -75.778252,
              44.519852
            ],
            [
              -75.976936,
              44.38361
            ],
            [
              -76.503067,
              44.227489
            ],
            [
              -76.688316,
              44.21693
            ],
            [
              -76.984161,
              44.071381
            ],
            [
              -77.070007,
              44.09222
            ],
            [
              -76.886673,
              44.17749
            ],
            [
              -77.046661,
              44.129429
            ],
            [
              -77.011398,
              44.211109
            ],
            [
              -77.55722,
              44.12138
            ],
            [
              -77.575562,
              44.07888
            ],
            [
              -77.314438,
              44.15221
            ],
            [
              -77.31778,
              44.08971
            ],
            [
              -77.076683,
              44.17944
            ],
            [
              -77.127777,
              44.033611
            ],
            [
              -77.015793,
              44.047249
            ],
            [
              -76.941109,
              44.071049
            ],
            [
              -76.846657,
              44.11832
            ],
            [
              -77.042221,
              43.94833
            ],
            [
              -76.848053,
              43.943321
            ],
            [
              -77.134453,
              43.855549
            ],
            [
              -77.232773,
              43.87999
            ],
            [
              -77.170273,
              43.954159
            ],
            [
              -77.275284,
              43.910549
            ],
            [
              -77.327789,
              43.953609
            ],
            [
              -77.25473,
              43.96888
            ],
            [
              -77.48555,
              43.942211
            ],
            [
              -77.580841,
              44.002781
            ],
            [
              -77.538887,
              44.01944
            ],
            [
              -77.703613,
              44.041111
            ],
            [
              -77.736938,
              44.023609
            ],
            [
              -77.678329,
              44.000832
            ],
            [
              -77.804718,
              44.016941
            ],
            [
              -79.092499,
              43.826389
            ],
            [
              -79.362503,
              43.64333
            ],
            [
              -79.467773,
              43.646938
            ],
            [
              -79.587784,
              43.564709
            ],
            [
              -79.775833,
              43.319988
            ],
            [
              -79.703613,
              43.244438
            ],
            [
              -79.345284,
              43.188042
            ],
            [
              -79.073982,
              43.267471
            ],
            [
              -79.065811,
              43.101341
            ],
            [
              -79.078613,
              43.081669
            ],
            [
              -79.031113,
              43.05666
            ],
            [
              -79.040833,
              43.007771
            ],
            [
              -78.92495,
              42.91011
            ],
            [
              -79.098892,
              42.840271
            ],
            [
              -79.454727,
              42.877491
            ],
            [
              -80.204178,
              42.790279
            ],
            [
              -80.450562,
              42.612209
            ],
            [
              -80.114441,
              42.541382
            ],
            [
              -80.521942,
              42.573051
            ],
            [
              -80.925003,
              42.665539
            ],
            [
              -81.333069,
              42.651379
            ],
            [
              -81.785278,
              42.39777
            ],
            [
              -81.856377,
              42.25639
            ],
            [
              -81.894173,
              42.257221
            ],
            [
              -81.857498,
              42.320831
            ],
            [
              -81.921112,
              42.260281
            ],
            [
              -82.071953,
              42.253052
            ],
            [
              -82.388062,
              42.1236
            ],
            [
              -82.479721,
              42.03249
            ],
            [
              -82.505836,
              41.913319
            ],
            [
              -82.627777,
              42.035271
            ],
            [
              -82.890556,
              41.982208
            ],
            [
              -83.123894,
              42.069721
            ],
            [
              -83.030457,
              42.313221
            ],
            [
              -82.493057,
              42.312771
            ],
            [
              -82.427223,
              42.353882
            ],
            [
              -82.418877,
              42.498329
            ],
            [
              -82.595284,
              42.509991
            ],
            [
              -82.638443,
              42.54705
            ],
            [
              -82.586403,
              42.558601
            ],
            [
              -82.518883,
              42.633781
            ],
            [
              -82.473328,
              42.762859
            ],
            [
              -82.472038,
              42.898651
            ],
            [
              -82.419701,
              42.972679
            ],
            [
              -82.175552,
              43.071659
            ],
            [
              -81.745537,
              43.345829
            ],
            [
              -81.697769,
              43.48777
            ],
            [
              -81.745003,
              44.083881
            ],
            [
              -81.615547,
              44.219151
            ],
            [
              -81.580566,
              44.346661
            ],
            [
              -81.405273,
              44.437771
            ],
            [
              -81.268066,
              44.616661
            ],
            [
              -81.349731,
              44.973049
            ],
            [
              -81.433884,
              44.982491
            ],
            [
              -81.591377,
              45.15221
            ],
            [
              -81.576111,
              45.19276
            ],
            [
              -81.720001,
              45.215832
            ],
            [
              -81.695267,
              45.264721
            ],
            [
              -81.283623,
              45.249161
            ],
            [
              -81.325562,
              45.15749
            ],
            [
              -81.265556,
              45.10416
            ],
            [
              -81.253616,
              45.001659
            ],
            [
              -81.110283,
              44.96027
            ],
            [
              -81.150284,
              44.911381
            ],
            [
              -80.948608,
              44.963612
            ],
            [
              -81.132767,
              44.758049
            ],
            [
              -80.997772,
              44.809158
            ],
            [
              -80.892776,
              44.791111
            ],
            [
              -80.942207,
              44.595268
            ],
            [
              -80.805557,
              44.702492
            ],
            [
              -80.649986,
              44.726101
            ],
            [
              -80.584442,
              44.612209
            ],
            [
              -80.089722,
              44.468048
            ],
            [
              -79.997498,
              44.556938
            ],
            [
              -79.987503,
              44.68277
            ],
            [
              -80.113327,
              44.752491
            ],
            [
              -80.103882,
              44.819988
            ],
            [
              -79.945831,
              44.865551
            ],
            [
              -79.877487,
              44.76833
            ],
            [
              -79.659729,
              44.751659
            ],
            [
              -79.721657,
              44.78722
            ],
            [
              -79.68306,
              44.879162
            ],
            [
              -79.736938,
              44.865551
            ],
            [
              -79.737778,
              44.8036
            ],
            [
              -79.783623,
              44.817501
            ],
            [
              -79.828613,
              44.9361
            ],
            [
              -79.914169,
              44.94471
            ],
            [
              -79.934998,
              45.013329
            ],
            [
              -80.099991,
              45.123322
            ],
            [
              -79.988602,
              45.148331
            ],
            [
              -80.12944,
              45.237499
            ],
            [
              -80.037514,
              45.314159
            ],
            [
              -80.059433,
              45.382771
            ],
            [
              -80.127777,
              45.411659
            ],
            [
              -80.19249,
              45.375549
            ],
            [
              -80.164719,
              45.33971
            ],
            [
              -80.233322,
              45.341099
            ],
            [
              -80.335831,
              45.435551
            ],
            [
              -80.349991,
              45.382771
            ],
            [
              -80.400284,
              45.606659
            ],
            [
              -80.501404,
              45.569439
            ],
            [
              -80.748337,
              45.921379
            ],
            [
              -81.043327,
              45.9561
            ],
            [
              -81.155273,
              45.925831
            ],
            [
              -81.168877,
              46.017769
            ],
            [
              -81.250557,
              45.9575
            ],
            [
              -81.497498,
              45.974991
            ],
            [
              -81.497772,
              46.020271
            ],
            [
              -81.670273,
              45.946381
            ],
            [
              -81.576111,
              45.996941
            ],
            [
              -81.64473,
              46.014999
            ],
            [
              -81.503891,
              46.04166
            ],
            [
              -81.703888,
              46.0186
            ],
            [
              -81.543327,
              46.05999
            ],
            [
              -81.621384,
              46.123051
            ],
            [
              -81.743332,
              46.06805
            ],
            [
              -81.761124,
              46.01305
            ],
            [
              -81.777222,
              46.06332
            ],
            [
              -81.714447,
              46.10416
            ],
            [
              -82.13501,
              46.11694
            ],
            [
              -82.369453,
              46.165821
            ],
            [
              -82.322243,
              46.190269
            ],
            [
              -82.674156,
              46.16444
            ],
            [
              -82.63028,
              46.21777
            ],
            [
              -82.986938,
              46.172218
            ],
            [
              -83.865547,
              46.29583
            ],
            [
              -83.883331,
              46.334991
            ],
            [
              -84.049728,
              46.326099
            ],
            [
              -84.119453,
              46.375271
            ],
            [
              -84.075012,
              46.503609
            ],
            [
              -84.127129,
              46.529591
            ],
            [
              -84.136177,
              46.534111
            ],
            [
              -84.17305,
              46.55249
            ],
            [
              -84.412132,
              46.50367
            ],
            [
              -84.433167,
              46.499371
            ],
            [
              -84.517227,
              46.482208
            ],
            [
              -84.590286,
              46.56583
            ],
            [
              -84.421944,
              46.696098
            ],
            [
              -84.465843,
              46.758331
            ],
            [
              -84.54834,
              46.699429
            ],
            [
              -84.544159,
              46.825001
            ],
            [
              -84.393066,
              46.8325
            ],
            [
              -84.351097,
              46.882771
            ],
            [
              -84.446663,
              46.938881
            ],
            [
              -84.576401,
              46.943878
            ],
            [
              -84.603607,
              46.901932
            ],
            [
              -84.777786,
              46.981659
            ],
            [
              -84.653877,
              47.225819
            ],
            [
              -84.578888,
              47.288891
            ],
            [
              -84.62471,
              47.361111
            ],
            [
              -85.027786,
              47.611382
            ],
            [
              -84.967499,
              47.68082
            ],
            [
              -84.991096,
              47.71693
            ],
            [
              -84.892502,
              47.789719
            ],
            [
              -84.931671,
              47.85527
            ],
            [
              -84.844452,
              47.949162
            ],
            [
              -85.557503,
              47.925549
            ],
            [
              -85.860283,
              47.985271
            ],
            [
              -86.131378,
              48.241379
            ],
            [
              -86.262787,
              48.584431
            ],
            [
              -86.434723,
              48.77721
            ],
            [
              -86.55249,
              48.732208
            ],
            [
              -86.703056,
              48.810268
            ],
            [
              -86.85556,
              48.750832
            ],
            [
              -86.970551,
              48.809719
            ],
            [
              -87.003067,
              48.77499
            ],
            [
              -87.289719,
              48.76749
            ],
            [
              -87.443604,
              48.84388
            ],
            [
              -87.547234,
              48.833599
            ],
            [
              -87.575844,
              48.88583
            ],
            [
              -87.649986,
              48.863049
            ],
            [
              -87.955841,
              48.95055
            ],
            [
              -88.035553,
              49.022221
            ],
            [
              -88.183319,
              48.965832
            ],
            [
              -88.261093,
              48.99295
            ],
            [
              -88.253891,
              48.872219
            ],
            [
              -88.10527,
              48.787498
            ],
            [
              -88.103333,
              48.711658
            ],
            [
              -88.204453,
              48.606659
            ],
            [
              -88.252792,
              48.59499
            ],
            [
              -88.229446,
              48.636662
            ],
            [
              -88.283623,
              48.603882
            ],
            [
              -88.332497,
              48.657211
            ],
            [
              -88.3125,
              48.581669
            ],
            [
              -88.391113,
              48.540829
            ],
            [
              -88.430283,
              48.573608
            ],
            [
              -88.482773,
              48.470539
            ],
            [
              -88.559723,
              48.430271
            ],
            [
              -88.590561,
              48.490269
            ],
            [
              -88.556381,
              48.546101
            ],
            [
              -88.350281,
              48.678051
            ],
            [
              -88.313889,
              48.760551
            ],
            [
              -88.360817,
              48.83638
            ],
            [
              -88.480293,
              48.854439
            ],
            [
              -88.547501,
              48.764721
            ],
            [
              -88.509453,
              48.716381
            ],
            [
              -88.551666,
              48.698601
            ],
            [
              -88.539993,
              48.645271
            ],
            [
              -88.624443,
              48.62471
            ],
            [
              -88.632767,
              48.522491
            ],
            [
              -88.693047,
              48.507771
            ],
            [
              -88.74028,
              48.369709
            ],
            [
              -88.938599,
              48.314442
            ],
            [
              -88.759453,
              48.580551
            ],
            [
              -89.138611,
              48.48444
            ],
            [
              -89.243057,
              48.388329
            ],
            [
              -89.198883,
              48.32444
            ],
            [
              -89.337784,
              48.1161
            ],
            [
              -89.530586,
              48.00177
            ],
            [
              -89.749222,
              48.0266
            ],
            [
              -89.900299,
              47.992619
            ],
            [
              -89.986931,
              48.02367
            ],
            [
              -90.026611,
              48.086189
            ],
            [
              -90.14518,
              48.112881
            ],
            [
              -90.556732,
              48.092861
            ],
            [
              -90.567352,
              48.121811
            ],
            [
              -90.743263,
              48.088551
            ],
            [
              -90.864388,
              48.254311
            ],
            [
              -91.027039,
              48.19545
            ],
            [
              -91.239326,
              48.081409
            ],
            [
              -91.571442,
              48.043678
            ],
            [
              -91.568657,
              48.104561
            ],
            [
              -91.703613,
              48.114941
            ],
            [
              -91.711807,
              48.19688
            ],
            [
              -91.788689,
              48.206249
            ],
            [
              -91.979401,
              48.2505
            ],
            [
              -92.035049,
              48.35561
            ],
            [
              -92.125816,
              48.366859
            ],
            [
              -92.275993,
              48.352421
            ],
            [
              -92.300133,
              48.298409
            ],
            [
              -92.276772,
              48.244438
            ],
            [
              -92.369957,
              48.220871
            ],
            [
              -92.473167,
              48.35759
            ],
            [
              -92.456192,
              48.40226
            ],
            [
              -92.497383,
              48.44017
            ],
            [
              -92.70649,
              48.460461
            ],
            [
              -92.698669,
              48.49482
            ],
            [
              -92.626228,
              48.502918
            ],
            [
              -92.64167,
              48.54044
            ],
            [
              -92.728851,
              48.54031
            ],
            [
              -92.946777,
              48.628441
            ],
            [
              -93.091301,
              48.626659
            ],
            [
              -93.304092,
              48.63723
            ],
            [
              -93.457619,
              48.59277
            ],
            [
              -93.465187,
              48.54958
            ],
            [
              -93.513977,
              48.534328
            ],
            [
              -93.780937,
              48.511639
            ],
            [
              -93.812523,
              48.525459
            ],
            [
              -93.843727,
              48.624779
            ],
            [
              -94.230637,
              48.65202
            ],
            [
              -94.292137,
              48.707741
            ],
            [
              -94.430443,
              48.710819
            ],
            [
              -94.570107,
              48.713699
            ],
            [
              -94.694221,
              48.777641
            ],
            [
              -94.681038,
              48.877178
            ],
            [
              -94.83181,
              49.330811
            ],
            [
              -95.151627,
              49.371731
            ],
            [
              -95.153877,
              49.452019
            ],
            [
              -95.154327,
              49.576729
            ],
            [
              -95.150284,
              52.826389
            ],
            [
              -93.652222,
              53.72221
            ],
            [
              -93.272087,
              53.998859
            ],
            [
              -92.698143,
              54.410931
            ],
            [
              -92.60231,
              54.478489
            ],
            [
              -92.476593,
              54.56694
            ],
            [
              -92.388641,
              54.62793
            ],
            [
              -90.843887,
              55.66777
            ],
            [
              -89.305557,
              56.627209
            ],
            [
              -89.179642,
              56.86895
            ],
            [
              -89.037781,
              56.836109
            ],
            [
              -88.87944,
              56.858051
            ],
            [
              -88.654716,
              56.696381
            ],
            [
              -88.48999,
              56.64222
            ],
            [
              -88.353607,
              56.553322
            ],
            [
              -87.975563,
              56.437489
            ],
            [
              -87.943047,
              56.377491
            ],
            [
              -87.723892,
              56.20388
            ],
            [
              -87.708054,
              56.156101
            ],
            [
              -87.583618,
              56.076099
            ],
            [
              -87.615829,
              56.002781
            ],
            [
              -87.548607,
              56.049999
            ],
            [
              -87.351936,
              55.992771
            ],
            [
              -87.362503,
              55.946659
            ],
            [
              -86.979446,
              55.93166
            ],
            [
              -86.958893,
              55.905819
            ],
            [
              -86.477783,
              55.80888
            ],
            [
              -86.277222,
              55.72887
            ],
            [
              -85.944443,
              55.697769
            ],
            [
              -85.577789,
              55.562771
            ],
            [
              -85.393341,
              55.408878
            ],
            [
              -85.128883,
              55.346378
            ],
            [
              -85.121933,
              55.303322
            ],
            [
              -85.214172,
              55.273319
            ],
            [
              -85.398064,
              55.10083
            ],
            [
              -85.383057,
              55.063049
            ],
            [
              -85.423889,
              54.990551
            ],
            [
              -85.194153,
              55.24416
            ],
            [
              -85.001953,
              55.296661
            ],
            [
              -84.599167,
              55.241661
            ],
            [
              -84.322777,
              55.289989
            ],
            [
              -84.092216,
              55.27166
            ],
            [
              -83.920273,
              55.31916
            ],
            [
              -83.730003,
              55.259441
            ],
            [
              -83.736938,
              55.215
            ],
            [
              -83.686943,
              55.24416
            ],
            [
              -83.601387,
              55.224159
            ],
            [
              -83.561943,
              55.130821
            ],
            [
              -83.583618,
              55.22916
            ],
            [
              -83.698608,
              55.283051
            ],
            [
              -83.179718,
              55.197208
            ],
            [
              -82.964722,
              55.233601
            ],
            [
              -82.895279,
              55.171661
            ],
            [
              -82.946663,
              55.10638
            ],
            [
              -82.884171,
              55.15332
            ],
            [
              -82.650284,
              55.16972
            ],
            [
              -82.555557,
              55.139721
            ],
            [
              -82.536667,
              55.16444
            ],
            [
              -82.319458,
              55.064159
            ],
            [
              -82.338608,
              55.09943
            ],
            [
              -82.308037,
              55.121929
            ],
            [
              -82.340561,
              55.164711
            ],
            [
              -82.245827,
              55.102779
            ],
            [
              -82.287216,
              55.036659
            ],
            [
              -82.254997,
              54.975269
            ],
            [
              -82.270844,
              54.931381
            ],
            [
              -82.195267,
              54.844711
            ],
            [
              -82.358887,
              54.500271
            ],
            [
              -82.441101,
              54.36166
            ],
            [
              -82.434158,
              54.209431
            ],
            [
              -82.362778,
              54.143608
            ],
            [
              -82.381378,
              54.118881
            ],
            [
              -82.321953,
              54.123878
            ],
            [
              -82.243881,
              54.06805
            ],
            [
              -82.12999,
              53.79916
            ],
            [
              -82.21666,
              53.603882
            ],
            [
              -82.114723,
              53.273609
            ],
            [
              -82.250557,
              53.211941
            ],
            [
              -82.301666,
              52.961109
            ],
            [
              -82.101669,
              52.87999
            ],
            [
              -81.973618,
              52.780548
            ],
            [
              -81.988892,
              52.762211
            ],
            [
              -81.921387,
              52.682209
            ],
            [
              -81.558037,
              52.4561
            ],
            [
              -81.553047,
              52.352489
            ],
            [
              -81.478058,
              52.302219
            ],
            [
              -81.561394,
              52.31638
            ],
            [
              -81.747498,
              52.251389
            ],
            [
              -81.82251,
              52.25444
            ],
            [
              -81.883621,
              52.187489
            ],
            [
              -81.758347,
              52.239429
            ],
            [
              -81.501678,
              52.23333
            ],
            [
              -81.352783,
              52.101101
            ],
            [
              -80.988327,
              52.008049
            ],
            [
              -80.899986,
              51.895271
            ],
            [
              -80.815277,
              51.87999
            ],
            [
              -80.599167,
              51.713329
            ],
            [
              -80.515007,
              51.524441
            ],
            [
              -80.436394,
              51.464161
            ],
            [
              -80.424438,
              51.363609
            ],
            [
              -80.487503,
              51.336651
            ],
            [
              -80.652496,
              51.278332
            ],
            [
              -80.75029,
              51.17749
            ],
            [
              -80.844727,
              51.150269
            ],
            [
              -81.015007,
              51.028332
            ],
            [
              -80.93277,
              51.049721
            ],
            [
              -80.943878,
              51.0075
            ],
            [
              -80.862213,
              51.1161
            ],
            [
              -80.694443,
              51.156101
            ],
            [
              -80.530563,
              51.283611
            ],
            [
              -80.392227,
              51.3386
            ],
            [
              -79.996384,
              51.254711
            ],
            [
              -79.741096,
              51.1236
            ],
            [
              -79.684998,
              51.045551
            ],
            [
              -79.537613,
              50.958599
            ]
          ],
          [
            [
              -82.878601,
              45.968601
            ],
            [
              -82.809998,
              45.992489
            ],
            [
              -82.842216,
              45.920269
            ],
            [
              -82.757507,
              45.844151
            ],
            [
              -82.592216,
              45.849998
            ],
            [
              -82.539169,
              45.785549
            ],
            [
              -82.509171,
              45.848598
            ],
            [
              -82.586937,
              45.902489
            ],
            [
              -82.299438,
              45.99194
            ],
            [
              -82.167503,
              45.837769
            ],
            [
              -82.048607,
              45.96471
            ],
            [
              -81.92778,
              45.978039
            ],
            [
              -81.888344,
              45.949429
            ],
            [
              -81.90834,
              45.891941
            ],
            [
              -81.820282,
              45.880268
            ],
            [
              -81.847229,
              45.785271
            ],
            [
              -81.799988,
              45.732208
            ],
            [
              -81.683609,
              45.904991
            ],
            [
              -81.655838,
              45.861111
            ],
            [
              -81.70723,
              45.798328
            ],
            [
              -81.587219,
              45.799999
            ],
            [
              -81.719452,
              45.606098
            ],
            [
              -81.863327,
              45.52055
            ],
            [
              -81.99472,
              45.559719
            ],
            [
              -81.898064,
              45.577492
            ],
            [
              -81.761124,
              45.701099
            ],
            [
              -82.065826,
              45.55888
            ],
            [
              -82.689163,
              45.793049
            ],
            [
              -83.213333,
              45.873878
            ],
            [
              -83.186943,
              45.96471
            ],
            [
              -83.091377,
              45.919441
            ],
            [
              -83.054993,
              45.960548
            ],
            [
              -82.983887,
              45.930271
            ],
            [
              -82.878601,
              45.968601
            ]
          ]
        ],
        "nom": "Ontario",
        "iso_code": "ON"
      },
      {
        "name": "New Brunswick",
        "id": 55,
        "coordinates": [
          [
            [
              -64.482773,
              47.91777
            ],
            [
              -64.514717,
              47.8325
            ],
            [
              -64.605003,
              47.748329
            ],
            [
              -64.646667,
              47.72887
            ],
            [
              -64.690552,
              47.753052
            ],
            [
              -64.646118,
              47.788601
            ],
            [
              -64.702789,
              47.823608
            ],
            [
              -64.667503,
              47.86694
            ],
            [
              -64.609161,
              47.88694
            ],
            [
              -64.533333,
              47.864159
            ],
            [
              -64.482773,
              47.91777
            ]
          ],
          [
            [
              -69.039711,
              47.29715
            ],
            [
              -68.576111,
              47.424709
            ],
            [
              -68.38028,
              47.558601
            ],
            [
              -68.378052,
              47.65416
            ],
            [
              -68.372772,
              47.924709
            ],
            [
              -68.12027,
              47.926659
            ],
            [
              -68.116096,
              48.001942
            ],
            [
              -67.612213,
              47.99749
            ],
            [
              -67.604172,
              47.9361
            ],
            [
              -67.364166,
              47.841381
            ],
            [
              -67.322777,
              47.896389
            ],
            [
              -67.188599,
              47.883881
            ],
            [
              -67.047234,
              47.93082
            ],
            [
              -66.946953,
              47.899158
            ],
            [
              -66.943604,
              47.963051
            ],
            [
              -66.843697,
              47.996651
            ],
            [
              -66.75,
              47.979988
            ],
            [
              -66.356659,
              48.07333
            ],
            [
              -66.339172,
              48.013611
            ],
            [
              -65.79361,
              47.890831
            ],
            [
              -65.628601,
              47.66972
            ],
            [
              -65.671387,
              47.651661
            ],
            [
              -65.634743,
              47.620831
            ],
            [
              -65.606659,
              47.659988
            ],
            [
              -65.476387,
              47.68082
            ],
            [
              -65.240829,
              47.80666
            ],
            [
              -65.044724,
              47.84444
            ],
            [
              -64.976936,
              47.838039
            ],
            [
              -65.071953,
              47.776939
            ],
            [
              -64.823624,
              47.811378
            ],
            [
              -64.786118,
              47.772221
            ],
            [
              -64.846947,
              47.744438
            ],
            [
              -64.791382,
              47.742222
            ],
            [
              -64.770279,
              47.706661
            ],
            [
              -64.733322,
              47.76194
            ],
            [
              -64.676666,
              47.73555
            ],
            [
              -64.8125,
              47.674999
            ],
            [
              -64.818619,
              47.608051
            ],
            [
              -64.89389,
              47.556938
            ],
            [
              -64.901108,
              47.513329
            ],
            [
              -64.87027,
              47.51083
            ],
            [
              -64.928329,
              47.450829
            ],
            [
              -64.866943,
              47.484718
            ],
            [
              -64.880829,
              47.432499
            ],
            [
              -64.910004,
              47.35305
            ],
            [
              -64.904167,
              47.393051
            ],
            [
              -64.941101,
              47.373878
            ],
            [
              -64.96611,
              47.28944
            ],
            [
              -65.370003,
              47.086651
            ],
            [
              -65.204727,
              47.087769
            ],
            [
              -65.212509,
              47.044998
            ],
            [
              -65.025284,
              47.09193
            ],
            [
              -64.979446,
              47.05555
            ],
            [
              -64.93277,
              47.08416
            ],
            [
              -64.798607,
              47.07999
            ],
            [
              -64.825844,
              47.049999
            ],
            [
              -64.803329,
              46.987499
            ],
            [
              -64.88028,
              46.93055
            ],
            [
              -64.904999,
              46.851101
            ],
            [
              -64.873047,
              46.782211
            ],
            [
              -64.788597,
              46.724159
            ],
            [
              -64.853333,
              46.70499
            ],
            [
              -64.854446,
              46.672218
            ],
            [
              -64.817497,
              46.696381
            ],
            [
              -64.752792,
              46.663601
            ],
            [
              -64.748611,
              46.70277
            ],
            [
              -64.711121,
              46.679989
            ],
            [
              -64.723618,
              46.580551
            ],
            [
              -64.673317,
              46.500832
            ],
            [
              -64.711403,
              46.479988
            ],
            [
              -64.611938,
              46.40971
            ],
            [
              -64.631943,
              46.338039
            ],
            [
              -64.701683,
              46.32666
            ],
            [
              -64.579178,
              46.328049
            ],
            [
              -64.551666,
              46.363331
            ],
            [
              -64.517227,
              46.320831
            ],
            [
              -64.568619,
              46.287498
            ],
            [
              -64.559433,
              46.21999
            ],
            [
              -64.518341,
              46.242771
            ],
            [
              -64.253891,
              46.23444
            ],
            [
              -64.154167,
              46.19305
            ],
            [
              -64.146118,
              46.157211
            ],
            [
              -64.116943,
              46.181938
            ],
            [
              -64.081947,
              46.151379
            ],
            [
              -64.057503,
              46.180271
            ],
            [
              -63.970829,
              46.18055
            ],
            [
              -63.77639,
              46.121101
            ],
            [
              -63.919449,
              46.053051
            ],
            [
              -64.068069,
              46.059429
            ],
            [
              -64.093887,
              46.02166
            ],
            [
              -64.042793,
              45.991901
            ],
            [
              -64.154999,
              45.96888
            ],
            [
              -64.272926,
              45.835751
            ],
            [
              -64.361389,
              45.879162
            ],
            [
              -64.339722,
              45.859718
            ],
            [
              -64.481949,
              45.72332
            ],
            [
              -64.538887,
              45.723049
            ],
            [
              -64.488052,
              45.801102
            ],
            [
              -64.534157,
              45.898048
            ],
            [
              -64.549728,
              45.86805
            ],
            [
              -64.576401,
              45.885551
            ],
            [
              -64.681381,
              45.994709
            ],
            [
              -64.690552,
              46.050831
            ],
            [
              -64.754463,
              46.089161
            ],
            [
              -64.699432,
              46.035
            ],
            [
              -64.70639,
              45.994709
            ],
            [
              -64.573624,
              45.863331
            ],
            [
              -64.672501,
              45.750271
            ],
            [
              -64.642227,
              45.717209
            ],
            [
              -64.693047,
              45.716099
            ],
            [
              -64.778343,
              45.60722
            ],
            [
              -64.904167,
              45.627769
            ],
            [
              -65.015007,
              45.548882
            ],
            [
              -65.326683,
              45.4575
            ],
            [
              -65.739723,
              45.24527
            ],
            [
              -65.799156,
              45.25666
            ],
            [
              -65.918877,
              45.2061
            ],
            [
              -65.999161,
              45.2286
            ],
            [
              -66.039169,
              45.28833
            ],
            [
              -66.126389,
              45.304161
            ],
            [
              -66.015289,
              45.377769
            ],
            [
              -66.002502,
              45.461658
            ],
            [
              -66.088333,
              45.35527
            ],
            [
              -66.193329,
              45.333599
            ],
            [
              -66.145279,
              45.27916
            ],
            [
              -66.05777,
              45.2575
            ],
            [
              -66.21666,
              45.159161
            ],
            [
              -66.245537,
              45.208889
            ],
            [
              -66.321953,
              45.19804
            ],
            [
              -66.253342,
              45.191662
            ],
            [
              -66.25,
              45.161381
            ],
            [
              -66.34584,
              45.14444
            ],
            [
              -66.440552,
              45.07333
            ],
            [
              -66.496384,
              45.149719
            ],
            [
              -66.648064,
              45.083599
            ],
            [
              -66.722229,
              45.089161
            ],
            [
              -66.75473,
              45.05555
            ],
            [
              -66.796951,
              45.05888
            ],
            [
              -66.790558,
              45.102779
            ],
            [
              -66.87999,
              45.057209
            ],
            [
              -66.907784,
              45.111382
            ],
            [
              -66.84584,
              45.132488
            ],
            [
              -66.949432,
              45.187489
            ],
            [
              -66.987213,
              45.153881
            ],
            [
              -67.027222,
              45.168049
            ],
            [
              -67.033333,
              45.0886
            ],
            [
              -67.122223,
              45.1586
            ],
            [
              -67.127213,
              45.220268
            ],
            [
              -67.182503,
              45.244709
            ],
            [
              -67.15123,
              45.12191
            ],
            [
              -67.166473,
              45.156189
            ],
            [
              -67.274658,
              45.182709
            ],
            [
              -67.346169,
              45.122181
            ],
            [
              -67.439987,
              45.18951
            ],
            [
              -67.478508,
              45.280209
            ],
            [
              -67.419113,
              45.375778
            ],
            [
              -67.504662,
              45.485748
            ],
            [
              -67.416641,
              45.50349
            ],
            [
              -67.439857,
              45.592491
            ],
            [
              -67.615692,
              45.605129
            ],
            [
              -67.718582,
              45.68124
            ],
            [
              -67.753502,
              45.659229
            ],
            [
              -67.803978,
              45.678051
            ],
            [
              -67.803596,
              45.794449
            ],
            [
              -67.759911,
              45.82774
            ],
            [
              -67.795113,
              45.878422
            ],
            [
              -67.756157,
              45.916519
            ],
            [
              -67.78083,
              45.94701
            ],
            [
              -67.79155,
              47.060959
            ],
            [
              -68.231323,
              47.352112
            ],
            [
              -68.335327,
              47.35733
            ],
            [
              -68.391769,
              47.285061
            ],
            [
              -68.515182,
              47.296921
            ],
            [
              -68.895393,
              47.18222
            ],
            [
              -69.037231,
              47.257332
            ],
            [
              -69.039711,
              47.29715
            ]
          ]
        ],
        "nom": "Nouveau-Brunswick",
        "iso_code": "NB"
      },
      {
        "name": "Prince Edward Island",
        "id": 59,
        "coordinates": [
          [
            [
              -63.98917,
              46.393608
            ],
            [
              -64.13501,
              46.416382
            ],
            [
              -64.105827,
              46.445541
            ],
            [
              -64.113327,
              46.534721
            ],
            [
              -64.058037,
              46.569439
            ],
            [
              -64.071404,
              46.63583
            ],
            [
              -64.133331,
              46.591381
            ],
            [
              -64.223618,
              46.629711
            ],
            [
              -64.387512,
              46.620831
            ],
            [
              -64.416656,
              46.68111
            ],
            [
              -64.226936,
              46.906101
            ],
            [
              -63.99889,
              47.061661
            ],
            [
              -63.967499,
              46.891659
            ],
            [
              -64.091667,
              46.778599
            ],
            [
              -64.067497,
              46.749161
            ],
            [
              -63.97472,
              46.742489
            ],
            [
              -63.980831,
              46.714161
            ],
            [
              -63.882221,
              46.64555
            ],
            [
              -63.904171,
              46.603882
            ],
            [
              -63.8325,
              46.61721
            ],
            [
              -63.833328,
              46.577492
            ],
            [
              -63.9375,
              46.47665
            ],
            [
              -63.831669,
              46.516392
            ],
            [
              -63.811668,
              46.49527
            ],
            [
              -63.840839,
              46.464439
            ],
            [
              -63.709438,
              46.437489
            ],
            [
              -63.700279,
              46.466381
            ],
            [
              -63.740002,
              46.496101
            ],
            [
              -63.70055,
              46.515549
            ],
            [
              -63.723331,
              46.54361
            ],
            [
              -63.647221,
              46.567211
            ],
            [
              -63.490841,
              46.524712
            ],
            [
              -63.496948,
              46.488331
            ],
            [
              -63.45472,
              46.461658
            ],
            [
              -63.422501,
              46.495831
            ],
            [
              -63.4575,
              46.509991
            ],
            [
              -63.320278,
              46.492771
            ],
            [
              -63.238892,
              46.399719
            ],
            [
              -63.210831,
              46.429161
            ],
            [
              -62.587502,
              46.42527
            ],
            [
              -62.692501,
              46.4561
            ],
            [
              -62.477219,
              46.477772
            ],
            [
              -62.166389,
              46.486111
            ],
            [
              -61.970551,
              46.45694
            ],
            [
              -62.173328,
              46.34943
            ],
            [
              -62.285839,
              46.377491
            ],
            [
              -62.272221,
              46.338329
            ],
            [
              -62.35778,
              46.355831
            ],
            [
              -62.334721,
              46.311939
            ],
            [
              -62.400551,
              46.306938
            ],
            [
              -62.35973,
              46.282768
            ],
            [
              -62.37389,
              46.262211
            ],
            [
              -62.494999,
              46.271381
            ],
            [
              -62.437222,
              46.251659
            ],
            [
              -62.424721,
              46.215549
            ],
            [
              -62.58527,
              46.232208
            ],
            [
              -62.498051,
              46.182499
            ],
            [
              -62.589439,
              46.206379
            ],
            [
              -62.56834,
              46.186649
            ],
            [
              -62.603889,
              46.177219
            ],
            [
              -62.534168,
              46.16777
            ],
            [
              -62.542229,
              46.12249
            ],
            [
              -62.478329,
              46.120831
            ],
            [
              -62.513889,
              46.14222
            ],
            [
              -62.473049,
              46.150269
            ],
            [
              -62.446949,
              46.090549
            ],
            [
              -62.573891,
              46.034721
            ],
            [
              -62.459171,
              46.00639
            ],
            [
              -62.74667,
              45.94833
            ],
            [
              -62.882771,
              45.995541
            ],
            [
              -62.935001,
              46.045551
            ],
            [
              -62.896111,
              46.064991
            ],
            [
              -63.03083,
              46.060822
            ],
            [
              -62.922501,
              46.092491
            ],
            [
              -62.868061,
              46.13472
            ],
            [
              -62.885281,
              46.155819
            ],
            [
              -62.959171,
              46.128601
            ],
            [
              -62.94416,
              46.189991
            ],
            [
              -62.985001,
              46.19582
            ],
            [
              -62.983891,
              46.173882
            ],
            [
              -63.122219,
              46.211658
            ],
            [
              -62.96278,
              46.319988
            ],
            [
              -63.122768,
              46.237209
            ],
            [
              -63.195,
              46.269989
            ],
            [
              -63.172501,
              46.224159
            ],
            [
              -63.27306,
              46.195271
            ],
            [
              -63.219158,
              46.181938
            ],
            [
              -63.173061,
              46.211109
            ],
            [
              -63.14056,
              46.186378
            ],
            [
              -63.261669,
              46.13805
            ],
            [
              -63.48138,
              46.21777
            ],
            [
              -63.591942,
              46.211941
            ],
            [
              -63.698608,
              46.249161
            ],
            [
              -63.812771,
              46.332211
            ],
            [
              -63.785561,
              46.339989
            ],
            [
              -63.800831,
              46.372761
            ],
            [
              -63.714722,
              46.35416
            ],
            [
              -63.76556,
              46.380268
            ],
            [
              -63.744999,
              46.394711
            ],
            [
              -63.889999,
              46.397491
            ],
            [
              -63.929729,
              46.424999
            ],
            [
              -63.98917,
              46.393608
            ]
          ]
        ],
        "nom": "Île-du-Prince-Édouard",
        "iso_code": "PE"
      },
      {
        "name": "Yukon",
        "id": 64,
        "coordinates": [
          [
            [
              -139.121094,
              69.52916
            ],
            [
              -139.252197,
              69.579712
            ],
            [
              -139.332504,
              69.566093
            ],
            [
              -139.133102,
              69.647774
            ],
            [
              -138.867203,
              69.588318
            ],
            [
              -139.023102,
              69.580833
            ],
            [
              -139.121094,
              69.52916
            ]
          ],
          [
            [
              -123.816902,
              59.994709
            ],
            [
              -125.000801,
              60.003868
            ],
            [
              -133.791199,
              60
            ],
            [
              -134.151703,
              59.999722
            ],
            [
              -134.639297,
              60.000198
            ],
            [
              -134.906601,
              60
            ],
            [
              -138.938599,
              59.997551
            ],
            [
              -139.188904,
              60.088879
            ],
            [
              -139.0681,
              60.352219
            ],
            [
              -139.676697,
              60.340549
            ],
            [
              -139.979401,
              60.187771
            ],
            [
              -140.450806,
              60.309719
            ],
            [
              -140.521393,
              60.22221
            ],
            [
              -140.995499,
              60.307209
            ],
            [
              -140.996857,
              61.894802
            ],
            [
              -141.000046,
              65.840279
            ],
            [
              -141.00206,
              68.428207
            ],
            [
              -141.003006,
              69.642357
            ],
            [
              -140.396103,
              69.5961
            ],
            [
              -139.888306,
              69.616653
            ],
            [
              -139.272797,
              69.508614
            ],
            [
              -139.143097,
              69.510818
            ],
            [
              -138.957504,
              69.407494
            ],
            [
              -138.751404,
              69.346367
            ],
            [
              -138.600601,
              69.236649
            ],
            [
              -138.5186,
              69.25444
            ],
            [
              -138.449997,
              69.229156
            ],
            [
              -138.395599,
              69.293053
            ],
            [
              -138.446106,
              69.288589
            ],
            [
              -138.378296,
              69.304703
            ],
            [
              -138.329407,
              69.234711
            ],
            [
              -138.1772,
              69.159988
            ],
            [
              -137.378296,
              69.001389
            ],
            [
              -137.419205,
              68.988876
            ],
            [
              -137.255005,
              68.948318
            ],
            [
              -137.0103,
              68.947479
            ],
            [
              -136.745499,
              68.875259
            ],
            [
              -136.419998,
              68.90052
            ],
            [
              -136.446106,
              68.873032
            ],
            [
              -136.445007,
              67.715553
            ],
            [
              -136.421097,
              67.655258
            ],
            [
              -136.241104,
              67.620529
            ],
            [
              -136.157806,
              67.507767
            ],
            [
              -136.205307,
              67.406937
            ],
            [
              -136.116394,
              67.382751
            ],
            [
              -136.142807,
              67.359421
            ],
            [
              -136.0914,
              67.302467
            ],
            [
              -136.134506,
              67.226379
            ],
            [
              -136.236404,
              67.173866
            ],
            [
              -136.198898,
              67.143333
            ],
            [
              -136.222198,
              67.064148
            ],
            [
              -136.164505,
              67.003601
            ],
            [
              -133.8311,
              67.003326
            ],
            [
              -134.046097,
              66.984421
            ],
            [
              -134.092499,
              66.955261
            ],
            [
              -133.908096,
              66.908867
            ],
            [
              -133.763901,
              66.806931
            ],
            [
              -133.840302,
              66.727768
            ],
            [
              -133.757202,
              66.685257
            ],
            [
              -133.771896,
              66.64415
            ],
            [
              -133.5867,
              66.564697
            ],
            [
              -133.690308,
              66.531937
            ],
            [
              -133.629395,
              66.445251
            ],
            [
              -133.750305,
              66.447746
            ],
            [
              -133.768097,
              66.373032
            ],
            [
              -133.824707,
              66.316673
            ],
            [
              -133.570007,
              66.27832
            ],
            [
              -133.563904,
              66.155823
            ],
            [
              -133.673096,
              66.116928
            ],
            [
              -133.704697,
              66.07193
            ],
            [
              -133.603302,
              66.051651
            ],
            [
              -133.629395,
              65.974426
            ],
            [
              -133.407501,
              65.943863
            ],
            [
              -133.147507,
              66.024696
            ],
            [
              -132.939697,
              66.02887
            ],
            [
              -132.908401,
              65.996933
            ],
            [
              -132.993896,
              65.923027
            ],
            [
              -132.869003,
              65.910477
            ],
            [
              -132.605804,
              66.031662
            ],
            [
              -132.542496,
              66.021378
            ],
            [
              -132.5103,
              65.978867
            ],
            [
              -132.342804,
              65.981934
            ],
            [
              -132.334396,
              65.94664
            ],
            [
              -132.525803,
              65.897774
            ],
            [
              -132.573303,
              65.848602
            ],
            [
              -132.286102,
              65.71582
            ],
            [
              -132.174194,
              65.59137
            ],
            [
              -132.273605,
              65.546944
            ],
            [
              -132.327805,
              65.434708
            ],
            [
              -132.521393,
              65.363037
            ],
            [
              -132.555298,
              65.2836
            ],
            [
              -132.780594,
              65.229713
            ],
            [
              -132.714203,
              65.199417
            ],
            [
              -132.787796,
              65.187759
            ],
            [
              -132.701904,
              65.15387
            ],
            [
              -132.576401,
              65.190536
            ],
            [
              -132.527496,
              65.162773
            ],
            [
              -132.556107,
              65.118042
            ],
            [
              -132.539993,
              65.093872
            ],
            [
              -132.381699,
              65.084991
            ],
            [
              -132.333298,
              65.034714
            ],
            [
              -132.507507,
              64.964432
            ],
            [
              -132.487503,
              64.870819
            ],
            [
              -132.622498,
              64.826927
            ],
            [
              -132.570602,
              64.772491
            ],
            [
              -132.368896,
              64.77832
            ],
            [
              -132.163605,
              64.70166
            ],
            [
              -132.046402,
              64.695534
            ],
            [
              -131.915802,
              64.582489
            ],
            [
              -131.702805,
              64.535538
            ],
            [
              -131.687805,
              64.498596
            ],
            [
              -131.803894,
              64.438026
            ],
            [
              -131.8181,
              64.378311
            ],
            [
              -131.593002,
              64.368591
            ],
            [
              -131.427505,
              64.416382
            ],
            [
              -131.388596,
              64.467209
            ],
            [
              -131.141693,
              64.424423
            ],
            [
              -131.001999,
              64.330276
            ],
            [
              -131.060501,
              64.279427
            ],
            [
              -130.979706,
              64.265549
            ],
            [
              -130.937195,
              64.209991
            ],
            [
              -130.867203,
              64.187759
            ],
            [
              -130.9478,
              64.137207
            ],
            [
              -130.853607,
              64.072487
            ],
            [
              -130.863907,
              64.043053
            ],
            [
              -130.751404,
              64.046097
            ],
            [
              -130.778595,
              64.009163
            ],
            [
              -130.7556,
              63.986938
            ],
            [
              -130.5811,
              63.92416
            ],
            [
              -130.522202,
              63.93499
            ],
            [
              -130.3703,
              63.84388
            ],
            [
              -130.119705,
              63.808041
            ],
            [
              -130.103897,
              63.76416
            ],
            [
              -130.282501,
              63.73777
            ],
            [
              -130.321106,
              63.69582
            ],
            [
              -130.251999,
              63.66666
            ],
            [
              -130.109695,
              63.703609
            ],
            [
              -130.082504,
              63.68832
            ],
            [
              -130.110794,
              63.66444
            ],
            [
              -130.093002,
              63.63055
            ],
            [
              -129.948593,
              63.613609
            ],
            [
              -129.957993,
              63.58527
            ],
            [
              -129.898102,
              63.57222
            ],
            [
              -129.819504,
              63.48333
            ],
            [
              -129.897202,
              63.452221
            ],
            [
              -129.919205,
              63.372761
            ],
            [
              -130.073593,
              63.33696
            ],
            [
              -130.138306,
              63.310268
            ],
            [
              -130.151093,
              63.265831
            ],
            [
              -130.044495,
              63.269989
            ],
            [
              -129.978607,
              63.203609
            ],
            [
              -129.900299,
              63.197208
            ],
            [
              -129.835297,
              63.114719
            ],
            [
              -129.847198,
              63.086941
            ],
            [
              -129.637207,
              63.08028
            ],
            [
              -129.598907,
              63.051929
            ],
            [
              -129.705795,
              62.952221
            ],
            [
              -129.726105,
              62.91415
            ],
            [
              -129.702499,
              62.89222
            ],
            [
              -129.773895,
              62.874439
            ],
            [
              -129.630798,
              62.76083
            ],
            [
              -129.625305,
              62.70499
            ],
            [
              -129.516998,
              62.672489
            ],
            [
              -129.523895,
              62.64333
            ],
            [
              -129.487793,
              62.618881
            ],
            [
              -129.567795,
              62.57444
            ],
            [
              -129.417801,
              62.584991
            ],
            [
              -129.172195,
              62.481659
            ],
            [
              -129.308304,
              62.421379
            ],
            [
              -129.215805,
              62.377491
            ],
            [
              -129.310501,
              62.326389
            ],
            [
              -129.282196,
              62.267208
            ],
            [
              -129.199203,
              62.21693
            ],
            [
              -129.2939,
              62.156101
            ],
            [
              -129.122803,
              62.116661
            ],
            [
              -128.984207,
              62.136108
            ],
            [
              -128.765305,
              62.058331
            ],
            [
              -128.677505,
              62.125271
            ],
            [
              -128.546997,
              62.122219
            ],
            [
              -128.375305,
              62.02721
            ],
            [
              -128.388107,
              61.993881
            ],
            [
              -128.279205,
              61.948879
            ],
            [
              -128.196701,
              61.844151
            ],
            [
              -128.080597,
              61.849159
            ],
            [
              -128.006104,
              61.732491
            ],
            [
              -127.972801,
              61.688599
            ],
            [
              -127.550301,
              61.511379
            ],
            [
              -127.2883,
              61.519161
            ],
            [
              -127.141701,
              61.46666
            ],
            [
              -127.125,
              61.4011
            ],
            [
              -127.059402,
              61.363049
            ],
            [
              -127.007004,
              61.18499
            ],
            [
              -127.031403,
              61.150539
            ],
            [
              -127.0131,
              61.12804
            ],
            [
              -127.101898,
              61.070831
            ],
            [
              -127.055801,
              61.039989
            ],
            [
              -126.935303,
              61.059158
            ],
            [
              -126.9394,
              61.002491
            ],
            [
              -126.898903,
              60.941662
            ],
            [
              -126.925301,
              60.862209
            ],
            [
              -126.8797,
              60.82999
            ],
            [
              -126.886902,
              60.78249
            ],
            [
              -126.826103,
              60.755001
            ],
            [
              -126.753304,
              60.782211
            ],
            [
              -126.672203,
              60.743469
            ],
            [
              -126.520302,
              60.814411
            ],
            [
              -126.394501,
              60.77668
            ],
            [
              -126.253601,
              60.78867
            ],
            [
              -126.217499,
              60.819771
            ],
            [
              -126.244698,
              60.850868
            ],
            [
              -126.202202,
              60.863651
            ],
            [
              -126.113602,
              60.862808
            ],
            [
              -126.107201,
              60.817009
            ],
            [
              -126.009201,
              60.80341
            ],
            [
              -125.957001,
              60.877239
            ],
            [
              -125.887199,
              60.896938
            ],
            [
              -125.711998,
              60.827259
            ],
            [
              -125.370003,
              60.782558
            ],
            [
              -125.184196,
              60.848881
            ],
            [
              -125,
              60.860519
            ],
            [
              -124.871696,
              60.858051
            ],
            [
              -124.8778,
              60.91721
            ],
            [
              -124.813004,
              60.972488
            ],
            [
              -124.578903,
              60.953049
            ],
            [
              -124.476898,
              60.79055
            ],
            [
              -124.619202,
              60.709721
            ],
            [
              -124.614197,
              60.668598
            ],
            [
              -124.459396,
              60.569721
            ],
            [
              -124.418297,
              60.47916
            ],
            [
              -124.206398,
              60.457211
            ],
            [
              -124.195297,
              60.32888
            ],
            [
              -123.974197,
              60.098049
            ],
            [
              -124.029198,
              60.023048
            ],
            [
              -123.8769,
              60.04166
            ],
            [
              -123.816902,
              59.994709
            ]
          ]
        ],
        "nom": "Territoire du Yukon",
        "iso_code": "YT"
      }
    ]
  },
  "US": {
    "name": "United States",
    "regions": [
      {
        "name": "Alabama",
        "id": 1,
        "coordinates": [
          [
            [
              -87.359296,
              35.00118
            ],
            [
              -85.606675,
              34.984749
            ],
            [
              -85.431413,
              34.124869
            ],
            [
              -85.184951,
              32.859696
            ],
            [
              -85.069935,
              32.580372
            ],
            [
              -84.960397,
              32.421541
            ],
            [
              -85.004212,
              32.322956
            ],
            [
              -84.889196,
              32.262709
            ],
            [
              -85.058981,
              32.13674
            ],
            [
              -85.053504,
              32.01077
            ],
            [
              -85.141136,
              31.840985
            ],
            [
              -85.042551,
              31.539753
            ],
            [
              -85.113751,
              31.27686
            ],
            [
              -85.004212,
              31.003013
            ],
            [
              -85.497137,
              30.997536
            ],
            [
              -87.600282,
              30.997536
            ],
            [
              -87.633143,
              30.86609
            ],
            [
              -87.408589,
              30.674397
            ],
            [
              -87.446927,
              30.510088
            ],
            [
              -87.37025,
              30.427934
            ],
            [
              -87.518128,
              30.280057
            ],
            [
              -87.655051,
              30.247195
            ],
            [
              -87.90699,
              30.411504
            ],
            [
              -87.934375,
              30.657966
            ],
            [
              -88.011052,
              30.685351
            ],
            [
              -88.10416,
              30.499135
            ],
            [
              -88.137022,
              30.318396
            ],
            [
              -88.394438,
              30.367688
            ],
            [
              -88.471115,
              31.895754
            ],
            [
              -88.241084,
              33.796253
            ],
            [
              -88.098683,
              34.891641
            ],
            [
              -88.202745,
              34.995703
            ],
            [
              -87.359296,
              35.00118
            ]
          ]
        ],
        "nom": "Alabama",
        "iso_code": "AL"
      },
      {
        "name": "Alaska",
        "id": 2,
        "coordinates": [
          [
            [
              -131.602021,
              55.117982
            ],
            [
              -131.569159,
              55.28229
            ],
            [
              -131.355558,
              55.183705
            ],
            [
              -131.38842,
              55.01392
            ],
            [
              -131.645836,
              55.035827
            ],
            [
              -131.602021,
              55.117982
            ]
          ],
          [
            [
              -131.832052,
              55.42469
            ],
            [
              -131.645836,
              55.304197
            ],
            [
              -131.749898,
              55.128935
            ],
            [
              -131.832052,
              55.189182
            ],
            [
              -131.832052,
              55.42469
            ]
          ],
          [
            [
              -132.976733,
              56.437924
            ],
            [
              -132.735747,
              56.459832
            ],
            [
              -132.631685,
              56.421493
            ],
            [
              -132.664547,
              56.273616
            ],
            [
              -132.878148,
              56.240754
            ],
            [
              -133.069841,
              56.333862
            ],
            [
              -132.976733,
              56.437924
            ]
          ],
          [
            [
              -133.595627,
              56.350293
            ],
            [
              -133.162949,
              56.317431
            ],
            [
              -133.05341,
              56.125739
            ],
            [
              -132.620732,
              55.912138
            ],
            [
              -132.472854,
              55.780691
            ],
            [
              -132.4619,
              55.671152
            ],
            [
              -132.357838,
              55.649245
            ],
            [
              -132.341408,
              55.506844
            ],
            [
              -132.166146,
              55.364444
            ],
            [
              -132.144238,
              55.238474
            ],
            [
              -132.029222,
              55.276813
            ],
            [
              -131.97993,
              55.178228
            ],
            [
              -131.958022,
              54.789365
            ],
            [
              -132.029222,
              54.701734
            ],
            [
              -132.308546,
              54.718165
            ],
            [
              -132.385223,
              54.915335
            ],
            [
              -132.483808,
              54.898904
            ],
            [
              -132.686455,
              55.046781
            ],
            [
              -132.746701,
              54.997489
            ],
            [
              -132.916486,
              55.046781
            ],
            [
              -132.889102,
              54.898904
            ],
            [
              -132.73027,
              54.937242
            ],
            [
              -132.626209,
              54.882473
            ],
            [
              -132.675501,
              54.679826
            ],
            [
              -132.867194,
              54.701734
            ],
            [
              -133.157472,
              54.95915
            ],
            [
              -133.239626,
              55.090597
            ],
            [
              -133.223195,
              55.22752
            ],
            [
              -133.453227,
              55.216566
            ],
            [
              -133.453227,
              55.320628
            ],
            [
              -133.277964,
              55.331582
            ],
            [
              -133.102702,
              55.42469
            ],
            [
              -133.17938,
              55.588998
            ],
            [
              -133.387503,
              55.62186
            ],
            [
              -133.420365,
              55.884753
            ],
            [
              -133.497042,
              56.0162
            ],
            [
              -133.639442,
              55.923092
            ],
            [
              -133.694212,
              56.070969
            ],
            [
              -133.546335,
              56.142169
            ],
            [
              -133.666827,
              56.311955
            ],
            [
              -133.595627,
              56.350293
            ]
          ],
          [
            [
              -133.738027,
              55.556137
            ],
            [
              -133.546335,
              55.490413
            ],
            [
              -133.414888,
              55.572568
            ],
            [
              -133.283441,
              55.534229
            ],
            [
              -133.420365,
              55.386352
            ],
            [
              -133.633966,
              55.430167
            ],
            [
              -133.738027,
              55.556137
            ]
          ],
          [
            [
              -133.907813,
              56.930849
            ],
            [
              -134.050213,
              57.029434
            ],
            [
              -133.885905,
              57.095157
            ],
            [
              -133.343688,
              57.002049
            ],
            [
              -133.102702,
              57.007526
            ],
            [
              -132.932917,
              56.82131
            ],
            [
              -132.620732,
              56.667956
            ],
            [
              -132.653593,
              56.55294
            ],
            [
              -132.817901,
              56.492694
            ],
            [
              -133.042456,
              56.520078
            ],
            [
              -133.201287,
              56.448878
            ],
            [
              -133.420365,
              56.492694
            ],
            [
              -133.66135,
              56.448878
            ],
            [
              -133.710643,
              56.684386
            ],
            [
              -133.688735,
              56.837741
            ],
            [
              -133.869474,
              56.843218
            ],
            [
              -133.907813,
              56.930849
            ]
          ],
          [
            [
              -134.115936,
              56.48174
            ],
            [
              -134.25286,
              56.558417
            ],
            [
              -134.400737,
              56.722725
            ],
            [
              -134.417168,
              56.848695
            ],
            [
              -134.296675,
              56.908941
            ],
            [
              -134.170706,
              56.848695
            ],
            [
              -134.143321,
              56.952757
            ],
            [
              -133.748981,
              56.772017
            ],
            [
              -133.710643,
              56.596755
            ],
            [
              -133.847566,
              56.574848
            ],
            [
              -133.935197,
              56.377678
            ],
            [
              -133.836612,
              56.322908
            ],
            [
              -133.957105,
              56.092877
            ],
            [
              -134.110459,
              56.142169
            ],
            [
              -134.132367,
              55.999769
            ],
            [
              -134.230952,
              56.070969
            ],
            [
              -134.291198,
              56.350293
            ],
            [
              -134.115936,
              56.48174
            ]
          ],
          [
            [
              -134.636246,
              56.28457
            ],
            [
              -134.669107,
              56.169554
            ],
            [
              -134.806031,
              56.235277
            ],
            [
              -135.178463,
              56.67891
            ],
            [
              -135.413971,
              56.810356
            ],
            [
              -135.331817,
              56.914418
            ],
            [
              -135.424925,
              57.166357
            ],
            [
              -135.687818,
              57.369004
            ],
            [
              -135.419448,
              57.566174
            ],
            [
              -135.298955,
              57.48402
            ],
            [
              -135.063447,
              57.418296
            ],
            [
              -134.849846,
              57.407343
            ],
            [
              -134.844369,
              57.248511
            ],
            [
              -134.636246,
              56.728202
            ],
            [
              -134.636246,
              56.28457
            ]
          ],
          [
            [
              -134.712923,
              58.223407
            ],
            [
              -134.373353,
              58.14673
            ],
            [
              -134.176183,
              58.157683
            ],
            [
              -134.187137,
              58.081006
            ],
            [
              -133.902336,
              57.807159
            ],
            [
              -134.099505,
              57.850975
            ],
            [
              -134.148798,
              57.757867
            ],
            [
              -133.935197,
              57.615466
            ],
            [
              -133.869474,
              57.363527
            ],
            [
              -134.083075,
              57.297804
            ],
            [
              -134.154275,
              57.210173
            ],
            [
              -134.499322,
              57.029434
            ],
            [
              -134.603384,
              57.034911
            ],
            [
              -134.6472,
              57.226604
            ],
            [
              -134.575999,
              57.341619
            ],
            [
              -134.608861,
              57.511404
            ],
            [
              -134.729354,
              57.719528
            ],
            [
              -134.707446,
              57.829067
            ],
            [
              -134.784123,
              58.097437
            ],
            [
              -134.91557,
              58.212453
            ],
            [
              -134.953908,
              58.409623
            ],
            [
              -134.712923,
              58.223407
            ]
          ],
          [
            [
              -135.857603,
              57.330665
            ],
            [
              -135.715203,
              57.330665
            ],
            [
              -135.567326,
              57.149926
            ],
            [
              -135.633049,
              57.023957
            ],
            [
              -135.857603,
              56.996572
            ],
            [
              -135.824742,
              57.193742
            ],
            [
              -135.857603,
              57.330665
            ]
          ],
          [
            [
              -136.279328,
              58.206976
            ],
            [
              -135.978096,
              58.201499
            ],
            [
              -135.780926,
              58.28913
            ],
            [
              -135.496125,
              58.168637
            ],
            [
              -135.64948,
              58.037191
            ],
            [
              -135.59471,
              57.987898
            ],
            [
              -135.45231,
              58.135776
            ],
            [
              -135.107263,
              58.086483
            ],
            [
              -134.91557,
              57.976944
            ],
            [
              -135.025108,
              57.779775
            ],
            [
              -134.937477,
              57.763344
            ],
            [
              -134.822462,
              57.500451
            ],
            [
              -135.085355,
              57.462112
            ],
            [
              -135.572802,
              57.675713
            ],
            [
              -135.556372,
              57.456635
            ],
            [
              -135.709726,
              57.369004
            ],
            [
              -135.890465,
              57.407343
            ],
            [
              -136.000004,
              57.544266
            ],
            [
              -136.208128,
              57.637374
            ],
            [
              -136.366959,
              57.829067
            ],
            [
              -136.569606,
              57.916698
            ],
            [
              -136.558652,
              58.075529
            ],
            [
              -136.421728,
              58.130299
            ],
            [
              -136.377913,
              58.267222
            ],
            [
              -136.279328,
              58.206976
            ]
          ],
          [
            [
              -147.079854,
              60.200582
            ],
            [
              -147.501579,
              59.948643
            ],
            [
              -147.53444,
              59.850058
            ],
            [
              -147.874011,
              59.784335
            ],
            [
              -147.80281,
              59.937689
            ],
            [
              -147.435855,
              60.09652
            ],
            [
              -147.205824,
              60.271782
            ],
            [
              -147.079854,
              60.200582
            ]
          ],
          [
            [
              -147.561825,
              60.578491
            ],
            [
              -147.616594,
              60.370367
            ],
            [
              -147.758995,
              60.156767
            ],
            [
              -147.956165,
              60.227967
            ],
            [
              -147.791856,
              60.474429
            ],
            [
              -147.561825,
              60.578491
            ]
          ],
          [
            [
              -147.786379,
              70.245291
            ],
            [
              -147.682318,
              70.201475
            ],
            [
              -147.162008,
              70.15766
            ],
            [
              -146.888161,
              70.185044
            ],
            [
              -146.510252,
              70.185044
            ],
            [
              -146.099482,
              70.146706
            ],
            [
              -145.858496,
              70.168614
            ],
            [
              -145.622988,
              70.08646
            ],
            [
              -145.195787,
              69.993352
            ],
            [
              -144.620708,
              69.971444
            ],
            [
              -144.461877,
              70.026213
            ],
            [
              -144.078491,
              70.059075
            ],
            [
              -143.914183,
              70.130275
            ],
            [
              -143.497935,
              70.141229
            ],
            [
              -143.503412,
              70.091936
            ],
            [
              -143.25695,
              70.119321
            ],
            [
              -142.747594,
              70.042644
            ],
            [
              -142.402547,
              69.916674
            ],
            [
              -142.079408,
              69.856428
            ],
            [
              -142.008207,
              69.801659
            ],
            [
              -141.712453,
              69.790705
            ],
            [
              -141.433129,
              69.697597
            ],
            [
              -141.378359,
              69.63735
            ],
            [
              -141.208574,
              69.686643
            ],
            [
              -141.00045,
              69.648304
            ],
            [
              -141.00045,
              60.304644
            ],
            [
              -140.53491,
              60.22249
            ],
            [
              -140.474664,
              60.310121
            ],
            [
              -139.987216,
              60.184151
            ],
            [
              -139.696939,
              60.342983
            ],
            [
              -139.088998,
              60.359413
            ],
            [
              -139.198537,
              60.091043
            ],
            [
              -139.045183,
              59.997935
            ],
            [
              -138.700135,
              59.910304
            ],
            [
              -138.623458,
              59.767904
            ],
            [
              -137.604747,
              59.242118
            ],
            [
              -137.445916,
              58.908024
            ],
            [
              -137.265177,
              59.001132
            ],
            [
              -136.827022,
              59.159963
            ],
            [
              -136.580559,
              59.16544
            ],
            [
              -136.465544,
              59.285933
            ],
            [
              -136.476498,
              59.466672
            ],
            [
              -136.301236,
              59.466672
            ],
            [
              -136.25742,
              59.625503
            ],
            [
              -135.945234,
              59.663842
            ],
            [
              -135.479694,
              59.800766
            ],
            [
              -135.025108,
              59.565257
            ],
            [
              -135.068924,
              59.422857
            ],
            [
              -134.959385,
              59.280456
            ],
            [
              -134.701969,
              59.247595
            ],
            [
              -134.378829,
              59.033994
            ],
            [
              -134.400737,
              58.973748
            ],
            [
              -134.25286,
              58.858732
            ],
            [
              -133.842089,
              58.727285
            ],
            [
              -133.173903,
              58.152206
            ],
            [
              -133.075318,
              57.998852
            ],
            [
              -132.867194,
              57.845498
            ],
            [
              -132.560485,
              57.505928
            ],
            [
              -132.253777,
              57.21565
            ],
            [
              -132.368792,
              57.095157
            ],
            [
              -132.05113,
              57.051341
            ],
            [
              -132.127807,
              56.876079
            ],
            [
              -131.870391,
              56.804879
            ],
            [
              -131.837529,
              56.602232
            ],
            [
              -131.580113,
              56.613186
            ],
            [
              -131.087188,
              56.405062
            ],
            [
              -130.78048,
              56.366724
            ],
            [
              -130.621648,
              56.268139
            ],
            [
              -130.468294,
              56.240754
            ],
            [
              -130.424478,
              56.142169
            ],
            [
              -130.101339,
              56.114785
            ],
            [
              -130.002754,
              55.994292
            ],
            [
              -130.150631,
              55.769737
            ],
            [
              -130.128724,
              55.583521
            ],
            [
              -129.986323,
              55.276813
            ],
            [
              -130.095862,
              55.200136
            ],
            [
              -130.336847,
              54.920812
            ],
            [
              -130.687372,
              54.718165
            ],
            [
              -130.785957,
              54.822227
            ],
            [
              -130.917403,
              54.789365
            ],
            [
              -131.010511,
              54.997489
            ],
            [
              -130.983126,
              55.08512
            ],
            [
              -131.092665,
              55.189182
            ],
            [
              -130.862634,
              55.298721
            ],
            [
              -130.928357,
              55.337059
            ],
            [
              -131.158389,
              55.200136
            ],
            [
              -131.284358,
              55.287767
            ],
            [
              -131.426759,
              55.238474
            ],
            [
              -131.843006,
              55.457552
            ],
            [
              -131.700606,
              55.698537
            ],
            [
              -131.963499,
              55.616383
            ],
            [
              -131.974453,
              55.49589
            ],
            [
              -132.182576,
              55.588998
            ],
            [
              -132.226392,
              55.704014
            ],
            [
              -132.083991,
              55.829984
            ],
            [
              -132.127807,
              55.955953
            ],
            [
              -132.324977,
              55.851892
            ],
            [
              -132.522147,
              56.076446
            ],
            [
              -132.642639,
              56.032631
            ],
            [
              -132.719317,
              56.218847
            ],
            [
              -132.527624,
              56.339339
            ],
            [
              -132.341408,
              56.339339
            ],
            [
              -132.396177,
              56.487217
            ],
            [
              -132.297592,
              56.67891
            ],
            [
              -132.450946,
              56.673433
            ],
            [
              -132.768609,
              56.837741
            ],
            [
              -132.993164,
              57.034911
            ],
            [
              -133.51895,
              57.177311
            ],
            [
              -133.507996,
              57.577128
            ],
            [
              -133.677781,
              57.62642
            ],
            [
              -133.639442,
              57.790728
            ],
            [
              -133.814705,
              57.834544
            ],
            [
              -134.072121,
              58.053622
            ],
            [
              -134.143321,
              58.168637
            ],
            [
              -134.586953,
              58.206976
            ],
            [
              -135.074401,
              58.502731
            ],
            [
              -135.282525,
              59.192825
            ],
            [
              -135.38111,
              59.033994
            ],
            [
              -135.337294,
              58.891593
            ],
            [
              -135.140124,
              58.617746
            ],
            [
              -135.189417,
              58.573931
            ],
            [
              -135.05797,
              58.349376
            ],
            [
              -135.085355,
              58.201499
            ],
            [
              -135.277048,
              58.234361
            ],
            [
              -135.430402,
              58.398669
            ],
            [
              -135.633049,
              58.426053
            ],
            [
              -135.91785,
              58.382238
            ],
            [
              -135.912373,
              58.617746
            ],
            [
              -136.087635,
              58.814916
            ],
            [
              -136.246466,
              58.75467
            ],
            [
              -136.876314,
              58.962794
            ],
            [
              -136.931084,
              58.902547
            ],
            [
              -136.586036,
              58.836824
            ],
            [
              -136.317666,
              58.672516
            ],
            [
              -136.213604,
              58.667039
            ],
            [
              -136.180743,
              58.535592
            ],
            [
              -136.043819,
              58.382238
            ],
            [
              -136.388867,
              58.294607
            ],
            [
              -136.591513,
              58.349376
            ],
            [
              -136.59699,
              58.212453
            ],
            [
              -136.859883,
              58.316515
            ],
            [
              -136.947514,
              58.393192
            ],
            [
              -137.111823,
              58.393192
            ],
            [
              -137.566409,
              58.590362
            ],
            [
              -137.900502,
              58.765624
            ],
            [
              -137.933364,
              58.869686
            ],
            [
              -138.11958,
              59.02304
            ],
            [
              -138.634412,
              59.132579
            ],
            [
              -138.919213,
              59.247595
            ],
            [
              -139.417615,
              59.379041
            ],
            [
              -139.746231,
              59.505011
            ],
            [
              -139.718846,
              59.641934
            ],
            [
              -139.625738,
              59.598119
            ],
            [
              -139.5162,
              59.68575
            ],
            [
              -139.625738,
              59.88292
            ],
            [
              -139.488815,
              59.992458
            ],
            [
              -139.554538,
              60.041751
            ],
            [
              -139.801,
              59.833627
            ],
            [
              -140.315833,
              59.696704
            ],
            [
              -140.92925,
              59.745996
            ],
            [
              -141.444083,
              59.871966
            ],
            [
              -141.46599,
              59.970551
            ],
            [
              -141.706976,
              59.948643
            ],
            [
              -141.964392,
              60.019843
            ],
            [
              -142.539471,
              60.085566
            ],
            [
              -142.873564,
              60.091043
            ],
            [
              -143.623905,
              60.036274
            ],
            [
              -143.892275,
              59.997935
            ],
            [
              -144.231845,
              60.140336
            ],
            [
              -144.65357,
              60.206059
            ],
            [
              -144.785016,
              60.29369
            ],
            [
              -144.834309,
              60.441568
            ],
            [
              -145.124586,
              60.430614
            ],
            [
              -145.223171,
              60.299167
            ],
            [
              -145.738004,
              60.474429
            ],
            [
              -145.820158,
              60.551106
            ],
            [
              -146.351421,
              60.408706
            ],
            [
              -146.608837,
              60.238921
            ],
            [
              -146.718376,
              60.397752
            ],
            [
              -146.608837,
              60.485383
            ],
            [
              -146.455483,
              60.463475
            ],
            [
              -145.951604,
              60.578491
            ],
            [
              -146.017328,
              60.666122
            ],
            [
              -146.252836,
              60.622307
            ],
            [
              -146.345944,
              60.737322
            ],
            [
              -146.565022,
              60.753753
            ],
            [
              -146.784099,
              61.044031
            ],
            [
              -146.866253,
              60.972831
            ],
            [
              -147.172962,
              60.934492
            ],
            [
              -147.271547,
              60.972831
            ],
            [
              -147.375609,
              60.879723
            ],
            [
              -147.758995,
              60.912584
            ],
            [
              -147.775426,
              60.808523
            ],
            [
              -148.032842,
              60.781138
            ],
            [
              -148.153334,
              60.819476
            ],
            [
              -148.065703,
              61.005692
            ],
            [
              -148.175242,
              61.000215
            ],
            [
              -148.350504,
              60.803046
            ],
            [
              -148.109519,
              60.737322
            ],
            [
              -148.087611,
              60.594922
            ],
            [
              -147.939734,
              60.441568
            ],
            [
              -148.027365,
              60.277259
            ],
            [
              -148.219058,
              60.332029
            ],
            [
              -148.273827,
              60.249875
            ],
            [
              -148.087611,
              60.217013
            ],
            [
              -147.983549,
              59.997935
            ],
            [
              -148.251919,
              59.95412
            ],
            [
              -148.399797,
              59.997935
            ],
            [
              -148.635305,
              59.937689
            ],
            [
              -148.755798,
              59.986981
            ],
            [
              -149.067984,
              59.981505
            ],
            [
              -149.05703,
              60.063659
            ],
            [
              -149.204907,
              60.008889
            ],
            [
              -149.287061,
              59.904827
            ],
            [
              -149.418508,
              59.997935
            ],
            [
              -149.582816,
              59.866489
            ],
            [
              -149.511616,
              59.806242
            ],
            [
              -149.741647,
              59.729565
            ],
            [
              -149.949771,
              59.718611
            ],
            [
              -150.031925,
              59.61455
            ],
            [
              -150.25648,
              59.521442
            ],
            [
              -150.409834,
              59.554303
            ],
            [
              -150.579619,
              59.444764
            ],
            [
              -150.716543,
              59.450241
            ],
            [
              -151.001343,
              59.225687
            ],
            [
              -151.308052,
              59.209256
            ],
            [
              -151.406637,
              59.280456
            ],
            [
              -151.592853,
              59.159963
            ],
            [
              -151.976239,
              59.253071
            ],
            [
              -151.888608,
              59.422857
            ],
            [
              -151.636669,
              59.483103
            ],
            [
              -151.47236,
              59.472149
            ],
            [
              -151.423068,
              59.537872
            ],
            [
              -151.127313,
              59.669319
            ],
            [
              -151.116359,
              59.778858
            ],
            [
              -151.505222,
              59.63098
            ],
            [
              -151.828361,
              59.718611
            ],
            [
              -151.8667,
              59.778858
            ],
            [
              -151.702392,
              60.030797
            ],
            [
              -151.423068,
              60.211536
            ],
            [
              -151.379252,
              60.359413
            ],
            [
              -151.297098,
              60.386798
            ],
            [
              -151.264237,
              60.545629
            ],
            [
              -151.406637,
              60.720892
            ],
            [
              -151.06159,
              60.786615
            ],
            [
              -150.404357,
              61.038554
            ],
            [
              -150.245526,
              60.939969
            ],
            [
              -150.042879,
              60.912584
            ],
            [
              -149.741647,
              61.016646
            ],
            [
              -150.075741,
              61.15357
            ],
            [
              -150.207187,
              61.257632
            ],
            [
              -150.47008,
              61.246678
            ],
            [
              -150.656296,
              61.29597
            ],
            [
              -150.711066,
              61.252155
            ],
            [
              -151.023251,
              61.180954
            ],
            [
              -151.165652,
              61.044031
            ],
            [
              -151.477837,
              61.011169
            ],
            [
              -151.800977,
              60.852338
            ],
            [
              -151.833838,
              60.748276
            ],
            [
              -152.080301,
              60.693507
            ],
            [
              -152.13507,
              60.578491
            ],
            [
              -152.310332,
              60.507291
            ],
            [
              -152.392486,
              60.304644
            ],
            [
              -152.732057,
              60.173197
            ],
            [
              -152.567748,
              60.069136
            ],
            [
              -152.704672,
              59.915781
            ],
            [
              -153.022334,
              59.888397
            ],
            [
              -153.049719,
              59.691227
            ],
            [
              -153.345474,
              59.620026
            ],
            [
              -153.438582,
              59.702181
            ],
            [
              -153.586459,
              59.548826
            ],
            [
              -153.761721,
              59.543349
            ],
            [
              -153.72886,
              59.433811
            ],
            [
              -154.117723,
              59.368087
            ],
            [
              -154.1944,
              59.066856
            ],
            [
              -153.750768,
              59.050425
            ],
            [
              -153.400243,
              58.968271
            ],
            [
              -153.301658,
              58.869686
            ],
            [
              -153.444059,
              58.710854
            ],
            [
              -153.679567,
              58.612269
            ],
            [
              -153.898645,
              58.606793
            ],
            [
              -153.920553,
              58.519161
            ],
            [
              -154.062953,
              58.4863
            ],
            [
              -153.99723,
              58.376761
            ],
            [
              -154.145107,
              58.212453
            ],
            [
              -154.46277,
              58.059098
            ],
            [
              -154.643509,
              58.059098
            ],
            [
              -154.818771,
              58.004329
            ],
            [
              -154.988556,
              58.015283
            ],
            [
              -155.120003,
              57.955037
            ],
            [
              -155.081664,
              57.872883
            ],
            [
              -155.328126,
              57.829067
            ],
            [
              -155.377419,
              57.708574
            ],
            [
              -155.547204,
              57.785251
            ],
            [
              -155.73342,
              57.549743
            ],
            [
              -156.045606,
              57.566174
            ],
            [
              -156.023698,
              57.440204
            ],
            [
              -156.209914,
              57.473066
            ],
            [
              -156.34136,
              57.418296
            ],
            [
              -156.34136,
              57.248511
            ],
            [
              -156.549484,
              56.985618
            ],
            [
              -156.883577,
              56.952757
            ],
            [
              -157.157424,
              56.832264
            ],
            [
              -157.20124,
              56.766541
            ],
            [
              -157.376502,
              56.859649
            ],
            [
              -157.672257,
              56.607709
            ],
            [
              -157.754411,
              56.67891
            ],
            [
              -157.918719,
              56.657002
            ],
            [
              -157.957058,
              56.514601
            ],
            [
              -158.126843,
              56.459832
            ],
            [
              -158.32949,
              56.48174
            ],
            [
              -158.488321,
              56.339339
            ],
            [
              -158.208997,
              56.295524
            ],
            [
              -158.510229,
              55.977861
            ],
            [
              -159.375585,
              55.873799
            ],
            [
              -159.616571,
              55.594475
            ],
            [
              -159.676817,
              55.654722
            ],
            [
              -159.643955,
              55.829984
            ],
            [
              -159.813741,
              55.857368
            ],
            [
              -160.027341,
              55.791645
            ],
            [
              -160.060203,
              55.720445
            ],
            [
              -160.394296,
              55.605429
            ],
            [
              -160.536697,
              55.473983
            ],
            [
              -160.580512,
              55.567091
            ],
            [
              -160.668143,
              55.457552
            ],
            [
              -160.865313,
              55.528752
            ],
            [
              -161.232268,
              55.358967
            ],
            [
              -161.506115,
              55.364444
            ],
            [
              -161.467776,
              55.49589
            ],
            [
              -161.588269,
              55.62186
            ],
            [
              -161.697808,
              55.517798
            ],
            [
              -161.686854,
              55.408259
            ],
            [
              -162.053809,
              55.074166
            ],
            [
              -162.179779,
              55.15632
            ],
            [
              -162.218117,
              55.03035
            ],
            [
              -162.470057,
              55.052258
            ],
            [
              -162.508395,
              55.249428
            ],
            [
              -162.661749,
              55.293244
            ],
            [
              -162.716519,
              55.222043
            ],
            [
              -162.579595,
              55.134412
            ],
            [
              -162.645319,
              54.997489
            ],
            [
              -162.847965,
              54.926289
            ],
            [
              -163.00132,
              55.079643
            ],
            [
              -163.187536,
              55.090597
            ],
            [
              -163.220397,
              55.03035
            ],
            [
              -163.034181,
              54.942719
            ],
            [
              -163.373752,
              54.800319
            ],
            [
              -163.14372,
              54.76198
            ],
            [
              -163.138243,
              54.696257
            ],
            [
              -163.329936,
              54.74555
            ],
            [
              -163.587352,
              54.614103
            ],
            [
              -164.085754,
              54.61958
            ],
            [
              -164.332216,
              54.531949
            ],
            [
              -164.354124,
              54.466226
            ],
            [
              -164.638925,
              54.389548
            ],
            [
              -164.847049,
              54.416933
            ],
            [
              -164.918249,
              54.603149
            ],
            [
              -164.710125,
              54.663395
            ],
            [
              -164.551294,
              54.88795
            ],
            [
              -164.34317,
              54.893427
            ],
            [
              -163.894061,
              55.041304
            ],
            [
              -163.532583,
              55.046781
            ],
            [
              -163.39566,
              54.904381
            ],
            [
              -163.291598,
              55.008443
            ],
            [
              -163.313505,
              55.128935
            ],
            [
              -163.105382,
              55.183705
            ],
            [
              -162.880827,
              55.183705
            ],
            [
              -162.579595,
              55.446598
            ],
            [
              -162.245502,
              55.682106
            ],
            [
              -161.807347,
              55.89023
            ],
            [
              -161.292514,
              55.983338
            ],
            [
              -161.078914,
              55.939523
            ],
            [
              -160.87079,
              55.999769
            ],
            [
              -160.816021,
              55.912138
            ],
            [
              -160.931036,
              55.813553
            ],
            [
              -160.805067,
              55.736876
            ],
            [
              -160.766728,
              55.857368
            ],
            [
              -160.509312,
              55.868322
            ],
            [
              -160.438112,
              55.791645
            ],
            [
              -160.27928,
              55.76426
            ],
            [
              -160.273803,
              55.857368
            ],
            [
              -160.536697,
              55.939523
            ],
            [
              -160.558604,
              55.994292
            ],
            [
              -160.383342,
              56.251708
            ],
            [
              -160.147834,
              56.399586
            ],
            [
              -159.830171,
              56.541986
            ],
            [
              -159.326293,
              56.667956
            ],
            [
              -158.959338,
              56.848695
            ],
            [
              -158.784076,
              56.782971
            ],
            [
              -158.641675,
              56.810356
            ],
            [
              -158.701922,
              56.925372
            ],
            [
              -158.658106,
              57.034911
            ],
            [
              -158.378782,
              57.264942
            ],
            [
              -157.995396,
              57.41282
            ],
            [
              -157.688688,
              57.609989
            ],
            [
              -157.705118,
              57.719528
            ],
            [
              -157.458656,
              58.497254
            ],
            [
              -157.07527,
              58.705377
            ],
            [
              -157.119086,
              58.869686
            ],
            [
              -158.039212,
              58.634177
            ],
            [
              -158.32949,
              58.661562
            ],
            [
              -158.40069,
              58.760147
            ],
            [
              -158.564998,
              58.803962
            ],
            [
              -158.619768,
              58.913501
            ],
            [
              -158.767645,
              58.864209
            ],
            [
              -158.860753,
              58.694424
            ],
            [
              -158.701922,
              58.480823
            ],
            [
              -158.893615,
              58.387715
            ],
            [
              -159.0634,
              58.420577
            ],
            [
              -159.392016,
              58.760147
            ],
            [
              -159.616571,
              58.929932
            ],
            [
              -159.731586,
              58.929932
            ],
            [
              -159.808264,
              58.803962
            ],
            [
              -159.906848,
              58.782055
            ],
            [
              -160.054726,
              58.886116
            ],
            [
              -160.235465,
              58.902547
            ],
            [
              -160.317619,
              59.072332
            ],
            [
              -160.854359,
              58.88064
            ],
            [
              -161.33633,
              58.743716
            ],
            [
              -161.374669,
              58.667039
            ],
            [
              -161.752577,
              58.552023
            ],
            [
              -161.938793,
              58.656085
            ],
            [
              -161.769008,
              58.776578
            ],
            [
              -161.829255,
              59.061379
            ],
            [
              -161.955224,
              59.36261
            ],
            [
              -161.703285,
              59.48858
            ],
            [
              -161.911409,
              59.740519
            ],
            [
              -162.092148,
              59.88292
            ],
            [
              -162.234548,
              60.091043
            ],
            [
              -162.448149,
              60.178674
            ],
            [
              -162.502918,
              59.997935
            ],
            [
              -162.760334,
              59.959597
            ],
            [
              -163.171105,
              59.844581
            ],
            [
              -163.66403,
              59.795289
            ],
            [
              -163.9324,
              59.806242
            ],
            [
              -164.162431,
              59.866489
            ],
            [
              -164.189816,
              60.02532
            ],
            [
              -164.386986,
              60.074613
            ],
            [
              -164.699171,
              60.29369
            ],
            [
              -164.962064,
              60.337506
            ],
            [
              -165.268773,
              60.578491
            ],
            [
              -165.060649,
              60.68803
            ],
            [
              -165.016834,
              60.890677
            ],
            [
              -165.175665,
              60.846861
            ],
            [
              -165.197573,
              60.972831
            ],
            [
              -165.120896,
              61.076893
            ],
            [
              -165.323543,
              61.170001
            ],
            [
              -165.34545,
              61.071416
            ],
            [
              -165.591913,
              61.109754
            ],
            [
              -165.624774,
              61.279539
            ],
            [
              -165.816467,
              61.301447
            ],
            [
              -165.920529,
              61.416463
            ],
            [
              -165.915052,
              61.558863
            ],
            [
              -166.106745,
              61.49314
            ],
            [
              -166.139607,
              61.630064
            ],
            [
              -165.904098,
              61.662925
            ],
            [
              -166.095791,
              61.81628
            ],
            [
              -165.756221,
              61.827233
            ],
            [
              -165.756221,
              62.013449
            ],
            [
              -165.674067,
              62.139419
            ],
            [
              -165.044219,
              62.539236
            ],
            [
              -164.912772,
              62.659728
            ],
            [
              -164.819664,
              62.637821
            ],
            [
              -164.874433,
              62.807606
            ],
            [
              -164.633448,
              63.097884
            ],
            [
              -164.425324,
              63.212899
            ],
            [
              -164.036462,
              63.262192
            ],
            [
              -163.73523,
              63.212899
            ],
            [
              -163.313505,
              63.037637
            ],
            [
              -163.039658,
              63.059545
            ],
            [
              -162.661749,
              63.22933
            ],
            [
              -162.272887,
              63.486746
            ],
            [
              -162.075717,
              63.514131
            ],
            [
              -162.026424,
              63.448408
            ],
            [
              -161.555408,
              63.448408
            ],
            [
              -161.13916,
              63.503177
            ],
            [
              -160.766728,
              63.771547
            ],
            [
              -160.766728,
              63.837271
            ],
            [
              -160.952944,
              64.08921
            ],
            [
              -160.974852,
              64.237087
            ],
            [
              -161.26513,
              64.395918
            ],
            [
              -161.374669,
              64.532842
            ],
            [
              -161.078914,
              64.494503
            ],
            [
              -160.79959,
              64.609519
            ],
            [
              -160.783159,
              64.719058
            ],
            [
              -161.144637,
              64.921705
            ],
            [
              -161.413007,
              64.762873
            ],
            [
              -161.664946,
              64.790258
            ],
            [
              -161.900455,
              64.702627
            ],
            [
              -162.168825,
              64.680719
            ],
            [
              -162.234548,
              64.620473
            ],
            [
              -162.541257,
              64.532842
            ],
            [
              -162.634365,
              64.384965
            ],
            [
              -162.787719,
              64.324718
            ],
            [
              -162.858919,
              64.49998
            ],
            [
              -163.045135,
              64.538319
            ],
            [
              -163.176582,
              64.401395
            ],
            [
              -163.253259,
              64.467119
            ],
            [
              -163.598306,
              64.565704
            ],
            [
              -164.304832,
              64.560227
            ],
            [
              -164.80871,
              64.450688
            ],
            [
              -165.000403,
              64.434257
            ],
            [
              -165.411174,
              64.49998
            ],
            [
              -166.188899,
              64.576658
            ],
            [
              -166.391546,
              64.636904
            ],
            [
              -166.484654,
              64.735489
            ],
            [
              -166.413454,
              64.872412
            ],
            [
              -166.692778,
              64.987428
            ],
            [
              -166.638008,
              65.113398
            ],
            [
              -166.462746,
              65.179121
            ],
            [
              -166.517516,
              65.337952
            ],
            [
              -166.796839,
              65.337952
            ],
            [
              -167.026871,
              65.381768
            ],
            [
              -167.47598,
              65.414629
            ],
            [
              -167.711489,
              65.496784
            ],
            [
              -168.072967,
              65.578938
            ],
            [
              -168.105828,
              65.682999
            ],
            [
              -167.541703,
              65.819923
            ],
            [
              -166.829701,
              66.049954
            ],
            [
              -166.3313,
              66.186878
            ],
            [
              -166.046499,
              66.110201
            ],
            [
              -165.756221,
              66.09377
            ],
            [
              -165.690498,
              66.203309
            ],
            [
              -165.86576,
              66.21974
            ],
            [
              -165.88219,
              66.312848
            ],
            [
              -165.186619,
              66.466202
            ],
            [
              -164.403417,
              66.581218
            ],
            [
              -163.981692,
              66.592172
            ],
            [
              -163.751661,
              66.553833
            ],
            [
              -163.872153,
              66.389525
            ],
            [
              -163.828338,
              66.274509
            ],
            [
              -163.915969,
              66.192355
            ],
            [
              -163.768091,
              66.060908
            ],
            [
              -163.494244,
              66.082816
            ],
            [
              -163.149197,
              66.060908
            ],
            [
              -162.749381,
              66.088293
            ],
            [
              -162.634365,
              66.039001
            ],
            [
              -162.371472,
              66.028047
            ],
            [
              -162.14144,
              66.077339
            ],
            [
              -161.840208,
              66.02257
            ],
            [
              -161.549931,
              66.241647
            ],
            [
              -161.341807,
              66.252601
            ],
            [
              -161.199406,
              66.208786
            ],
            [
              -161.128206,
              66.334755
            ],
            [
              -161.528023,
              66.395002
            ],
            [
              -161.911409,
              66.345709
            ],
            [
              -161.87307,
              66.510017
            ],
            [
              -162.174302,
              66.68528
            ],
            [
              -162.502918,
              66.740049
            ],
            [
              -162.601503,
              66.89888
            ],
            [
              -162.344087,
              66.937219
            ],
            [
              -162.015471,
              66.778388
            ],
            [
              -162.075717,
              66.652418
            ],
            [
              -161.916886,
              66.553833
            ],
            [
              -161.571838,
              66.438817
            ],
            [
              -161.489684,
              66.55931
            ],
            [
              -161.884024,
              66.718141
            ],
            [
              -161.714239,
              67.002942
            ],
            [
              -161.851162,
              67.052235
            ],
            [
              -162.240025,
              66.991988
            ],
            [
              -162.639842,
              67.008419
            ],
            [
              -162.700088,
              67.057712
            ],
            [
              -162.902735,
              67.008419
            ],
            [
              -163.740707,
              67.128912
            ],
            [
              -163.757138,
              67.254881
            ],
            [
              -164.009077,
              67.534205
            ],
            [
              -164.211724,
              67.638267
            ],
            [
              -164.534863,
              67.725898
            ],
            [
              -165.192096,
              67.966884
            ],
            [
              -165.493328,
              68.059992
            ],
            [
              -165.794559,
              68.081899
            ],
            [
              -166.243668,
              68.246208
            ],
            [
              -166.681824,
              68.339316
            ],
            [
              -166.703731,
              68.372177
            ],
            [
              -166.375115,
              68.42147
            ],
            [
              -166.227238,
              68.574824
            ],
            [
              -166.216284,
              68.881533
            ],
            [
              -165.329019,
              68.859625
            ],
            [
              -164.255539,
              68.930825
            ],
            [
              -163.976215,
              68.985595
            ],
            [
              -163.532583,
              69.138949
            ],
            [
              -163.110859,
              69.374457
            ],
            [
              -163.023228,
              69.609966
            ],
            [
              -162.842489,
              69.812613
            ],
            [
              -162.470057,
              69.982398
            ],
            [
              -162.311225,
              70.108367
            ],
            [
              -161.851162,
              70.311014
            ],
            [
              -161.779962,
              70.256245
            ],
            [
              -161.396576,
              70.239814
            ],
            [
              -160.837928,
              70.343876
            ],
            [
              -160.487404,
              70.453415
            ],
            [
              -159.649432,
              70.792985
            ],
            [
              -159.33177,
              70.809416
            ],
            [
              -159.298908,
              70.760123
            ],
            [
              -158.975769,
              70.798462
            ],
            [
              -158.658106,
              70.787508
            ],
            [
              -158.033735,
              70.831323
            ],
            [
              -157.420318,
              70.979201
            ],
            [
              -156.812377,
              71.285909
            ],
            [
              -156.565915,
              71.351633
            ],
            [
              -156.522099,
              71.296863
            ],
            [
              -155.585543,
              71.170894
            ],
            [
              -155.508865,
              71.083263
            ],
            [
              -155.832005,
              70.968247
            ],
            [
              -155.979882,
              70.96277
            ],
            [
              -155.974405,
              70.809416
            ],
            [
              -155.503388,
              70.858708
            ],
            [
              -155.476004,
              70.940862
            ],
            [
              -155.262403,
              71.017539
            ],
            [
              -155.191203,
              70.973724
            ],
            [
              -155.032372,
              71.148986
            ],
            [
              -154.566832,
              70.990155
            ],
            [
              -154.643509,
              70.869662
            ],
            [
              -154.353231,
              70.8368
            ],
            [
              -154.183446,
              70.7656
            ],
            [
              -153.931507,
              70.880616
            ],
            [
              -153.487874,
              70.886093
            ],
            [
              -153.235935,
              70.924431
            ],
            [
              -152.589656,
              70.886093
            ],
            [
              -152.26104,
              70.842277
            ],
            [
              -152.419871,
              70.606769
            ],
            [
              -151.817408,
              70.546523
            ],
            [
              -151.773592,
              70.486276
            ],
            [
              -151.187559,
              70.382214
            ],
            [
              -151.182082,
              70.431507
            ],
            [
              -150.760358,
              70.49723
            ],
            [
              -150.355064,
              70.491753
            ],
            [
              -150.349588,
              70.436984
            ],
            [
              -150.114079,
              70.431507
            ],
            [
              -149.867617,
              70.508184
            ],
            [
              -149.462323,
              70.519138
            ],
            [
              -149.177522,
              70.486276
            ],
            [
              -148.78866,
              70.404122
            ],
            [
              -148.607921,
              70.420553
            ],
            [
              -148.350504,
              70.305537
            ],
            [
              -148.202627,
              70.349353
            ],
            [
              -147.961642,
              70.316491
            ],
            [
              -147.786379,
              70.245291
            ]
          ],
          [
            [
              -152.94018,
              58.026237
            ],
            [
              -152.945657,
              57.982421
            ],
            [
              -153.290705,
              58.048145
            ],
            [
              -153.044242,
              58.305561
            ],
            [
              -152.819688,
              58.327469
            ],
            [
              -152.666333,
              58.562977
            ],
            [
              -152.496548,
              58.354853
            ],
            [
              -152.354148,
              58.426053
            ],
            [
              -152.080301,
              58.311038
            ],
            [
              -152.080301,
              58.152206
            ],
            [
              -152.480117,
              58.130299
            ],
            [
              -152.655379,
              58.059098
            ],
            [
              -152.94018,
              58.026237
            ]
          ],
          [
            [
              -153.958891,
              57.538789
            ],
            [
              -153.67409,
              57.670236
            ],
            [
              -153.931507,
              57.69762
            ],
            [
              -153.936983,
              57.812636
            ],
            [
              -153.723383,
              57.889313
            ],
            [
              -153.570028,
              57.834544
            ],
            [
              -153.548121,
              57.719528
            ],
            [
              -153.46049,
              57.796205
            ],
            [
              -153.455013,
              57.96599
            ],
            [
              -153.268797,
              57.889313
            ],
            [
              -153.235935,
              57.998852
            ],
            [
              -153.071627,
              57.933129
            ],
            [
              -152.874457,
              57.933129
            ],
            [
              -152.721103,
              57.993375
            ],
            [
              -152.469163,
              57.889313
            ],
            [
              -152.469163,
              57.599035
            ],
            [
              -152.151501,
              57.620943
            ],
            [
              -152.359625,
              57.42925
            ],
            [
              -152.74301,
              57.505928
            ],
            [
              -152.60061,
              57.379958
            ],
            [
              -152.710149,
              57.275896
            ],
            [
              -152.907319,
              57.325188
            ],
            [
              -152.912796,
              57.128019
            ],
            [
              -153.214027,
              57.073249
            ],
            [
              -153.312612,
              56.991095
            ],
            [
              -153.498828,
              57.067772
            ],
            [
              -153.695998,
              56.859649
            ],
            [
              -153.849352,
              56.837741
            ],
            [
              -154.013661,
              56.744633
            ],
            [
              -154.073907,
              56.969187
            ],
            [
              -154.303938,
              56.848695
            ],
            [
              -154.314892,
              56.919895
            ],
            [
              -154.523016,
              56.991095
            ],
            [
              -154.539447,
              57.193742
            ],
            [
              -154.742094,
              57.275896
            ],
            [
              -154.627078,
              57.511404
            ],
            [
              -154.227261,
              57.659282
            ],
            [
              -153.980799,
              57.648328
            ],
            [
              -153.958891,
              57.538789
            ]
          ],
          [
            [
              -154.53397,
              56.602232
            ],
            [
              -154.742094,
              56.399586
            ],
            [
              -154.807817,
              56.432447
            ],
            [
              -154.53397,
              56.602232
            ]
          ],
          [
            [
              -155.634835,
              55.923092
            ],
            [
              -155.476004,
              55.912138
            ],
            [
              -155.530773,
              55.704014
            ],
            [
              -155.793666,
              55.731399
            ],
            [
              -155.837482,
              55.802599
            ],
            [
              -155.634835,
              55.923092
            ]
          ],
          [
            [
              -159.890418,
              55.28229
            ],
            [
              -159.950664,
              55.068689
            ],
            [
              -160.257373,
              54.893427
            ],
            [
              -160.109495,
              55.161797
            ],
            [
              -160.005433,
              55.134412
            ],
            [
              -159.890418,
              55.28229
            ]
          ],
          [
            [
              -160.520266,
              55.358967
            ],
            [
              -160.33405,
              55.358967
            ],
            [
              -160.339527,
              55.249428
            ],
            [
              -160.525743,
              55.128935
            ],
            [
              -160.690051,
              55.211089
            ],
            [
              -160.794113,
              55.134412
            ],
            [
              -160.854359,
              55.320628
            ],
            [
              -160.79959,
              55.380875
            ],
            [
              -160.520266,
              55.358967
            ]
          ],
          [
            [
              -162.256456,
              54.981058
            ],
            [
              -162.234548,
              54.893427
            ],
            [
              -162.349564,
              54.838658
            ],
            [
              -162.437195,
              54.931766
            ],
            [
              -162.256456,
              54.981058
            ]
          ],
          [
            [
              -162.415287,
              63.634624
            ],
            [
              -162.563165,
              63.536039
            ],
            [
              -162.612457,
              63.62367
            ],
            [
              -162.415287,
              63.634624
            ]
          ],
          [
            [
              -162.80415,
              54.488133
            ],
            [
              -162.590549,
              54.449795
            ],
            [
              -162.612457,
              54.367641
            ],
            [
              -162.782242,
              54.373118
            ],
            [
              -162.80415,
              54.488133
            ]
          ],
          [
            [
              -165.548097,
              54.29644
            ],
            [
              -165.476897,
              54.181425
            ],
            [
              -165.630251,
              54.132132
            ],
            [
              -165.685021,
              54.252625
            ],
            [
              -165.548097,
              54.29644
            ]
          ],
          [
            [
              -165.73979,
              54.15404
            ],
            [
              -166.046499,
              54.044501
            ],
            [
              -166.112222,
              54.121178
            ],
            [
              -165.980775,
              54.219763
            ],
            [
              -165.73979,
              54.15404
            ]
          ],
          [
            [
              -166.364161,
              60.359413
            ],
            [
              -166.13413,
              60.397752
            ],
            [
              -166.084837,
              60.326552
            ],
            [
              -165.88219,
              60.342983
            ],
            [
              -165.685021,
              60.277259
            ],
            [
              -165.646682,
              59.992458
            ],
            [
              -165.750744,
              59.89935
            ],
            [
              -166.00816,
              59.844581
            ],
            [
              -166.062929,
              59.745996
            ],
            [
              -166.440838,
              59.855535
            ],
            [
              -166.6161,
              59.850058
            ],
            [
              -166.994009,
              59.992458
            ],
            [
              -167.125456,
              59.992458
            ],
            [
              -167.344534,
              60.074613
            ],
            [
              -167.421211,
              60.206059
            ],
            [
              -167.311672,
              60.238921
            ],
            [
              -166.93924,
              60.206059
            ],
            [
              -166.763978,
              60.310121
            ],
            [
              -166.577762,
              60.321075
            ],
            [
              -166.495608,
              60.392275
            ],
            [
              -166.364161,
              60.359413
            ]
          ],
          [
            [
              -166.375115,
              54.01164
            ],
            [
              -166.210807,
              53.934962
            ],
            [
              -166.5449,
              53.748746
            ],
            [
              -166.539423,
              53.715885
            ],
            [
              -166.117699,
              53.852808
            ],
            [
              -166.112222,
              53.776131
            ],
            [
              -166.282007,
              53.683023
            ],
            [
              -166.555854,
              53.622777
            ],
            [
              -166.583239,
              53.529669
            ],
            [
              -166.878994,
              53.431084
            ],
            [
              -167.13641,
              53.425607
            ],
            [
              -167.306195,
              53.332499
            ],
            [
              -167.623857,
              53.250345
            ],
            [
              -167.793643,
              53.337976
            ],
            [
              -167.459549,
              53.442038
            ],
            [
              -167.355487,
              53.425607
            ],
            [
              -167.103548,
              53.513238
            ],
            [
              -167.163794,
              53.611823
            ],
            [
              -167.021394,
              53.715885
            ],
            [
              -166.807793,
              53.666592
            ],
            [
              -166.785886,
              53.732316
            ],
            [
              -167.015917,
              53.754223
            ],
            [
              -167.141887,
              53.825424
            ],
            [
              -167.032348,
              53.945916
            ],
            [
              -166.643485,
              54.017116
            ],
            [
              -166.561331,
              53.880193
            ],
            [
              -166.375115,
              54.01164
            ]
          ],
          [
            [
              -168.790446,
              53.157237
            ],
            [
              -168.40706,
              53.34893
            ],
            [
              -168.385152,
              53.431084
            ],
            [
              -168.237275,
              53.524192
            ],
            [
              -168.007243,
              53.568007
            ],
            [
              -167.886751,
              53.518715
            ],
            [
              -167.842935,
              53.387268
            ],
            [
              -168.270136,
              53.244868
            ],
            [
              -168.500168,
              53.036744
            ],
            [
              -168.686384,
              52.965544
            ],
            [
              -168.790446,
              53.157237
            ]
          ],
          [
            [
              -169.74891,
              52.894344
            ],
            [
              -169.705095,
              52.795759
            ],
            [
              -169.962511,
              52.790282
            ],
            [
              -169.989896,
              52.856005
            ],
            [
              -169.74891,
              52.894344
            ]
          ],
          [
            [
              -170.148727,
              57.221127
            ],
            [
              -170.28565,
              57.128019
            ],
            [
              -170.313035,
              57.221127
            ],
            [
              -170.148727,
              57.221127
            ]
          ],
          [
            [
              -170.669036,
              52.697174
            ],
            [
              -170.603313,
              52.604066
            ],
            [
              -170.789529,
              52.538343
            ],
            [
              -170.816914,
              52.636928
            ],
            [
              -170.669036,
              52.697174
            ]
          ],
          [
            [
              -171.742517,
              63.716778
            ],
            [
              -170.94836,
              63.5689
            ],
            [
              -170.488297,
              63.69487
            ],
            [
              -170.280174,
              63.683916
            ],
            [
              -170.093958,
              63.612716
            ],
            [
              -170.044665,
              63.492223
            ],
            [
              -169.644848,
              63.4265
            ],
            [
              -169.518879,
              63.366254
            ],
            [
              -168.99857,
              63.338869
            ],
            [
              -168.686384,
              63.295053
            ],
            [
              -168.856169,
              63.147176
            ],
            [
              -169.108108,
              63.180038
            ],
            [
              -169.376478,
              63.152653
            ],
            [
              -169.513402,
              63.08693
            ],
            [
              -169.639372,
              62.939052
            ],
            [
              -169.831064,
              63.075976
            ],
            [
              -170.055619,
              63.169084
            ],
            [
              -170.263743,
              63.180038
            ],
            [
              -170.362328,
              63.2841
            ],
            [
              -170.866206,
              63.415546
            ],
            [
              -171.101715,
              63.421023
            ],
            [
              -171.463193,
              63.306007
            ],
            [
              -171.73704,
              63.366254
            ],
            [
              -171.852055,
              63.486746
            ],
            [
              -171.742517,
              63.716778
            ]
          ],
          [
            [
              -172.432611,
              52.390465
            ],
            [
              -172.41618,
              52.275449
            ],
            [
              -172.607873,
              52.253542
            ],
            [
              -172.569535,
              52.352127
            ],
            [
              -172.432611,
              52.390465
            ]
          ],
          [
            [
              -173.626584,
              52.14948
            ],
            [
              -173.495138,
              52.105664
            ],
            [
              -173.122706,
              52.111141
            ],
            [
              -173.106275,
              52.07828
            ],
            [
              -173.549907,
              52.028987
            ],
            [
              -173.626584,
              52.14948
            ]
          ],
          [
            [
              -174.322156,
              52.280926
            ],
            [
              -174.327632,
              52.379511
            ],
            [
              -174.185232,
              52.41785
            ],
            [
              -173.982585,
              52.319265
            ],
            [
              -174.059262,
              52.226157
            ],
            [
              -174.179755,
              52.231634
            ],
            [
              -174.141417,
              52.127572
            ],
            [
              -174.333109,
              52.116618
            ],
            [
              -174.738403,
              52.007079
            ],
            [
              -174.968435,
              52.039941
            ],
            [
              -174.902711,
              52.116618
            ],
            [
              -174.656249,
              52.105664
            ],
            [
              -174.322156,
              52.280926
            ]
          ],
          [
            [
              -176.469116,
              51.853725
            ],
            [
              -176.288377,
              51.870156
            ],
            [
              -176.288377,
              51.744186
            ],
            [
              -176.518409,
              51.760617
            ],
            [
              -176.80321,
              51.61274
            ],
            [
              -176.912748,
              51.80991
            ],
            [
              -176.792256,
              51.815386
            ],
            [
              -176.775825,
              51.963264
            ],
            [
              -176.627947,
              51.968741
            ],
            [
              -176.627947,
              51.859202
            ],
            [
              -176.469116,
              51.853725
            ]
          ],
          [
            [
              -177.153734,
              51.946833
            ],
            [
              -177.044195,
              51.897541
            ],
            [
              -177.120872,
              51.727755
            ],
            [
              -177.274226,
              51.678463
            ],
            [
              -177.279703,
              51.782525
            ],
            [
              -177.153734,
              51.946833
            ]
          ],
          [
            [
              -178.123152,
              51.919448
            ],
            [
              -177.953367,
              51.913971
            ],
            [
              -177.800013,
              51.793479
            ],
            [
              -177.964321,
              51.651078
            ],
            [
              -178.123152,
              51.919448
            ]
          ],
          [
            [
              -187.107557,
              52.992929
            ],
            [
              -187.293773,
              52.927205
            ],
            [
              -187.304726,
              52.823143
            ],
            [
              -188.90491,
              52.762897
            ],
            [
              -188.642017,
              52.927205
            ],
            [
              -188.642017,
              53.003883
            ],
            [
              -187.107557,
              52.992929
            ]
          ]
        ],
        "iso_code": "AK"
      },
      {
        "name": "Arizona",
        "id": 3,
        "coordinates": [
          [
            [
              -109.042503,
              37.000263
            ],
            [
              -109.04798,
              31.331629
            ],
            [
              -111.074448,
              31.331629
            ],
            [
              -112.246513,
              31.704061
            ],
            [
              -114.815198,
              32.492741
            ],
            [
              -114.72209,
              32.717295
            ],
            [
              -114.524921,
              32.755634
            ],
            [
              -114.470151,
              32.843265
            ],
            [
              -114.524921,
              33.029481
            ],
            [
              -114.661844,
              33.034958
            ],
            [
              -114.727567,
              33.40739
            ],
            [
              -114.524921,
              33.54979
            ],
            [
              -114.497536,
              33.697668
            ],
            [
              -114.535874,
              33.933176
            ],
            [
              -114.415382,
              34.108438
            ],
            [
              -114.256551,
              34.174162
            ],
            [
              -114.136058,
              34.305608
            ],
            [
              -114.333228,
              34.448009
            ],
            [
              -114.470151,
              34.710902
            ],
            [
              -114.634459,
              34.87521
            ],
            [
              -114.634459,
              35.00118
            ],
            [
              -114.574213,
              35.138103
            ],
            [
              -114.596121,
              35.324319
            ],
            [
              -114.678275,
              35.516012
            ],
            [
              -114.738521,
              36.102045
            ],
            [
              -114.371566,
              36.140383
            ],
            [
              -114.251074,
              36.01989
            ],
            [
              -114.152489,
              36.025367
            ],
            [
              -114.048427,
              36.195153
            ],
            [
              -114.048427,
              37.000263
            ],
            [
              -110.499369,
              37.00574
            ],
            [
              -109.042503,
              37.000263
            ]
          ]
        ],
        "iso_code": "AZ"
      },
      {
        "name": "Arkansas",
        "id": 4,
        "coordinates": [
          [
            [
              -94.473842,
              36.501861
            ],
            [
              -90.152536,
              36.496384
            ],
            [
              -90.064905,
              36.304691
            ],
            [
              -90.218259,
              36.184199
            ],
            [
              -90.377091,
              35.997983
            ],
            [
              -89.730812,
              35.997983
            ],
            [
              -89.763673,
              35.811767
            ],
            [
              -89.911551,
              35.756997
            ],
            [
              -89.944412,
              35.603643
            ],
            [
              -90.130628,
              35.439335
            ],
            [
              -90.114197,
              35.198349
            ],
            [
              -90.212782,
              35.023087
            ],
            [
              -90.311367,
              34.995703
            ],
            [
              -90.251121,
              34.908072
            ],
            [
              -90.409952,
              34.831394
            ],
            [
              -90.481152,
              34.661609
            ],
            [
              -90.585214,
              34.617794
            ],
            [
              -90.568783,
              34.420624
            ],
            [
              -90.749522,
              34.365854
            ],
            [
              -90.744046,
              34.300131
            ],
            [
              -90.952169,
              34.135823
            ],
            [
              -90.891923,
              34.026284
            ],
            [
              -91.072662,
              33.867453
            ],
            [
              -91.231493,
              33.560744
            ],
            [
              -91.056231,
              33.429298
            ],
            [
              -91.143862,
              33.347144
            ],
            [
              -91.089093,
              33.13902
            ],
            [
              -91.16577,
              33.002096
            ],
            [
              -93.608485,
              33.018527
            ],
            [
              -94.041164,
              33.018527
            ],
            [
              -94.041164,
              33.54979
            ],
            [
              -94.183564,
              33.593606
            ],
            [
              -94.380734,
              33.544313
            ],
            [
              -94.484796,
              33.637421
            ],
            [
              -94.430026,
              35.395519
            ],
            [
              -94.616242,
              36.501861
            ],
            [
              -94.473842,
              36.501861
            ]
          ]
        ],
        "iso_code": "AR"
      },
      {
        "name": "California",
        "id": 5,
        "coordinates": [
          [
            [
              -123.233256,
              42.006186
            ],
            [
              -122.378853,
              42.011663
            ],
            [
              -121.037003,
              41.995232
            ],
            [
              -120.001861,
              41.995232
            ],
            [
              -119.996384,
              40.264519
            ],
            [
              -120.001861,
              38.999346
            ],
            [
              -118.71478,
              38.101128
            ],
            [
              -117.498899,
              37.21934
            ],
            [
              -116.540435,
              36.501861
            ],
            [
              -115.85034,
              35.970598
            ],
            [
              -114.634459,
              35.00118
            ],
            [
              -114.634459,
              34.87521
            ],
            [
              -114.470151,
              34.710902
            ],
            [
              -114.333228,
              34.448009
            ],
            [
              -114.136058,
              34.305608
            ],
            [
              -114.256551,
              34.174162
            ],
            [
              -114.415382,
              34.108438
            ],
            [
              -114.535874,
              33.933176
            ],
            [
              -114.497536,
              33.697668
            ],
            [
              -114.524921,
              33.54979
            ],
            [
              -114.727567,
              33.40739
            ],
            [
              -114.661844,
              33.034958
            ],
            [
              -114.524921,
              33.029481
            ],
            [
              -114.470151,
              32.843265
            ],
            [
              -114.524921,
              32.755634
            ],
            [
              -114.72209,
              32.717295
            ],
            [
              -116.04751,
              32.624187
            ],
            [
              -117.126467,
              32.536556
            ],
            [
              -117.24696,
              32.668003
            ],
            [
              -117.252437,
              32.876127
            ],
            [
              -117.329114,
              33.122589
            ],
            [
              -117.471515,
              33.297851
            ],
            [
              -117.7837,
              33.538836
            ],
            [
              -118.183517,
              33.763391
            ],
            [
              -118.260194,
              33.703145
            ],
            [
              -118.413548,
              33.741483
            ],
            [
              -118.391641,
              33.840068
            ],
            [
              -118.566903,
              34.042715
            ],
            [
              -118.802411,
              33.998899
            ],
            [
              -119.218659,
              34.146777
            ],
            [
              -119.278905,
              34.26727
            ],
            [
              -119.558229,
              34.415147
            ],
            [
              -119.875891,
              34.40967
            ],
            [
              -120.138784,
              34.475393
            ],
            [
              -120.472878,
              34.448009
            ],
            [
              -120.64814,
              34.579455
            ],
            [
              -120.609801,
              34.858779
            ],
            [
              -120.670048,
              34.902595
            ],
            [
              -120.631709,
              35.099764
            ],
            [
              -120.894602,
              35.247642
            ],
            [
              -120.905556,
              35.450289
            ],
            [
              -121.004141,
              35.461243
            ],
            [
              -121.168449,
              35.636505
            ],
            [
              -121.283465,
              35.674843
            ],
            [
              -121.332757,
              35.784382
            ],
            [
              -121.716143,
              36.195153
            ],
            [
              -121.896882,
              36.315645
            ],
            [
              -121.935221,
              36.638785
            ],
            [
              -121.858544,
              36.6114
            ],
            [
              -121.787344,
              36.803093
            ],
            [
              -121.929744,
              36.978355
            ],
            [
              -122.105006,
              36.956447
            ],
            [
              -122.335038,
              37.115279
            ],
            [
              -122.417192,
              37.241248
            ],
            [
              -122.400761,
              37.361741
            ],
            [
              -122.515777,
              37.520572
            ],
            [
              -122.515777,
              37.783465
            ],
            [
              -122.329561,
              37.783465
            ],
            [
              -122.406238,
              38.15042
            ],
            [
              -122.488392,
              38.112082
            ],
            [
              -122.504823,
              37.931343
            ],
            [
              -122.701993,
              37.893004
            ],
            [
              -122.937501,
              38.029928
            ],
            [
              -122.97584,
              38.265436
            ],
            [
              -123.129194,
              38.451652
            ],
            [
              -123.331841,
              38.566668
            ],
            [
              -123.44138,
              38.698114
            ],
            [
              -123.737134,
              38.95553
            ],
            [
              -123.687842,
              39.032208
            ],
            [
              -123.824765,
              39.366301
            ],
            [
              -123.764519,
              39.552517
            ],
            [
              -123.85215,
              39.831841
            ],
            [
              -124.109566,
              40.105688
            ],
            [
              -124.361506,
              40.259042
            ],
            [
              -124.410798,
              40.439781
            ],
            [
              -124.158859,
              40.877937
            ],
            [
              -124.109566,
              41.025814
            ],
            [
              -124.158859,
              41.14083
            ],
            [
              -124.065751,
              41.442061
            ],
            [
              -124.147905,
              41.715908
            ],
            [
              -124.257444,
              41.781632
            ],
            [
              -124.213628,
              42.000709
            ],
            [
              -123.233256,
              42.006186
            ]
          ]
        ],
        "iso_code": "CA"
      },
      {
        "name": "Colorado",
        "id": 6,
        "coordinates": [
          [
            [
              -107.919731,
              41.003906
            ],
            [
              -105.728954,
              40.998429
            ],
            [
              -104.053011,
              41.003906
            ],
            [
              -102.053927,
              41.003906
            ],
            [
              -102.053927,
              40.001626
            ],
            [
              -102.042974,
              36.994786
            ],
            [
              -103.001438,
              37.000263
            ],
            [
              -104.337812,
              36.994786
            ],
            [
              -106.868158,
              36.994786
            ],
            [
              -107.421329,
              37.000263
            ],
            [
              -109.042503,
              37.000263
            ],
            [
              -109.042503,
              38.166851
            ],
            [
              -109.058934,
              38.27639
            ],
            [
              -109.053457,
              39.125316
            ],
            [
              -109.04798,
              40.998429
            ],
            [
              -107.919731,
              41.003906
            ]
          ]
        ],
        "iso_code": "CO"
      },
      {
        "name": "Connecticut",
        "id": 7,
        "coordinates": [
          [
            [
              -73.053528,
              42.039048
            ],
            [
              -71.799309,
              42.022617
            ],
            [
              -71.799309,
              42.006186
            ],
            [
              -71.799309,
              41.414677
            ],
            [
              -71.859555,
              41.321569
            ],
            [
              -71.947186,
              41.338
            ],
            [
              -72.385341,
              41.261322
            ],
            [
              -72.905651,
              41.28323
            ],
            [
              -73.130205,
              41.146307
            ],
            [
              -73.371191,
              41.102491
            ],
            [
              -73.655992,
              40.987475
            ],
            [
              -73.727192,
              41.102491
            ],
            [
              -73.48073,
              41.21203
            ],
            [
              -73.55193,
              41.294184
            ],
            [
              -73.486206,
              42.050002
            ],
            [
              -73.053528,
              42.039048
            ]
          ]
        ],
        "iso_code": "CT"
      },
      {
        "name": "Delaware",
        "id": 8,
        "coordinates": [
          [
            [
              -75.414089,
              39.804456
            ],
            [
              -75.507197,
              39.683964
            ],
            [
              -75.611259,
              39.61824
            ],
            [
              -75.589352,
              39.459409
            ],
            [
              -75.441474,
              39.311532
            ],
            [
              -75.403136,
              39.065069
            ],
            [
              -75.189535,
              38.807653
            ],
            [
              -75.09095,
              38.796699
            ],
            [
              -75.047134,
              38.451652
            ],
            [
              -75.693413,
              38.462606
            ],
            [
              -75.786521,
              39.722302
            ],
            [
              -75.616736,
              39.831841
            ],
            [
              -75.414089,
              39.804456
            ]
          ]
        ],
        "iso_code": "DE"
      },
      {
        "name": "District of Columbia",
        "id": 9,
        "coordinates": [
          [
            [
              -77.035264,
              38.993869
            ],
            [
              -76.909294,
              38.895284
            ],
            [
              -77.040741,
              38.791222
            ],
            [
              -77.117418,
              38.933623
            ],
            [
              -77.035264,
              38.993869
            ]
          ]
        ],
        "iso_code": "DC"
      },
      {
        "name": "Florida",
        "id": 10,
        "coordinates": [
          [
            [
              -85.497137,
              30.997536
            ],
            [
              -85.004212,
              31.003013
            ],
            [
              -84.867289,
              30.712735
            ],
            [
              -83.498053,
              30.647012
            ],
            [
              -82.216449,
              30.570335
            ],
            [
              -82.167157,
              30.356734
            ],
            [
              -82.046664,
              30.362211
            ],
            [
              -82.002849,
              30.564858
            ],
            [
              -82.041187,
              30.751074
            ],
            [
              -81.948079,
              30.827751
            ],
            [
              -81.718048,
              30.745597
            ],
            [
              -81.444201,
              30.707258
            ],
            [
              -81.383954,
              30.27458
            ],
            [
              -81.257985,
              29.787132
            ],
            [
              -80.967707,
              29.14633
            ],
            [
              -80.524075,
              28.461713
            ],
            [
              -80.589798,
              28.41242
            ],
            [
              -80.56789,
              28.094758
            ],
            [
              -80.381674,
              27.738757
            ],
            [
              -80.091397,
              27.021277
            ],
            [
              -80.03115,
              26.796723
            ],
            [
              -80.036627,
              26.566691
            ],
            [
              -80.146166,
              25.739673
            ],
            [
              -80.239274,
              25.723243
            ],
            [
              -80.337859,
              25.465826
            ],
            [
              -80.304997,
              25.383672
            ],
            [
              -80.49669,
              25.197456
            ],
            [
              -80.573367,
              25.241272
            ],
            [
              -80.759583,
              25.164595
            ],
            [
              -81.077246,
              25.120779
            ],
            [
              -81.170354,
              25.224841
            ],
            [
              -81.126538,
              25.378195
            ],
            [
              -81.351093,
              25.821827
            ],
            [
              -81.526355,
              25.903982
            ],
            [
              -81.679709,
              25.843735
            ],
            [
              -81.800202,
              26.090198
            ],
            [
              -81.833064,
              26.292844
            ],
            [
              -82.041187,
              26.517399
            ],
            [
              -82.09048,
              26.665276
            ],
            [
              -82.057618,
              26.878877
            ],
            [
              -82.172634,
              26.917216
            ],
            [
              -82.145249,
              26.791246
            ],
            [
              -82.249311,
              26.758384
            ],
            [
              -82.566974,
              27.300601
            ],
            [
              -82.692943,
              27.437525
            ],
            [
              -82.391711,
              27.837342
            ],
            [
              -82.588881,
              27.815434
            ],
            [
              -82.720328,
              27.689464
            ],
            [
              -82.851774,
              27.886634
            ],
            [
              -82.676512,
              28.434328
            ],
            [
              -82.643651,
              28.888914
            ],
            [
              -82.764143,
              28.998453
            ],
            [
              -82.802482,
              29.14633
            ],
            [
              -82.994175,
              29.179192
            ],
            [
              -83.218729,
              29.420177
            ],
            [
              -83.399469,
              29.518762
            ],
            [
              -83.410422,
              29.66664
            ],
            [
              -83.536392,
              29.721409
            ],
            [
              -83.640454,
              29.885717
            ],
            [
              -84.02384,
              30.104795
            ],
            [
              -84.357933,
              30.055502
            ],
            [
              -84.341502,
              29.902148
            ],
            [
              -84.451041,
              29.929533
            ],
            [
              -84.867289,
              29.743317
            ],
            [
              -85.310921,
              29.699501
            ],
            [
              -85.299967,
              29.80904
            ],
            [
              -85.404029,
              29.940487
            ],
            [
              -85.924338,
              30.236241
            ],
            [
              -86.29677,
              30.362211
            ],
            [
              -86.630863,
              30.395073
            ],
            [
              -86.910187,
              30.373165
            ],
            [
              -87.518128,
              30.280057
            ],
            [
              -87.37025,
              30.427934
            ],
            [
              -87.446927,
              30.510088
            ],
            [
              -87.408589,
              30.674397
            ],
            [
              -87.633143,
              30.86609
            ],
            [
              -87.600282,
              30.997536
            ],
            [
              -85.497137,
              30.997536
            ]
          ]
        ],
        "iso_code": "FL"
      },
      {
        "name": "Georgia",
        "id": 11,
        "coordinates": [
          [
            [
              -83.109191,
              35.00118
            ],
            [
              -83.322791,
              34.787579
            ],
            [
              -83.339222,
              34.683517
            ],
            [
              -83.005129,
              34.469916
            ],
            [
              -82.901067,
              34.486347
            ],
            [
              -82.747713,
              34.26727
            ],
            [
              -82.714851,
              34.152254
            ],
            [
              -82.55602,
              33.94413
            ],
            [
              -82.325988,
              33.81816
            ],
            [
              -82.194542,
              33.631944
            ],
            [
              -81.926172,
              33.462159
            ],
            [
              -81.937125,
              33.347144
            ],
            [
              -81.761863,
              33.160928
            ],
            [
              -81.493493,
              33.007573
            ],
            [
              -81.42777,
              32.843265
            ],
            [
              -81.416816,
              32.629664
            ],
            [
              -81.279893,
              32.558464
            ],
            [
              -81.121061,
              32.290094
            ],
            [
              -81.115584,
              32.120309
            ],
            [
              -80.885553,
              32.032678
            ],
            [
              -81.132015,
              31.693108
            ],
            [
              -81.175831,
              31.517845
            ],
            [
              -81.279893,
              31.364491
            ],
            [
              -81.290846,
              31.20566
            ],
            [
              -81.400385,
              31.13446
            ],
            [
              -81.444201,
              30.707258
            ],
            [
              -81.718048,
              30.745597
            ],
            [
              -81.948079,
              30.827751
            ],
            [
              -82.041187,
              30.751074
            ],
            [
              -82.002849,
              30.564858
            ],
            [
              -82.046664,
              30.362211
            ],
            [
              -82.167157,
              30.356734
            ],
            [
              -82.216449,
              30.570335
            ],
            [
              -83.498053,
              30.647012
            ],
            [
              -84.867289,
              30.712735
            ],
            [
              -85.004212,
              31.003013
            ],
            [
              -85.113751,
              31.27686
            ],
            [
              -85.042551,
              31.539753
            ],
            [
              -85.141136,
              31.840985
            ],
            [
              -85.053504,
              32.01077
            ],
            [
              -85.058981,
              32.13674
            ],
            [
              -84.889196,
              32.262709
            ],
            [
              -85.004212,
              32.322956
            ],
            [
              -84.960397,
              32.421541
            ],
            [
              -85.069935,
              32.580372
            ],
            [
              -85.184951,
              32.859696
            ],
            [
              -85.431413,
              34.124869
            ],
            [
              -85.606675,
              34.984749
            ],
            [
              -84.319594,
              34.990226
            ],
            [
              -83.618546,
              34.984749
            ],
            [
              -83.109191,
              35.00118
            ]
          ]
        ],
        "iso_code": "GA"
      },
      {
        "name": "Hawaii",
        "id": 12,
        "coordinates": [
          [
            [
              -155.634835,
              18.948267
            ],
            [
              -155.881297,
              19.035898
            ],
            [
              -155.919636,
              19.123529
            ],
            [
              -155.886774,
              19.348084
            ],
            [
              -156.062036,
              19.73147
            ],
            [
              -155.925113,
              19.857439
            ],
            [
              -155.826528,
              20.032702
            ],
            [
              -155.897728,
              20.147717
            ],
            [
              -155.87582,
              20.26821
            ],
            [
              -155.596496,
              20.12581
            ],
            [
              -155.284311,
              20.021748
            ],
            [
              -155.092618,
              19.868393
            ],
            [
              -155.092618,
              19.736947
            ],
            [
              -154.807817,
              19.523346
            ],
            [
              -154.983079,
              19.348084
            ],
            [
              -155.295265,
              19.26593
            ],
            [
              -155.514342,
              19.134483
            ],
            [
              -155.634835,
              18.948267
            ]
          ],
          [
            [
              -156.587823,
              21.029505
            ],
            [
              -156.472807,
              20.892581
            ],
            [
              -156.324929,
              20.952827
            ],
            [
              -156.00179,
              20.793996
            ],
            [
              -156.051082,
              20.651596
            ],
            [
              -156.379699,
              20.580396
            ],
            [
              -156.445422,
              20.60778
            ],
            [
              -156.461853,
              20.783042
            ],
            [
              -156.631638,
              20.821381
            ],
            [
              -156.697361,
              20.919966
            ],
            [
              -156.587823,
              21.029505
            ]
          ],
          [
            [
              -156.982162,
              21.210244
            ],
            [
              -157.080747,
              21.106182
            ],
            [
              -157.310779,
              21.106182
            ],
            [
              -157.239579,
              21.221198
            ],
            [
              -156.982162,
              21.210244
            ]
          ],
          [
            [
              -157.951581,
              21.697691
            ],
            [
              -157.842042,
              21.462183
            ],
            [
              -157.896811,
              21.325259
            ],
            [
              -158.110412,
              21.303352
            ],
            [
              -158.252813,
              21.582676
            ],
            [
              -158.126843,
              21.588153
            ],
            [
              -157.951581,
              21.697691
            ]
          ],
          [
            [
              -159.468693,
              22.228955
            ],
            [
              -159.353678,
              22.218001
            ],
            [
              -159.298908,
              22.113939
            ],
            [
              -159.33177,
              21.966061
            ],
            [
              -159.446786,
              21.872953
            ],
            [
              -159.764448,
              21.987969
            ],
            [
              -159.726109,
              22.152277
            ],
            [
              -159.468693,
              22.228955
            ]
          ]
        ],
        "iso_code": "HI"
      },
      {
        "name": "Idaho",
        "id": 13,
        "coordinates": [
          [
            [
              -116.04751,
              49.000239
            ],
            [
              -116.04751,
              47.976051
            ],
            [
              -115.724371,
              47.696727
            ],
            [
              -115.718894,
              47.42288
            ],
            [
              -115.527201,
              47.302388
            ],
            [
              -115.324554,
              47.258572
            ],
            [
              -115.302646,
              47.187372
            ],
            [
              -114.930214,
              46.919002
            ],
            [
              -114.886399,
              46.809463
            ],
            [
              -114.623506,
              46.705401
            ],
            [
              -114.612552,
              46.639678
            ],
            [
              -114.322274,
              46.645155
            ],
            [
              -114.464674,
              46.272723
            ],
            [
              -114.492059,
              46.037214
            ],
            [
              -114.387997,
              45.88386
            ],
            [
              -114.568736,
              45.774321
            ],
            [
              -114.497536,
              45.670259
            ],
            [
              -114.546828,
              45.560721
            ],
            [
              -114.333228,
              45.456659
            ],
            [
              -114.086765,
              45.593582
            ],
            [
              -113.98818,
              45.703121
            ],
            [
              -113.807441,
              45.604536
            ],
            [
              -113.834826,
              45.522382
            ],
            [
              -113.736241,
              45.330689
            ],
            [
              -113.571933,
              45.128042
            ],
            [
              -113.45144,
              45.056842
            ],
            [
              -113.456917,
              44.865149
            ],
            [
              -113.341901,
              44.782995
            ],
            [
              -113.133778,
              44.772041
            ],
            [
              -113.002331,
              44.448902
            ],
            [
              -112.887315,
              44.394132
            ],
            [
              -112.783254,
              44.48724
            ],
            [
              -112.471068,
              44.481763
            ],
            [
              -112.241036,
              44.569394
            ],
            [
              -112.104113,
              44.520102
            ],
            [
              -111.868605,
              44.563917
            ],
            [
              -111.819312,
              44.509148
            ],
            [
              -111.616665,
              44.547487
            ],
            [
              -111.386634,
              44.75561
            ],
            [
              -111.227803,
              44.580348
            ],
            [
              -111.047063,
              44.476286
            ],
            [
              -111.047063,
              42.000709
            ],
            [
              -112.164359,
              41.995232
            ],
            [
              -114.04295,
              41.995232
            ],
            [
              -117.027882,
              42.000709
            ],
            [
              -117.027882,
              43.830007
            ],
            [
              -116.896436,
              44.158624
            ],
            [
              -116.97859,
              44.240778
            ],
            [
              -117.170283,
              44.257209
            ],
            [
              -117.241483,
              44.394132
            ],
            [
              -117.038836,
              44.750133
            ],
            [
              -116.934774,
              44.782995
            ],
            [
              -116.830713,
              44.930872
            ],
            [
              -116.847143,
              45.02398
            ],
            [
              -116.732128,
              45.144473
            ],
            [
              -116.671881,
              45.319735
            ],
            [
              -116.463758,
              45.61549
            ],
            [
              -116.545912,
              45.752413
            ],
            [
              -116.78142,
              45.823614
            ],
            [
              -116.918344,
              45.993399
            ],
            [
              -116.92382,
              46.168661
            ],
            [
              -117.055267,
              46.343923
            ],
            [
              -117.038836,
              46.426077
            ],
            [
              -117.044313,
              47.762451
            ],
            [
              -117.033359,
              49.000239
            ],
            [
              -116.04751,
              49.000239
            ]
          ]
        ],
        "iso_code": "ID"
      },
      {
        "name": "Illinois",
        "id": 14,
        "coordinates": [
          [
            [
              -90.639984,
              42.510065
            ],
            [
              -88.788778,
              42.493634
            ],
            [
              -87.802929,
              42.493634
            ],
            [
              -87.83579,
              42.301941
            ],
            [
              -87.682436,
              42.077386
            ],
            [
              -87.523605,
              41.710431
            ],
            [
              -87.529082,
              39.34987
            ],
            [
              -87.63862,
              39.169131
            ],
            [
              -87.512651,
              38.95553
            ],
            [
              -87.49622,
              38.780268
            ],
            [
              -87.62219,
              38.637868
            ],
            [
              -87.655051,
              38.506421
            ],
            [
              -87.83579,
              38.292821
            ],
            [
              -87.950806,
              38.27639
            ],
            [
              -87.923421,
              38.15042
            ],
            [
              -88.000098,
              38.101128
            ],
            [
              -88.060345,
              37.865619
            ],
            [
              -88.027483,
              37.799896
            ],
            [
              -88.15893,
              37.657496
            ],
            [
              -88.065822,
              37.482234
            ],
            [
              -88.476592,
              37.389126
            ],
            [
              -88.514931,
              37.285064
            ],
            [
              -88.421823,
              37.153617
            ],
            [
              -88.547792,
              37.071463
            ],
            [
              -88.914747,
              37.224817
            ],
            [
              -89.029763,
              37.213863
            ],
            [
              -89.183118,
              37.038601
            ],
            [
              -89.133825,
              36.983832
            ],
            [
              -89.292656,
              36.994786
            ],
            [
              -89.517211,
              37.279587
            ],
            [
              -89.435057,
              37.34531
            ],
            [
              -89.517211,
              37.537003
            ],
            [
              -89.517211,
              37.690357
            ],
            [
              -89.84035,
              37.903958
            ],
            [
              -89.949889,
              37.88205
            ],
            [
              -90.059428,
              38.013497
            ],
            [
              -90.355183,
              38.216144
            ],
            [
              -90.349706,
              38.374975
            ],
            [
              -90.179921,
              38.632391
            ],
            [
              -90.207305,
              38.725499
            ],
            [
              -90.10872,
              38.845992
            ],
            [
              -90.251121,
              38.917192
            ],
            [
              -90.470199,
              38.961007
            ],
            [
              -90.585214,
              38.867899
            ],
            [
              -90.661891,
              38.928146
            ],
            [
              -90.727615,
              39.256762
            ],
            [
              -91.061708,
              39.470363
            ],
            [
              -91.368417,
              39.727779
            ],
            [
              -91.494386,
              40.034488
            ],
            [
              -91.50534,
              40.237135
            ],
            [
              -91.417709,
              40.379535
            ],
            [
              -91.401278,
              40.560274
            ],
            [
              -91.121954,
              40.669813
            ],
            [
              -91.09457,
              40.823167
            ],
            [
              -90.963123,
              40.921752
            ],
            [
              -90.946692,
              41.097014
            ],
            [
              -91.111001,
              41.239415
            ],
            [
              -91.045277,
              41.414677
            ],
            [
              -90.656414,
              41.463969
            ],
            [
              -90.344229,
              41.589939
            ],
            [
              -90.311367,
              41.743293
            ],
            [
              -90.179921,
              41.809016
            ],
            [
              -90.141582,
              42.000709
            ],
            [
              -90.168967,
              42.126679
            ],
            [
              -90.393521,
              42.225264
            ],
            [
              -90.420906,
              42.329326
            ],
            [
              -90.639984,
              42.510065
            ]
          ]
        ],
        "iso_code": "IL"
      },
      {
        "name": "Indiana",
        "id": 15,
        "coordinates": [
          [
            [
              -85.990061,
              41.759724
            ],
            [
              -84.807042,
              41.759724
            ],
            [
              -84.807042,
              41.694001
            ],
            [
              -84.801565,
              40.500028
            ],
            [
              -84.817996,
              39.103408
            ],
            [
              -84.894673,
              39.059592
            ],
            [
              -84.812519,
              38.785745
            ],
            [
              -84.987781,
              38.780268
            ],
            [
              -85.173997,
              38.68716
            ],
            [
              -85.431413,
              38.730976
            ],
            [
              -85.42046,
              38.533806
            ],
            [
              -85.590245,
              38.451652
            ],
            [
              -85.655968,
              38.325682
            ],
            [
              -85.83123,
              38.27639
            ],
            [
              -85.924338,
              38.024451
            ],
            [
              -86.039354,
              37.958727
            ],
            [
              -86.263908,
              38.051835
            ],
            [
              -86.302247,
              38.166851
            ],
            [
              -86.521325,
              38.040881
            ],
            [
              -86.504894,
              37.931343
            ],
            [
              -86.729448,
              37.893004
            ],
            [
              -86.795172,
              37.991589
            ],
            [
              -87.047111,
              37.893004
            ],
            [
              -87.129265,
              37.788942
            ],
            [
              -87.381204,
              37.93682
            ],
            [
              -87.512651,
              37.903958
            ],
            [
              -87.600282,
              37.975158
            ],
            [
              -87.682436,
              37.903958
            ],
            [
              -87.934375,
              37.893004
            ],
            [
              -88.027483,
              37.799896
            ],
            [
              -88.060345,
              37.865619
            ],
            [
              -88.000098,
              38.101128
            ],
            [
              -87.923421,
              38.15042
            ],
            [
              -87.950806,
              38.27639
            ],
            [
              -87.83579,
              38.292821
            ],
            [
              -87.655051,
              38.506421
            ],
            [
              -87.62219,
              38.637868
            ],
            [
              -87.49622,
              38.780268
            ],
            [
              -87.512651,
              38.95553
            ],
            [
              -87.63862,
              39.169131
            ],
            [
              -87.529082,
              39.34987
            ],
            [
              -87.523605,
              41.710431
            ],
            [
              -87.42502,
              41.644708
            ],
            [
              -87.118311,
              41.644708
            ],
            [
              -86.822556,
              41.759724
            ],
            [
              -85.990061,
              41.759724
            ]
          ]
        ],
        "iso_code": "IN"
      },
      {
        "name": "Iowa",
        "id": 16,
        "coordinates": [
          [
            [
              -91.368417,
              43.501391
            ],
            [
              -91.215062,
              43.501391
            ],
            [
              -91.204109,
              43.353514
            ],
            [
              -91.056231,
              43.254929
            ],
            [
              -91.176724,
              43.134436
            ],
            [
              -91.143862,
              42.909881
            ],
            [
              -91.067185,
              42.75105
            ],
            [
              -90.711184,
              42.636034
            ],
            [
              -90.639984,
              42.510065
            ],
            [
              -90.420906,
              42.329326
            ],
            [
              -90.393521,
              42.225264
            ],
            [
              -90.168967,
              42.126679
            ],
            [
              -90.141582,
              42.000709
            ],
            [
              -90.179921,
              41.809016
            ],
            [
              -90.311367,
              41.743293
            ],
            [
              -90.344229,
              41.589939
            ],
            [
              -90.656414,
              41.463969
            ],
            [
              -91.045277,
              41.414677
            ],
            [
              -91.111001,
              41.239415
            ],
            [
              -90.946692,
              41.097014
            ],
            [
              -90.963123,
              40.921752
            ],
            [
              -91.09457,
              40.823167
            ],
            [
              -91.121954,
              40.669813
            ],
            [
              -91.401278,
              40.560274
            ],
            [
              -91.417709,
              40.379535
            ],
            [
              -91.527248,
              40.412397
            ],
            [
              -91.729895,
              40.615043
            ],
            [
              -91.833957,
              40.609566
            ],
            [
              -93.257961,
              40.582182
            ],
            [
              -94.632673,
              40.571228
            ],
            [
              -95.7664,
              40.587659
            ],
            [
              -95.881416,
              40.719105
            ],
            [
              -95.826646,
              40.976521
            ],
            [
              -95.925231,
              41.201076
            ],
            [
              -95.919754,
              41.453015
            ],
            [
              -96.095016,
              41.540646
            ],
            [
              -96.122401,
              41.67757
            ],
            [
              -96.062155,
              41.798063
            ],
            [
              -96.127878,
              41.973325
            ],
            [
              -96.264801,
              42.039048
            ],
            [
              -96.44554,
              42.488157
            ],
            [
              -96.631756,
              42.707235
            ],
            [
              -96.544125,
              42.855112
            ],
            [
              -96.511264,
              43.052282
            ],
            [
              -96.434587,
              43.123482
            ],
            [
              -96.560556,
              43.222067
            ],
            [
              -96.527695,
              43.397329
            ],
            [
              -96.582464,
              43.479483
            ],
            [
              -96.451017,
              43.501391
            ],
            [
              -91.368417,
              43.501391
            ]
          ]
        ],
        "iso_code": "IA"
      },
      {
        "name": "Kansas",
        "id": 17,
        "coordinates": [
          [
            [
              -101.90605,
              40.001626
            ],
            [
              -95.306337,
              40.001626
            ],
            [
              -95.207752,
              39.908518
            ],
            [
              -94.884612,
              39.831841
            ],
            [
              -95.109167,
              39.541563
            ],
            [
              -94.983197,
              39.442978
            ],
            [
              -94.824366,
              39.20747
            ],
            [
              -94.610765,
              39.158177
            ],
            [
              -94.616242,
              37.000263
            ],
            [
              -100.087706,
              37.000263
            ],
            [
              -102.042974,
              36.994786
            ],
            [
              -102.053927,
              40.001626
            ],
            [
              -101.90605,
              40.001626
            ]
          ]
        ],
        "iso_code": "KS"
      },
      {
        "name": "Kentucky",
        "id": 18,
        "coordinates": [
          [
            [
              -83.903347,
              38.769315
            ],
            [
              -83.678792,
              38.632391
            ],
            [
              -83.519961,
              38.703591
            ],
            [
              -83.142052,
              38.626914
            ],
            [
              -83.032514,
              38.725499
            ],
            [
              -82.890113,
              38.758361
            ],
            [
              -82.846298,
              38.588575
            ],
            [
              -82.731282,
              38.561191
            ],
            [
              -82.594358,
              38.424267
            ],
            [
              -82.621743,
              38.123036
            ],
            [
              -82.50125,
              37.931343
            ],
            [
              -82.342419,
              37.783465
            ],
            [
              -82.293127,
              37.668449
            ],
            [
              -82.101434,
              37.553434
            ],
            [
              -81.969987,
              37.537003
            ],
            [
              -82.353373,
              37.268633
            ],
            [
              -82.720328,
              37.120755
            ],
            [
              -82.720328,
              37.044078
            ],
            [
              -82.868205,
              36.978355
            ],
            [
              -82.879159,
              36.890724
            ],
            [
              -83.070852,
              36.852385
            ],
            [
              -83.136575,
              36.742847
            ],
            [
              -83.673316,
              36.600446
            ],
            [
              -83.689746,
              36.584015
            ],
            [
              -84.544149,
              36.594969
            ],
            [
              -85.289013,
              36.627831
            ],
            [
              -85.486183,
              36.616877
            ],
            [
              -86.592525,
              36.655216
            ],
            [
              -87.852221,
              36.633308
            ],
            [
              -88.071299,
              36.677123
            ],
            [
              -88.054868,
              36.496384
            ],
            [
              -89.298133,
              36.507338
            ],
            [
              -89.418626,
              36.496384
            ],
            [
              -89.363857,
              36.622354
            ],
            [
              -89.215979,
              36.578538
            ],
            [
              -89.133825,
              36.983832
            ],
            [
              -89.183118,
              37.038601
            ],
            [
              -89.029763,
              37.213863
            ],
            [
              -88.914747,
              37.224817
            ],
            [
              -88.547792,
              37.071463
            ],
            [
              -88.421823,
              37.153617
            ],
            [
              -88.514931,
              37.285064
            ],
            [
              -88.476592,
              37.389126
            ],
            [
              -88.065822,
              37.482234
            ],
            [
              -88.15893,
              37.657496
            ],
            [
              -88.027483,
              37.799896
            ],
            [
              -87.934375,
              37.893004
            ],
            [
              -87.682436,
              37.903958
            ],
            [
              -87.600282,
              37.975158
            ],
            [
              -87.512651,
              37.903958
            ],
            [
              -87.381204,
              37.93682
            ],
            [
              -87.129265,
              37.788942
            ],
            [
              -87.047111,
              37.893004
            ],
            [
              -86.795172,
              37.991589
            ],
            [
              -86.729448,
              37.893004
            ],
            [
              -86.504894,
              37.931343
            ],
            [
              -86.521325,
              38.040881
            ],
            [
              -86.302247,
              38.166851
            ],
            [
              -86.263908,
              38.051835
            ],
            [
              -86.039354,
              37.958727
            ],
            [
              -85.924338,
              38.024451
            ],
            [
              -85.83123,
              38.27639
            ],
            [
              -85.655968,
              38.325682
            ],
            [
              -85.590245,
              38.451652
            ],
            [
              -85.42046,
              38.533806
            ],
            [
              -85.431413,
              38.730976
            ],
            [
              -85.173997,
              38.68716
            ],
            [
              -84.987781,
              38.780268
            ],
            [
              -84.812519,
              38.785745
            ],
            [
              -84.894673,
              39.059592
            ],
            [
              -84.817996,
              39.103408
            ],
            [
              -84.43461,
              39.103408
            ],
            [
              -84.231963,
              38.895284
            ],
            [
              -84.215533,
              38.807653
            ],
            [
              -83.903347,
              38.769315
            ]
          ]
        ],
        "iso_code": "KY"
      },
      {
        "name": "Louisiana",
        "id": 19,
        "coordinates": [
          [
            [
              -93.608485,
              33.018527
            ],
            [
              -91.16577,
              33.002096
            ],
            [
              -91.072662,
              32.887081
            ],
            [
              -91.143862,
              32.843265
            ],
            [
              -91.154816,
              32.640618
            ],
            [
              -91.006939,
              32.514649
            ],
            [
              -90.985031,
              32.218894
            ],
            [
              -91.105524,
              31.988862
            ],
            [
              -91.341032,
              31.846462
            ],
            [
              -91.401278,
              31.621907
            ],
            [
              -91.499863,
              31.643815
            ],
            [
              -91.516294,
              31.27686
            ],
            [
              -91.636787,
              31.265906
            ],
            [
              -91.565587,
              31.068736
            ],
            [
              -91.636787,
              30.997536
            ],
            [
              -89.747242,
              30.997536
            ],
            [
              -89.845827,
              30.66892
            ],
            [
              -89.681519,
              30.449842
            ],
            [
              -89.643181,
              30.285534
            ],
            [
              -89.522688,
              30.181472
            ],
            [
              -89.818443,
              30.044549
            ],
            [
              -89.84035,
              29.945964
            ],
            [
              -89.599365,
              29.88024
            ],
            [
              -89.495303,
              30.039072
            ],
            [
              -89.287179,
              29.88024
            ],
            [
              -89.30361,
              29.754271
            ],
            [
              -89.424103,
              29.699501
            ],
            [
              -89.648657,
              29.748794
            ],
            [
              -89.621273,
              29.655686
            ],
            [
              -89.69795,
              29.513285
            ],
            [
              -89.506257,
              29.387316
            ],
            [
              -89.199548,
              29.348977
            ],
            [
              -89.09001,
              29.2011
            ],
            [
              -89.002379,
              29.179192
            ],
            [
              -89.16121,
              29.009407
            ],
            [
              -89.336472,
              29.042268
            ],
            [
              -89.484349,
              29.217531
            ],
            [
              -89.851304,
              29.310638
            ],
            [
              -89.851304,
              29.480424
            ],
            [
              -90.032043,
              29.425654
            ],
            [
              -90.021089,
              29.283254
            ],
            [
              -90.103244,
              29.151807
            ],
            [
              -90.23469,
              29.129899
            ],
            [
              -90.333275,
              29.277777
            ],
            [
              -90.563307,
              29.283254
            ],
            [
              -90.645461,
              29.129899
            ],
            [
              -90.798815,
              29.086084
            ],
            [
              -90.963123,
              29.179192
            ],
            [
              -91.09457,
              29.190146
            ],
            [
              -91.220539,
              29.436608
            ],
            [
              -91.445094,
              29.546147
            ],
            [
              -91.532725,
              29.529716
            ],
            [
              -91.620356,
              29.73784
            ],
            [
              -91.883249,
              29.710455
            ],
            [
              -91.888726,
              29.836425
            ],
            [
              -92.146142,
              29.715932
            ],
            [
              -92.113281,
              29.622824
            ],
            [
              -92.31045,
              29.535193
            ],
            [
              -92.617159,
              29.579009
            ],
            [
              -92.97316,
              29.715932
            ],
            [
              -93.2251,
              29.776178
            ],
            [
              -93.767317,
              29.726886
            ],
            [
              -93.838517,
              29.688547
            ],
            [
              -93.926148,
              29.787132
            ],
            [
              -93.690639,
              30.143133
            ],
            [
              -93.767317,
              30.334826
            ],
            [
              -93.696116,
              30.438888
            ],
            [
              -93.728978,
              30.575812
            ],
            [
              -93.630393,
              30.679874
            ],
            [
              -93.526331,
              30.93729
            ],
            [
              -93.542762,
              31.15089
            ],
            [
              -93.816609,
              31.556184
            ],
            [
              -93.822086,
              31.775262
            ],
            [
              -94.041164,
              31.994339
            ],
            [
              -94.041164,
              33.018527
            ],
            [
              -93.608485,
              33.018527
            ]
          ]
        ],
        "iso_code": "LA"
      },
      {
        "name": "Maine",
        "id": 20,
        "coordinates": [
          [
            [
              -70.703921,
              43.057759
            ],
            [
              -70.824413,
              43.128959
            ],
            [
              -70.807983,
              43.227544
            ],
            [
              -70.966814,
              43.34256
            ],
            [
              -71.032537,
              44.657025
            ],
            [
              -71.08183,
              45.303304
            ],
            [
              -70.649151,
              45.440228
            ],
            [
              -70.720352,
              45.511428
            ],
            [
              -70.556043,
              45.664782
            ],
            [
              -70.386258,
              45.735983
            ],
            [
              -70.41912,
              45.796229
            ],
            [
              -70.260289,
              45.889337
            ],
            [
              -70.309581,
              46.064599
            ],
            [
              -70.210996,
              46.327492
            ],
            [
              -70.057642,
              46.415123
            ],
            [
              -69.997395,
              46.694447
            ],
            [
              -69.225147,
              47.461219
            ],
            [
              -69.044408,
              47.428357
            ],
            [
              -69.033454,
              47.242141
            ],
            [
              -68.902007,
              47.176418
            ],
            [
              -68.578868,
              47.285957
            ],
            [
              -68.376221,
              47.285957
            ],
            [
              -68.233821,
              47.357157
            ],
            [
              -67.954497,
              47.198326
            ],
            [
              -67.790188,
              47.066879
            ],
            [
              -67.779235,
              45.944106
            ],
            [
              -67.801142,
              45.675736
            ],
            [
              -67.456095,
              45.604536
            ],
            [
              -67.505388,
              45.48952
            ],
            [
              -67.417757,
              45.379982
            ],
            [
              -67.488957,
              45.281397
            ],
            [
              -67.346556,
              45.128042
            ],
            [
              -67.16034,
              45.160904
            ],
            [
              -66.979601,
              44.804903
            ],
            [
              -67.187725,
              44.646072
            ],
            [
              -67.308218,
              44.706318
            ],
            [
              -67.406803,
              44.596779
            ],
            [
              -67.549203,
              44.624164
            ],
            [
              -67.565634,
              44.531056
            ],
            [
              -67.75185,
              44.54201
            ],
            [
              -68.047605,
              44.328409
            ],
            [
              -68.118805,
              44.476286
            ],
            [
              -68.222867,
              44.48724
            ],
            [
              -68.173574,
              44.328409
            ],
            [
              -68.403606,
              44.251732
            ],
            [
              -68.458375,
              44.377701
            ],
            [
              -68.567914,
              44.311978
            ],
            [
              -68.82533,
              44.311978
            ],
            [
              -68.830807,
              44.459856
            ],
            [
              -68.984161,
              44.426994
            ],
            [
              -68.956777,
              44.322932
            ],
            [
              -69.099177,
              44.103854
            ],
            [
              -69.071793,
              44.043608
            ],
            [
              -69.258008,
              43.923115
            ],
            [
              -69.444224,
              43.966931
            ],
            [
              -69.553763,
              43.840961
            ],
            [
              -69.707118,
              43.82453
            ],
            [
              -69.833087,
              43.720469
            ],
            [
              -69.986442,
              43.742376
            ],
            [
              -70.030257,
              43.851915
            ],
            [
              -70.254812,
              43.676653
            ],
            [
              -70.194565,
              43.567114
            ],
            [
              -70.358873,
              43.528776
            ],
            [
              -70.369827,
              43.435668
            ],
            [
              -70.556043,
              43.320652
            ],
            [
              -70.703921,
              43.057759
            ]
          ]
        ],
        "iso_code": "ME"
      },
      {
        "name": "Maryland",
        "id": 21,
        "coordinates": [
          [
            [
              -75.994645,
              37.95325
            ],
            [
              -76.016553,
              37.95325
            ],
            [
              -76.043938,
              37.95325
            ],
            [
              -75.994645,
              37.95325
            ]
          ],
          [
            [
              -79.477979,
              39.722302
            ],
            [
              -75.786521,
              39.722302
            ],
            [
              -75.693413,
              38.462606
            ],
            [
              -75.047134,
              38.451652
            ],
            [
              -75.244304,
              38.029928
            ],
            [
              -75.397659,
              38.013497
            ],
            [
              -75.671506,
              37.95325
            ],
            [
              -75.885106,
              37.909435
            ],
            [
              -75.879629,
              38.073743
            ],
            [
              -75.961783,
              38.139466
            ],
            [
              -75.846768,
              38.210667
            ],
            [
              -76.000122,
              38.374975
            ],
            [
              -76.049415,
              38.303775
            ],
            [
              -76.257538,
              38.320205
            ],
            [
              -76.328738,
              38.500944
            ],
            [
              -76.263015,
              38.500944
            ],
            [
              -76.257538,
              38.736453
            ],
            [
              -76.191815,
              38.829561
            ],
            [
              -76.279446,
              39.147223
            ],
            [
              -76.169907,
              39.333439
            ],
            [
              -76.000122,
              39.366301
            ],
            [
              -75.972737,
              39.557994
            ],
            [
              -76.098707,
              39.536086
            ],
            [
              -76.104184,
              39.437501
            ],
            [
              -76.367077,
              39.311532
            ],
            [
              -76.443754,
              39.196516
            ],
            [
              -76.460185,
              38.906238
            ],
            [
              -76.55877,
              38.769315
            ],
            [
              -76.514954,
              38.539283
            ],
            [
              -76.383508,
              38.380452
            ],
            [
              -76.399939,
              38.259959
            ],
            [
              -76.317785,
              38.139466
            ],
            [
              -76.3616,
              38.057312
            ],
            [
              -76.591632,
              38.216144
            ],
            [
              -76.920248,
              38.292821
            ],
            [
              -77.018833,
              38.446175
            ],
            [
              -77.205049,
              38.358544
            ],
            [
              -77.276249,
              38.479037
            ],
            [
              -77.128372,
              38.632391
            ],
            [
              -77.040741,
              38.791222
            ],
            [
              -76.909294,
              38.895284
            ],
            [
              -77.035264,
              38.993869
            ],
            [
              -77.117418,
              38.933623
            ],
            [
              -77.248864,
              39.026731
            ],
            [
              -77.456988,
              39.076023
            ],
            [
              -77.456988,
              39.223901
            ],
            [
              -77.566527,
              39.306055
            ],
            [
              -77.719881,
              39.322485
            ],
            [
              -77.834897,
              39.601809
            ],
            [
              -78.004682,
              39.601809
            ],
            [
              -78.174467,
              39.694917
            ],
            [
              -78.267575,
              39.61824
            ],
            [
              -78.431884,
              39.623717
            ],
            [
              -78.470222,
              39.514178
            ],
            [
              -78.765977,
              39.585379
            ],
            [
              -78.963147,
              39.437501
            ],
            [
              -79.094593,
              39.470363
            ],
            [
              -79.291763,
              39.300578
            ],
            [
              -79.488933,
              39.20747
            ],
            [
              -79.477979,
              39.722302
            ]
          ]
        ],
        "iso_code": "MD"
      },
      {
        "name": "Massachusetts",
        "id": 22,
        "coordinates": [
          [
            [
              -70.917521,
              42.887974
            ],
            [
              -70.818936,
              42.871543
            ],
            [
              -70.780598,
              42.696281
            ],
            [
              -70.824413,
              42.55388
            ],
            [
              -70.983245,
              42.422434
            ],
            [
              -70.988722,
              42.269079
            ],
            [
              -70.769644,
              42.247172
            ],
            [
              -70.638197,
              42.08834
            ],
            [
              -70.660105,
              41.962371
            ],
            [
              -70.550566,
              41.929509
            ],
            [
              -70.539613,
              41.814493
            ],
            [
              -70.260289,
              41.715908
            ],
            [
              -69.937149,
              41.809016
            ],
            [
              -70.008349,
              41.672093
            ],
            [
              -70.484843,
              41.5516
            ],
            [
              -70.660105,
              41.546123
            ],
            [
              -70.764167,
              41.639231
            ],
            [
              -70.928475,
              41.611847
            ],
            [
              -70.933952,
              41.540646
            ],
            [
              -71.120168,
              41.496831
            ],
            [
              -71.196845,
              41.67757
            ],
            [
              -71.22423,
              41.710431
            ],
            [
              -71.328292,
              41.781632
            ],
            [
              -71.383061,
              42.01714
            ],
            [
              -71.530939,
              42.01714
            ],
            [
              -71.799309,
              42.006186
            ],
            [
              -71.799309,
              42.022617
            ],
            [
              -73.053528,
              42.039048
            ],
            [
              -73.486206,
              42.050002
            ],
            [
              -73.508114,
              42.08834
            ],
            [
              -73.267129,
              42.745573
            ],
            [
              -72.456542,
              42.729142
            ],
            [
              -71.29543,
              42.696281
            ],
            [
              -71.185891,
              42.789389
            ],
            [
              -70.917521,
              42.887974
            ]
          ]
        ],
        "iso_code": "MA"
      },
      {
        "name": "Michigan",
        "id": 23,
        "coordinates": [
          [
            [
              -83.454238,
              41.732339
            ],
            [
              -84.807042,
              41.694001
            ],
            [
              -84.807042,
              41.759724
            ],
            [
              -85.990061,
              41.759724
            ],
            [
              -86.822556,
              41.759724
            ],
            [
              -86.619909,
              41.891171
            ],
            [
              -86.482986,
              42.115725
            ],
            [
              -86.357016,
              42.252649
            ],
            [
              -86.263908,
              42.444341
            ],
            [
              -86.209139,
              42.718189
            ],
            [
              -86.231047,
              43.013943
            ],
            [
              -86.526801,
              43.594499
            ],
            [
              -86.433693,
              43.813577
            ],
            [
              -86.499417,
              44.07647
            ],
            [
              -86.269385,
              44.34484
            ],
            [
              -86.220093,
              44.569394
            ],
            [
              -86.252954,
              44.689887
            ],
            [
              -86.088646,
              44.73918
            ],
            [
              -86.066738,
              44.903488
            ],
            [
              -85.809322,
              44.947303
            ],
            [
              -85.612152,
              45.128042
            ],
            [
              -85.628583,
              44.766564
            ],
            [
              -85.524521,
              44.750133
            ],
            [
              -85.393075,
              44.930872
            ],
            [
              -85.387598,
              45.237581
            ],
            [
              -85.305444,
              45.314258
            ],
            [
              -85.031597,
              45.363551
            ],
            [
              -85.119228,
              45.577151
            ],
            [
              -84.938489,
              45.75789
            ],
            [
              -84.713934,
              45.768844
            ],
            [
              -84.461995,
              45.653829
            ],
            [
              -84.215533,
              45.637398
            ],
            [
              -84.09504,
              45.494997
            ],
            [
              -83.908824,
              45.484043
            ],
            [
              -83.596638,
              45.352597
            ],
            [
              -83.4871,
              45.358074
            ],
            [
              -83.317314,
              45.144473
            ],
            [
              -83.454238,
              45.029457
            ],
            [
              -83.322791,
              44.88158
            ],
            [
              -83.273499,
              44.711795
            ],
            [
              -83.333745,
              44.339363
            ],
            [
              -83.536392,
              44.246255
            ],
            [
              -83.585684,
              44.054562
            ],
            [
              -83.82667,
              43.988839
            ],
            [
              -83.958116,
              43.758807
            ],
            [
              -83.908824,
              43.671176
            ],
            [
              -83.667839,
              43.589022
            ],
            [
              -83.481623,
              43.714992
            ],
            [
              -83.262545,
              43.972408
            ],
            [
              -82.917498,
              44.070993
            ],
            [
              -82.747713,
              43.994316
            ],
            [
              -82.643651,
              43.851915
            ],
            [
              -82.539589,
              43.435668
            ],
            [
              -82.523158,
              43.227544
            ],
            [
              -82.413619,
              42.975605
            ],
            [
              -82.517681,
              42.614127
            ],
            [
              -82.681989,
              42.559357
            ],
            [
              -82.687466,
              42.690804
            ],
            [
              -82.797005,
              42.652465
            ],
            [
              -82.922975,
              42.351234
            ],
            [
              -83.125621,
              42.236218
            ],
            [
              -83.185868,
              42.006186
            ],
            [
              -83.437807,
              41.814493
            ],
            [
              -83.454238,
              41.732339
            ]
          ],
          [
            [
              -85.508091,
              45.730506
            ],
            [
              -85.49166,
              45.610013
            ],
            [
              -85.623106,
              45.588105
            ],
            [
              -85.568337,
              45.75789
            ],
            [
              -85.508091,
              45.730506
            ]
          ],
          [
            [
              -87.589328,
              45.095181
            ],
            [
              -87.742682,
              45.199243
            ],
            [
              -87.649574,
              45.341643
            ],
            [
              -87.885083,
              45.363551
            ],
            [
              -87.791975,
              45.500474
            ],
            [
              -87.781021,
              45.675736
            ],
            [
              -87.989145,
              45.796229
            ],
            [
              -88.10416,
              45.922199
            ],
            [
              -88.531362,
              46.020784
            ],
            [
              -88.662808,
              45.987922
            ],
            [
              -89.09001,
              46.135799
            ],
            [
              -90.119674,
              46.338446
            ],
            [
              -90.229213,
              46.508231
            ],
            [
              -90.415429,
              46.568478
            ],
            [
              -90.026566,
              46.672539
            ],
            [
              -89.851304,
              46.793032
            ],
            [
              -89.413149,
              46.842325
            ],
            [
              -89.128348,
              46.990202
            ],
            [
              -88.996902,
              46.995679
            ],
            [
              -88.887363,
              47.099741
            ],
            [
              -88.575177,
              47.247618
            ],
            [
              -88.416346,
              47.373588
            ],
            [
              -88.180837,
              47.455742
            ],
            [
              -87.956283,
              47.384542
            ],
            [
              -88.350623,
              47.077833
            ],
            [
              -88.443731,
              46.973771
            ],
            [
              -88.438254,
              46.787555
            ],
            [
              -88.246561,
              46.929956
            ],
            [
              -87.901513,
              46.908048
            ],
            [
              -87.633143,
              46.809463
            ],
            [
              -87.392158,
              46.535616
            ],
            [
              -87.260711,
              46.486323
            ],
            [
              -87.008772,
              46.530139
            ],
            [
              -86.948526,
              46.469893
            ],
            [
              -86.696587,
              46.437031
            ],
            [
              -86.159846,
              46.667063
            ],
            [
              -85.880522,
              46.68897
            ],
            [
              -85.508091,
              46.678016
            ],
            [
              -85.256151,
              46.754694
            ],
            [
              -85.064458,
              46.760171
            ],
            [
              -85.02612,
              46.480847
            ],
            [
              -84.82895,
              46.442508
            ],
            [
              -84.63178,
              46.486323
            ],
            [
              -84.549626,
              46.4206
            ],
            [
              -84.418179,
              46.502754
            ],
            [
              -84.127902,
              46.530139
            ],
            [
              -84.122425,
              46.179615
            ],
            [
              -83.990978,
              46.031737
            ],
            [
              -83.793808,
              45.993399
            ],
            [
              -83.7719,
              46.091984
            ],
            [
              -83.580208,
              46.091984
            ],
            [
              -83.476146,
              45.987922
            ],
            [
              -83.563777,
              45.911245
            ],
            [
              -84.111471,
              45.976968
            ],
            [
              -84.374364,
              45.933153
            ],
            [
              -84.659165,
              46.053645
            ],
            [
              -84.741319,
              45.944106
            ],
            [
              -84.70298,
              45.850998
            ],
            [
              -84.82895,
              45.872906
            ],
            [
              -85.015166,
              46.00983
            ],
            [
              -85.338305,
              46.091984
            ],
            [
              -85.502614,
              46.097461
            ],
            [
              -85.661445,
              45.966014
            ],
            [
              -85.924338,
              45.933153
            ],
            [
              -86.209139,
              45.960537
            ],
            [
              -86.324155,
              45.905768
            ],
            [
              -86.351539,
              45.796229
            ],
            [
              -86.663725,
              45.703121
            ],
            [
              -86.647294,
              45.834568
            ],
            [
              -86.784218,
              45.861952
            ],
            [
              -86.838987,
              45.725029
            ],
            [
              -87.069019,
              45.719552
            ],
            [
              -87.17308,
              45.659305
            ],
            [
              -87.326435,
              45.423797
            ],
            [
              -87.611236,
              45.122565
            ],
            [
              -87.589328,
              45.095181
            ]
          ],
          [
            [
              -88.805209,
              47.976051
            ],
            [
              -89.057148,
              47.850082
            ],
            [
              -89.188594,
              47.833651
            ],
            [
              -89.177641,
              47.937713
            ],
            [
              -88.547792,
              48.173221
            ],
            [
              -88.668285,
              48.008913
            ],
            [
              -88.805209,
              47.976051
            ]
          ]
        ],
        "iso_code": "MI"
      },
      {
        "name": "Minnesota",
        "id": 24,
        "coordinates": [
          [
            [
              -92.014696,
              46.705401
            ],
            [
              -92.091373,
              46.749217
            ],
            [
              -92.29402,
              46.667063
            ],
            [
              -92.29402,
              46.075553
            ],
            [
              -92.354266,
              46.015307
            ],
            [
              -92.639067,
              45.933153
            ],
            [
              -92.869098,
              45.719552
            ],
            [
              -92.885529,
              45.577151
            ],
            [
              -92.770513,
              45.566198
            ],
            [
              -92.644544,
              45.440228
            ],
            [
              -92.75956,
              45.286874
            ],
            [
              -92.737652,
              45.117088
            ],
            [
              -92.808852,
              44.750133
            ],
            [
              -92.545959,
              44.569394
            ],
            [
              -92.337835,
              44.552964
            ],
            [
              -92.233773,
              44.443425
            ],
            [
              -91.927065,
              44.333886
            ],
            [
              -91.877772,
              44.202439
            ],
            [
              -91.592971,
              44.032654
            ],
            [
              -91.43414,
              43.994316
            ],
            [
              -91.242447,
              43.775238
            ],
            [
              -91.269832,
              43.616407
            ],
            [
              -91.215062,
              43.501391
            ],
            [
              -91.368417,
              43.501391
            ],
            [
              -96.451017,
              43.501391
            ],
            [
              -96.451017,
              45.297827
            ],
            [
              -96.681049,
              45.412843
            ],
            [
              -96.856311,
              45.604536
            ],
            [
              -96.582464,
              45.818137
            ],
            [
              -96.560556,
              45.933153
            ],
            [
              -96.598895,
              46.332969
            ],
            [
              -96.719387,
              46.437031
            ],
            [
              -96.801542,
              46.656109
            ],
            [
              -96.785111,
              46.924479
            ],
            [
              -96.823449,
              46.968294
            ],
            [
              -96.856311,
              47.609096
            ],
            [
              -97.053481,
              47.948667
            ],
            [
              -97.130158,
              48.140359
            ],
            [
              -97.16302,
              48.545653
            ],
            [
              -97.097296,
              48.682577
            ],
            [
              -97.228743,
              49.000239
            ],
            [
              -95.152983,
              49.000239
            ],
            [
              -95.152983,
              49.383625
            ],
            [
              -94.955813,
              49.372671
            ],
            [
              -94.824366,
              49.295994
            ],
            [
              -94.69292,
              48.775685
            ],
            [
              -94.588858,
              48.715438
            ],
            [
              -94.260241,
              48.699007
            ],
            [
              -94.221903,
              48.649715
            ],
            [
              -93.838517,
              48.627807
            ],
            [
              -93.794701,
              48.518268
            ],
            [
              -93.466085,
              48.545653
            ],
            [
              -93.466085,
              48.589469
            ],
            [
              -93.208669,
              48.644238
            ],
            [
              -92.984114,
              48.62233
            ],
            [
              -92.726698,
              48.540176
            ],
            [
              -92.655498,
              48.436114
            ],
            [
              -92.50762,
              48.447068
            ],
            [
              -92.370697,
              48.222514
            ],
            [
              -92.304974,
              48.315622
            ],
            [
              -92.053034,
              48.359437
            ],
            [
              -92.009219,
              48.266329
            ],
            [
              -91.713464,
              48.200606
            ],
            [
              -91.713464,
              48.112975
            ],
            [
              -91.565587,
              48.041775
            ],
            [
              -91.264355,
              48.080113
            ],
            [
              -91.083616,
              48.178698
            ],
            [
              -90.837154,
              48.238944
            ],
            [
              -90.749522,
              48.091067
            ],
            [
              -90.579737,
              48.123929
            ],
            [
              -90.377091,
              48.091067
            ],
            [
              -90.141582,
              48.112975
            ],
            [
              -89.873212,
              47.987005
            ],
            [
              -89.615796,
              48.008913
            ],
            [
              -89.637704,
              47.954144
            ],
            [
              -89.971797,
              47.828174
            ],
            [
              -90.437337,
              47.729589
            ],
            [
              -90.738569,
              47.625527
            ],
            [
              -91.171247,
              47.368111
            ],
            [
              -91.357463,
              47.20928
            ],
            [
              -91.642264,
              47.028541
            ],
            [
              -92.091373,
              46.787555
            ],
            [
              -92.014696,
              46.705401
            ]
          ]
        ],
        "iso_code": "MN"
      },
      {
        "name": "Mississippi",
        "id": 25,
        "coordinates": [
          [
            [
              -88.471115,
              34.995703
            ],
            [
              -88.202745,
              34.995703
            ],
            [
              -88.098683,
              34.891641
            ],
            [
              -88.241084,
              33.796253
            ],
            [
              -88.471115,
              31.895754
            ],
            [
              -88.394438,
              30.367688
            ],
            [
              -88.503977,
              30.323872
            ],
            [
              -88.744962,
              30.34578
            ],
            [
              -88.843547,
              30.411504
            ],
            [
              -89.084533,
              30.367688
            ],
            [
              -89.418626,
              30.252672
            ],
            [
              -89.522688,
              30.181472
            ],
            [
              -89.643181,
              30.285534
            ],
            [
              -89.681519,
              30.449842
            ],
            [
              -89.845827,
              30.66892
            ],
            [
              -89.747242,
              30.997536
            ],
            [
              -91.636787,
              30.997536
            ],
            [
              -91.565587,
              31.068736
            ],
            [
              -91.636787,
              31.265906
            ],
            [
              -91.516294,
              31.27686
            ],
            [
              -91.499863,
              31.643815
            ],
            [
              -91.401278,
              31.621907
            ],
            [
              -91.341032,
              31.846462
            ],
            [
              -91.105524,
              31.988862
            ],
            [
              -90.985031,
              32.218894
            ],
            [
              -91.006939,
              32.514649
            ],
            [
              -91.154816,
              32.640618
            ],
            [
              -91.143862,
              32.843265
            ],
            [
              -91.072662,
              32.887081
            ],
            [
              -91.16577,
              33.002096
            ],
            [
              -91.089093,
              33.13902
            ],
            [
              -91.143862,
              33.347144
            ],
            [
              -91.056231,
              33.429298
            ],
            [
              -91.231493,
              33.560744
            ],
            [
              -91.072662,
              33.867453
            ],
            [
              -90.891923,
              34.026284
            ],
            [
              -90.952169,
              34.135823
            ],
            [
              -90.744046,
              34.300131
            ],
            [
              -90.749522,
              34.365854
            ],
            [
              -90.568783,
              34.420624
            ],
            [
              -90.585214,
              34.617794
            ],
            [
              -90.481152,
              34.661609
            ],
            [
              -90.409952,
              34.831394
            ],
            [
              -90.251121,
              34.908072
            ],
            [
              -90.311367,
              34.995703
            ],
            [
              -88.471115,
              34.995703
            ]
          ]
        ],
        "iso_code": "MS"
      },
      {
        "name": "Missouri",
        "id": 26,
        "coordinates": [
          [
            [
              -91.833957,
              40.609566
            ],
            [
              -91.729895,
              40.615043
            ],
            [
              -91.527248,
              40.412397
            ],
            [
              -91.417709,
              40.379535
            ],
            [
              -91.50534,
              40.237135
            ],
            [
              -91.494386,
              40.034488
            ],
            [
              -91.368417,
              39.727779
            ],
            [
              -91.061708,
              39.470363
            ],
            [
              -90.727615,
              39.256762
            ],
            [
              -90.661891,
              38.928146
            ],
            [
              -90.585214,
              38.867899
            ],
            [
              -90.470199,
              38.961007
            ],
            [
              -90.251121,
              38.917192
            ],
            [
              -90.10872,
              38.845992
            ],
            [
              -90.207305,
              38.725499
            ],
            [
              -90.179921,
              38.632391
            ],
            [
              -90.349706,
              38.374975
            ],
            [
              -90.355183,
              38.216144
            ],
            [
              -90.059428,
              38.013497
            ],
            [
              -89.949889,
              37.88205
            ],
            [
              -89.84035,
              37.903958
            ],
            [
              -89.517211,
              37.690357
            ],
            [
              -89.517211,
              37.537003
            ],
            [
              -89.435057,
              37.34531
            ],
            [
              -89.517211,
              37.279587
            ],
            [
              -89.292656,
              36.994786
            ],
            [
              -89.133825,
              36.983832
            ],
            [
              -89.215979,
              36.578538
            ],
            [
              -89.363857,
              36.622354
            ],
            [
              -89.418626,
              36.496384
            ],
            [
              -89.484349,
              36.496384
            ],
            [
              -89.539119,
              36.496384
            ],
            [
              -89.533642,
              36.249922
            ],
            [
              -89.730812,
              35.997983
            ],
            [
              -90.377091,
              35.997983
            ],
            [
              -90.218259,
              36.184199
            ],
            [
              -90.064905,
              36.304691
            ],
            [
              -90.152536,
              36.496384
            ],
            [
              -94.473842,
              36.501861
            ],
            [
              -94.616242,
              36.501861
            ],
            [
              -94.616242,
              37.000263
            ],
            [
              -94.610765,
              39.158177
            ],
            [
              -94.824366,
              39.20747
            ],
            [
              -94.983197,
              39.442978
            ],
            [
              -95.109167,
              39.541563
            ],
            [
              -94.884612,
              39.831841
            ],
            [
              -95.207752,
              39.908518
            ],
            [
              -95.306337,
              40.001626
            ],
            [
              -95.552799,
              40.264519
            ],
            [
              -95.7664,
              40.587659
            ],
            [
              -94.632673,
              40.571228
            ],
            [
              -93.257961,
              40.582182
            ],
            [
              -91.833957,
              40.609566
            ]
          ]
        ],
        "iso_code": "MO"
      },
      {
        "name": "Montana",
        "id": 27,
        "coordinates": [
          [
            [
              -104.047534,
              49.000239
            ],
            [
              -104.042057,
              47.861036
            ],
            [
              -104.047534,
              45.944106
            ],
            [
              -104.042057,
              44.996596
            ],
            [
              -104.058488,
              44.996596
            ],
            [
              -105.91517,
              45.002073
            ],
            [
              -109.080842,
              45.002073
            ],
            [
              -111.05254,
              45.002073
            ],
            [
              -111.047063,
              44.476286
            ],
            [
              -111.227803,
              44.580348
            ],
            [
              -111.386634,
              44.75561
            ],
            [
              -111.616665,
              44.547487
            ],
            [
              -111.819312,
              44.509148
            ],
            [
              -111.868605,
              44.563917
            ],
            [
              -112.104113,
              44.520102
            ],
            [
              -112.241036,
              44.569394
            ],
            [
              -112.471068,
              44.481763
            ],
            [
              -112.783254,
              44.48724
            ],
            [
              -112.887315,
              44.394132
            ],
            [
              -113.002331,
              44.448902
            ],
            [
              -113.133778,
              44.772041
            ],
            [
              -113.341901,
              44.782995
            ],
            [
              -113.456917,
              44.865149
            ],
            [
              -113.45144,
              45.056842
            ],
            [
              -113.571933,
              45.128042
            ],
            [
              -113.736241,
              45.330689
            ],
            [
              -113.834826,
              45.522382
            ],
            [
              -113.807441,
              45.604536
            ],
            [
              -113.98818,
              45.703121
            ],
            [
              -114.086765,
              45.593582
            ],
            [
              -114.333228,
              45.456659
            ],
            [
              -114.546828,
              45.560721
            ],
            [
              -114.497536,
              45.670259
            ],
            [
              -114.568736,
              45.774321
            ],
            [
              -114.387997,
              45.88386
            ],
            [
              -114.492059,
              46.037214
            ],
            [
              -114.464674,
              46.272723
            ],
            [
              -114.322274,
              46.645155
            ],
            [
              -114.612552,
              46.639678
            ],
            [
              -114.623506,
              46.705401
            ],
            [
              -114.886399,
              46.809463
            ],
            [
              -114.930214,
              46.919002
            ],
            [
              -115.302646,
              47.187372
            ],
            [
              -115.324554,
              47.258572
            ],
            [
              -115.527201,
              47.302388
            ],
            [
              -115.718894,
              47.42288
            ],
            [
              -115.724371,
              47.696727
            ],
            [
              -116.04751,
              47.976051
            ],
            [
              -116.04751,
              49.000239
            ],
            [
              -111.50165,
              48.994762
            ],
            [
              -109.453274,
              49.000239
            ],
            [
              -104.047534,
              49.000239
            ]
          ]
        ],
        "iso_code": "MT"
      },
      {
        "name": "Nebraska",
        "id": 28,
        "coordinates": [
          [
            [
              -103.324578,
              43.002989
            ],
            [
              -101.626726,
              42.997512
            ],
            [
              -98.499393,
              42.997512
            ],
            [
              -98.466531,
              42.94822
            ],
            [
              -97.951699,
              42.767481
            ],
            [
              -97.831206,
              42.866066
            ],
            [
              -97.688806,
              42.844158
            ],
            [
              -97.217789,
              42.844158
            ],
            [
              -96.692003,
              42.657942
            ],
            [
              -96.626279,
              42.515542
            ],
            [
              -96.44554,
              42.488157
            ],
            [
              -96.264801,
              42.039048
            ],
            [
              -96.127878,
              41.973325
            ],
            [
              -96.062155,
              41.798063
            ],
            [
              -96.122401,
              41.67757
            ],
            [
              -96.095016,
              41.540646
            ],
            [
              -95.919754,
              41.453015
            ],
            [
              -95.925231,
              41.201076
            ],
            [
              -95.826646,
              40.976521
            ],
            [
              -95.881416,
              40.719105
            ],
            [
              -95.7664,
              40.587659
            ],
            [
              -95.552799,
              40.264519
            ],
            [
              -95.306337,
              40.001626
            ],
            [
              -101.90605,
              40.001626
            ],
            [
              -102.053927,
              40.001626
            ],
            [
              -102.053927,
              41.003906
            ],
            [
              -104.053011,
              41.003906
            ],
            [
              -104.053011,
              43.002989
            ],
            [
              -103.324578,
              43.002989
            ]
          ]
        ],
        "iso_code": "NE"
      },
      {
        "name": "Nevada",
        "id": 29,
        "coordinates": [
          [
            [
              -117.027882,
              42.000709
            ],
            [
              -114.04295,
              41.995232
            ],
            [
              -114.048427,
              37.000263
            ],
            [
              -114.048427,
              36.195153
            ],
            [
              -114.152489,
              36.025367
            ],
            [
              -114.251074,
              36.01989
            ],
            [
              -114.371566,
              36.140383
            ],
            [
              -114.738521,
              36.102045
            ],
            [
              -114.678275,
              35.516012
            ],
            [
              -114.596121,
              35.324319
            ],
            [
              -114.574213,
              35.138103
            ],
            [
              -114.634459,
              35.00118
            ],
            [
              -115.85034,
              35.970598
            ],
            [
              -116.540435,
              36.501861
            ],
            [
              -117.498899,
              37.21934
            ],
            [
              -118.71478,
              38.101128
            ],
            [
              -120.001861,
              38.999346
            ],
            [
              -119.996384,
              40.264519
            ],
            [
              -120.001861,
              41.995232
            ],
            [
              -118.698349,
              41.989755
            ],
            [
              -117.027882,
              42.000709
            ]
          ]
        ],
        "iso_code": "NV"
      },
      {
        "name": "New Hampshire",
        "id": 30,
        "coordinates": [
          [
            [
              -71.08183,
              45.303304
            ],
            [
              -71.032537,
              44.657025
            ],
            [
              -70.966814,
              43.34256
            ],
            [
              -70.807983,
              43.227544
            ],
            [
              -70.824413,
              43.128959
            ],
            [
              -70.703921,
              43.057759
            ],
            [
              -70.818936,
              42.871543
            ],
            [
              -70.917521,
              42.887974
            ],
            [
              -71.185891,
              42.789389
            ],
            [
              -71.29543,
              42.696281
            ],
            [
              -72.456542,
              42.729142
            ],
            [
              -72.544173,
              42.80582
            ],
            [
              -72.533219,
              42.953697
            ],
            [
              -72.445588,
              43.008466
            ],
            [
              -72.456542,
              43.150867
            ],
            [
              -72.379864,
              43.572591
            ],
            [
              -72.204602,
              43.769761
            ],
            [
              -72.116971,
              43.994316
            ],
            [
              -72.02934,
              44.07647
            ],
            [
              -72.034817,
              44.322932
            ],
            [
              -71.700724,
              44.41604
            ],
            [
              -71.536416,
              44.585825
            ],
            [
              -71.629524,
              44.750133
            ],
            [
              -71.4926,
              44.914442
            ],
            [
              -71.503554,
              45.013027
            ],
            [
              -71.361154,
              45.270443
            ],
            [
              -71.131122,
              45.243058
            ],
            [
              -71.08183,
              45.303304
            ]
          ]
        ],
        "iso_code": "NH"
      },
      {
        "name": "New Jersey",
        "id": 31,
        "coordinates": [
          [
            [
              -74.236547,
              41.14083
            ],
            [
              -73.902454,
              40.998429
            ],
            [
              -74.022947,
              40.708151
            ],
            [
              -74.187255,
              40.642428
            ],
            [
              -74.274886,
              40.489074
            ],
            [
              -74.001039,
              40.412397
            ],
            [
              -73.979131,
              40.297381
            ],
            [
              -74.099624,
              39.760641
            ],
            [
              -74.411809,
              39.360824
            ],
            [
              -74.614456,
              39.245808
            ],
            [
              -74.795195,
              38.993869
            ],
            [
              -74.888303,
              39.158177
            ],
            [
              -75.178581,
              39.240331
            ],
            [
              -75.534582,
              39.459409
            ],
            [
              -75.55649,
              39.607286
            ],
            [
              -75.561967,
              39.629194
            ],
            [
              -75.507197,
              39.683964
            ],
            [
              -75.414089,
              39.804456
            ],
            [
              -75.145719,
              39.88661
            ],
            [
              -75.129289,
              39.963288
            ],
            [
              -74.82258,
              40.127596
            ],
            [
              -74.773287,
              40.215227
            ],
            [
              -75.058088,
              40.417874
            ],
            [
              -75.069042,
              40.543843
            ],
            [
              -75.195012,
              40.576705
            ],
            [
              -75.205966,
              40.691721
            ],
            [
              -75.052611,
              40.866983
            ],
            [
              -75.134765,
              40.971045
            ],
            [
              -74.882826,
              41.179168
            ],
            [
              -74.828057,
              41.288707
            ],
            [
              -74.69661,
              41.359907
            ],
            [
              -74.236547,
              41.14083
            ]
          ]
        ],
        "iso_code": "NJ"
      },
      {
        "name": "New Mexico",
        "id": 32,
        "coordinates": [
          [
            [
              -107.421329,
              37.000263
            ],
            [
              -106.868158,
              36.994786
            ],
            [
              -104.337812,
              36.994786
            ],
            [
              -103.001438,
              37.000263
            ],
            [
              -103.001438,
              36.501861
            ],
            [
              -103.039777,
              36.501861
            ],
            [
              -103.045254,
              34.01533
            ],
            [
              -103.067161,
              33.002096
            ],
            [
              -103.067161,
              31.999816
            ],
            [
              -106.616219,
              31.999816
            ],
            [
              -106.643603,
              31.901231
            ],
            [
              -106.528588,
              31.786216
            ],
            [
              -108.210008,
              31.786216
            ],
            [
              -108.210008,
              31.331629
            ],
            [
              -109.04798,
              31.331629
            ],
            [
              -109.042503,
              37.000263
            ],
            [
              -107.421329,
              37.000263
            ]
          ]
        ],
        "iso_code": "NM"
      },
      {
        "name": "New York",
        "id": 33,
        "coordinates": [
          [
            [
              -73.343806,
              45.013027
            ],
            [
              -73.332852,
              44.804903
            ],
            [
              -73.387622,
              44.618687
            ],
            [
              -73.294514,
              44.437948
            ],
            [
              -73.321898,
              44.246255
            ],
            [
              -73.436914,
              44.043608
            ],
            [
              -73.349283,
              43.769761
            ],
            [
              -73.404052,
              43.687607
            ],
            [
              -73.245221,
              43.523299
            ],
            [
              -73.278083,
              42.833204
            ],
            [
              -73.267129,
              42.745573
            ],
            [
              -73.508114,
              42.08834
            ],
            [
              -73.486206,
              42.050002
            ],
            [
              -73.55193,
              41.294184
            ],
            [
              -73.48073,
              41.21203
            ],
            [
              -73.727192,
              41.102491
            ],
            [
              -73.655992,
              40.987475
            ],
            [
              -73.22879,
              40.905321
            ],
            [
              -73.141159,
              40.965568
            ],
            [
              -72.774204,
              40.965568
            ],
            [
              -72.587988,
              40.998429
            ],
            [
              -72.28128,
              41.157261
            ],
            [
              -72.259372,
              41.042245
            ],
            [
              -72.100541,
              40.992952
            ],
            [
              -72.467496,
              40.845075
            ],
            [
              -73.239744,
              40.625997
            ],
            [
              -73.562884,
              40.582182
            ],
            [
              -73.776484,
              40.593136
            ],
            [
              -73.935316,
              40.543843
            ],
            [
              -74.022947,
              40.708151
            ],
            [
              -73.902454,
              40.998429
            ],
            [
              -74.236547,
              41.14083
            ],
            [
              -74.69661,
              41.359907
            ],
            [
              -74.740426,
              41.431108
            ],
            [
              -74.89378,
              41.436584
            ],
            [
              -75.074519,
              41.60637
            ],
            [
              -75.052611,
              41.754247
            ],
            [
              -75.173104,
              41.869263
            ],
            [
              -75.249781,
              41.863786
            ],
            [
              -75.35932,
              42.000709
            ],
            [
              -79.76278,
              42.000709
            ],
            [
              -79.76278,
              42.252649
            ],
            [
              -79.76278,
              42.269079
            ],
            [
              -79.149363,
              42.55388
            ],
            [
              -79.050778,
              42.690804
            ],
            [
              -78.853608,
              42.783912
            ],
            [
              -78.930285,
              42.953697
            ],
            [
              -79.012439,
              42.986559
            ],
            [
              -79.072686,
              43.260406
            ],
            [
              -78.486653,
              43.375421
            ],
            [
              -77.966344,
              43.369944
            ],
            [
              -77.75822,
              43.34256
            ],
            [
              -77.533665,
              43.233021
            ],
            [
              -77.391265,
              43.276836
            ],
            [
              -76.958587,
              43.271359
            ],
            [
              -76.695693,
              43.34256
            ],
            [
              -76.41637,
              43.523299
            ],
            [
              -76.235631,
              43.528776
            ],
            [
              -76.230154,
              43.802623
            ],
            [
              -76.137046,
              43.961454
            ],
            [
              -76.3616,
              44.070993
            ],
            [
              -76.312308,
              44.196962
            ],
            [
              -75.912491,
              44.366748
            ],
            [
              -75.764614,
              44.514625
            ],
            [
              -75.282643,
              44.848718
            ],
            [
              -74.828057,
              45.018503
            ],
            [
              -74.148916,
              44.991119
            ],
            [
              -73.343806,
              45.013027
            ]
          ]
        ],
        "iso_code": "NY"
      },
      {
        "name": "North Carolina",
        "id": 34,
        "coordinates": [
          [
            [
              -80.978661,
              36.562108
            ],
            [
              -80.294043,
              36.545677
            ],
            [
              -79.510841,
              36.5402
            ],
            [
              -75.868676,
              36.551154
            ],
            [
              -75.75366,
              36.151337
            ],
            [
              -76.032984,
              36.189676
            ],
            [
              -76.071322,
              36.140383
            ],
            [
              -76.410893,
              36.080137
            ],
            [
              -76.460185,
              36.025367
            ],
            [
              -76.68474,
              36.008937
            ],
            [
              -76.673786,
              35.937736
            ],
            [
              -76.399939,
              35.987029
            ],
            [
              -76.3616,
              35.943213
            ],
            [
              -76.060368,
              35.992506
            ],
            [
              -75.961783,
              35.899398
            ],
            [
              -75.781044,
              35.937736
            ],
            [
              -75.715321,
              35.696751
            ],
            [
              -75.775568,
              35.581735
            ],
            [
              -75.89606,
              35.570781
            ],
            [
              -76.147999,
              35.324319
            ],
            [
              -76.482093,
              35.313365
            ],
            [
              -76.536862,
              35.14358
            ],
            [
              -76.394462,
              34.973795
            ],
            [
              -76.279446,
              34.940933
            ],
            [
              -76.493047,
              34.661609
            ],
            [
              -76.673786,
              34.694471
            ],
            [
              -76.991448,
              34.667086
            ],
            [
              -77.210526,
              34.60684
            ],
            [
              -77.555573,
              34.415147
            ],
            [
              -77.82942,
              34.163208
            ],
            [
              -77.971821,
              33.845545
            ],
            [
              -78.179944,
              33.916745
            ],
            [
              -78.541422,
              33.851022
            ],
            [
              -79.675149,
              34.80401
            ],
            [
              -80.797922,
              34.820441
            ],
            [
              -80.781491,
              34.935456
            ],
            [
              -80.934845,
              35.105241
            ],
            [
              -81.038907,
              35.044995
            ],
            [
              -81.044384,
              35.149057
            ],
            [
              -82.276696,
              35.198349
            ],
            [
              -82.550543,
              35.160011
            ],
            [
              -82.764143,
              35.066903
            ],
            [
              -83.109191,
              35.00118
            ],
            [
              -83.618546,
              34.984749
            ],
            [
              -84.319594,
              34.990226
            ],
            [
              -84.29221,
              35.225734
            ],
            [
              -84.09504,
              35.247642
            ],
            [
              -84.018363,
              35.41195
            ],
            [
              -83.7719,
              35.559827
            ],
            [
              -83.498053,
              35.565304
            ],
            [
              -83.251591,
              35.718659
            ],
            [
              -82.994175,
              35.773428
            ],
            [
              -82.775097,
              35.997983
            ],
            [
              -82.638174,
              36.063706
            ],
            [
              -82.610789,
              35.965121
            ],
            [
              -82.216449,
              36.156814
            ],
            [
              -82.03571,
              36.118475
            ],
            [
              -81.909741,
              36.304691
            ],
            [
              -81.723525,
              36.353984
            ],
            [
              -81.679709,
              36.589492
            ],
            [
              -80.978661,
              36.562108
            ]
          ]
        ],
        "iso_code": "NC"
      },
      {
        "name": "North Dakota",
        "id": 35,
        "coordinates": [
          [
            [
              -97.228743,
              49.000239
            ],
            [
              -97.097296,
              48.682577
            ],
            [
              -97.16302,
              48.545653
            ],
            [
              -97.130158,
              48.140359
            ],
            [
              -97.053481,
              47.948667
            ],
            [
              -96.856311,
              47.609096
            ],
            [
              -96.823449,
              46.968294
            ],
            [
              -96.785111,
              46.924479
            ],
            [
              -96.801542,
              46.656109
            ],
            [
              -96.719387,
              46.437031
            ],
            [
              -96.598895,
              46.332969
            ],
            [
              -96.560556,
              45.933153
            ],
            [
              -104.047534,
              45.944106
            ],
            [
              -104.042057,
              47.861036
            ],
            [
              -104.047534,
              49.000239
            ],
            [
              -97.228743,
              49.000239
            ]
          ]
        ],
        "iso_code": "ND"
      },
      {
        "name": "Ohio",
        "id": 36,
        "coordinates": [
          [
            [
              -80.518598,
              41.978802
            ],
            [
              -80.518598,
              40.636951
            ],
            [
              -80.666475,
              40.582182
            ],
            [
              -80.595275,
              40.472643
            ],
            [
              -80.600752,
              40.319289
            ],
            [
              -80.737675,
              40.078303
            ],
            [
              -80.830783,
              39.711348
            ],
            [
              -81.219646,
              39.388209
            ],
            [
              -81.345616,
              39.344393
            ],
            [
              -81.455155,
              39.410117
            ],
            [
              -81.57017,
              39.267716
            ],
            [
              -81.685186,
              39.273193
            ],
            [
              -81.811156,
              39.0815
            ],
            [
              -81.783771,
              38.966484
            ],
            [
              -81.887833,
              38.873376
            ],
            [
              -82.03571,
              39.026731
            ],
            [
              -82.221926,
              38.785745
            ],
            [
              -82.172634,
              38.632391
            ],
            [
              -82.293127,
              38.577622
            ],
            [
              -82.331465,
              38.446175
            ],
            [
              -82.594358,
              38.424267
            ],
            [
              -82.731282,
              38.561191
            ],
            [
              -82.846298,
              38.588575
            ],
            [
              -82.890113,
              38.758361
            ],
            [
              -83.032514,
              38.725499
            ],
            [
              -83.142052,
              38.626914
            ],
            [
              -83.519961,
              38.703591
            ],
            [
              -83.678792,
              38.632391
            ],
            [
              -83.903347,
              38.769315
            ],
            [
              -84.215533,
              38.807653
            ],
            [
              -84.231963,
              38.895284
            ],
            [
              -84.43461,
              39.103408
            ],
            [
              -84.817996,
              39.103408
            ],
            [
              -84.801565,
              40.500028
            ],
            [
              -84.807042,
              41.694001
            ],
            [
              -83.454238,
              41.732339
            ],
            [
              -83.065375,
              41.595416
            ],
            [
              -82.933929,
              41.513262
            ],
            [
              -82.835344,
              41.589939
            ],
            [
              -82.616266,
              41.431108
            ],
            [
              -82.479343,
              41.381815
            ],
            [
              -82.013803,
              41.513262
            ],
            [
              -81.739956,
              41.485877
            ],
            [
              -81.444201,
              41.672093
            ],
            [
              -81.011523,
              41.852832
            ],
            [
              -80.518598,
              41.978802
            ],
            [
              -80.518598,
              41.978802
            ]
          ]
        ],
        "iso_code": "OH"
      },
      {
        "name": "Oklahoma",
        "id": 37,
        "coordinates": [
          [
            [
              -100.087706,
              37.000263
            ],
            [
              -94.616242,
              37.000263
            ],
            [
              -94.616242,
              36.501861
            ],
            [
              -94.430026,
              35.395519
            ],
            [
              -94.484796,
              33.637421
            ],
            [
              -94.868182,
              33.74696
            ],
            [
              -94.966767,
              33.861976
            ],
            [
              -95.224183,
              33.960561
            ],
            [
              -95.289906,
              33.87293
            ],
            [
              -95.547322,
              33.878407
            ],
            [
              -95.602092,
              33.933176
            ],
            [
              -95.8376,
              33.834591
            ],
            [
              -95.936185,
              33.889361
            ],
            [
              -96.149786,
              33.840068
            ],
            [
              -96.346956,
              33.686714
            ],
            [
              -96.423633,
              33.774345
            ],
            [
              -96.631756,
              33.845545
            ],
            [
              -96.850834,
              33.845545
            ],
            [
              -96.922034,
              33.960561
            ],
            [
              -97.173974,
              33.736006
            ],
            [
              -97.256128,
              33.861976
            ],
            [
              -97.371143,
              33.823637
            ],
            [
              -97.458774,
              33.905791
            ],
            [
              -97.694283,
              33.982469
            ],
            [
              -97.869545,
              33.851022
            ],
            [
              -97.946222,
              33.987946
            ],
            [
              -98.088623,
              34.004376
            ],
            [
              -98.170777,
              34.113915
            ],
            [
              -98.36247,
              34.157731
            ],
            [
              -98.488439,
              34.064623
            ],
            [
              -98.570593,
              34.146777
            ],
            [
              -98.767763,
              34.135823
            ],
            [
              -98.986841,
              34.223454
            ],
            [
              -99.189488,
              34.2125
            ],
            [
              -99.260688,
              34.404193
            ],
            [
              -99.57835,
              34.415147
            ],
            [
              -99.698843,
              34.382285
            ],
            [
              -99.923398,
              34.573978
            ],
            [
              -100.000075,
              34.563024
            ],
            [
              -100.000075,
              36.501861
            ],
            [
              -101.812942,
              36.501861
            ],
            [
              -103.001438,
              36.501861
            ],
            [
              -103.001438,
              37.000263
            ],
            [
              -102.042974,
              36.994786
            ],
            [
              -100.087706,
              37.000263
            ]
          ]
        ],
        "iso_code": "OK"
      },
      {
        "name": "Oregon",
        "id": 38,
        "coordinates": [
          [
            [
              -123.211348,
              46.174138
            ],
            [
              -123.11824,
              46.185092
            ],
            [
              -122.904639,
              46.08103
            ],
            [
              -122.811531,
              45.960537
            ],
            [
              -122.762239,
              45.659305
            ],
            [
              -122.247407,
              45.549767
            ],
            [
              -121.809251,
              45.708598
            ],
            [
              -121.535404,
              45.725029
            ],
            [
              -121.217742,
              45.670259
            ],
            [
              -121.18488,
              45.604536
            ],
            [
              -120.637186,
              45.746937
            ],
            [
              -120.505739,
              45.697644
            ],
            [
              -120.209985,
              45.725029
            ],
            [
              -119.963522,
              45.823614
            ],
            [
              -119.525367,
              45.911245
            ],
            [
              -119.125551,
              45.933153
            ],
            [
              -118.988627,
              45.998876
            ],
            [
              -116.918344,
              45.993399
            ],
            [
              -116.78142,
              45.823614
            ],
            [
              -116.545912,
              45.752413
            ],
            [
              -116.463758,
              45.61549
            ],
            [
              -116.671881,
              45.319735
            ],
            [
              -116.732128,
              45.144473
            ],
            [
              -116.847143,
              45.02398
            ],
            [
              -116.830713,
              44.930872
            ],
            [
              -116.934774,
              44.782995
            ],
            [
              -117.038836,
              44.750133
            ],
            [
              -117.241483,
              44.394132
            ],
            [
              -117.170283,
              44.257209
            ],
            [
              -116.97859,
              44.240778
            ],
            [
              -116.896436,
              44.158624
            ],
            [
              -117.027882,
              43.830007
            ],
            [
              -117.027882,
              42.000709
            ],
            [
              -118.698349,
              41.989755
            ],
            [
              -120.001861,
              41.995232
            ],
            [
              -121.037003,
              41.995232
            ],
            [
              -122.378853,
              42.011663
            ],
            [
              -123.233256,
              42.006186
            ],
            [
              -124.213628,
              42.000709
            ],
            [
              -124.356029,
              42.115725
            ],
            [
              -124.432706,
              42.438865
            ],
            [
              -124.416275,
              42.663419
            ],
            [
              -124.553198,
              42.838681
            ],
            [
              -124.454613,
              43.002989
            ],
            [
              -124.383413,
              43.271359
            ],
            [
              -124.235536,
              43.55616
            ],
            [
              -124.169813,
              43.8081
            ],
            [
              -124.060274,
              44.657025
            ],
            [
              -124.076705,
              44.772041
            ],
            [
              -123.97812,
              45.144473
            ],
            [
              -123.939781,
              45.659305
            ],
            [
              -123.994551,
              45.944106
            ],
            [
              -123.945258,
              46.113892
            ],
            [
              -123.545441,
              46.261769
            ],
            [
              -123.370179,
              46.146753
            ],
            [
              -123.211348,
              46.174138
            ]
          ]
        ],
        "iso_code": "OR"
      },
      {
        "name": "Pennsylvania",
        "id": 39,
        "coordinates": [
          [
            [
              -79.76278,
              42.252649
            ],
            [
              -79.76278,
              42.000709
            ],
            [
              -75.35932,
              42.000709
            ],
            [
              -75.249781,
              41.863786
            ],
            [
              -75.173104,
              41.869263
            ],
            [
              -75.052611,
              41.754247
            ],
            [
              -75.074519,
              41.60637
            ],
            [
              -74.89378,
              41.436584
            ],
            [
              -74.740426,
              41.431108
            ],
            [
              -74.69661,
              41.359907
            ],
            [
              -74.828057,
              41.288707
            ],
            [
              -74.882826,
              41.179168
            ],
            [
              -75.134765,
              40.971045
            ],
            [
              -75.052611,
              40.866983
            ],
            [
              -75.205966,
              40.691721
            ],
            [
              -75.195012,
              40.576705
            ],
            [
              -75.069042,
              40.543843
            ],
            [
              -75.058088,
              40.417874
            ],
            [
              -74.773287,
              40.215227
            ],
            [
              -74.82258,
              40.127596
            ],
            [
              -75.129289,
              39.963288
            ],
            [
              -75.145719,
              39.88661
            ],
            [
              -75.414089,
              39.804456
            ],
            [
              -75.616736,
              39.831841
            ],
            [
              -75.786521,
              39.722302
            ],
            [
              -79.477979,
              39.722302
            ],
            [
              -80.518598,
              39.722302
            ],
            [
              -80.518598,
              40.636951
            ],
            [
              -80.518598,
              41.978802
            ],
            [
              -80.518598,
              41.978802
            ],
            [
              -80.332382,
              42.033571
            ],
            [
              -79.76278,
              42.269079
            ],
            [
              -79.76278,
              42.252649
            ]
          ]
        ],
        "iso_code": "PA"
      },
      {
        "name": "Rhode Island",
        "id": 40,
        "coordinates": [
          [
            [
              -71.196845,
              41.67757
            ],
            [
              -71.120168,
              41.496831
            ],
            [
              -71.317338,
              41.474923
            ],
            [
              -71.196845,
              41.67757
            ]
          ],
          [
            [
              -71.530939,
              42.01714
            ],
            [
              -71.383061,
              42.01714
            ],
            [
              -71.328292,
              41.781632
            ],
            [
              -71.22423,
              41.710431
            ],
            [
              -71.344723,
              41.726862
            ],
            [
              -71.448785,
              41.578985
            ],
            [
              -71.481646,
              41.370861
            ],
            [
              -71.859555,
              41.321569
            ],
            [
              -71.799309,
              41.414677
            ],
            [
              -71.799309,
              42.006186
            ],
            [
              -71.530939,
              42.01714
            ]
          ]
        ],
        "iso_code": "RI"
      },
      {
        "name": "South Carolina",
        "id": 41,
        "coordinates": [
          [
            [
              -82.764143,
              35.066903
            ],
            [
              -82.550543,
              35.160011
            ],
            [
              -82.276696,
              35.198349
            ],
            [
              -81.044384,
              35.149057
            ],
            [
              -81.038907,
              35.044995
            ],
            [
              -80.934845,
              35.105241
            ],
            [
              -80.781491,
              34.935456
            ],
            [
              -80.797922,
              34.820441
            ],
            [
              -79.675149,
              34.80401
            ],
            [
              -78.541422,
              33.851022
            ],
            [
              -78.716684,
              33.80173
            ],
            [
              -78.935762,
              33.637421
            ],
            [
              -79.149363,
              33.380005
            ],
            [
              -79.187701,
              33.171881
            ],
            [
              -79.357487,
              33.007573
            ],
            [
              -79.582041,
              33.007573
            ],
            [
              -79.631334,
              32.887081
            ],
            [
              -79.866842,
              32.755634
            ],
            [
              -79.998289,
              32.613234
            ],
            [
              -80.206412,
              32.552987
            ],
            [
              -80.430967,
              32.399633
            ],
            [
              -80.452875,
              32.328433
            ],
            [
              -80.660998,
              32.246279
            ],
            [
              -80.885553,
              32.032678
            ],
            [
              -81.115584,
              32.120309
            ],
            [
              -81.121061,
              32.290094
            ],
            [
              -81.279893,
              32.558464
            ],
            [
              -81.416816,
              32.629664
            ],
            [
              -81.42777,
              32.843265
            ],
            [
              -81.493493,
              33.007573
            ],
            [
              -81.761863,
              33.160928
            ],
            [
              -81.937125,
              33.347144
            ],
            [
              -81.926172,
              33.462159
            ],
            [
              -82.194542,
              33.631944
            ],
            [
              -82.325988,
              33.81816
            ],
            [
              -82.55602,
              33.94413
            ],
            [
              -82.714851,
              34.152254
            ],
            [
              -82.747713,
              34.26727
            ],
            [
              -82.901067,
              34.486347
            ],
            [
              -83.005129,
              34.469916
            ],
            [
              -83.339222,
              34.683517
            ],
            [
              -83.322791,
              34.787579
            ],
            [
              -83.109191,
              35.00118
            ],
            [
              -82.764143,
              35.066903
            ]
          ]
        ],
        "iso_code": "SC"
      },
      {
        "name": "South Dakota",
        "id": 42,
        "coordinates": [
          [
            [
              -104.047534,
              45.944106
            ],
            [
              -96.560556,
              45.933153
            ],
            [
              -96.582464,
              45.818137
            ],
            [
              -96.856311,
              45.604536
            ],
            [
              -96.681049,
              45.412843
            ],
            [
              -96.451017,
              45.297827
            ],
            [
              -96.451017,
              43.501391
            ],
            [
              -96.582464,
              43.479483
            ],
            [
              -96.527695,
              43.397329
            ],
            [
              -96.560556,
              43.222067
            ],
            [
              -96.434587,
              43.123482
            ],
            [
              -96.511264,
              43.052282
            ],
            [
              -96.544125,
              42.855112
            ],
            [
              -96.631756,
              42.707235
            ],
            [
              -96.44554,
              42.488157
            ],
            [
              -96.626279,
              42.515542
            ],
            [
              -96.692003,
              42.657942
            ],
            [
              -97.217789,
              42.844158
            ],
            [
              -97.688806,
              42.844158
            ],
            [
              -97.831206,
              42.866066
            ],
            [
              -97.951699,
              42.767481
            ],
            [
              -98.466531,
              42.94822
            ],
            [
              -98.499393,
              42.997512
            ],
            [
              -101.626726,
              42.997512
            ],
            [
              -103.324578,
              43.002989
            ],
            [
              -104.053011,
              43.002989
            ],
            [
              -104.058488,
              44.996596
            ],
            [
              -104.042057,
              44.996596
            ],
            [
              -104.047534,
              45.944106
            ]
          ]
        ],
        "iso_code": "SD"
      },
      {
        "name": "Tennessee",
        "id": 43,
        "coordinates": [
          [
            [
              -88.054868,
              36.496384
            ],
            [
              -88.071299,
              36.677123
            ],
            [
              -87.852221,
              36.633308
            ],
            [
              -86.592525,
              36.655216
            ],
            [
              -85.486183,
              36.616877
            ],
            [
              -85.289013,
              36.627831
            ],
            [
              -84.544149,
              36.594969
            ],
            [
              -83.689746,
              36.584015
            ],
            [
              -83.673316,
              36.600446
            ],
            [
              -81.679709,
              36.589492
            ],
            [
              -81.723525,
              36.353984
            ],
            [
              -81.909741,
              36.304691
            ],
            [
              -82.03571,
              36.118475
            ],
            [
              -82.216449,
              36.156814
            ],
            [
              -82.610789,
              35.965121
            ],
            [
              -82.638174,
              36.063706
            ],
            [
              -82.775097,
              35.997983
            ],
            [
              -82.994175,
              35.773428
            ],
            [
              -83.251591,
              35.718659
            ],
            [
              -83.498053,
              35.565304
            ],
            [
              -83.7719,
              35.559827
            ],
            [
              -84.018363,
              35.41195
            ],
            [
              -84.09504,
              35.247642
            ],
            [
              -84.29221,
              35.225734
            ],
            [
              -84.319594,
              34.990226
            ],
            [
              -85.606675,
              34.984749
            ],
            [
              -87.359296,
              35.00118
            ],
            [
              -88.202745,
              34.995703
            ],
            [
              -88.471115,
              34.995703
            ],
            [
              -90.311367,
              34.995703
            ],
            [
              -90.212782,
              35.023087
            ],
            [
              -90.114197,
              35.198349
            ],
            [
              -90.130628,
              35.439335
            ],
            [
              -89.944412,
              35.603643
            ],
            [
              -89.911551,
              35.756997
            ],
            [
              -89.763673,
              35.811767
            ],
            [
              -89.730812,
              35.997983
            ],
            [
              -89.533642,
              36.249922
            ],
            [
              -89.539119,
              36.496384
            ],
            [
              -89.484349,
              36.496384
            ],
            [
              -89.418626,
              36.496384
            ],
            [
              -89.298133,
              36.507338
            ],
            [
              -88.054868,
              36.496384
            ]
          ]
        ],
        "iso_code": "TN"
      },
      {
        "name": "Texas",
        "id": 44,
        "coordinates": [
          [
            [
              -101.812942,
              36.501861
            ],
            [
              -100.000075,
              36.501861
            ],
            [
              -100.000075,
              34.563024
            ],
            [
              -99.923398,
              34.573978
            ],
            [
              -99.698843,
              34.382285
            ],
            [
              -99.57835,
              34.415147
            ],
            [
              -99.260688,
              34.404193
            ],
            [
              -99.189488,
              34.2125
            ],
            [
              -98.986841,
              34.223454
            ],
            [
              -98.767763,
              34.135823
            ],
            [
              -98.570593,
              34.146777
            ],
            [
              -98.488439,
              34.064623
            ],
            [
              -98.36247,
              34.157731
            ],
            [
              -98.170777,
              34.113915
            ],
            [
              -98.088623,
              34.004376
            ],
            [
              -97.946222,
              33.987946
            ],
            [
              -97.869545,
              33.851022
            ],
            [
              -97.694283,
              33.982469
            ],
            [
              -97.458774,
              33.905791
            ],
            [
              -97.371143,
              33.823637
            ],
            [
              -97.256128,
              33.861976
            ],
            [
              -97.173974,
              33.736006
            ],
            [
              -96.922034,
              33.960561
            ],
            [
              -96.850834,
              33.845545
            ],
            [
              -96.631756,
              33.845545
            ],
            [
              -96.423633,
              33.774345
            ],
            [
              -96.346956,
              33.686714
            ],
            [
              -96.149786,
              33.840068
            ],
            [
              -95.936185,
              33.889361
            ],
            [
              -95.8376,
              33.834591
            ],
            [
              -95.602092,
              33.933176
            ],
            [
              -95.547322,
              33.878407
            ],
            [
              -95.289906,
              33.87293
            ],
            [
              -95.224183,
              33.960561
            ],
            [
              -94.966767,
              33.861976
            ],
            [
              -94.868182,
              33.74696
            ],
            [
              -94.484796,
              33.637421
            ],
            [
              -94.380734,
              33.544313
            ],
            [
              -94.183564,
              33.593606
            ],
            [
              -94.041164,
              33.54979
            ],
            [
              -94.041164,
              33.018527
            ],
            [
              -94.041164,
              31.994339
            ],
            [
              -93.822086,
              31.775262
            ],
            [
              -93.816609,
              31.556184
            ],
            [
              -93.542762,
              31.15089
            ],
            [
              -93.526331,
              30.93729
            ],
            [
              -93.630393,
              30.679874
            ],
            [
              -93.728978,
              30.575812
            ],
            [
              -93.696116,
              30.438888
            ],
            [
              -93.767317,
              30.334826
            ],
            [
              -93.690639,
              30.143133
            ],
            [
              -93.926148,
              29.787132
            ],
            [
              -93.838517,
              29.688547
            ],
            [
              -94.002825,
              29.68307
            ],
            [
              -94.523134,
              29.546147
            ],
            [
              -94.70935,
              29.622824
            ],
            [
              -94.742212,
              29.787132
            ],
            [
              -94.873659,
              29.672117
            ],
            [
              -94.966767,
              29.699501
            ],
            [
              -95.016059,
              29.557101
            ],
            [
              -94.911997,
              29.496854
            ],
            [
              -94.895566,
              29.310638
            ],
            [
              -95.081782,
              29.113469
            ],
            [
              -95.383014,
              28.867006
            ],
            [
              -95.985477,
              28.604113
            ],
            [
              -96.045724,
              28.647929
            ],
            [
              -96.226463,
              28.582205
            ],
            [
              -96.23194,
              28.642452
            ],
            [
              -96.478402,
              28.598636
            ],
            [
              -96.593418,
              28.724606
            ],
            [
              -96.664618,
              28.697221
            ],
            [
              -96.401725,
              28.439805
            ],
            [
              -96.593418,
              28.357651
            ],
            [
              -96.774157,
              28.406943
            ],
            [
              -96.801542,
              28.226204
            ],
            [
              -97.026096,
              28.039988
            ],
            [
              -97.256128,
              27.694941
            ],
            [
              -97.404005,
              27.333463
            ],
            [
              -97.513544,
              27.360848
            ],
            [
              -97.540929,
              27.229401
            ],
            [
              -97.425913,
              27.262263
            ],
            [
              -97.480682,
              26.99937
            ],
            [
              -97.557359,
              26.988416
            ],
            [
              -97.562836,
              26.840538
            ],
            [
              -97.469728,
              26.758384
            ],
            [
              -97.442344,
              26.457153
            ],
            [
              -97.332805,
              26.353091
            ],
            [
              -97.30542,
              26.161398
            ],
            [
              -97.217789,
              25.991613
            ],
            [
              -97.524498,
              25.887551
            ],
            [
              -97.650467,
              26.018997
            ],
            [
              -97.885976,
              26.06829
            ],
            [
              -98.198161,
              26.057336
            ],
            [
              -98.466531,
              26.221644
            ],
            [
              -98.669178,
              26.238075
            ],
            [
              -98.822533,
              26.369522
            ],
            [
              -99.030656,
              26.413337
            ],
            [
              -99.173057,
              26.539307
            ],
            [
              -99.266165,
              26.840538
            ],
            [
              -99.446904,
              27.021277
            ],
            [
              -99.424996,
              27.174632
            ],
            [
              -99.50715,
              27.33894
            ],
            [
              -99.479765,
              27.48134
            ],
            [
              -99.605735,
              27.640172
            ],
            [
              -99.709797,
              27.656603
            ],
            [
              -99.879582,
              27.799003
            ],
            [
              -99.934351,
              27.979742
            ],
            [
              -100.082229,
              28.14405
            ],
            [
              -100.29583,
              28.280974
            ],
            [
              -100.399891,
              28.582205
            ],
            [
              -100.498476,
              28.66436
            ],
            [
              -100.629923,
              28.905345
            ],
            [
              -100.673738,
              29.102515
            ],
            [
              -100.799708,
              29.244915
            ],
            [
              -101.013309,
              29.370885
            ],
            [
              -101.062601,
              29.458516
            ],
            [
              -101.259771,
              29.535193
            ],
            [
              -101.413125,
              29.754271
            ],
            [
              -101.851281,
              29.803563
            ],
            [
              -102.114174,
              29.792609
            ],
            [
              -102.338728,
              29.869286
            ],
            [
              -102.388021,
              29.765225
            ],
            [
              -102.629006,
              29.732363
            ],
            [
              -102.809745,
              29.524239
            ],
            [
              -102.919284,
              29.190146
            ],
            [
              -102.97953,
              29.184669
            ],
            [
              -103.116454,
              28.987499
            ],
            [
              -103.280762,
              28.982022
            ],
            [
              -103.527224,
              29.135376
            ],
            [
              -104.146119,
              29.381839
            ],
            [
              -104.266611,
              29.513285
            ],
            [
              -104.507597,
              29.639255
            ],
            [
              -104.677382,
              29.924056
            ],
            [
              -104.688336,
              30.181472
            ],
            [
              -104.858121,
              30.389596
            ],
            [
              -104.896459,
              30.570335
            ],
            [
              -105.005998,
              30.685351
            ],
            [
              -105.394861,
              30.855136
            ],
            [
              -105.602985,
              31.085167
            ],
            [
              -105.77277,
              31.167321
            ],
            [
              -105.953509,
              31.364491
            ],
            [
              -106.205448,
              31.468553
            ],
            [
              -106.38071,
              31.731446
            ],
            [
              -106.528588,
              31.786216
            ],
            [
              -106.643603,
              31.901231
            ],
            [
              -106.616219,
              31.999816
            ],
            [
              -103.067161,
              31.999816
            ],
            [
              -103.067161,
              33.002096
            ],
            [
              -103.045254,
              34.01533
            ],
            [
              -103.039777,
              36.501861
            ],
            [
              -103.001438,
              36.501861
            ],
            [
              -101.812942,
              36.501861
            ]
          ]
        ],
        "iso_code": "TX"
      },
      {
        "name": "Utah",
        "id": 45,
        "coordinates": [
          [
            [
              -112.164359,
              41.995232
            ],
            [
              -111.047063,
              42.000709
            ],
            [
              -111.047063,
              40.998429
            ],
            [
              -109.04798,
              40.998429
            ],
            [
              -109.053457,
              39.125316
            ],
            [
              -109.058934,
              38.27639
            ],
            [
              -109.042503,
              38.166851
            ],
            [
              -109.042503,
              37.000263
            ],
            [
              -110.499369,
              37.00574
            ],
            [
              -114.048427,
              37.000263
            ],
            [
              -114.04295,
              41.995232
            ],
            [
              -112.164359,
              41.995232
            ]
          ]
        ],
        "iso_code": "UT"
      },
      {
        "name": "Vermont",
        "id": 46,
        "coordinates": [
          [
            [
              -71.503554,
              45.013027
            ],
            [
              -71.4926,
              44.914442
            ],
            [
              -71.629524,
              44.750133
            ],
            [
              -71.536416,
              44.585825
            ],
            [
              -71.700724,
              44.41604
            ],
            [
              -72.034817,
              44.322932
            ],
            [
              -72.02934,
              44.07647
            ],
            [
              -72.116971,
              43.994316
            ],
            [
              -72.204602,
              43.769761
            ],
            [
              -72.379864,
              43.572591
            ],
            [
              -72.456542,
              43.150867
            ],
            [
              -72.445588,
              43.008466
            ],
            [
              -72.533219,
              42.953697
            ],
            [
              -72.544173,
              42.80582
            ],
            [
              -72.456542,
              42.729142
            ],
            [
              -73.267129,
              42.745573
            ],
            [
              -73.278083,
              42.833204
            ],
            [
              -73.245221,
              43.523299
            ],
            [
              -73.404052,
              43.687607
            ],
            [
              -73.349283,
              43.769761
            ],
            [
              -73.436914,
              44.043608
            ],
            [
              -73.321898,
              44.246255
            ],
            [
              -73.294514,
              44.437948
            ],
            [
              -73.387622,
              44.618687
            ],
            [
              -73.332852,
              44.804903
            ],
            [
              -73.343806,
              45.013027
            ],
            [
              -72.308664,
              45.002073
            ],
            [
              -71.503554,
              45.013027
            ]
          ]
        ],
        "iso_code": "VT"
      },
      {
        "name": "Virginia",
        "id": 47,
        "coordinates": [
          [
            [
              -75.397659,
              38.013497
            ],
            [
              -75.244304,
              38.029928
            ],
            [
              -75.375751,
              37.860142
            ],
            [
              -75.512674,
              37.799896
            ],
            [
              -75.594828,
              37.569865
            ],
            [
              -75.802952,
              37.197433
            ],
            [
              -75.972737,
              37.120755
            ],
            [
              -76.027507,
              37.257679
            ],
            [
              -75.939876,
              37.564388
            ],
            [
              -75.671506,
              37.95325
            ],
            [
              -75.397659,
              38.013497
            ]
          ],
          [
            [
              -76.016553,
              37.95325
            ],
            [
              -75.994645,
              37.95325
            ],
            [
              -76.043938,
              37.95325
            ],
            [
              -76.016553,
              37.95325
            ]
          ],
          [
            [
              -78.349729,
              39.464886
            ],
            [
              -77.82942,
              39.130793
            ],
            [
              -77.719881,
              39.322485
            ],
            [
              -77.566527,
              39.306055
            ],
            [
              -77.456988,
              39.223901
            ],
            [
              -77.456988,
              39.076023
            ],
            [
              -77.248864,
              39.026731
            ],
            [
              -77.117418,
              38.933623
            ],
            [
              -77.040741,
              38.791222
            ],
            [
              -77.128372,
              38.632391
            ],
            [
              -77.248864,
              38.588575
            ],
            [
              -77.325542,
              38.446175
            ],
            [
              -77.281726,
              38.342113
            ],
            [
              -77.013356,
              38.374975
            ],
            [
              -76.964064,
              38.216144
            ],
            [
              -76.613539,
              38.15042
            ],
            [
              -76.514954,
              38.024451
            ],
            [
              -76.235631,
              37.887527
            ],
            [
              -76.3616,
              37.608203
            ],
            [
              -76.246584,
              37.389126
            ],
            [
              -76.383508,
              37.285064
            ],
            [
              -76.399939,
              37.159094
            ],
            [
              -76.273969,
              37.082417
            ],
            [
              -76.410893,
              36.961924
            ],
            [
              -76.619016,
              37.120755
            ],
            [
              -76.668309,
              37.065986
            ],
            [
              -76.48757,
              36.95097
            ],
            [
              -75.994645,
              36.923586
            ],
            [
              -75.868676,
              36.551154
            ],
            [
              -79.510841,
              36.5402
            ],
            [
              -80.294043,
              36.545677
            ],
            [
              -80.978661,
              36.562108
            ],
            [
              -81.679709,
              36.589492
            ],
            [
              -83.673316,
              36.600446
            ],
            [
              -83.136575,
              36.742847
            ],
            [
              -83.070852,
              36.852385
            ],
            [
              -82.879159,
              36.890724
            ],
            [
              -82.868205,
              36.978355
            ],
            [
              -82.720328,
              37.044078
            ],
            [
              -82.720328,
              37.120755
            ],
            [
              -82.353373,
              37.268633
            ],
            [
              -81.969987,
              37.537003
            ],
            [
              -81.986418,
              37.454849
            ],
            [
              -81.849494,
              37.285064
            ],
            [
              -81.679709,
              37.20291
            ],
            [
              -81.55374,
              37.208387
            ],
            [
              -81.362047,
              37.339833
            ],
            [
              -81.225123,
              37.235771
            ],
            [
              -80.967707,
              37.290541
            ],
            [
              -80.513121,
              37.482234
            ],
            [
              -80.474782,
              37.421987
            ],
            [
              -80.29952,
              37.509618
            ],
            [
              -80.294043,
              37.690357
            ],
            [
              -80.184505,
              37.849189
            ],
            [
              -79.998289,
              37.997066
            ],
            [
              -79.921611,
              38.177805
            ],
            [
              -79.724442,
              38.364021
            ],
            [
              -79.647764,
              38.594052
            ],
            [
              -79.477979,
              38.457129
            ],
            [
              -79.313671,
              38.413313
            ],
            [
              -79.209609,
              38.495467
            ],
            [
              -78.996008,
              38.851469
            ],
            [
              -78.870039,
              38.763838
            ],
            [
              -78.404499,
              39.169131
            ],
            [
              -78.349729,
              39.464886
            ]
          ]
        ],
        "iso_code": "VA"
      },
      {
        "name": "Washington",
        "id": 48,
        "coordinates": [
          [
            [
              -117.033359,
              49.000239
            ],
            [
              -117.044313,
              47.762451
            ],
            [
              -117.038836,
              46.426077
            ],
            [
              -117.055267,
              46.343923
            ],
            [
              -116.92382,
              46.168661
            ],
            [
              -116.918344,
              45.993399
            ],
            [
              -118.988627,
              45.998876
            ],
            [
              -119.125551,
              45.933153
            ],
            [
              -119.525367,
              45.911245
            ],
            [
              -119.963522,
              45.823614
            ],
            [
              -120.209985,
              45.725029
            ],
            [
              -120.505739,
              45.697644
            ],
            [
              -120.637186,
              45.746937
            ],
            [
              -121.18488,
              45.604536
            ],
            [
              -121.217742,
              45.670259
            ],
            [
              -121.535404,
              45.725029
            ],
            [
              -121.809251,
              45.708598
            ],
            [
              -122.247407,
              45.549767
            ],
            [
              -122.762239,
              45.659305
            ],
            [
              -122.811531,
              45.960537
            ],
            [
              -122.904639,
              46.08103
            ],
            [
              -123.11824,
              46.185092
            ],
            [
              -123.211348,
              46.174138
            ],
            [
              -123.370179,
              46.146753
            ],
            [
              -123.545441,
              46.261769
            ],
            [
              -123.72618,
              46.300108
            ],
            [
              -123.874058,
              46.239861
            ],
            [
              -124.065751,
              46.327492
            ],
            [
              -124.027412,
              46.464416
            ],
            [
              -123.895966,
              46.535616
            ],
            [
              -124.098612,
              46.74374
            ],
            [
              -124.235536,
              47.285957
            ],
            [
              -124.31769,
              47.357157
            ],
            [
              -124.427229,
              47.740543
            ],
            [
              -124.624399,
              47.88842
            ],
            [
              -124.706553,
              48.184175
            ],
            [
              -124.597014,
              48.381345
            ],
            [
              -124.394367,
              48.288237
            ],
            [
              -123.983597,
              48.162267
            ],
            [
              -123.704273,
              48.167744
            ],
            [
              -123.424949,
              48.118452
            ],
            [
              -123.162056,
              48.167744
            ],
            [
              -123.036086,
              48.080113
            ],
            [
              -122.800578,
              48.08559
            ],
            [
              -122.636269,
              47.866512
            ],
            [
              -122.515777,
              47.882943
            ],
            [
              -122.493869,
              47.587189
            ],
            [
              -122.422669,
              47.318818
            ],
            [
              -122.324084,
              47.346203
            ],
            [
              -122.422669,
              47.576235
            ],
            [
              -122.395284,
              47.800789
            ],
            [
              -122.230976,
              48.030821
            ],
            [
              -122.362422,
              48.123929
            ],
            [
              -122.373376,
              48.288237
            ],
            [
              -122.471961,
              48.468976
            ],
            [
              -122.422669,
              48.600422
            ],
            [
              -122.488392,
              48.753777
            ],
            [
              -122.647223,
              48.775685
            ],
            [
              -122.795101,
              48.8907
            ],
            [
              -122.756762,
              49.000239
            ],
            [
              -117.033359,
              49.000239
            ]
          ],
          [
            [
              -122.718423,
              48.310145
            ],
            [
              -122.586977,
              48.35396
            ],
            [
              -122.608885,
              48.151313
            ],
            [
              -122.767716,
              48.227991
            ],
            [
              -122.718423,
              48.310145
            ]
          ],
          [
            [
              -123.025132,
              48.583992
            ],
            [
              -122.915593,
              48.715438
            ],
            [
              -122.767716,
              48.556607
            ],
            [
              -122.811531,
              48.419683
            ],
            [
              -123.041563,
              48.458022
            ],
            [
              -123.025132,
              48.583992
            ]
          ]
        ],
        "iso_code": "WA"
      },
      {
        "name": "West Virginia",
        "id": 49,
        "coordinates": [
          [
            [
              -80.518598,
              40.636951
            ],
            [
              -80.518598,
              39.722302
            ],
            [
              -79.477979,
              39.722302
            ],
            [
              -79.488933,
              39.20747
            ],
            [
              -79.291763,
              39.300578
            ],
            [
              -79.094593,
              39.470363
            ],
            [
              -78.963147,
              39.437501
            ],
            [
              -78.765977,
              39.585379
            ],
            [
              -78.470222,
              39.514178
            ],
            [
              -78.431884,
              39.623717
            ],
            [
              -78.267575,
              39.61824
            ],
            [
              -78.174467,
              39.694917
            ],
            [
              -78.004682,
              39.601809
            ],
            [
              -77.834897,
              39.601809
            ],
            [
              -77.719881,
              39.322485
            ],
            [
              -77.82942,
              39.130793
            ],
            [
              -78.349729,
              39.464886
            ],
            [
              -78.404499,
              39.169131
            ],
            [
              -78.870039,
              38.763838
            ],
            [
              -78.996008,
              38.851469
            ],
            [
              -79.209609,
              38.495467
            ],
            [
              -79.313671,
              38.413313
            ],
            [
              -79.477979,
              38.457129
            ],
            [
              -79.647764,
              38.594052
            ],
            [
              -79.724442,
              38.364021
            ],
            [
              -79.921611,
              38.177805
            ],
            [
              -79.998289,
              37.997066
            ],
            [
              -80.184505,
              37.849189
            ],
            [
              -80.294043,
              37.690357
            ],
            [
              -80.29952,
              37.509618
            ],
            [
              -80.474782,
              37.421987
            ],
            [
              -80.513121,
              37.482234
            ],
            [
              -80.967707,
              37.290541
            ],
            [
              -81.225123,
              37.235771
            ],
            [
              -81.362047,
              37.339833
            ],
            [
              -81.55374,
              37.208387
            ],
            [
              -81.679709,
              37.20291
            ],
            [
              -81.849494,
              37.285064
            ],
            [
              -81.986418,
              37.454849
            ],
            [
              -81.969987,
              37.537003
            ],
            [
              -82.101434,
              37.553434
            ],
            [
              -82.293127,
              37.668449
            ],
            [
              -82.342419,
              37.783465
            ],
            [
              -82.50125,
              37.931343
            ],
            [
              -82.621743,
              38.123036
            ],
            [
              -82.594358,
              38.424267
            ],
            [
              -82.331465,
              38.446175
            ],
            [
              -82.293127,
              38.577622
            ],
            [
              -82.172634,
              38.632391
            ],
            [
              -82.221926,
              38.785745
            ],
            [
              -82.03571,
              39.026731
            ],
            [
              -81.887833,
              38.873376
            ],
            [
              -81.783771,
              38.966484
            ],
            [
              -81.811156,
              39.0815
            ],
            [
              -81.685186,
              39.273193
            ],
            [
              -81.57017,
              39.267716
            ],
            [
              -81.455155,
              39.410117
            ],
            [
              -81.345616,
              39.344393
            ],
            [
              -81.219646,
              39.388209
            ],
            [
              -80.830783,
              39.711348
            ],
            [
              -80.737675,
              40.078303
            ],
            [
              -80.600752,
              40.319289
            ],
            [
              -80.595275,
              40.472643
            ],
            [
              -80.666475,
              40.582182
            ],
            [
              -80.518598,
              40.636951
            ]
          ]
        ],
        "iso_code": "WV"
      },
      {
        "name": "Wisconsin",
        "id": 50,
        "coordinates": [
          [
            [
              -90.415429,
              46.568478
            ],
            [
              -90.229213,
              46.508231
            ],
            [
              -90.119674,
              46.338446
            ],
            [
              -89.09001,
              46.135799
            ],
            [
              -88.662808,
              45.987922
            ],
            [
              -88.531362,
              46.020784
            ],
            [
              -88.10416,
              45.922199
            ],
            [
              -87.989145,
              45.796229
            ],
            [
              -87.781021,
              45.675736
            ],
            [
              -87.791975,
              45.500474
            ],
            [
              -87.885083,
              45.363551
            ],
            [
              -87.649574,
              45.341643
            ],
            [
              -87.742682,
              45.199243
            ],
            [
              -87.589328,
              45.095181
            ],
            [
              -87.627666,
              44.974688
            ],
            [
              -87.819359,
              44.95278
            ],
            [
              -87.983668,
              44.722749
            ],
            [
              -88.043914,
              44.563917
            ],
            [
              -87.928898,
              44.536533
            ],
            [
              -87.775544,
              44.640595
            ],
            [
              -87.611236,
              44.837764
            ],
            [
              -87.403112,
              44.914442
            ],
            [
              -87.238804,
              45.166381
            ],
            [
              -87.03068,
              45.22115
            ],
            [
              -87.047111,
              45.089704
            ],
            [
              -87.189511,
              44.969211
            ],
            [
              -87.468835,
              44.552964
            ],
            [
              -87.545512,
              44.322932
            ],
            [
              -87.540035,
              44.158624
            ],
            [
              -87.644097,
              44.103854
            ],
            [
              -87.737205,
              43.8793
            ],
            [
              -87.704344,
              43.687607
            ],
            [
              -87.791975,
              43.561637
            ],
            [
              -87.912467,
              43.249452
            ],
            [
              -87.885083,
              43.002989
            ],
            [
              -87.76459,
              42.783912
            ],
            [
              -87.802929,
              42.493634
            ],
            [
              -88.788778,
              42.493634
            ],
            [
              -90.639984,
              42.510065
            ],
            [
              -90.711184,
              42.636034
            ],
            [
              -91.067185,
              42.75105
            ],
            [
              -91.143862,
              42.909881
            ],
            [
              -91.176724,
              43.134436
            ],
            [
              -91.056231,
              43.254929
            ],
            [
              -91.204109,
              43.353514
            ],
            [
              -91.215062,
              43.501391
            ],
            [
              -91.269832,
              43.616407
            ],
            [
              -91.242447,
              43.775238
            ],
            [
              -91.43414,
              43.994316
            ],
            [
              -91.592971,
              44.032654
            ],
            [
              -91.877772,
              44.202439
            ],
            [
              -91.927065,
              44.333886
            ],
            [
              -92.233773,
              44.443425
            ],
            [
              -92.337835,
              44.552964
            ],
            [
              -92.545959,
              44.569394
            ],
            [
              -92.808852,
              44.750133
            ],
            [
              -92.737652,
              45.117088
            ],
            [
              -92.75956,
              45.286874
            ],
            [
              -92.644544,
              45.440228
            ],
            [
              -92.770513,
              45.566198
            ],
            [
              -92.885529,
              45.577151
            ],
            [
              -92.869098,
              45.719552
            ],
            [
              -92.639067,
              45.933153
            ],
            [
              -92.354266,
              46.015307
            ],
            [
              -92.29402,
              46.075553
            ],
            [
              -92.29402,
              46.667063
            ],
            [
              -92.091373,
              46.749217
            ],
            [
              -92.014696,
              46.705401
            ],
            [
              -91.790141,
              46.694447
            ],
            [
              -91.09457,
              46.864232
            ],
            [
              -90.837154,
              46.95734
            ],
            [
              -90.749522,
              46.88614
            ],
            [
              -90.886446,
              46.754694
            ],
            [
              -90.55783,
              46.584908
            ],
            [
              -90.415429,
              46.568478
            ]
          ]
        ],
        "iso_code": "WI"
      },
      {
        "name": "Wyoming",
        "id": 51,
        "coordinates": [
          [
            [
              -109.080842,
              45.002073
            ],
            [
              -105.91517,
              45.002073
            ],
            [
              -104.058488,
              44.996596
            ],
            [
              -104.053011,
              43.002989
            ],
            [
              -104.053011,
              41.003906
            ],
            [
              -105.728954,
              40.998429
            ],
            [
              -107.919731,
              41.003906
            ],
            [
              -109.04798,
              40.998429
            ],
            [
              -111.047063,
              40.998429
            ],
            [
              -111.047063,
              42.000709
            ],
            [
              -111.047063,
              44.476286
            ],
            [
              -111.05254,
              45.002073
            ],
            [
              -109.080842,
              45.002073
            ]
          ]
        ],
        "iso_code": "WY"
      },
      {
        "name": "Puerto Rico",
        "id": 52,
        "coordinates": [
          [
            [
              -66.448338,
              17.984326
            ],
            [
              -66.771478,
              18.006234
            ],
            [
              -66.924832,
              17.929556
            ],
            [
              -66.985078,
              17.973372
            ],
            [
              -67.209633,
              17.956941
            ],
            [
              -67.154863,
              18.19245
            ],
            [
              -67.269879,
              18.362235
            ],
            [
              -67.094617,
              18.515589
            ],
            [
              -66.957694,
              18.488204
            ],
            [
              -66.409999,
              18.488204
            ],
            [
              -65.840398,
              18.433435
            ],
            [
              -65.632274,
              18.367712
            ],
            [
              -65.626797,
              18.203403
            ],
            [
              -65.730859,
              18.186973
            ],
            [
              -65.834921,
              18.017187
            ],
            [
              -66.234737,
              17.929556
            ],
            [
              -66.448338,
              17.984326
            ]
          ]
        ],
        "iso_code": "PR"
      }
    ]
  }
}
